import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { WebExperimentReducer } from './web-experimentation.reducer';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('web-experimentation', WebExperimentReducer)],
})
export class WebExperimentationModule {}
