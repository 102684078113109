import { Inject, Injectable } from '@angular/core';
import { Store, createSelector, select } from '@ngrx/store';
import { get } from 'lodash-es';
import { WINDOW } from 'src/app/core/injection-token/window/window';
import { Observable, catchError, from, switchMap, take, tap } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { selectAppConfig } from 'src/app/core/application-config/application-config.selectors';
import { RoktConfiguration } from 'src/app/core/application-config/application-config.state';
import { selectIsProduction } from 'src/app/core/environment/environment.selectors';
import { selectParentConfig } from 'src/app/core/parent-config/parent-config.selectors';
import { APP_CONFIG_ROKT } from 'src/app/shared/enums/application-config.enum';
import { toObservable } from 'src/app/shared/utilities/observable.utils';
import { addScript } from 'src/app/shared/utilities/script-loader.utils';
import { validString } from 'src/app/shared/utilities/types.utils';
import { createAdPlacementLauncherAction } from './ad-placement.actions';

@Injectable({
  providedIn: 'root',
})
export class AdPlacementService {
  /**
   * Prod environment
   */
  private isProd: boolean = false;
  /**
   * Rokt app configuration
   */
  private rokt: RoktConfiguration = null;
  /**
   * Ad service enabled
   */
  private enabled: boolean = false;
  /**
   * Rokt ad launcher
   */
  private roktLauncher: AP_RoktIntegrationLauncher = null;

  /**
   * Constructor
   * @param window
   * @param store
   */
  constructor(@Inject(WINDOW) private window: Window, private store: Store<AppState>) {
    this.store
      .pipe(
        select(
          createSelector(
            selectAppConfig([APP_CONFIG_ROKT]),
            selectParentConfig,
            selectIsProduction,
            ({ [APP_CONFIG_ROKT]: rokt = null }, { config }, isProduction) => ({ rokt, config, isProduction })
          )
        ),
        take(1)
      )
      .subscribe(({ rokt = null, config: parentConfig, isProduction = false }) => {
        this.rokt = rokt;
        this.isProd = isProduction;
      });
  }

  /**
   * Return SDK instance
   */
  get instance(): AP_RoktSDK {
    return get(this.window, 'Rokt', null) as AP_RoktSDK;
  }

  /**
   * Return if service is active/enabled.
   * Should be triggered when script is loaded
   */
  get isEnabled(): boolean {
    return !!this.instance;
  }

  /**
   * Return ad launcher instance.
   */
  get launcher(): Observable<AP_RoktIntegrationLauncher> {
    return this.roktLauncher ? toObservable(this.roktLauncher) : this.createLauncher();
  }

  /**
   * Load ROKT SDK script
   */
  loadScript(): Observable<boolean> {
    if (!this.instance && this.rokt?.enable) {
      return addScript('https://apps.rokt.com/wsdk/integrations/launcher.js', true, {
        id: 'rokt-launcher',
        fetchpriority: 'high',
        crossOrigin: 'anonymous',
        type: 'text/javascript',
      }).pipe(
        switchMap(() => toObservable(this.isEnabled)),
        tap((isLoaded: boolean) => {
          if (isLoaded) {
            this.store.dispatch(createAdPlacementLauncherAction());
          }
        }),
        catchError((err) => toObservable(false))
      );
    } else {
      return toObservable(this.isEnabled);
    }
  }

  /**
   * ROKT launcher
   */
  createLauncher(): Observable<AP_RoktIntegrationLauncher> {
    return from(
      this.instance.createLauncher({
        accountId: this.rokt.accountId,
        sandbox: !this.isProd,
      })
    ).pipe(
      take(1),
      tap((launcher) => {
        this.roktLauncher = launcher;
      })
    );
  }
}
