import { validString } from 'src/app/shared/utilities/types.utils';
import { AmazonPayLedgerCurrency, AmazonPayLocales, AmazonPayRegion } from './amazon-pay.enums';
import { AmazonPayApplicationConfig } from 'src/app/core/application-config/application-config.state';
import { getLocaleLanguage } from 'src/app/core/locale/locale.utils';

/**
 * Validates Amazon Pay region
 * @param region amazon pay region
 */
export function isValidAmazonPayRegion(region: string): boolean {
  return validString(region) && Object.values(AmazonPayRegion).includes(region.toLowerCase() as AmazonPayRegion);
}

/**
 * Validates Amazon Pay ledger currency
 * @param currency checkout currency
 */
export function isValidAmazonPayCurrency(currency: string): boolean {
  return validString(currency) && Object.values(AmazonPayLedgerCurrency).includes(currency.toUpperCase() as AmazonPayLedgerCurrency);
}

/**
 * Format language for Amazon Pay SDK
 * @param lang language locale
 */
export function formatLanguage(lang: string): string {
  return validString(lang) ? lang.replace('-', '_') : '';
}

/**
 * Validates languge for Amazon Pay
 * @param lang accessoPay locale
 */
export function isValidAmazonPayLocale(lang: string): boolean {
  const amazonPayLanguages = Object.values(AmazonPayLocales).flat();
  return validString(lang) && amazonPayLanguages.includes(lang);
}

/**
 * Get Amazon Pay checkout language
 * @param region Amazon Pay region
 * @param apayLocale accessoPay locale
 * @returns Amazon Pay checkout language
 */
export function getAmazonPayCheckoutLanguage(amazonPayConfig: AmazonPayApplicationConfig, apayLocale: string): AmazonPaySDKCheckoutLanguage {
  const appLang = formatLanguage(apayLocale);

  // app config's locale matching Amazon Pay's locale
  if (isValidAmazonPayLocale(amazonPayConfig?.locale) && isValidAmazonPayLocale(appLang) && amazonPayConfig?.locale === appLang) {
    return amazonPayConfig.locale as AmazonPaySDKCheckoutLanguage;
  }
  // use region to obtain language
  if (isValidAmazonPayRegion(amazonPayConfig.region)) {
    // the region with one language
    if (AmazonPayLocales[amazonPayConfig.region]?.length === 1) {
      return AmazonPayLocales[amazonPayConfig.region][0];
    }
    // the region with multiple languages
    else {
      const apayLang = getLocaleLanguage(apayLocale);
      return (
        AmazonPayLocales[amazonPayConfig.region].find((locale) => validString(apayLang) && locale.includes(apayLang)) ??
        AmazonPayLocales[amazonPayConfig.region][0]
      );
    }
  }
  return 'en_US';
}
