/**
 * Determine if a DOM contains modal element
 * @returns has modal element
 */
export const hasModal = (): boolean => {
  return hasUpModal();
};

/**
 * Check if a DOM contains Uplift modal element
 * @returns has Uplift modal element
 */
export const hasUpModal = (): boolean => {
  const upModalBackdropDisplay = window.document.getElementById('up-modal-backdrop')?.style.display ?? '';
  return !!upModalBackdropDisplay && upModalBackdropDisplay !== 'none';
};
