export interface ParentConfigState {
  /**
   * If the parent config loaded or not.
   */
  loaded: boolean;
  /**
   * If there was an error loading.
   */
  errorLoading: boolean;
  /**
   * The parent configs session id
   */
  sessionId: string;
  /**
   * The actual configuration the parent sent wants.
   */
  config: ParentConfig;
}

export interface ParentConfig {
  cartId?: string;
  /**
   * If there was an error in the config, this will be the error code.
   */
  configErrorCode?: number;
  /**
   * The amount.
   */
  amount: number;
  /**
   * The order id.
   */
  orderId?: string | number;
  /**
   * The parent configs session id for native applications
   */
  sessionId?: string;
  /**
   * The tax reference id associated with the transaction
   */
  taxReferenceId?: string | number;
  /**
   * The credit card configuration.
   */
  creditCard?: Partial<CreditCardConfig>;
  /**
   * The gift card configuration.
   */
  giftCard?: Partial<GiftCardConfig>;
  /**
   * Currency code
   */
  currencyCode?: string;
  /**
   * Is this a recurring payment?
   */
  recurring?: boolean;
  /**
   * If accessoPay should support multiple payments
   */
  supportMultiplePayments?: boolean;
  /**
   * The different line items.
   */
  lineItems?: Array<LineItem | DisplayItem>;
  /**
   * The origin URL. This is the top-level domain if you're application is running within an iframe.
   * @example
   *  https://someurl.com -- TOP LEVEL (This is the origin URL we'd need)
   *    -> iframe -> Your Application
   *      -> iframe -> AccessoPay Iframe
   */
  originUrl?: string;
  /**
   * The parent application's URL.
   * @example
   *  https://someurl.com
   *    -> iframe -> Your Application (This is the parent URL we'd need)
   *      -> iframe -> AccessoPay Iframe
   */
  parentUrl?: string;
  /**
   * URL used for alternate payments to redirect back to.
   */
  paymentRedirectUrl?: string;
  /**
   * Styling options.
   */
  style?: Partial<Styles>;
  /**
   * The user information.
   */
  user?: Partial<User>;
  /**
   * The Url to be redirected to after payment complete / cancel
   */
  paymentStatusBaseUrl?: string;
  /**
   * Disable payments
   */
  disablePayments?: Partial<DisablePayments>;
  /**
   * Disable checkout features
   */
  disableFeature?: Partial<DisableFeature>;
  /**
   * The delivery options
   */
  delivery?: Partial<Delivery>;
  /**
   * The css global variables
   */
  cssVariables?: CssVariables;
  /**
   * Host App name
   */
  hostAppName?: string;
  /**
   * Unified Design Changes
   */
  unifiedDesign?: UnifiedDesign;
  /**
   * Tesco Promocode
   */
  tescoPromoCode?: string;
}

export interface UnifiedDesign {
  useNewSpinner?: boolean;
}

export interface Delivery {
  /**
   * The Delivery method options
   */
  options: DeliveryOption[];
  /**
   * The default selected delivery option id
   */
  defaultSelectedId: string;
  /**
   * Phone number required for delivery method
   */
  phoneNumberRequired: boolean;
  /**
   * The address to auto fill
   */
  address: Partial<{
    /**
     * The users first name.
     */
    firstName: string;
    /**
     * The users middle initial.
     */
    middleInitial: string;
    /**
     * The users last name.
     */
    lastName: string;
    /**
     * The users phone number.
     */
    phone: string;
    /**
     * The users phone number country code.
     */
    phoneCountryCode: string;
    /**
     * The users email.
     */
    email: string;
    /**
     * The users street address.
     */
    address1: string;
    /**
     * The users suite number.
     */
    address2: string;
    /**
     * The users city.
     */
    city: string;
    /**
     * The users state or province.
     */
    stateProvince: string;
    /**
     * The users country code.
     */
    country: string;
    /**
     * The users zip/postal code.
     */
    zipPostal: string;
  }>;
}

export interface DeliveryOption {
  /**
   * Allowed countries for delivery
   */
  allowedCountries?: string[];
  /**
   * If the delivery option needs address collection
   */
  collectAddress: boolean;
  /**
   * The id for delivery option
   */
  id: string;
  /**
   * The label to be displayed as the option.
   */
  label: string;
  /**
   * A descriptive text that is displayed below the option label.
   */
  description: string;
  /**
   * Additional information about the delivery method
   */
  additionalInfo?: string;
  /**
   * The amount for delivery option
   */
  amount: number;
}

export interface DisablePayments {
  /**
   * Card brands that has to be disabled
   */
  cardBrandName: string[];
  /**
   * Payment method that has to be disabled
   */
  paymentMethodName: string[];
  /**
   * All alternate payment methods has to be disabled
   */
  alternatePayments: boolean;
}

export interface DisableFeature {
  /**
   * Disable checkout donation
   */
  donation?: boolean;
  /**
   * Disable ticket insurance/ticket guardian feature
   */
  ticketInsurance?: boolean;
}

export interface CreditCardConfig {
  /**
   * For stored credit cards, should we require them to enter their CVV?
   */
  requireStoredCVV: boolean;
  /**
   * If you want to require demographics to be filled out for the credit card for anonymous users or authorized users who are using a card.
   */
  requireDemographics: boolean;
  /**
   * If you want to disable save card options
   */
  disableSave?: boolean;
}

export interface GiftCardConfig {
  /**
   * Max number of gift cards allowed.
   */
  maxEntries: number;
}

/**
 * Style Configuration for the merchant
 */
export interface Styles {
  /**
   * The Layout styles
   */
  layout?: {
    /**
     * The background color
     */
    backgroundColor?: string;
    /**
     * The text color
     */
    textColor?: string;
    /**
     * The label color
     */
    labelColor?: string;
    /**
     * The border color
     */
    borderColor?: string;
  };
  /**
   * Button Styles
   */
  buttons?: ButtonsStyle;
  /**
   * Radio button styles
   */
  radio?: RadioStyles;
  /**
   * Checkbox Styles
   */
  checkbox?: CheckboxStyles;
  /**
   * Input, Select Element styles
   */
  input?: InputStyles;
  /**
   * Modal background color
   */
  modalBackgroundColor?: string;
  /**
   * Mat Form Field padding
   */
  formPadding?: {
    /**
     * Padding top
     */
    top: string;
    /**
     * Padding bottom
     */
    bottom: string;
  };
}

export interface CssVariables {
  'gap-primary'?: string;
  'gap-primary-on-surface'?: string;
  'gap-primary-lighter'?: string;
  'gap-primary-darker'?: string;
  'gap-secondary'?: string;
  'gap-secondary-on-surface'?: string;
  'gap-secondary-lighter'?: string;
  'gap-secondary-darker'?: string;
  'gap-tertiary'?: string;
  'gap-tertiary-on-surface'?: string;
  'gap-tertiary-lighter'?: string;
  'gap-tertiary-darker'?: string;
  'gap-error'?: string;
  'gap-error-on-surface'?: string;
  'gap-error-lighter'?: string;
  'gap-error-darker'?: string;
  'gap-success'?: string;
  'gap-success-on-surface'?: string;
  'gap-success-lighter'?: string;
  'gap-success-darker'?: string;
  'gap-font-family'?: string;
  'bar-color'?: string;
}
/**
 * Buttons Style Interface
 */
export interface ButtonsStyle {
  /**
   * Primary button styles
   */
  primary: ButtonStyles;
  /**
   * Primary button active state styles
   */
  primaryActive: ButtonStyles;
  /**
   * Primary button hover state styles
   */
  primaryHover: ButtonStyles;
  /**
   * Default button styles
   */
  default: ButtonStyles;
  /**
   * Default button active state styles
   */
  defaultActive: ButtonStyles;
  /**
   * Default button hover state styles
   */
  defaultHover: ButtonStyles;
  /**
   * Warn button styles
   */
  warn: ButtonStyles;
  /**
   * Warn button active state styles
   */
  warnActive: ButtonStyles;
  /**
   * Warn button hover state styles
   */
  warnHover: ButtonStyles;
}

/**
 * Actual properties that can be configured on buttons
 */
export interface ButtonStyles {
  /**
   * The background color
   */
  backgroundColor?: string;
  /**
   * The text color
   */
  color?: string;
  /**
   * The border color
   */
  borderColor?: string;
}

/**
 * Actual properties that can be configured on checkboxes
 */
export interface CheckboxStyles {
  /**
   * The default color when unchecked
   */
  defaultColor?: string;
  /**
   * The background color when checked
   */
  backgroundColor?: string;
  /**
   * The checked mark color
   */
  color?: string;
}

/**
 * Actual properties that can be configured on radio buttons
 */
export interface RadioStyles {
  /**
   * The default color when the radio button is not selected
   */
  defaultColor?: string;
  /**
   * The background color when selected
   */
  backgroundColor?: string;
  /**
   * The border color when selected
   */
  borderColor?: string;
}

/**
 * Actual properties that can be configured on input, select elements
 */
export interface InputStyles {
  /**
   * Error text color that appears under the field
   */
  errorTextColor?: string;
  /**
   * Styles when input is in error state
   */
  error?: {
    /**
     * The background color
     */
    backgroundColor: string;
    /**
     * The border color
     */
    borderColor: string;
  };
  /**
   * Styles when input is in focused state
   */
  focused?: {
    /**
     * The background color
     */
    backgroundColor: string;
    /**
     * The border color
     */
    borderColor: string;
  };
  /**
   * Styles when input is in default state
   */
  default?: {
    /**
     * The background color
     */
    backgroundColor: string;
    /**
     * The border color
     */
    borderColor: string;
    /**
     * The placeholder color
     */
    placeholderColor: string;
    /**
     * The background color of the element when on hover state
     */
    hoverColor: string;
  };
}

export interface User {
  /**
   * The access token.
   */
  accessToken: string;
  /**
   * The users customer ID.
   */
  customerId: string;
  /**
   * The users first name.
   */
  firstName: string;
  /**
   * The users middle initial.
   */
  middleInitial: string;
  /**
   * The users last name.
   */
  lastName: string;
  /**
   * The users phone number.
   */
  phone: string;
  /**
   * The users phone number dial/country code.
   */
  phoneCountryCode: string;
  /**
   * The users phone number region code.
   */
  phoneRegionCode?: string;
  /**
   * The users email.
   */
  email: string;
  /**
   * The users street address.
   */
  address1: string;
  /**
   * The users suite number.
   */
  address2: string;
  /**
   * The users city.
   */
  city: string;
  /**
   * The users state or province.
   */
  stateProvince: string;
  /**
   * The users country code.
   */
  country: string;
  /**
   * The users zip/postal code.
   */
  zipPostal: string;
  /**
   * The user's date of birth.
   */
  dateOfBirth?: string;
}

export interface LineItem {
  /**
   * Line Item Details.
   */
  details: LineItemDetails;
  /**
   * The type.
   */
  type: 'lineitem';
}

export interface DisplayItem {
  /**
   * Display Item Details
   */
  details: DisplayItemDetails;
  /**
   * The type
   */
  type: 'subtotal' | 'tax' | 'fee' | 'discount' | 'total';
}

export interface DisplayItemDetails {
  /**
   * The label to be displayed
   */
  label: string;
  /**
   * Amount
   */
  amount: number;
  /**
   * Tax for fees
   */
  tax?: number;
}

export interface FeeItemDetails {
  /**
   * The label to be displayed
   */
  name: string;
  /**
   * Amount
   */
  amount: number;
  /**
   * Tax for fees
   */
  tax?: number;
}

export interface LineItemDetails {
  /**
   * The product description
   */
  productDescription?: string;
  /**
   * The product event details
   */
  eventInfo?: Partial<ProductEventDetails>;
  /**
   * The product name
   */
  productName?: string;
  /**
   * Unique identifier for the product
   */
  productSKU?: string;
  /**
   * Quantity of the product
   */
  quantity: number;
  /**
   * Product unit price
   */
  unitPrice: number;
  /**
   * Product tax amount
   */
  taxAmount: number;
  /**
   * Product fee amount
   */
  feeAmount?: number;
}

export interface ProductEventDetails {
  /**
   * The Event id
   */
  id: number | string;
  /**
   * The event name
   */
  name: string;
  /**
   * The event start date in YYYY-MM-DD format
   */
  startDate: string;
  /**
   * The event start time in HH:mm format
   */
  startTime: string;
  /**
   * The event end date in YYYY-MM-DD format
   */
  endDate: string;
  /**
   * The event end time in HH:mm format
   */
  endTime: string;
}

export const initialState: ParentConfigState = {
  loaded: false,
  errorLoading: false,
  sessionId: '',
  config: {
    amount: 0,
  },
};
