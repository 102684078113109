import { Inject, Injectable } from '@angular/core';
import { Store, createSelector, select } from '@ngrx/store';
import { get } from 'lodash-es';
import { AcLoggerService } from 'src/app/core/logger/logger.service';
import { DOCUMENT } from 'src/app/core/injection-token/document/document';
import { WINDOW } from 'src/app/core/injection-token/window/window';
import { Observable, catchError, forkJoin, of, switchMap, tap } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { selectAppConfig } from 'src/app/core/application-config/application-config.selectors';
import { OptimizelyApplicationConfig } from 'src/app/core/application-config/application-config.state';
import { toObservable } from 'src/app/shared/utilities/observable.utils';
import { addScript } from 'src/app/shared/utilities/script-loader.utils';
import { validObject, validString } from 'src/app/shared/utilities/types.utils';
import { APP_CONFIG_OPTIMIZELY } from './../../../shared/enums/application-config.enum';
import { OptimizelyInstance } from './optimizely.state';
import { loadOptimizelyFeatureControllerAction } from '../../web-experimentation/web-experimentation.actions';
import { CookieService } from 'src/app/core/storages/cookie.service';

const OPTIMIZELY_API = 'optimizely';
const OPTIMIZELY_MESSAGE_TIMEOUT = 3000;
const OPTIMIZELY_END_USER_ID_KEY = 'optimizelyEndUserId';

interface OptimizelyControllerMsg {
  source: string;
  disableFeature: { [key: string]: boolean };
}

@Injectable({
  providedIn: 'root',
})
export class OptimizelyService {
  private optimizelyConfigs: OptimizelyApplicationConfig | null = null;
  private isOptimizelyEnabled: boolean = false;
  private optimizelyMessageReceived = false;

  /**
   * Constructor
   * @param window window
   * @param document document
   * @param store our store
   * @param apiService ApiService
   * @param logger AcLoggerService
   * @param localeService AcLoggerService
   */
  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    private store: Store<AppState>,
    private logger: AcLoggerService,
    private cookieService: CookieService
  ) {
    this.store
      .pipe(
        select(
          createSelector(selectAppConfig([APP_CONFIG_OPTIMIZELY]), ({ [APP_CONFIG_OPTIMIZELY]: optimizely = null }) => ({
            optimizely,
          }))
        )
      )
      .subscribe(({ optimizely }) => {
        this.optimizelyConfigs = validObject(optimizely) && optimizely;
        this.isOptimizelyEnabled = this.optimizelyConfigs && optimizely.enable && validString(optimizely.projectId);
      });
  }

  /**
   * Fetch the Optimizely instance from DOM
   */
  get instance(): OptimizelyInstance {
    return get(this.window, 'optimizely', null) as OptimizelyInstance;
  }

  /**
   * Optimizely enabled
   */
  get isEnabled(): boolean {
    return this.isOptimizelyEnabled;
  }

  /**
   * Validate and build script from configs
   */
  private getOptimizelyScript(projectId: string): string {
    if (this.isOptimizelyEnabled) {
      return `https://cdn.optimizely.com/js/${projectId}.js`;
    }
    return '';
  }

  /**
   * Load the Optimizely script
   */
  loadScript(): Observable<boolean> {
    if (this.isOptimizelyEnabled) {
      const scriptsArr = this.optimizelyConfigs?.projectId.replace(/\s/g, '').split(',') ?? [];
      const obsArr = scriptsArr.map((srciptVal, index) => addScript(this.getOptimizelyScript(srciptVal), true, { id: `ap-optimizely-${index}` }));

      return forkJoin(obsArr).pipe(
        tap(() => {
          this.onOptimizelyMessage((msg) => this.handleOptimizelyMessage(msg));
        }),
        switchMap(() => of(true)),
        catchError((err) => {
          this.logger.log('[optimizely] script not loaded ' + err);
          return of(false);
        })
      );
    } else {
      this.logger.log('[optimizely] disabled');
      return toObservable(false);
    }
  }

  /**
   * Handles the Optimizely message by dispatching an action to load the Optimizely feature controller.
   * @param msg - The OptimizelyControllerMsg object containing the message data.
   */
  handleOptimizelyMessage(msg: OptimizelyControllerMsg): void {
    if (msg) {
      this.store.dispatch(loadOptimizelyFeatureControllerAction({ payload: msg.disableFeature }));
    }
  }

  /**
   * Send event to Optimizely
   * @param event Optimizely event
   */
  sendEvent(event: OptimizelyEvent): void {
    if (this.isEnabled && this.instance) {
      this.instance.push(event);
    }
  }

  /**
   * Registers a callback function to be called when an Optimizely message is received.
   * @param callback - The callback function to be called when an Optimizely message is received.
   */
  onOptimizelyMessage(callback: Function): void {
    const optimizelyEventListener = ({ origin: msgOrigin, data }: MessageEvent) => {
      const currOrigin = this.window.location.origin;
      const msg = data as OptimizelyControllerMsg;

      if (msgOrigin === currOrigin && msg.source === OPTIMIZELY_API) {
        if (typeof callback === 'function') {
          callback(msg);
        }
        // Stop listening once controls are received
        this.window.removeEventListener('message', optimizelyEventListener);
        this.optimizelyMessageReceived = true;
      }
    };

    this.window.addEventListener('message', optimizelyEventListener, false);

    // remove listener if waiting too long for Optimizely
    setTimeout(() => {
      if (!this.optimizelyMessageReceived) {
        this.window.removeEventListener('message', optimizelyEventListener);
        callback(null);
      }
    }, OPTIMIZELY_MESSAGE_TIMEOUT);
  }

  /**
   * Get visitor id from cookies
   * @returns Visitor id
   */
  getVisitorId(): string | null {
    if (!this.isOptimizelyEnabled) {
      return null;
    }
    const visitorId = this.cookieService.get(OPTIMIZELY_END_USER_ID_KEY);
    return validString(visitorId) ? visitorId : null;
  }
}
