import { Response } from './api.interface';
import { Observable, switchMap, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.state';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiServiceBase } from './api-base.service';
import { PAYMENT_AUTH_ENDPOINT, PaymentAuthRequest, PaymentAuthResponse } from './endpoints/payment-auth';
import { selectPaymentAuthRequest } from 'src/app/feature/payment/payment.selectors';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends ApiServiceBase {
  constructor(
    http: HttpClient,
    private store: Store<AppState>
  ) {
    super(http, store);
  }

  /**
   * This endpoint should be called to authorize any payment method processed through AccessoPay.
   * This API can accept an array of payments which will be authorized in order.
   */
  public paymentAuth(): Observable<Response<PaymentAuthResponse>> {
    return this.store.select(selectPaymentAuthRequest).pipe(
      take(1),
      switchMap((request) => {
        return this.post<PaymentAuthResponse>(PAYMENT_AUTH_ENDPOINT, request);
      })
    );
  }
}
