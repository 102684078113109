import { AbstractControl, FormGroup } from '@angular/forms';

export const DEFAULT_MAX_DONATION = 1000000;

export enum DonationType {
  Round = 'round',
  Preset = 'preset',
  Custom = 'custom',
}

export interface DonationOption {
  type: DonationType;
  amount?: number;
  label?: string;
  selected?: boolean | string;
}

export interface DonationFormChangeEvent {
  donationAmount: number;
  donationType: DonationType;
}

export interface DonationForm {
  donationType: AbstractControl<string>;
  donationAmount: AbstractControl<string>;
}

export interface DonationApi {
  submitDonation: (data) => void;
}
