import { TemplateRef } from '@angular/core';
import { createAction, props } from '@ngrx/store';

import { DialogData } from './dialog/confirm-dialog/confirm-dialog.component';

export enum NotificationActionTypes {
  SHOW_NOTIFICATION = '[Notifications] Show',
  SHOW_ADYEN_CUSTOM_NOTIFICATION = '[Adyen 3DS] Show Custom Notifcation for 3DS2 Challenge',
  CLOSE_ADYEN_CUSTOM_NOTIFICATION = '[Adyen 3DS] Close Custom Notifcation for 3DS2 Challenge',
}

/**
 * Shows a message with an "OK" and "Cancel" button.
 */
export const showNotificationAction = createAction(NotificationActionTypes.SHOW_NOTIFICATION, props<DialogData>());

/**
 * Shows custom notifcation dialog for adyen
 */
export const showAdyenCustomNotificationAction = createAction(NotificationActionTypes.SHOW_ADYEN_CUSTOM_NOTIFICATION, props<{ id: string }>());

/**
 * Close adyen custom notification dialog
 */
export const closeAdyenCustomNotificationAction = createAction(NotificationActionTypes.CLOSE_ADYEN_CUSTOM_NOTIFICATION);
