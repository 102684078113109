import { isBoolean } from 'lodash-es';

export function toInt(num: any, radix: number = 10) {
  if (isBoolean(num)) {
    return +num;
  }

  const value: number = parseInt(num, radix);
  return Number.isNaN(value) ? 0 : value;
}

/**
 * transform dollar value to cents (handle decimals)
 * @param amount Value in dollars
 * @returns Value in cents
 */
export function toCents(amount: number): number {
  return Math.round(amount * 100);
}

/**
 * Convert numeric string to nth decimal places
 * @param value numeric value
 * @param decimalPlaces number of decimal places
 */
export function toDecimal(value: string | number, decimalPlaces: number): number {
  const decimalRange = decimalPlaces > 0 ? Math.min(decimalPlaces, 100) : 0;
  return +Number(value).toFixed(decimalRange);
}

/**
 * Check if number is between 2 values.
 * @param num
 * @param min
 * @param max
 */
export function isBetween(num, min, max): boolean {
  return num >= min && num <= max;
}
