import { createAction, props } from '@ngrx/store';

import { PostMessage } from './application-bridge.models';

export enum ApplicationBridgeTypes {
  ReceiveMessage = '[ApplicationBridge] Receive Message',
  SendMessage = '[ApplicationBridge] Send Message',
}

/**
 * When we receive a message from the bridge
 */
export const receiveMessageAction = createAction(ApplicationBridgeTypes.ReceiveMessage, props<{ key: string; message: PostMessage; sessionId?: string }>());

/**
 * When we send a message to the bridge
 */
export const sendMessageAction = createAction(ApplicationBridgeTypes.SendMessage, props<{ key: string; payload?: any }>());
