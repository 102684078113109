import { Params } from '@angular/router';
import { createAction, props } from '@ngrx/store';
import { DonationAuthRequest, InsuranceAuthRequest } from 'src/app/core/api/responses/authorize';
import { CreateAccessoPaySessionRequest } from 'src/app/core/api/responses/create-accesso-pay-session';
import { InitiateAltPayResponse } from 'src/app/core/api/responses/initiate-alt-pay';
import { UpdateAltPayResponse } from 'src/app/core/api/responses/update-alt-pay';
import { PaymentType } from 'src/app/core/application-bridge/application-bridge.models';
import { PaymentMethodCode, PaymentProviderType } from 'src/app/core/payment-configuration/payment-configuration.model';
import { AlternatePaymentRedirectParams, AltPayRedirectDetails } from './alternate-payments.utils';

export enum AlternatePaymentsActions {
  InitiateAlternatePayments = '[Alternate Payments Demographics Page] Initiate Alternate Payments',
  InitiateAlternatePaymentRequest = '[Alternate Payments Effects] Send InitiateAltPay Request',
  InitiateAlternatePaymentFailure = '[Alternate Payments Effects] InitiateAltPay Request Failed',
  InitiateQrPayment = '[Alternate Payments Effects] Initiate QR Payment',
  InitiateAltPayPaymentAuthorizedAction = '[Alternate Payments Effects] InitiateAltPay - Payment Authorized',
  InitiateBanContactCardAlternatePayment = '[Credit Card Page] Initiate BanContact Card Alternate Payment',
  InitiateAlternatePaymentRedirect = '[Alternate Payments Demographics Page] Initiate Alternate Payment Redirect',
  ConfirmReadyForRedirect = '[Alternate Payments Demographics Page] Confirm Ready For Redirect',
  PreparePaymentComplete = '[Alternate Payments Effects] Prepare for Payment Complete',
  RedirectPaymentsQueryParams = '[App Initialization] Set Redirect Payments Query Params',
  RenderAdyenWebComponent = '[Alternate Payments Effects] Render Adyen Web Component',
  RenderCustomQrComponent = '[Alternate Payments Effects] Render Custom QR Component',
  InitializeGiftCardSplitPayment = '[Alternate Payments Effects] Initialize Gift Card Split Payment With Alternate Payment',
  SplitPaymentWithGiftCardSuccess = '[Gift Card Effects] Gift Card Split Payment With Alternate Payments Success',
  PrepareForRedirect = '[Alternate Payments Service] Prepare for redirect',
}

/**
 * Initiate Alternate Payments transaction request
 */
export const initiateAlternatePaymentsAction = createAction(
  AlternatePaymentsActions.InitiateAlternatePayments,
  props<Partial<AltPayRedirectDetails>>()
  // props<{ paymentMerchantId: number; paymentMethodCode: PaymentMethodCode; paymentType: string; issuerId?: string }>()
);

/**
 * Initiate Alternate Payments transaction request
 */
export const sendInitiateAltPayRequestAction = createAction(
  AlternatePaymentsActions.InitiateAlternatePaymentRequest,
  props<{ accessoPaySessionId: string; paymentDetails: Partial<AltPayRedirectDetails> }>()
);
/**
 * Initiate Alternate Payments transaction request
 */
export const initiateAltPayFailureAction = createAction(
  AlternatePaymentsActions.InitiateAlternatePaymentFailure,
  props<{ response: InitiateAltPayResponse }>()
);

export const InitiateAltPayPaymentAuthorizedAction = createAction(
  AlternatePaymentsActions.InitiateAltPayPaymentAuthorizedAction,
  props<{ response: InitiateAltPayResponse & UpdateAltPayResponse }>()
);

/**
 * Initiate Alternate Payments transaction request
 */
export const initiateQrPaymentAction = createAction(AlternatePaymentsActions.InitiateQrPayment, props<{ useAdyenWebComponent: boolean }>());

/**
 * Initiate BanContact Card Alternate Payment
 */
export const initiateBanContactCardAlternatePaymentAction = createAction(
  AlternatePaymentsActions.InitiateBanContactCardAlternatePayment,
  props<{ token: string; paymentMerchantId: number; paymentProvider: PaymentProviderType }>()
);

/**
 * Handles initiating an alternate payment redirect
 */
export const initiateAlternatePaymentRedirectAction = createAction(
  AlternatePaymentsActions.InitiateAlternatePaymentRedirect,
  props<{
    amount: number;
    url: string;
    method: string;
    paymentReference: string;
    authId: number;
    paymentMethodCode: PaymentMethodCode;
    redirectParams?: AlternatePaymentRedirectParams;
    donation?: DonationAuthRequest;
    insurance?: InsuranceAuthRequest;
  }>()
);

/**
 * Handles initiating an alternate payment redirect
 */
export const confirmReadyForRedirectAction = createAction(
  AlternatePaymentsActions.ConfirmReadyForRedirect,
  props<{
    amount: number;
    url: string;
    method: string;
    paymentReference: string;
    authId: number;
    paymentMethodCode: PaymentMethodCode;
    redirectParams?: AlternatePaymentRedirectParams;
    donation?: DonationAuthRequest;
    insurance?: InsuranceAuthRequest;
  }>()
);

/**
 * Stores redirect payments query params
 */
export const setRedirectPaymentsQueryParamsAction = createAction(AlternatePaymentsActions.RedirectPaymentsQueryParams, props<{ payload: Params }>());

/**
 * Prepare for redirect
 */
export const prepareForAlternatePaymentRedirectAction = createAction(
  AlternatePaymentsActions.PrepareForRedirect,
  props<{
    payload: {
      sessionRequest: CreateAccessoPaySessionRequest;
      paymentDetails: Partial<AltPayRedirectDetails>;
    };
  }>()
);

/**
 * Initiate split payment with gift card
 */
export const initializeGiftCardSplitPaymentWithAlternatePaymentsAction = createAction(
  AlternatePaymentsActions.InitializeGiftCardSplitPayment,
  props<{
    amount: number;
    payload: {
      sessionRequest: CreateAccessoPaySessionRequest;
      paymentDetails: Partial<AltPayRedirectDetails>;
    };
  }>()
);

/**
 * Gift card split payment success
 */
export const giftCardSplitPaymentWithAlternatePaymentsSuccessAction = createAction(
  AlternatePaymentsActions.SplitPaymentWithGiftCardSuccess,
  props<{
    payload: {
      sessionRequest: CreateAccessoPaySessionRequest;
      paymentDetails: Partial<AltPayRedirectDetails>;
    };
  }>()
);

export const renderAdyenWebComponentAction = createAction(
  AlternatePaymentsActions.RenderAdyenWebComponent,
  props<{
    payload: { paymentType: PaymentType };
  }>()
);

export const renderCustomQRAction = createAction(
  AlternatePaymentsActions.RenderCustomQrComponent,
  props<{
    payload: {
      authId: number;
      paymentMethodCode: PaymentMethodCode;
      paymentMerchantId: number;
      paymentProvider: PaymentProviderType;
      qrData: string;
    };
  }>()
);

export const prepPaymentCompleteAction = createAction(
  AlternatePaymentsActions.PreparePaymentComplete,
  props<{
    payload: { amount: number; paymentDetails: Partial<AltPayRedirectDetails>; response: InitiateAltPayResponse };
  }>()
);
