import { createAction, props } from '@ngrx/store';
import { DonationAuthRequest, InsuranceAuthRequest } from 'src/app/core/api/responses/authorize';
import { SVCPreAuthResponse } from 'src/app/core/api/responses/svc-pre-auth';
import { AmountType, GiftCard, GiftCardState } from './gift-card.state';

export enum GiftCardActions {
  FetchGiftCardBalance = '[Gift Card Component] Fetch Gift Card Balance',
  UpdateGiftCardBalance = '[Gift Card Effects] Update Gift Card Balance',
  GiftCardShowSuccessMessage = '[Gift Card Effects] Show Gift Card Add Success Message',
  GiftCardHideSuccessMessage = '[Gift Card Effects] Hide Gift Card Add Success Message',
  ResetGiftCardStateOnDelivery = '[Delivery Component] Reset Gift Card State',
  ClearGiftCardField = '[Gift Card Effects] Clear Gift Card Field',
  PayWithGiftCard = '[Select Screen] Pay With Gift Card',
  PayPartialAmountWithGiftCard = '[Billing Effects] Pay Partial Amount With Gift Card',
  PartialPaymentWithGiftCardSuccess = '[Billing Effects] Partial Payment With Gift Card Success',
  PayWithGiftCardSuccess = '[Select Screen] Pay With Gift Card Success',
  PayWithGiftCardFailed = '[Select Screen] Pay With Gift Card Failed',
  UpdateGiftCardOnlyPayment = '[Select Screen] Update Gift Card Only Payment',
  UpdateGiftCardStateFromSession = '[Parent Config Effects] Update Gift Card State From Session',
  UpdateGiftCardMaxEntries = '[Parent Config Effects] Update Gift Card max entries allowed',
  AuthSVCAction = '[Gift Card Component] Auth multiple giftcards',
  ProcessSvcAuth = '[Gift Card Component] Process auth response',
}

/**
 * The action to fetch gift card balance
 */
export const fetchGiftCardBalanceAction = createAction(GiftCardActions.FetchGiftCardBalance, props<{ giftCardNumber: string; pincode?: string }>());

/**
 * The action to update gift card balance
 */
export const updateGiftCardBalanceAction = createAction(
  GiftCardActions.UpdateGiftCardBalance,
  props<{ balance: number; giftCardNumber: string; currencyCode: string; pin?: string }>()
);

/**
 * The action to reset gift card state on delivery screen
 */
export const resetGiftCardStateOnDeliveryAction = createAction(GiftCardActions.ResetGiftCardStateOnDelivery);

/**
 * The action to clear gift card input field
 */
export const clearGiftCardFieldAction = createAction(GiftCardActions.ClearGiftCardField);
/**
 * The action to show gift card added success message
 */
export const showGiftCardSuccessMsgAction = createAction(GiftCardActions.GiftCardShowSuccessMessage);
/**
 * The action to hide gift card added success message
 */
export const hideGiftCardSuccessMsgAction = createAction(GiftCardActions.GiftCardHideSuccessMessage);

/**
 * The action to update payment as gift card only
 */
export const updateGiftCardOnlyPaymentAction = createAction(GiftCardActions.UpdateGiftCardOnlyPayment, props<{ payload: boolean }>());

/**
 * The action to update payment as gift card only
 */
export const updateGiftCardMaxEntriesAction = createAction(GiftCardActions.UpdateGiftCardMaxEntries, props<{ payload: number }>());

/**
 * The action to pay with gift card
 */
export const payWithGiftCardAction = createAction(
  GiftCardActions.PayWithGiftCard,
  props<{ amount: number; donation: DonationAuthRequest; insurance?: InsuranceAuthRequest; quoteToken?: string }>()
);

/**
 * The action to update gift card state from session
 */
export const updateGiftCardStateFromSessionAction = createAction(GiftCardActions.UpdateGiftCardStateFromSession, props<{ payload: GiftCardState }>());

/**
 * The action to pay partial amount with gift card
 */
export const payPartialAmountWithGiftCardAction = createAction(
  GiftCardActions.PayPartialAmountWithGiftCard,
  props<{
    amount: number;
    callback: { action: string; payload: any };
    donation?: DonationAuthRequest;
    insurance?: InsuranceAuthRequest;
    quoteToken?: string;
  }>()
);

/**
 * The action to indicate partial payment with gift card success
 */
export const payPartialAmountWithGiftCardSuccessAction = createAction(
  GiftCardActions.PartialPaymentWithGiftCardSuccess,
  props<{
    payload: GiftCard[];
    callback: { action: string; payload: any };
  }>()
);

/**
 * The action to indicate pay with gift card success
 */
export const payWithGiftCardSuccessAction = createAction(
  GiftCardActions.PayWithGiftCardSuccess,
  props<{
    payload: GiftCard[];
  }>()
);

/**
 * The action to indicate pay with gift card failure
 */
export const payWithGiftCardFailedAction = createAction(GiftCardActions.PayWithGiftCardFailed);
export const processSvcAuth = createAction(
  GiftCardActions.ProcessSvcAuth,
  props<{
    response: SVCPreAuthResponse;
    giftCardData: GiftCard[];
    demographics: Partial<DemographicsFormData>;
    amountTypes: AmountType[];
    lastAction: any;
  }>()
);

/**
 * The action to indicate pay with gift card success
 */
export const authSVCAction = createAction(
  GiftCardActions.AuthSVCAction,
  props<{
    payload: {
      requests: any[];
      nonPreAuthGiftCards: GiftCard[];
      lastAction;
      amountTypes;
      preAuthGiftCards: GiftCard[];
    };
  }>()
);
