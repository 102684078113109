import { createReducer, on } from '@ngrx/store';
import { has, isObject } from 'lodash-es';
import {
  updatePaymentConfigurationAction,
  updatePaymentConfigurationWithConfiguredPaymentsAction,
} from 'src/app/core/payment-configuration/payment-configuration.actions';
import { validArray, validObject, validString } from 'src/app/shared/utilities/types.utils';
import {
  clearAlternatePaymentDemographicsAction,
  hideAlternatePaymentsAction,
  loadCountryCodesAction,
  loadPhoneCountryCodesAction,
  updateAlternatePaymentAvailabilityAction,
  updateAlternatePaymentDemographicsAction,
  updateAmazonPayAvailabilityAction,
  updateApplePayAvailabilityAction,
  updateApplePayAvailabilityOnParentAction,
  updateBillingDemographicsAction,
  updateCreditCardAvailabilityAction,
  updateCreditCardAvailabilityWithConfiguredPaymentsAction,
  updateGiftCardAvailabilityAction,
  updateGooglePayAvailabilityAction,
  updatePayPalAvailabilityAction,
  updateUpliftAvailabilityAction,
} from './billing.actions';
import { BillingState, initialState } from './billing.state';
import { PaymentType } from 'src/app/core/application-bridge/application-bridge.models';
import { setParentConfig } from 'src/app/core/parent-config/parent-config.actions';
import { User } from 'src/app/core/parent-config/parent-config.state';
import { PaymentMethodCode } from 'src/app/core/payment-configuration/payment-configuration.model';
import { clearRedirectPaymentQueryParamsAction } from 'src/app/core/routing/routing.actions';
import { getAlternatePaymentDisplayOrder } from 'src/app/shared/utilities/alternate-payment.utils';
import { returnOrDefaultString } from 'src/app/shared/utilities/string.utils';
import { setRedirectPaymentsQueryParamsAction } from './alternate-payments/alternate-payments.actions';

const creditCardAvailabilityUpdate = (state, action) => ({ ...state, creditcard: action.status });
const googlePayAvailabilityUpdate = (state, action) => ({ ...state, googlepay: action.status });
const applePayAvailabilityUpdate = (state, action) => ({ ...state, applepay: action.status });
const amazonPayAvailabilityUpdate = (state, action) => ({ ...state, amazonpay: action.status });
const payPalAvailabilityUpdate = (state, action) => ({ ...state, paypal: action.status });
const giftCardAvailabilityUpdate = (state, action) => ({ ...state, giftcard: action.status });
const upliftAvailabilityUpdate = (state, action) => ({ ...state, uplift: action.status });
const paymentConfigurationUpdate = (state, action) => {
  const { configuredPayments } = action;

  return {
    ...state,
    order: {
      [PaymentType.ADYEN_MOBILE_PAY]: getAlternatePaymentDisplayOrder(configuredPayments.alternatePayments, PaymentMethodCode.ADYEN_MOBILE_PAY),
      [PaymentType.AFTERPAY]: getAlternatePaymentDisplayOrder(configuredPayments.alternatePayments, PaymentMethodCode.AFTERPAY),
      [PaymentType.ALIPAY]: getAlternatePaymentDisplayOrder(configuredPayments.alternatePayments, PaymentMethodCode.ALIPAY),
      [PaymentType.ALIPAY_HK]: getAlternatePaymentDisplayOrder(configuredPayments.alternatePayments, PaymentMethodCode.ALIPAY_HK),
      [PaymentType.AMAZON_PAY]: getAlternatePaymentDisplayOrder(configuredPayments.alternatePayments, PaymentMethodCode.AMAZON_PAY),
      [PaymentType.BANCONTACT_DESKTOP]: getAlternatePaymentDisplayOrder(configuredPayments.alternatePayments, PaymentMethodCode.BANCONTACT_DESKTOP),
      [PaymentType.BANCONTACT_MOBILE]: getAlternatePaymentDisplayOrder(configuredPayments.alternatePayments, PaymentMethodCode.BANCONTACT_MOBILE),
      [PaymentType.GIROPAY]: getAlternatePaymentDisplayOrder(configuredPayments.alternatePayments, PaymentMethodCode.GIROPAY),
      [PaymentType.GRABPAY]: getAlternatePaymentDisplayOrder(configuredPayments.alternatePayments, PaymentMethodCode.GRABPAY),
      [PaymentType.IDEAL]: getAlternatePaymentDisplayOrder(configuredPayments.alternatePayments, PaymentMethodCode.IDEAL),
      [PaymentType.KAKAO_PAY]: getAlternatePaymentDisplayOrder(configuredPayments.alternatePayments, PaymentMethodCode.KAKAO_PAY),
      [PaymentType.KOREA_CYBER_PAYMENTS]: getAlternatePaymentDisplayOrder(configuredPayments.alternatePayments, PaymentMethodCode.KOREA_CYBER_PAYMENTS),
      [PaymentType.LINE_PAY]: getAlternatePaymentDisplayOrder(configuredPayments.alternatePayments, PaymentMethodCode.LINE_PAY),
      [PaymentType.MB_WAY]: getAlternatePaymentDisplayOrder(configuredPayments.alternatePayments, PaymentMethodCode.MB_WAY),
      [PaymentType.NAVER_PAY]: getAlternatePaymentDisplayOrder(configuredPayments.alternatePayments, PaymentMethodCode.NAVER_PAY),
      [PaymentType.PAYPAL]: getAlternatePaymentDisplayOrder(configuredPayments.alternatePayments, PaymentMethodCode.PAYPAL),
      [PaymentType.SEPADD]: getAlternatePaymentDisplayOrder(configuredPayments.alternatePayments, PaymentMethodCode.SEPADD),
      [PaymentType.SOFORT]: getAlternatePaymentDisplayOrder(configuredPayments.alternatePayments, PaymentMethodCode.SOFORT),
      [PaymentType.TTB_QR_CODE]: getAlternatePaymentDisplayOrder(configuredPayments.alternatePayments, PaymentMethodCode.TTB_QR_CODE),
      [PaymentType.WECHAT_PAY]: getAlternatePaymentDisplayOrder(configuredPayments.alternatePayments, PaymentMethodCode.WECHAT_PAY),
      [PaymentType.APPLE_PAY]: getMinOrderFromType(configuredPayments.applePayCards),
      [PaymentType.CREDIT_CARD]: getMinOrderFromType(configuredPayments.creditCards),
      [PaymentType.GOOGLE_PAY]: getMinOrderFromType(configuredPayments.googlePayCards),
    },
  };
};
const alternatePaymentAvailabilityUpdate = (state, action) => {
  const { payload } = action;

  if (validString(payload) && has(state, payload)) {
    return {
      ...state,
      [payload]: true,
    };
  }
  return state;
};
const updateDemographics = (state, action) => {
  const { payload } = action;
  if (payload.valid) {
    return {
      ...state,
      demographics: payload,
    };
  }
  return state;
};
const clearAltPayDemographics = (state, action) => {
  const newState = { ...state };
  if (has(newState, 'demographics')) {
    delete newState.demographics;
    return newState;
  }
  return state;
};
const hideAlternatePayments = (state, action): BillingState => {
  return {
    ...initialState,
    [PaymentType.CREDIT_CARD]: state[PaymentType.CREDIT_CARD],
    [PaymentType.APPLE_PAY]: state[PaymentType.APPLE_PAY],
    [PaymentType.GOOGLE_PAY]: state[PaymentType.GOOGLE_PAY],
    [PaymentType.UPLIFT]: state[PaymentType.UPLIFT],
    demographics: { ...state.demographics },
    formOptions: { ...state.formOptions },
    order: { ...state.order },
  };
};
const onSetParentConfig = (state, action): BillingState => {
  const user: Partial<User> = action.payload.user;

  if (isObject(user)) {
    return {
      ...state,
      demographics: {
        firstName: returnOrDefaultString(user.firstName),
        lastName: returnOrDefaultString(user.lastName),
        middleInitial: returnOrDefaultString(user.middleInitial),
        address1: returnOrDefaultString(user.address1),
        address2: returnOrDefaultString(user.address2),
        city: returnOrDefaultString(user.city),
        state: returnOrDefaultString(user.stateProvince),
        country: returnOrDefaultString(user.country),
        zip: returnOrDefaultString(user.zipPostal),
        email: returnOrDefaultString(user.email),
        phone: returnOrDefaultString(user.phone),
        telCode: returnOrDefaultString(user.phoneCountryCode),
      },
    };
  }

  return state;
};
const onSetAlternatePaymentsQueryParams = (state, action) => {
  const { payload } = action;

  if (validObject(payload)) {
    return {
      ...state,
      redirectPaymentsQueryParams: payload,
    };
  }

  return state;
};
const onClearRedirectPaymentsQueryParams = (state, action) => {
  if (has(state, 'redirectPaymentsQueryParams')) {
    const newState = { ...state };
    delete newState.redirectPaymentsQueryParams;
    return newState;
  }
  return state;
};
const LoadCountryCodes = (state, action) => {
  const { countries, preferredCountriesFound } = action;
  return {
    ...state,
    formOptions: {
      ...state.formOptions,
      countryCodes: countries,
      preferredCountriesFound,
    },
  };
};
const LoadPhoneCountryCodes = (state, action) => {
  const { countries, preferredTelCodesFound } = action;
  return {
    ...state,
    formOptions: {
      ...state.formOptions,
      phoneCountryCodes: countries,
      preferredTelCodesFound,
    },
  };
};

const reducer = createReducer(
  initialState,
  on(updateCreditCardAvailabilityAction, creditCardAvailabilityUpdate),
  on(updateCreditCardAvailabilityWithConfiguredPaymentsAction, creditCardAvailabilityUpdate),
  on(updateGooglePayAvailabilityAction, googlePayAvailabilityUpdate),
  on(updateAmazonPayAvailabilityAction, amazonPayAvailabilityUpdate),
  on(updateApplePayAvailabilityAction, applePayAvailabilityUpdate),
  on(updateApplePayAvailabilityOnParentAction, applePayAvailabilityUpdate),
  on(updatePayPalAvailabilityAction, payPalAvailabilityUpdate),
  on(updateGiftCardAvailabilityAction, giftCardAvailabilityUpdate),
  on(updateUpliftAvailabilityAction, upliftAvailabilityUpdate),
  on(updatePaymentConfigurationAction, paymentConfigurationUpdate),
  on(updatePaymentConfigurationWithConfiguredPaymentsAction, paymentConfigurationUpdate),
  on(updateAlternatePaymentAvailabilityAction, alternatePaymentAvailabilityUpdate),
  on(updateAlternatePaymentDemographicsAction, updateDemographics),
  on(updateBillingDemographicsAction, updateDemographics),
  on(clearAlternatePaymentDemographicsAction, clearAltPayDemographics),
  on(hideAlternatePaymentsAction, hideAlternatePayments),
  on(setParentConfig, onSetParentConfig),
  on(setRedirectPaymentsQueryParamsAction, onSetAlternatePaymentsQueryParams),
  on(clearRedirectPaymentQueryParamsAction, onClearRedirectPaymentsQueryParams),
  on(loadCountryCodesAction, LoadCountryCodes),
  on(loadPhoneCountryCodesAction, LoadPhoneCountryCodes)
);

/**
 * The billing state reducer.
 * @param state The current state
 * @param action The action being performed
 */
export function billingReducer(state, action): BillingState {
  return reducer(state, action);
}

/**
 * Returns the minimum order found in an array to use as the order to display them in the UI
 * @param types The array of cards or payment types.
 */
export function getMinOrderFromType(types: any[]): number {
  if (!validArray(types)) {
    return 9999;
  }

  return types.reduce((acc, type) => {
    return Math.min(acc, type.displayOrder);
  }, 9999);
}
