import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { validString } from 'src/app/shared/utilities/types.utils';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { selectMerchantId } from '../client-configuration/client-configuration.selectors';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.state';
import { selectLocales } from './locale.selectors';
import { get, toLower } from 'lodash-es';
import { Locales, LocaleState } from './locale.state';
import { AcLoggerService } from 'src/app/core/logger/logger.service';

/**
 * Handles looking up all locales and returning the strings.
 */
@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  protected debugEnabled: boolean;
  protected merchantId: string;
  protected locales: Locales;

  /**
   * @param localeId The configured locale id.
   * @param store The store state.
   * @param logger Our console logger.
   * @param sanitizer The DOM sanitizer for using SafeHTML
   */
  constructor(@Inject(LOCALE_ID) private localeId, protected store: Store<AppState>, protected logger: AcLoggerService, private sanitizer: DomSanitizer) {
    this.debugEnabled = logger.isEnabled();

    store.select(selectLocales).subscribe((applicationLocales: LocaleState) => {
      this.locales = applicationLocales.locales;
    });

    store.select(selectMerchantId).subscribe((merchantId) => {
      this.merchantId = merchantId;
    });
  }

  /**
   * Returns the array of searchable locales to use when receiving a section and target
   * @param section The section
   * @param target The target
   * @param suffix The prefix
   */
  getSearchableSectionTargets(section: string, target: string, suffix?: string): string[] {
    const sectionTarget = toLower(`${section}.${target}`);
    const searchMap: string[] = [`${sectionTarget}_${this.merchantId}`, `${sectionTarget}`];

    if (validString(suffix)) {
      searchMap.unshift(toLower(`${sectionTarget}_${suffix}_${this.merchantId}`), toLower(`${sectionTarget}_${suffix}`));
    }

    return searchMap;
  }

  /**
   * Returns a locale
   * @param value The string of section and target separated by a period. E.g. `section.target`.
   * @param suffix The suffix, if you want to make it more specific.
   * @param asHtml Pass in true if you plan on using this as HTML with `[textContent]`
   */
  get(value: any, suffix?: string, asHtml?: false): string;
  get(value: any, suffix?: string, asHtml?: true): SafeHtml;
  get(value: any, suffix?: string, asHtml?: boolean): string | SafeHtml;
  get(value: any, suffix?: string, asHtml: boolean = false): string | SafeHtml {
    const newValue = validString(value) ? value : '';
    const [section, target] = newValue.split('.');
    const defaultValue = this.debugEnabled ? `[Missing Locale: ${section}.${target}]` : '';

    if (validString(section) && validString(target)) {
      const searchMap = this.getSearchableSectionTargets(section, target, suffix);
      const foundIndex: number = searchMap.findIndex((search) => {
        return validString(get(this.locales, search));
      });

      if (foundIndex > -1) {
        const foundValue = get(this.locales, searchMap[foundIndex]);
        /**
         * We have to tsignore the next line as we already know its a string from 3 lines up but for some reason `get` doesnt allow us
         * to notify TS that its a string
         */

        // @ts-ignore
        return asHtml ? this.sanitizer.bypassSecurityTrustHtml(foundValue) : foundValue;
      }
      return defaultValue;
    } else if (!validString(newValue)) {
      return '';
    } else {
      return defaultValue;
    }
  }

  /**
   * Find out if a locale exists.
   * @param value The section.target string
   * @param suffix The suffix if needed
   * @returns If a locale exists for it
   */
  has(value: any, suffix?: string): boolean {
    const newValue = validString(value) ? value : '';
    const [section, target] = newValue.split('.');

    if (validString(section) && validString(target)) {
      const searchMap = this.getSearchableSectionTargets(section, target, suffix);
      const foundIndex: number = searchMap.findIndex((search) => {
        return validString(get(this.locales, search));
      });

      return foundIndex > -1;
    }
    return false;
  }

  /**
   * Find out if this is a last name first language.
   */
  isLastNameFirst(): boolean {
    const parts = this.localeId.toString().split('-');
    return ['cn', 'jp', 'tw', 'vn', 'kp', 'kr', 'sg', 'hant', 'hans', 'ko'].includes(toLower(parts[parts.length - 1]));
  }
}
