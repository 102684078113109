/**
 * The initialization state.
 */
export interface InitializeState {
  /**
   * If we're fully initialized.
   */
  initialized: boolean;
  /**
   * If initialization failed.
   */
  initializeFailed: boolean;
  /**
   * If the application config has loaded.
   */
  applicationConfigLoaded: boolean;
  /**
   * If the application locales have loaded.
   */
  applicationLocalesLoaded: boolean;
  /**
   * If the environment data has loaded.
   */
  environmentLoaded: boolean;
  /**
   * If the payment configuration has loaded.
   */
  paymentConfiguration: boolean;
  /**
   * If the parent configuration has loaded.
   */
  parentConfiguration: boolean;
}

/**
 * Our default initialization state.
 */
export const initialState: InitializeState = {
  initialized: false,
  initializeFailed: false,
  applicationConfigLoaded: false,
  applicationLocalesLoaded: false,
  environmentLoaded: false,
  paymentConfiguration: false,
  parentConfiguration: false,
};
