import { createAction, props } from '@ngrx/store';

export enum SessionActionTypes {
  AUTHORIZING = '[Session] Set Authorizing',
}

/**
 * Set the authorizing property.
 */
export const setAuthorizingAction = createAction(SessionActionTypes.AUTHORIZING, props<{ authorizing: boolean; }>());
