import { Action, createReducer, on } from '@ngrx/store';
import { loadOptimizelyFeatureControllerAction } from './web-experimentation.actions';
import { initialState, WebExperimentState } from './web-experimentation.state';

const reducer = createReducer(
  initialState,
  on(loadOptimizelyFeatureControllerAction, (state, action) => ({
    ...state,
    disableFeature: action.payload ? { ...state.disableFeature, ...action.payload } : {},
  }))
);

export const WebExperimentReducer = (state: WebExperimentState, action: Action): WebExperimentState => {
  return reducer(state, action);
};
