import { CountriesProperties } from '../dynamic-form/utilities/countries-props.models';
import { Params } from '@angular/router';
import { PaymentType } from 'src/app/core/application-bridge/application-bridge.models';

export interface BillingState {
  [PaymentType.ADYEN_MOBILE_PAY]?: boolean;
  [PaymentType.AFTERPAY]?: boolean;
  [PaymentType.ALIPAY]?: boolean;
  [PaymentType.ALIPAY_HK]?: boolean;
  [PaymentType.AMAZON_PAY]?: boolean;
  [PaymentType.APPLE_PAY]?: boolean;
  [PaymentType.BANCONTACT_MOBILE]?: boolean;
  [PaymentType.BANCONTACT_DESKTOP]?: boolean;
  [PaymentType.CREDIT_CARD]?: boolean;
  [PaymentType.GIFT_CARD]?: boolean;
  [PaymentType.GIROPAY]?: boolean;
  [PaymentType.GRABPAY]?: boolean;
  [PaymentType.GOOGLE_PAY]?: boolean;
  [PaymentType.IDEAL]?: boolean;
  [PaymentType.KAKAO_PAY]?: boolean;
  [PaymentType.KOREA_CYBER_PAYMENTS]?: boolean;
  [PaymentType.KLARNA_PAY_NOW]?: boolean;
  [PaymentType.KLARNA_PAY_OVER_TIME]?: boolean;
  [PaymentType.LINE_PAY]?: boolean;
  [PaymentType.NAVER_PAY]?: boolean;
  [PaymentType.MB_WAY]?: boolean;
  [PaymentType.PAYPAL]?: boolean;
  [PaymentType.SEPADD]?: boolean;
  [PaymentType.SOFORT]?: boolean;
  [PaymentType.TTB_QR_CODE]?: boolean;
  [PaymentType.UPLIFT]?: boolean;
  [PaymentType.WECHAT_PAY]?: boolean;
  demographics?: Partial<DemographicsFormData>;
  redirectPaymentsQueryParams?: Params;
  formOptions?: FormOptionState;
  order: {
    [P in keyof BillingState]?: number;
  };
}

export interface FormOptionState {
  /**
   * List of country select options.
   */
  countryCodes?: CountriesProperties[];
  /**
   * Number of preferred countries found with given value.
   */
  preferredCountriesFound?: number;
  /**
   * List of country telephone code select options.
   */
  phoneCountryCodes?: CountriesProperties[];
  /**
   * Number of preferred country dial codes found with given value.
   */
  preferredTelCodesFound?: number;
}

export type BillingStateProperty = keyof BillingState;

export const initialState: BillingState = {
  [PaymentType.ADYEN_MOBILE_PAY]: false,
  [PaymentType.AFTERPAY]: false,
  [PaymentType.ALIPAY]: false,
  [PaymentType.ALIPAY_HK]: false,
  [PaymentType.AMAZON_PAY]: false,
  [PaymentType.APPLE_PAY]: false,
  [PaymentType.BANCONTACT_MOBILE]: false,
  [PaymentType.BANCONTACT_DESKTOP]: false,
  [PaymentType.CREDIT_CARD]: false,
  [PaymentType.GIFT_CARD]: false,
  [PaymentType.GIROPAY]: false,
  [PaymentType.GRABPAY]: false,
  [PaymentType.GOOGLE_PAY]: false,
  [PaymentType.IDEAL]: false,
  [PaymentType.KAKAO_PAY]: false,
  [PaymentType.KOREA_CYBER_PAYMENTS]: false,
  [PaymentType.KLARNA_PAY_NOW]: false,
  [PaymentType.KLARNA_PAY_OVER_TIME]: false,
  [PaymentType.LINE_PAY]: false,
  [PaymentType.NAVER_PAY]: false,
  [PaymentType.MB_WAY]: false,
  [PaymentType.PAYPAL]: false,
  [PaymentType.SEPADD]: false,
  [PaymentType.SOFORT]: false,
  [PaymentType.TTB_QR_CODE]: false,
  [PaymentType.UPLIFT]: false,
  [PaymentType.WECHAT_PAY]: false,
  demographics: null,
  formOptions: {
    countryCodes: [],
    preferredCountriesFound: 0,
  },
  order: {
    applepay: 9999,
    creditcard: 9999,
    googlepay: 9999,
    wechatpay: 9999,
  },
};
