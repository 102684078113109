import { selectTenantName, selectLanguage, selectMerchantId } from '../client-configuration/client-configuration.selectors';

import { ParentConfigState } from '../parent-config/parent-config.state';
import { createSelector } from '@ngrx/store';
import { selectGatewayUrl } from '../environment/environment.selectors';
import { selectParentConfig } from '../parent-config/parent-config.selectors';
import { selectSessionId } from '../session/session.selectors';
import { validString } from 'src/app/shared/utilities/types.utils';

/**
 * The API configuration selector interface
 */
export interface ApiConfiguration {
  /**
   * The URL to make requests to.
   */
  gatewayUrl: string;

  /**
   * The configured tenantName.
   */
  tenantName: string;

  /**
   * The configured locale
   */
  language: string;

  /**
   * The configured merchant id
   */
  merchantId: string;

  /**
   * The backend session id used for debugging purposes.
   */
  sessionId: string;

  /**
   * The parent configuration
   */
  parentConfig: ParentConfigState;
}

/**
 * The gateway configuration interface
 */
export interface GatewayConfiguration {
  /**
   * The configured gateway.
   */
  gatewayUrl: string;
  /**
   * The configured tenantName.
   */
  tenantName: string;
}

/**
 * Filters any selector to ensure we have a valid gateway URL string
 * @param configuration The current configuration
 */
export const filterValidConfiguration = (configuration: GatewayConfiguration) => validString(configuration.gatewayUrl);

/**
 * Select the main configuration
 */
export const selectConfiguration = createSelector(selectGatewayUrl, selectTenantName, (gatewayUrl: string, tenantName: string): GatewayConfiguration => {
  return {
    gatewayUrl,
    tenantName,
  };
});

/**
 * Selects the api configuration
 */
export const apiConfigurationSelector = createSelector(
  selectSessionId,
  selectConfiguration,
  selectLanguage,
  selectMerchantId,
  selectParentConfig,
  (sessionId: string, configuration: GatewayConfiguration, language: string, merchantId: string, parentConfig: ParentConfigState): ApiConfiguration => {
    const { gatewayUrl, tenantName } = configuration;

    return {
      gatewayUrl,
      tenantName,
      language,
      merchantId,
      sessionId,
      parentConfig,
    };
  }
);
