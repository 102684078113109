import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, createSelector } from '@ngrx/store';
import { filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { sendAnalyticsEventAction } from 'src/app/core/analytics/analytics.actions';
import { apiResponse, postApiRequest } from 'src/app/core/api/api.actions';
import { responseByModule, responseRequestType } from 'src/app/core/api/api.utilities';
import { AuthorizeResponse } from 'src/app/core/api/responses/authorize';
import { PaymentType } from 'src/app/core/application-bridge/application-bridge.models';
import { selectAppConfig } from 'src/app/core/application-config/application-config.selectors';
import { selectClientConfiguration } from 'src/app/core/client-configuration/client-configuration.selectors';
import { selectDeviceInfo } from 'src/app/core/environment/environment.selectors';
import { WINDOW } from 'src/app/core/injection-token/window/window';
import { LocaleService } from 'src/app/core/locale/locale.service';
import { NotificationDialogType } from 'src/app/core/notification/dialog/confirm-dialog/confirm-dialog.component';
import { showNotificationAction } from 'src/app/core/notification/notification.actions';
import { hidePageSpinner, showPageSpinner } from 'src/app/core/page-spinner/page-spinner.actions';
import { selectParentConfig } from 'src/app/core/parent-config/parent-config.selectors';
import { getLineItemsAndFees } from 'src/app/core/parent-config/parent-config.utils';
import { PaymentMethodCode } from 'src/app/core/payment-configuration/payment-configuration.model';
import { selectAlternatePayments, selectCreditCards } from 'src/app/core/payment-configuration/payment-configuration.selectors';
import { routeTo } from 'src/app/core/routing/routing.actions';
import { selectExtras, selectTicketInsuranceQuoteToken } from 'src/app/feature/extras/extras.selectors';
import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import { TokenType, UpliftCardTypeCodes } from 'src/app/shared/enums/billing.enums';
import { getPaymentConfigurationByPaymentMethodCode } from 'src/app/shared/utilities/alternate-payment.utils';
import { buildCardPaymentDetailsFromAuthResponse, getPaymentErrorActions } from 'src/app/shared/utilities/credit-cards.utils';
import { formatInsuranceRequest } from 'src/app/shared/utilities/insurance.utils';
import { toInt } from 'src/app/shared/utilities/number.utils';
import { returnOrDefaultString } from 'src/app/shared/utilities/string.utils';
import { validString } from 'src/app/shared/utilities/types.utils';
import {
  gatherPaymentDetailsForUpliftPaymentAction,
  updateAlternatePaymentAvailabilityAction,
  updateBillingDemographicsAction,
} from '../billing/billing.actions';
import { selectDeliveryAmount } from '../delivery/delivery.selectors';
import { RecaptchaAction, RecaptchaService } from '../recaptcha/recaptcha.service';
import { DonationAuthRequest } from './../../core/api/responses/authorize';
import {
  authorizeUpliftPaymentAction,
  deselectMonthlyPayment,
  initializeUpliftAction,
  initializeUpliftWithConfigsAction,
  retrieveMonthlyPaymentTokenAction,
  updateMonthlyPaymentAvailabilityAction,
  updateMonthlyPaymentTokenAvailabilityAction,
  updatePaymentOptionsAvailabilityAction,
  updateUpliftPaymentReadyAction,
} from './payment-options.actions';
import { selectShowUplift } from './payment-options.selectors';
import { getErrorLocaleFromAuthResponse, getUpliftErrorReportType } from './payment-options.utils';

const { UPLIFT } = PaymentType;

@Injectable()
export class PaymentOptionsEffects {
  /**
   * PaymentOptionsEffects constructor
   * @param window Our Window
   * @param actions$ Our action stream
   * @param store Our store
   */
  constructor(
    @Inject(WINDOW) private window: Window,
    private actions$: Actions,
    private store: Store<AppState>,
    private localeService: LocaleService,
    private recaptcha: RecaptchaService
  ) {}

  /**
   * Uplift object
   */
  private uplift: Uplift | undefined;
  /**
   * Returns the window
   * @returns window object
   */
  private getWindow(): Window {
    return this.window;
  }
  /**
   * Returns Uplift service
   * @returns Uplift object
   */
  private getUpliftService(): Uplift | undefined {
    return (this.getWindow() as any).Uplift;
  }

  /**
   * Handle monethly payment options config
   */
  enableMonthlyPaymentOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAlternatePaymentAvailabilityAction),
      filter(({ payload: paymentMethod }) => paymentMethod === PaymentType.UPLIFT),
      withLatestFrom(this.store.select(selectShowUplift)),
      switchMap(([_, showUplift]) => [updatePaymentOptionsAvailabilityAction({ available: showUplift })])
    )
  );

  /**
   * The effect to initialize uplift
   */
  initializeUplift$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initializeUpliftAction, initializeUpliftWithConfigsAction),
        withLatestFrom(
          this.store.select(
            createSelector(
              selectDeviceInfo,
              selectAppConfig([UPLIFT]),
              selectParentConfig,
              selectClientConfiguration,
              selectAlternatePayments,
              selectCreditCards,
              selectDeliveryAmount,
              (deviceInfo, appConfig, parentConfig, clientConfig, alternatePayments, creditCards, deliveryAmount) => ({
                deviceInfo,
                appConfig,
                parentConfig,
                clientConfig,
                upliftConfig: alternatePayments?.find((method) => method.paymentMethodName?.toLowerCase() === UPLIFT),
                creditCards,
                amountTotal: parentConfig?.config?.amount + deliveryAmount,
              })
            )
          )
        ),
        map(([action, { deviceInfo, appConfig, parentConfig, clientConfig, upliftConfig, creditCards, amountTotal }]) => {
          this.uplift = this.getUpliftService();
          this.uplift?.Payments.init({
            apiKey: appConfig?.uplift?.apiKey,
            locale: clientConfig.language || 'en-US',
            currency: parentConfig.config.currencyCode || 'USD',
            checkout: true,
            container: (action as any)?.containerId ?? '#up-pay-monthly-container',
            channel: deviceInfo.isDesktop ? 'desktop' : 'mobile',
            onChange: (res) => {
              const statusHandlers = {
                OFFER_AVAILABLE: () => {
                  this.store.dispatch(updateMonthlyPaymentTokenAvailabilityAction({ available: false }));
                  this.store.dispatch(updateMonthlyPaymentAvailabilityAction({ available: true }));
                },
                TOKEN_AVAILABLE: () => {
                  this.store.dispatch(updateMonthlyPaymentTokenAvailabilityAction({ available: true }));
                },
                TOKEN_RETRIEVED: () => {
                  // retrieve and utilize the token:
                  const { contact = {} } = res.token ?? ({} as UpliftToken);
                  const { cartItems, fees } = getLineItemsAndFees(parentConfig);
                  const {
                    street_address: address1,
                    city,
                    country,
                    email,
                    first_name: firstName,
                    last_name: lastName,
                    phone,
                    postal_code: zip,
                    region: state,
                  } = contact;
                  const expYear = res.token.expiration_year?.toString().slice(-2);
                  const expMonth = ('0' + res.token.expiration_month).slice(-2);
                  const cardTypeCode = UpliftCardTypeCodes[res.token.card_type] || '';
                  const { customer, order_id } = this.uplift.Payments.confirmPayload();

                  [
                    updateBillingDemographicsAction({
                      payload: {
                        valid: true,
                        address1,
                        city,
                        country,
                        email,
                        firstName,
                        lastName,
                        phone,
                        zip,
                        state,
                      },
                    }),
                    authorizeUpliftPaymentAction({
                      payload: {
                        ignore_avs: 0,
                        ignore_cvv: 0,
                        recurring: 1,
                        recurring_type: 'recurring',
                        payment_index: 1,
                        amount: amountTotal,
                        merchant_id: upliftConfig.paymentMerchantId,
                        CARDHOLDER: {
                          customer_id: parentConfig?.config?.user?.customerId || '',
                          f_name: returnOrDefaultString(firstName),
                          m_name: returnOrDefaultString(parentConfig?.config?.user?.middleInitial),
                          l_name: returnOrDefaultString(lastName),
                          address: address1,
                          city,
                          country,
                          state,
                          zip,
                          email,
                          phone_number: returnOrDefaultString(phone),
                        },
                        CARD: {
                          cvv2: res.token.card_ccv,
                          expire_date: `${expYear}${expMonth}`,
                          card_type_code: cardTypeCode,
                          token_response: JSON.stringify({
                            token: res.token.card_token,
                            maskedPan: res.token.card_number,
                            ...(res.token?.additional_tokens?.instrument_token && { instrumentIdentifierValue: res.token.additional_tokens.instrument_token }),
                          }),
                          tokenType: TokenType.UPLIFT_CYBS,
                        },
                        cartItems,
                        fees,
                        provider_reference: {
                          order_id,
                          customer_id: customer.customer_id,
                        },
                      },
                    }),
                    hidePageSpinner({ initiator: 'Uplift token retrieved' }),
                  ].forEach((actn) => this.store.dispatch(actn));
                },
                OFFER_UNAVAILABLE: () => {
                  this.store.dispatch(updateMonthlyPaymentAvailabilityAction({ available: false }));
                },
                SERVICE_UNAVAILABLE: () => {
                  this.store.dispatch(updateMonthlyPaymentAvailabilityAction({ available: false }));
                },
              };
              statusHandlers[res.status]();
              this.store.dispatch(updateUpliftPaymentReadyAction({ ready: true }));
            },
          });
        })
      ),
    { dispatch: false }
  );

  /**
   * Handles authorization for Uplift payment
   */
  authorizeUpliftPaymentAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authorizeUpliftPaymentAction),
      withLatestFrom(
        this.store.select(
          createSelector(selectExtras, selectTicketInsuranceQuoteToken, ({ ticketProtection, donation }, quoteToken) => ({
            ticketProtection,
            donation,
            quoteToken,
          }))
        )
      ),
      map(([action, { ticketProtection, donation, quoteToken }]) => {
        const body = { ...action.payload };

        if (validString(quoteToken)) {
          body.insurance_quote_token = quoteToken;
          if (!ticketProtection.splitInsurance) {
            body.insurance = formatInsuranceRequest(ticketProtection);
          }
        }

        if (donation.configured && donation.amount > 0) {
          body.donation = { donation_amount: donation.amount, split_donation: donation.splitDonation } as DonationAuthRequest;
        }

        return body;
      }),
      switchMap((body) => {
        return this.recaptcha.execute(RecaptchaAction.AUTHORIZE).pipe(
          map((token) => {
            // Add recaptcha if configured
            if (validString(token)) {
              body.recaptcha_token = token;
            }
            return body;
          })
        );
      }),
      mergeMap((body) => {
        return [
          showPageSpinner({ initiator: 'Authorize Uplift transaction ' }),
          postApiRequest({
            requestType: ApiRequestType.AUTHORIZE,
            body,
            params: { module: UPLIFT },
          }),
        ];
      })
    )
  );

  /**
   * Handles watching with the Authorize responses comes back
   */
  onAuthorization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(apiResponse),
      filter(responseRequestType(ApiRequestType.AUTHORIZE)),
      filter(responseByModule(PaymentType.UPLIFT)),
      withLatestFrom(this.store.select(selectAlternatePayments)),
      mergeMap(([action, altPayments]) => {
        const response = action.response as AuthorizeResponse;
        const actions: Action[] = [];

        if (action.isOk) {
          const paymentConfiguration = getPaymentConfigurationByPaymentMethodCode(altPayments, PaymentMethodCode.UPLIFT);

          actions.push(
            sendAnalyticsEventAction({ action: 'pay', category: UPLIFT, label: 'payment complete', nonInteraction: true }),
            gatherPaymentDetailsForUpliftPaymentAction({
              payload: buildCardPaymentDetailsFromAuthResponse(response, paymentConfiguration, PaymentType.UPLIFT, paymentConfiguration.paymentMerchantId),
            })
          );
        } else {
          const errorCodeLocale = getErrorLocaleFromAuthResponse(this.localeService, response, 'uplift.internalerror');
          const upliftErrorType = getUpliftErrorReportType(toInt(response.error_code));
          actions.push(
            ...getPaymentErrorActions(response, PaymentType.UPLIFT),
            showNotificationAction({
              buttonLabel: this.localeService.get('common.close'),
              dialogType: NotificationDialogType.GENERAL,
              initiator: 'Uplift: authorize failed',
              message: errorCodeLocale,
              onClose: () => this.store.dispatch(routeTo({ payload: ['select'] })),
            })
          );
          this.uplift?.Payments.error(errorCodeLocale as string, upliftErrorType);
        }
        return actions;
      })
    )
  );

  /**
   * The effect to retrieve monthly payment token
   */
  retrieveMonthlyPaymentToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(retrieveMonthlyPaymentTokenAction),
        map(() => this.uplift.Payments.getToken())
      ),
    { dispatch: false }
  );

  /**
   * The effect to remove the selected uplift payment
   * https://docs.uplift.com/docs/step-5-setup-pay-monthly-iframe
   */
  deselectOption$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deselectMonthlyPayment),
        map(() => this.uplift.Payments.deselect('full'))
      ),
    { dispatch: false }
  );
}
