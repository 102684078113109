import { createAction, props } from '@ngrx/store';

import { Dictionary } from 'src/app/shared/utilities/types.utils';

export enum ApiActionTypes {
  GetApiRequest = '[Api] GET Request',
  PostApiRequest = '[Api] POST Request',
  DeleteApiRequest = '[Api] DELETE Request',
  ApiResponse = '[Api] Response',
  StaticApiResponse = '[Static Data] Response',
}

/**
 * Sends a GET request
 */
export const getApiRequest = createAction(
  ApiActionTypes.GetApiRequest,
  props<{ requestType: string; params?: Dictionary<any>; headers?: Dictionary<any>; resources?: string[] }>()
);

/**
 * Sends a POST request
 */
export const postApiRequest = createAction(
  ApiActionTypes.PostApiRequest,
  props<{
    requestType: string;
    params?: Dictionary<any>;
    headers?: Dictionary<any>;
    body?: Dictionary<any>;
    resources?: string[];
    callbackData?: Dictionary<any>;
  }>()
);

/**
 * Sends a DELETE request
 */
export const deleteApiRequest = createAction(
  ApiActionTypes.DeleteApiRequest,
  props<{ requestType: string; params?: Dictionary<any>; headers?: Dictionary<any>; body?: Dictionary<any>; resources?: string[] }>()
);

/**
 * The action for when a response comes back
 */
export const apiResponse = createAction(
  ApiActionTypes.ApiResponse,
  props<{
    isOk: boolean;
    status: string;
    requestType: string;
    response: any;
    params: Dictionary<any>;
    body?: Dictionary<any>;
    resources?: string[];
    callbackData?: any;
  }>()
);

/**
 * The action for when a response comes back from static data
 */
export const staticApiResponseAction = createAction(
  ApiActionTypes.StaticApiResponse,
  props<{ isOk: boolean; status: string; requestType: string; response: any }>()
);
