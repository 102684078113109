/**
 * This is the typscript imports of the guest ui library. To update the
 * components.js in this same directory and compile it, you need to
 * run:
 *
 * 	# npx rollup --config rollup.config.ts
 *
 * It will then generate the new component.js that you can commit to
 * the repository.
 */

import {
  GapButton,
  GapButtonGroup,
  GapCard,
  GapCheckbox,
  GapChip,
  GapFormControl,
  GapIcon,
  GapIconography,
  GapInput,
  GapLine,
  GapLinearProgress,
  GapMenu,
  GapPaymentIcon,
  GapRadioGroup,
  GapSelect,
} from 'guest-app-ui';

GapButton.registerComponent();
GapButtonGroup.registerComponent();
GapCard.registerComponent();
GapCheckbox.registerComponent();
GapChip.registerComponent();
GapFormControl.registerComponent();
GapIcon.registerComponent();
GapIconography.registerComponent();
GapPaymentIcon.registerComponent();
GapInput.registerComponent();
GapLine.registerComponent();
GapLinearProgress.registerComponent();
GapMenu.registerComponent();
GapRadioGroup.registerComponent();
GapSelect.registerComponent();
