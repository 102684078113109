import Bugsnag from '@bugsnag/js';
import { cloneDeep, isNumber, isObject, toNumber } from 'lodash-es';
import { ERROR_CODE_INVALID_AMOUNT, ERROR_CODE_INVALID_CONFIGURATION } from 'src/app/shared/enums/error-code.enums';
import { validArray, validObject, validString } from 'src/app/shared/utilities/types.utils';
import { v4 as uuidv4 } from 'uuid';
import { FeeItemDetails, LineItemDetails, ParentConfig, ParentConfigState } from './parent-config.state';

export interface LineItemsAndFees {
  cartItems: Partial<LineItemDetails>[];
  fees: FeeItemDetails[];
}

/**
 * Set Bugsnag meta data
 * @param sessionId
 * @param userId
 */
export function setBugnsnagClientConfig(sessionId, userId = ''): void {
  const bugsnagClientConfig = Bugsnag.getMetadata('ClientConfig');
  bugsnagClientConfig.parentSessionId = sessionId;
  Bugsnag.addMetadata('ClientConfig', bugsnagClientConfig);

  if (validString(userId)) {
    Bugsnag.setUser(userId);
  }
}

/**
 * Finds out if there are any errors in the configuration and returns the error.
 * @param payload The parent applications configuration
 */
export function isValidConfig(payload: ParentConfig): boolean {
  return validObject(payload) && isNumber(payload.amount) && payload.amount >= 0;
}

/**
 * Finds out if there are any errors in the configuration and returns the error.
 * @param payload The parent applications configuration
 */
export function getConfigErrors(payload: ParentConfig): number {
  if (!isObject(payload)) {
    return ERROR_CODE_INVALID_CONFIGURATION;
  } else if (!isNumber(payload.amount) || payload.amount < 0) {
    return ERROR_CODE_INVALID_AMOUNT;
  }

  return null;
}

export function canSaveCard(state: ParentConfigState): boolean {
  const { config } = state;
  if (!config.user || config.creditCard?.disableSave) {
    return false;
  }

  const { user } = config;

  return !!user && validString(user.accessToken) && validString(user.customerId);
}

export function getLineItemsAndFees(state: ParentConfigState): LineItemsAndFees {
  const { config } = state;
  const lineItemsObj = { cartItems: [], fees: [] };
  if (!validArray(config?.lineItems)) {
    return lineItemsObj;
  }

  config.lineItems.forEach((item) => {
    if (item.type === 'lineitem') {
      const { quantity, unitPrice, taxAmount, feeAmount } = item.details as Partial<LineItemDetails>;
      lineItemsObj.cartItems.push({
        ...item.details,
        quantity: toNumber(quantity) || 0,
        unitPrice: toNumber(unitPrice) || 0,
        taxAmount: toNumber(taxAmount) || 0,
        feeAmount: toNumber(feeAmount) || 0,
      });
    } else if (item.type === 'fee') {
      const { label, amount, tax } = item.details;
      lineItemsObj.fees.push({ name: label, amount: toNumber(amount) || 0, tax: toNumber(tax) || 0 });
    }
  });

  return lineItemsObj;
}

/**
 * Checks if amount is non-zero amount. Amounts not equal to 0 are not considered complimentary
 * @param orderTotal order amount
 */
export function isComplimentaryOrder(orderTotal: number): boolean {
  return !orderTotal || orderTotal === 0;
}

/**
 * Fixes inconsistencies in the payload
 * @param config The parent apps configuration
 */
export function convertPayload(config: ParentConfig): ParentConfig {
  const configCopy = cloneDeep(config);
  const { user, disablePayments, delivery, cartId } = configCopy;

  if (!validString(cartId)) {
    configCopy.cartId = uuidv4(); // Generate random cartId if not present so it can be passed back to the parent app
  }

  if (isObject(user)) {
    if (validString(user.phone)) {
      user.phone = user.phone.replace(/[^\d]/g, '');
    }

    if (validString(user.phoneCountryCode)) {
      if (user.phoneCountryCode.charAt(0) === '+') {
        user.phoneCountryCode = user.phoneCountryCode.substring(1);
      }
    }

    if (isObject(disablePayments)) {
      if (!validArray(disablePayments.cardBrandName)) {
        disablePayments.cardBrandName = [];
      }

      if (!validArray(disablePayments.paymentMethodName)) {
        disablePayments.paymentMethodName = [];
      }
    }
  }

  if (isObject(delivery) && isObject(delivery.address)) {
    if (validString(delivery.address.phone)) {
      delivery.address.phone = delivery.address.phone.replace(/[^\d]/g, '');
    }

    if (validString(user.phoneCountryCode)) {
      if (delivery.address.phoneCountryCode.charAt(0) === '+') {
        delivery.address.phoneCountryCode = delivery.address.phoneCountryCode.substring(1);
      }
    }
  }

  return configCopy;
}
