import { createAction, props } from '@ngrx/store';

import { AuthorizedPaymentInfo } from 'src/app/core/application-bridge/application-bridge.models';
import { CountriesProperties } from '../dynamic-form/utilities/countries-props.models';

export enum BillingActionTypes {
  InitializeGooglePay = '[Merchant Details Response] Initialize Google Pay',
  UpdateApplePayAvailabilityWrapper = '[Apple Pay Effects] Update Apple Pay Availability Wrapper',
  UpdateAmazonPayAvailability = '[Amazon Pay Service] Update Amazon Pay Availability',
  UpdateApplePayAvailability = '[Apple Pay Effects] Update Apple Pay Availability',
  UpdateApplePayAvailabilityOnParent = '[Window Message] Update Apple Pay Availability',
  UpdateCreditCardAvailability = '[Payment Configuration Effects] Update CC Availability',
  UpdateCreditCardAvailabilityFromConfiguredPayments = '[Payment Configuration Effects] Update CC Availability',
  UpdateGooglePayAvailability = '[Google Pay Resolve] Update Google Pay Availability',
  UpdateAlternatePaymentAvailability = '[Payment Configuration Effects] Update Alternate Payments Availability',
  UpdateAlternatePaymentDemographics = '[Alt Pay Demographics Screen] Update Alternate Payments Demographics',
  UpdateUpliftAvailability = '[Payment Configuration Effects] Update Uplift Availability',
  ClearAlternatePaymentDemographics = '[Alt Pay Demographics Screen] Clear Alternate Payments Demographics',
  HideAlternatePaymentsAction = '[Payment Configuration Effects] Hide Alternate Payments',
  UpdateBillingDemographics = '[Credit Card Screen] Update Billing Demographics',
  InitiateCybersource3DSTransaction = '[Credit Card Screen] Initiate Cybersource 3DS Transaction',
  RouteToRedirectResult = '[Parent Config Effects] Route to Redirect Results',
  GatherPaymentDetailsForStoredWallet = '[Stored Wallet Effects] Gather Payment Details',
  GatherPaymentDetailsForAmazonPay = '[Amazon Pay Effects] Gather Payment Details',
  GatherPaymentDetailsForApplePay = '[Apple Pay Service] Gather Payment Details',
  GatherPaymentDetailsForGooglePay = '[Google Pay Service] Gather Payment Details',
  GatherPaymentDetailsForCybersource3DS = '[Cardinal 3DS Service] Gather Payment Details',
  GatherPaymentDetailsForAdyen3DS = '[Adyen 3DS Service] Gather Payment Details',
  GatherPaymentDetailsForPayPal = '[PayPal Service] Gather Payment Details',
  GatherPaymentDetailsForGiftCardOnlyPayment = '[GiftCard Effects] Gather Gift Card Only Payment Details',
  GatherPaymentDetailsForCreditCard = '[Credit Card Effects] Gather Payment Details',
  GatherPaymentDetailsForRedirectPayment = '[Redirect Result] Gather Payment Details',
  GatherPaymentDetailsForAdyenWebComponent = '[Adyen Effects] Gather Payment Details for Adyen Web Component',
  GatherPaymentDetailsForUpliftPayment = '[Uplift Service] Gather Payment Details',
  UpdatePayPalAvailability = '[PayPal Service] Update PayPal Availability',
  UpdateGiftCardAvailability = '[Payment Configuration Effects] Update Gift Card Availability',
  FinalizePaymentDetails = '[Billing Effects] Finalize Payment Details',
  InitializeGiftCardSplitPayment = '[Billing Effects] Initialize Gift Card Split Payment With Cybersource 3DS',
  SplitPaymentWithGiftCardSuccess = '[Gift Card Effects] Gift Card Split Payment With Cybersource 3DS Success',
  GetCountriesList = '[Billing Form Options] Load Country Codes',
  GetPhoneCountriesList = '[Billing Form Options] Load Telephone Country Codes',
}

// Google Pay Actions
export const initializeGooglePayAction = createAction(BillingActionTypes.InitializeGooglePay);
export const updateGooglePayAvailabilityAction = createAction(BillingActionTypes.UpdateGooglePayAvailability, props<{ status: boolean }>());

// Credit Card Actions
export const updateCreditCardAvailabilityAction = createAction(BillingActionTypes.UpdateCreditCardAvailability, props<{ status: boolean }>());
export const updateCreditCardAvailabilityWithConfiguredPaymentsAction = createAction(
  BillingActionTypes.UpdateCreditCardAvailabilityFromConfiguredPayments,
  props<{ status: boolean }>()
);
export const updateBillingDemographicsAction = createAction(BillingActionTypes.UpdateBillingDemographics, props<{ payload: Partial<DemographicsFormData> }>());

// Credit Card 3DS Actions
export const initiateCybersource3DSTransactionAction = createAction(BillingActionTypes.InitiateCybersource3DSTransaction, props<{ payload: ThreeDSData }>());
export const initializeGiftCardSplitPaymentWithCybersource3DSAction = createAction(
  BillingActionTypes.InitializeGiftCardSplitPayment,
  props<{ amount: number; payload: ThreeDSData }>()
);
export const giftCardSplitPaymentWithCybersource3DSSuccessAction = createAction(
  BillingActionTypes.SplitPaymentWithGiftCardSuccess,
  props<{ payload: ThreeDSData }>()
);

// Amazon Pay Action
export const updateAmazonPayAvailabilityAction = createAction(BillingActionTypes.UpdateAmazonPayAvailability, props<{ status: boolean }>());

// Apple Pay Actions
export const updateApplePayAvailabilityWrapperAction = createAction(BillingActionTypes.UpdateApplePayAvailabilityWrapper, props<{ status: boolean }>());
export const updateApplePayAvailabilityAction = createAction(BillingActionTypes.UpdateApplePayAvailability, props<{ status: boolean }>());
export const updateApplePayAvailabilityOnParentAction = createAction(BillingActionTypes.UpdateApplePayAvailability, props<{ status: boolean }>());

// PayPal Action
export const updatePayPalAvailabilityAction = createAction(BillingActionTypes.UpdatePayPalAvailability, props<{ status: boolean }>());

// Alternate Payments Actions
export const updateAlternatePaymentAvailabilityAction = createAction(BillingActionTypes.UpdateAlternatePaymentAvailability, props<{ payload: string }>());
export const updateAlternatePaymentDemographicsAction = createAction(
  BillingActionTypes.UpdateAlternatePaymentDemographics,
  props<{ payload: Partial<DemographicsFormData> }>()
);
export const clearAlternatePaymentDemographicsAction = createAction(BillingActionTypes.ClearAlternatePaymentDemographics);
export const hideAlternatePaymentsAction = createAction(BillingActionTypes.HideAlternatePaymentsAction);

// Gift Card Actions
export const updateGiftCardAvailabilityAction = createAction(BillingActionTypes.UpdateGiftCardAvailability, props<{ status: boolean }>());

// Uplift Actions
export const updateUpliftAvailabilityAction = createAction(BillingActionTypes.UpdateUpliftAvailability, props<{ status: boolean }>());

// Redirect Actions

/**
 * Handle redirecting to alternate payments results
 */
export const routeToRedirectResultAction = createAction(BillingActionTypes.RouteToRedirectResult);

// Payment Authorized Actions
export const gatherPaymentDetailsForStoredWalletAction = createAction(
  BillingActionTypes.GatherPaymentDetailsForStoredWallet,
  props<{ payload: AuthorizedPaymentInfo }>()
);
export const gatherPaymentDetailsForGooglePayAction = createAction(
  BillingActionTypes.GatherPaymentDetailsForGooglePay,
  props<{ payload: AuthorizedPaymentInfo }>()
);
export const gatherPaymentDetailsForAmazonPayAction = createAction(
  BillingActionTypes.GatherPaymentDetailsForAmazonPay,
  props<{ payload: AuthorizedPaymentInfo }>()
);
export const gatherPaymentDetailsForApplePayAction = createAction(
  BillingActionTypes.GatherPaymentDetailsForApplePay,
  props<{ payload: AuthorizedPaymentInfo }>()
);
export const gatherPaymentDetailsAdyen3DSAction = createAction(BillingActionTypes.GatherPaymentDetailsForAdyen3DS, props<{ payload: AuthorizedPaymentInfo }>());
export const gatherPaymentDetailsForPayPalAction = createAction(BillingActionTypes.GatherPaymentDetailsForPayPal, props<{ payload: AuthorizedPaymentInfo }>());
export const gatherPaymentDetailsForGiftCardOnlyPaymentAction = createAction(
  BillingActionTypes.GatherPaymentDetailsForGiftCardOnlyPayment,
  props<{ payload: AuthorizedPaymentInfo[] }>()
);
export const gatherPaymentDetailsForCreditCardAction = createAction(
  BillingActionTypes.GatherPaymentDetailsForCreditCard,
  props<{ payload: AuthorizedPaymentInfo }>()
);
export const gatherPaymentDetailsForCybersource3DSAction = createAction(
  BillingActionTypes.GatherPaymentDetailsForCybersource3DS,
  props<{ payload: AuthorizedPaymentInfo }>()
);
export const gatherPaymentDetailsForRedirectPaymentAction = createAction(
  BillingActionTypes.GatherPaymentDetailsForRedirectPayment,
  props<{ payload: AuthorizedPaymentInfo }>()
);
export const gatherPaymentDetailsForAdyenWebComponentAction = createAction(
  BillingActionTypes.GatherPaymentDetailsForAdyenWebComponent,
  props<{ payload: AuthorizedPaymentInfo }>()
);
export const gatherPaymentDetailsForUpliftPaymentAction = createAction(
  BillingActionTypes.GatherPaymentDetailsForUpliftPayment,
  props<{ payload: AuthorizedPaymentInfo }>()
);
export const finalizeFullPaymentDetailsAction = createAction(BillingActionTypes.FinalizePaymentDetails, props<{ payload: AuthorizedPaymentInfo }>());

// FormOptions Actions

export const loadCountryCodesAction = createAction(
  BillingActionTypes.GetCountriesList,
  props<{ countries: CountriesProperties[]; preferredCountriesFound: number }>()
);
export const loadPhoneCountryCodesAction = createAction(
  BillingActionTypes.GetPhoneCountriesList,
  props<{ countries: CountriesProperties[]; preferredTelCodesFound: number }>()
);
