import { createSelector } from '@ngrx/store';
import { selectAppConfig } from 'src/app/core/application-config/application-config.selectors';
import { selectParentConfig, selectParentConfigAmount } from 'src/app/core/parent-config/parent-config.selectors';
import { selectDeliveryAmount } from 'src/app/feature/delivery/delivery.selectors';
import { selectGiftCardState } from 'src/app/feature/gift-card/gift-card.selectors';
import { toDecimal } from '../utilities/number.utils';
import { selectDonation } from './../../feature/extras/extras.selectors';
import { selectPaymentBalance } from 'src/app/feature/payment/payment.selectors';

export const selectRequireCVV = createSelector(selectAppConfig(['require_stored_cvv']), selectParentConfig, (appConfigs, parentConfig): boolean => {
  return appConfigs.require_stored_cvv || parentConfig.config?.creditCard?.requireStoredCVV || false;
});

/**
 * This is the sum of base amount + delivery.
 * This was intended to be sum total but another selector has been created to avoid conflict.
 */
export const selectTotalAmount = createSelector(selectParentConfigAmount, selectDeliveryAmount, (parentConfigAmount, deliveryAmount): number => {
  return toDecimal(parentConfigAmount + deliveryAmount, 2);
});

export const selectPaymentAmount = createSelector(
  selectTotalAmount,
  selectGiftCardState,
  selectPaymentBalance,
  (totalAmount, giftCardState, appliedBalance): number => {
    const total = totalAmount - appliedBalance;
    if (!giftCardState.isGiftCardOnlyPayment && giftCardState.totalBalance > 0) {
      return toDecimal(total - giftCardState.totalBalance, 2);
    }
    return total;
  }
);

/**
 * Payment amount with donation add-on
 */
export const selectPaymentAmountWithDonation = createSelector(
  selectTotalAmount,
  selectGiftCardState,
  selectDonation,
  selectPaymentBalance,
  (totalAmount, giftCardState, donationState, appliedBalance): number => {
    const donationAmount = donationState.configured && !donationState.error && donationState.amount > 0 ? donationState.amount : 0;
    const total = totalAmount + donationAmount - appliedBalance;
    if (!giftCardState.isGiftCardOnlyPayment && giftCardState.totalBalance > 0) {
      return toDecimal(total - giftCardState.totalBalance, 2);
    }
    return total;
  }
);
