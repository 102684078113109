import { ApiStatus } from 'src/app/core/api/api.interface';
import { InsuranceAuthAPIResponse, InsuranceAuthRequest, InsuranceAuthResponse } from 'src/app/core/api/responses/authorize';
import { TicketProtectionState } from 'src/app/feature/extras/extras.state';
import { toDecimal } from './number.utils';
import { returnOrDefaultString } from './string.utils';

export interface InsuranceCalcResult {
  amount: number;
  insurance: InsuranceAuthRequest;
}
/**
 * Returns fixed keys for insurance payload integration
 * @param response insurance auth response from auth request
 */
export function convertInsuranceResponse(response: InsuranceAuthAPIResponse): InsuranceAuthResponse {
  return {
    premiumAmount: response.insurance_amount,
    paymentStatus: response.payment_status || ApiStatus.PENDING,
    splitInsurance: response.split_insurance || false,
    approvalCode: returnOrDefaultString(response.approval_code),
    gatewayTxnReference: returnOrDefaultString(response.gateway_txn_reference),
    policyInfoId: returnOrDefaultString(response.policy_info_id),
  };
}

/**
 * Format insurance values to include in auth request
 * @param insurance insurance state
 */
export function formatInsuranceRequest(insuranceState: TicketProtectionState): InsuranceAuthRequest {
  return {
    insurance_quote_token: insuranceState.quoteToken,
    premium_amount: insuranceState.quoteAmount || 0,
    split_insurance: insuranceState.splitInsurance || false,
    ...(insuranceState.policyInfoId && { policy_info_id: insuranceState.policyInfoId }),
  };
}

/**
 * Calculate the transaction's amount involving insurance
 * @param amountDiff the difference of order amount and giftcardBalance
 * @param insurance request node with the insurance properties
 * @returns amount and donation request object
 */
export function calculateSplitAmountWithInsurance(amountDiff: number, insuranceState: TicketProtectionState): InsuranceCalcResult {
  let amount = amountDiff,
    insurance = null;
  if (insuranceState.isProtected) {
    if (insuranceState.splitInsurance) {
      insurance = formatInsuranceRequest(insuranceState);
    } else {
      const { amount: calculatedAmount, insurance: insuranceAfterCalculations } = calculateConsolidateInsurance(amountDiff, insuranceState);
      amount = calculatedAmount;
      insurance = insuranceAfterCalculations;
    }
  }
  return { amount, insurance };
}

/**
 * calculations for consolidate insurance
 * @param amountDiff
 * @param insuranceState
 * @returns amount and donation request object
 */
function calculateConsolidateInsurance(amountDiff: number, insuranceState: TicketProtectionState): InsuranceCalcResult {
  let insurance: InsuranceAuthRequest = null,
    amount: number = amountDiff;
  // if amountDiff is negative, then the amount is less than the giftcard balance
  if (amountDiff < 0) {
    // if the insurance amount is greater than 0, then format the insurance request with the new amount
    // if not means the remain value is returned for further calcs
    const totalAmount = toDecimal(insuranceState.quoteAmount + amountDiff, 2);
    if (totalAmount > 0) {
      insurance = formatInsuranceRequest({ ...insuranceState, quoteAmount: totalAmount });
    }
    amount = totalAmount > 0 ? 0 : totalAmount;
  } else {
    insurance =
      insuranceState.quoteAmount > 0 && insuranceState.isProtected
        ? formatInsuranceRequest({ ...insuranceState, quoteAmount: insuranceState.quoteAmount } as any)
        : null;
  }
  return { insurance, amount };
}
