import { Action, createReducer, on } from '@ngrx/store';
import { SessionState, initialState } from './session.state';

import Bugsnag from '@bugsnag/js';
import { apiResponse } from '../api/api.actions';
import { validString } from 'src/app/shared/utilities/types.utils';
import { sendMessageAction } from '../application-bridge/application-bridge.actions';
import {
  EVENT_CANCEL,
  EVENT_PAYMENT_CANCELLED,
  EVENT_PAYMENT_COMPLETE,
  EVENT_PAYMENT_FAILED,
  EVENT_PAYMENT_REDIRECT,
} from 'src/app/shared/enums/application-bridge.enums';
import { setAuthorizingAction } from './session.actions';

const onApiResponse = on(apiResponse, (state: SessionState, action) => {
  if (!action.isOk) {
    return state;
  }
  const id = action.response.session_id;
  if (validString(id)) {
    const config = Bugsnag.getMetadata('ClientConfig');
    config.sessionId = id;
    Bugsnag.addMetadata('ClientConfig', config);

    return {
      ...state,
      id,
    };
  }
  return state;
});

/**
 * Sets the authorizing flag. This prevents movement within accesso pay components so we can finalize the payment.
 */
const onSetAuthorizing = on(setAuthorizingAction, (state: SessionState, action) => ({
  ...state,
  authorizing: action.authorizing,
}));

/**
 * Resets the authorizing flag whenever a payment is canceled, failed, complete or we need to redirect.
 */
const onResetAuthorizing = on(sendMessageAction, (state: SessionState, action) => {
  if (![EVENT_CANCEL, EVENT_PAYMENT_FAILED, EVENT_PAYMENT_CANCELLED, EVENT_PAYMENT_COMPLETE, EVENT_PAYMENT_REDIRECT].includes(action.key)) {
    return state;
  }

  return {
    ...state,
    authorizing: false,
  };
});

const reducer = createReducer(initialState, onApiResponse, onResetAuthorizing, onSetAuthorizing);

export function sessionReducer(state: SessionState, action: Action): SessionState {
  return reducer(state, action) as SessionState;
}
