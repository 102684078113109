import { Component } from '@angular/core';

/**
 * @ngModule AcSpinnerModule
 * @description
 *
 * <storybook-example story="components-spinner--default"></storybook-example>
 */
@Component({
  selector: 'ac-spinner',
  template: '<div class="ac-spinner"></div>',
  styleUrls: ['./spinner.component.scss'],
})
export class AcSpinnerComponent {}
