import { createSelector, select, Store } from '@ngrx/store';

import { Injectable } from '@angular/core';
import { CanActivateChild } from '@angular/router';
import { AppState } from 'src/app/app.state';
import { routeToWithOptions } from '../../core/routing/routing.actions';
import { selectDelivery } from '../delivery/delivery.selectors';
import { selectDonation, selectShowTicketProtection, selectTicketInsuranceQuoteToken } from '../extras/extras.selectors';
import { selectShowPaymentOptions } from './payment-options.selectors';

@Injectable({
  providedIn: 'root',
})
export class PaymentOptionsGuard implements CanActivateChild {
  /**
   * Has the application rendered a previous page
   */
  private hasRenderedPrevPage: boolean = false;
  /**
   * Recurring payment
   */
  private showPaymentOptionsPage: boolean = false;

  /**
   * @param store The store
   * @param deliveryGuard The delivery guard
   */
  constructor(private store: Store<AppState>) {
    this.store
      .pipe(
        select(
          createSelector(
            selectDonation,
            selectDelivery,
            selectShowTicketProtection,
            selectTicketInsuranceQuoteToken,
            selectShowPaymentOptions,
            (donationState, { deliveryMethodsConfigured }, showTicketProtection, insuranceQuoteToken, showPaymentOptionsPage) => ({
              hasRenderedDonation: donationState.configured && donationState.rendered,
              hasDeliveryConfigured: deliveryMethodsConfigured,
              hasRenderedTicketProtection: showTicketProtection && !!insuranceQuoteToken,
              insuranceQuoteToken,
              showPaymentOptionsPage,
            })
          )
        )
      )
      .subscribe(({ hasRenderedDonation, hasDeliveryConfigured, hasRenderedTicketProtection, showPaymentOptionsPage }) => {
        this.showPaymentOptionsPage = showPaymentOptionsPage;
        this.hasRenderedPrevPage = hasDeliveryConfigured || hasRenderedDonation || hasRenderedTicketProtection;
      });
  }

  /**
   * Guards route by checking the extras and payment options configuration
   */
  canActivateChild(): boolean {
    if (this.showPaymentOptionsPage) {
      return true;
    } else {
      // Check previous steps configuration
      this.store.dispatch(routeToWithOptions({ routeParams: ['select'], options: { replaceUrl: !this.hasRenderedPrevPage } }));
      return false;
    }
  }
}
