import { ApiServiceResponse } from '../api.interface';
import { GatewayConfiguration, filterValidConfiguration, selectConfiguration } from '../api.selectors';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { catchError, filter, map, take } from 'rxjs/operators';
import { get, isObject, isString } from 'lodash-es';
import { isNullOrUndefined, isNumeric, validString } from 'src/app/shared/utilities/types.utils';

import { AcLoggerService } from '../../logger/logger.service';
import { AppState } from 'src/app/app.state';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { getHeaders, getParams } from '../api.utilities';

@Injectable()
export class LogInterceptor implements HttpInterceptor {
  /**
   * The gateway URL to match against.
   */
  private gatewayUrl: string;

  constructor(private logger: AcLoggerService, private store: Store<AppState>) {
    store
      .select(selectConfiguration)
      .pipe(filter(filterValidConfiguration), take(1))
      .subscribe((configuration: GatewayConfiguration) => {
        this.gatewayUrl = configuration.gatewayUrl;
      });
  }

  /**
   * Intercepts HTTP communication and logs the request and response to the console if enabled
   * @param req The HTTP request object
   * @param next The HTTP handler
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.logger.isEnabled()) {
      return next.handle(req);
    }

    const start = Date.now();
    const { log } = this.logger;
    const { method } = req;
    const isGatewayRequest = req.url.indexOf(this.gatewayUrl) === 0;
    const requestType = getRequestType(isGatewayRequest, req.params);

    // Our request log group
    const requestLabel = `%c [HTTP Request] ${method}${requestType}`;
    console.groupCollapsed(requestLabel, `color: purple`);
    log(`URL: ${req.url}`);
    log(`Body`, req.body);
    log('Headers', getHeaders(req.headers));
    log('Params', getParams(req.params));
    console.groupEnd();

    return next.handle(req).pipe(
      map((event) => {
        if (event instanceof HttpResponse) {
          let body = event.body;
          try {
            body = JSON.parse(body);
          } catch (ignore) {}

          // Our response log group
          const end = Date.now();
          const errorMsg = get(event, 'body.error_msg');
          const errorCode = get(event, 'body.error_code');
          const error =
            validString(errorCode) || validString(errorMsg) ? ` - [Code: ${validString(errorCode) ? errorCode : 'none provided'}] - ${errorMsg}` : '';
          const responseLabel = `%c [HTTP Response] ${req.method}${requestType}${error} (${((end - start) / 1000).toFixed(2)}/sec)`;
          console.groupCollapsed(responseLabel, `color: ${getColorByStatus(event, isGatewayRequest, body)}`);
          log(`Http Status Code`, event.status);
          log(`URL: ${req.url}`);
          log(`Data`, body);
          console.groupEnd();
        }

        return event;
      }),
      catchError((event) => {
        if (event instanceof HttpErrorResponse) {
          const end = Date.now();
          const errorMsg = get(event, 'message');
          const errorCode = get(event, 'status');
          const error = validString(errorMsg) ? ` - [Code: ${isNumeric(errorCode) ? errorCode : 'none provided'}] - ${errorMsg}` : '';
          const responseLabel = `%c [HTTP Response] ${req.method}${requestType}${error} (${((end - start) / 1000).toFixed(2)}/sec)`;
          console.groupCollapsed(responseLabel, `color: red`);
          log(`Http Status Code`, event.status);
          log(`URL: ${req.url}`);
          log(`Error`, event.error);
          console.groupEnd();
        }
        return throwError(event);
      })
    );
  }
}

/**
 * Returns the request type if its an API response
 * @param isGatewayRequest If this specific request was a gateway request
 * @param body The response object
 */
function getRequestType(isGatewayRequest: boolean, params: HttpParams) {
  const requestType = params.get('requestType');
  if (isGatewayRequest && isString(requestType)) {
    return ` ${requestType}`;
  } else if (validString(requestType)) {
    return ` ${requestType}`;
  }

  return '';
}

/**
 * Get the color to use by the status.
 * @param event The http response object
 * @param isGatewayRequest If it's a gateway request or not
 * @param body The body of the response
 */
function getColorByStatus(event: HttpResponse<ApiServiceResponse>, isGatewayRequest: boolean, body: ApiServiceResponse): string {
  if (isGatewayRequest && !isNullOrUndefined(event) && isObject(body)) {
    const { status } = event;
    if (isNumeric(status) && !validString(event.body.status)) {
      return getColorByStatusCode(status);
    } else if (body.status === 'OK') {
      return 'green';
    }
    return 'red';
  }

  return getColorByStatusCode(event.status);
}

/**
 * Returns the color to use with a specific http response code
 * @param code The http status code
 */
function getColorByStatusCode(code: string | number): string {
  if (inRange(200, 299, code)) {
    return 'green';
  } else if (inRange(500, 599, code)) {
    return 'red';
  }
  return 'orange';
}

/**
 * Returns the text for if the request actually went through or not, not the real http response.
 * @param code The http status code
 */
function getStatusText(code: number) {
  if (inRange(200, 299, code)) {
    return 'OK';
  } else if (inRange(400, 599, code)) {
    return 'FAILED';
  } else {
    return 'UNKNOWN';
  }
}

/**
 * Find out if a number is in range
 * @param start The starting range
 * @param stop The end of the range
 * @param test The number to test
 */
function inRange(start, stop, test) {
  return test >= start && test <= stop;
}
