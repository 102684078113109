/**
 * The Extras state interface
 */
export interface ExtrasState {
  donation: DonationState;
  ticketProtection: TicketProtectionState;
}

/**
 * Donation State
 */
export interface DonationState {
  configured: boolean;
  rendered: boolean;
  splitDonation: boolean;
  amount: number;
  type?: string;
  error?: boolean;
  continued?: boolean;
}
/**
 * Ticket Protection State
 */
export interface TicketProtectionState {
  configured: boolean;
  error: boolean;
  quoteToken: string;
  quoteAmount: number;
  isProtected: boolean;
  splitInsurance: boolean;
  continued?: boolean;
  policyInfoId?: string;
}

/**
 * The initial state for extras
 */
export const initialState: ExtrasState = {
  donation: {
    configured: false,
    error: false,
    rendered: false,
    splitDonation: false,
    amount: 0,
    type: null,
    continued: false,
  },
  ticketProtection: {
    configured: false,
    error: false,
    quoteToken: null,
    quoteAmount: 0,
    isProtected: false,
    continued: false,
    splitInsurance: true,
  },
};
