import { UntypedFormGroup } from '@angular/forms';
import { DynamicFormValidation } from 'src/app/shared/utilities/form.validation.util';
import { validString } from 'src/app/shared/utilities/types.utils';

export function emailMatchValidator(fieldKey: string, matchField: string) {
  return (formGroup: UntypedFormGroup): { emailMatch: boolean } | null => {
    const fieldControl = formGroup.get(fieldKey);
    const fieldToMatch = formGroup.get(matchField);

    if (!fieldToMatch) {
      return;
    }

    if (!validString(fieldControl.value)) {
      fieldControl.setErrors({
        required: true,
      });
      return;
    }

    if (!new RegExp(DynamicFormValidation.getEmail()).test(fieldControl.value)) {
      fieldControl.setErrors({
        email: true,
      });
      return;
    }

    if (fieldToMatch.value !== fieldControl.value) {
      fieldControl.setErrors({
        emailMatch: true,
      });
      return;
    }

    fieldControl.setErrors(null);
  };
}
