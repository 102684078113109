import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, createSelector } from '@ngrx/store';
import { isBoolean } from 'lodash-es';
import { WINDOW } from 'src/app/core/injection-token/window/window';
import { EMPTY } from 'rxjs';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { sendAnalyticsEventAction } from 'src/app/core/analytics/analytics.actions';
import { apiResponse, postApiRequest } from 'src/app/core/api/api.actions';
import { responseByModule, responseRequestType } from 'src/app/core/api/api.utilities';
import { receiveMessageAction, sendMessageAction } from 'src/app/core/application-bridge/application-bridge.actions';
import { PaymentType } from 'src/app/core/application-bridge/application-bridge.models';
import { initializeStart } from 'src/app/core/initialize/initialize.actions';
import { LocaleService } from 'src/app/core/locale/locale.service';
import { NotificationDialogType } from 'src/app/core/notification/dialog/confirm-dialog/confirm-dialog.component';
import { showNotificationAction } from 'src/app/core/notification/notification.actions';
import { selectRecurring } from 'src/app/core/parent-config/parent-config.selectors';
import {
  updatePaymentConfigurationAction,
  updatePaymentConfigurationWithConfiguredPaymentsAction,
} from 'src/app/core/payment-configuration/payment-configuration.actions';
import { selectApplePayCards } from 'src/app/core/payment-configuration/payment-configuration.selectors';
import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import {
  EVENT_APPLEPAY_AUTHORIZED,
  EVENT_APPLEPAY_ON_AVAILABLE,
  EVENT_APPLEPAY_PAYMENT_RESPONSE,
  EVENT_APPLEPAY_VALIDATE_MERCHANT,
} from 'src/app/shared/enums/application-bridge.enums';
import { selectTotalAmount } from 'src/app/shared/selectors/configuration.selectors';
import { validArray } from 'src/app/shared/utilities/types.utils';
import * as ExtrasAction from '../../extras/extras.actions';
import { isFreedomPay } from '../../freedompay/freedompay.utils';
import { selectGiftCardState } from '../../gift-card/gift-card.selectors';
import { hasPreAuthGiftCards } from '../../gift-card/gift-card.utils';
import { updateApplePayAvailabilityAction, updateApplePayAvailabilityOnParentAction, updateApplePayAvailabilityWrapperAction } from '../billing.actions';
import {
  cancelApplePayTransactionAction,
  giftCardSplitPaymentWithApplePaySuccessAction,
  initializeGiftCardSplitPaymentWithApplePayAction,
  initiateApplePayEWalletAuthorizationAction,
  initiateApplePayTransactionAction,
} from './applepay.actions';
import { ApplePayService } from './applepay.service';

const { APPLE_PAY } = PaymentType;
@Injectable()
export class ApplePayEffects {
  private hasApplePayCards: boolean;

  /**
   * Requests information from the ApplePaySession object on the window to see if its available.
   */
  initialize$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initializeStart),
        map(() => this.applePayService.init())
      ),
    { dispatch: false }
  );

  /**
   * Watches for when we try to cancel the apple pay transaction
   */
  cancelApplePay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelApplePayTransactionAction),
      map(() => {
        return sendAnalyticsEventAction({
          action: 'pay',
          category: 'apple pay',
          label: 'payment canceled',
          nonInteraction: true,
        });
      })
    )
  );

  /**
   * Listens for the apple pay check to see if it's available.
   */
  applePayCheck$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(receiveMessageAction),
        filter(({ key }) => key === EVENT_APPLEPAY_ON_AVAILABLE),
        withLatestFrom(this.store.select(createSelector(selectApplePayCards, selectRecurring, (applePayCards, recurring) => ({ applePayCards, recurring })))),
        map(([{ message }, { applePayCards, recurring }]) => {
          const noRecurrWithFreedomPay = !(isFreedomPay(applePayCards) && recurring);
          const status = message?.payload?.available && this.hasApplePayCards && noRecurrWithFreedomPay;
          this.applePayService.setAvailability(status);

          if (isBoolean(this.hasApplePayCards)) {
            this.store.dispatch(updateApplePayAvailabilityOnParentAction({ status }));
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Listens for when the merchant is set to see if we have apple pay credit cards to use.
   */
  onMerchantLoaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updatePaymentConfigurationAction, updatePaymentConfigurationWithConfiguredPaymentsAction),
        withLatestFrom(this.store.select(selectRecurring)),
        map(([{ configuredPayments }, recurring]) => {
          const hasApplePayCards = validArray(configuredPayments.applePayCards);
          const noRecurrWithFreedomPay = !(isFreedomPay(configuredPayments.applePayCards) && recurring);
          const status = this.applePayService.isAvailable && hasApplePayCards && noRecurrWithFreedomPay;
          if (this.applePayService.isInitialized || this.applePayService.isAvailable) {
            this.hasApplePayCards = hasApplePayCards;
            this.store.dispatch(updateApplePayAvailabilityWrapperAction({ status }));
          } else {
            this.hasApplePayCards = hasApplePayCards;
            this.store.dispatch(updateApplePayAvailabilityWrapperAction({ status: noRecurrWithFreedomPay }));
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * An effect wrapper that listens to update ApplePay availability action
   * Different actions will be dispatched depending on the status of ApplePay service
   */
  updateApplePayAvailabilityWrapper$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateApplePayAvailabilityWrapperAction),
      mergeMap(({ status }) => {
        return this.applePayService.isInitialized
          ? [updateApplePayAvailabilityAction({ status })]
          : this.applePayService.isAvailable
          ? [updateApplePayAvailabilityOnParentAction({ status })]
          : EMPTY;
      })
    )
  );

  /**
   * Listens when requested for ApplePay transaction initiation
   */
  onApplePayInitiation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initiateApplePayTransactionAction),
        map(() => this.applePayService.initiateTransaction())
      ),
    { dispatch: false }
  );

  /**
   * Listens when request for parent ApplePay Merchant Validation
   */
  onApplePayParentValidateMerchant$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(receiveMessageAction),
        filter(({ key }) => key === EVENT_APPLEPAY_VALIDATE_MERCHANT),
        map(({ message }) => {
          this.applePayService.onParentValidateMerchant(message?.payload as ApplePayValidateMerchantEvent);
        })
      ),
    { dispatch: false }
  );

  /**
   * Handles making payment with stored token
   */
  onEWalletAuthorize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initiateApplePayEWalletAuthorizationAction),
      withLatestFrom(
        this.store.select(createSelector(selectGiftCardState, selectTotalAmount, (giftCardState, totalAmount) => ({ giftCardState, totalAmount })))
      ),
      map(([action, currState]) => {
        if (currState.giftCardState.totalBalance > 0 && !hasPreAuthGiftCards(currState.giftCardState.giftcards)) {
          return initializeGiftCardSplitPaymentWithApplePayAction({ amount: currState.totalAmount, payload: action.payload });
        } else {
          const { eWalletRequest, callbackData } = action.payload;
          return postApiRequest({
            requestType: ApiRequestType.EWALLET_AUTHORIZE,
            body: eWalletRequest,
            params: { module: APPLE_PAY },
            callbackData,
          });
        }
      })
    )
  );

  /**
   * Completes split payment
   */
  completeSplitPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(giftCardSplitPaymentWithApplePaySuccessAction),
      map((action) => {
        const { eWalletRequest, callbackData } = action.payload;
        return postApiRequest({
          requestType: ApiRequestType.EWALLET_AUTHORIZE,
          body: eWalletRequest,
          params: { module: APPLE_PAY },
          callbackData,
        });
      })
    )
  );

  /**
   * Listens when request for parent ApplePay Payment Authorization
   */
  onApplePayParentPaymentAuthorized$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(receiveMessageAction),
        filter(({ key }) => key === EVENT_APPLEPAY_AUTHORIZED),
        map(({ message }) => {
          this.applePayService.onParentPaymentAuthorized(message?.payload as ApplePayPaymentAuthorizedEvent);
        })
      ),
    { dispatch: false }
  );

  /**
   * Handles eWallet autorization
   */
  onEWalletAuthorizeResponse$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(apiResponse),
        filter(responseRequestType(ApiRequestType.EWALLET_AUTHORIZE)),
        filter(responseByModule(APPLE_PAY)),
        map((action) => {
          const actions = [];
          const { callbackData, response } = action;
          const ApplePaySession = (this.window as any).ApplePaySession as ApplePaySession;
          const { evt, session, authFromParent } = callbackData;
          if (action.isOk) {
            if (authFromParent) {
              actions.push(
                sendMessageAction({
                  key: EVENT_APPLEPAY_PAYMENT_RESPONSE,
                  payload: { status: ApplePaySession.STATUS_SUCCESS },
                })
              );
            } else {
              session.completePayment({ status: ApplePaySession?.STATUS_SUCCESS });
            }
            this.applePayService.handleAuthorizeResponseSuccess(evt.payment as ApplePayPayment, response);
          } else {
            actions.push(
              showNotificationAction({
                buttonLabel: this.localeService.get('common.close'),
                dialogType: NotificationDialogType.GENERAL,
                initiator: 'apple pay authorize failure',
                message: this.localeService.get('applepay.redirectfailure'),
              })
            );
            if (authFromParent) {
              actions.push(
                sendMessageAction({
                  key: EVENT_APPLEPAY_PAYMENT_RESPONSE,
                  payload: { status: ApplePaySession.STATUS_FAILURE },
                })
              );
            } else {
              session.completePayment({ status: ApplePaySession.STATUS_FAILURE });
            }
            this.applePayService.handleAuthorizeResponseFailed(response);
          }
          actions.forEach((actn) => this.store.dispatch(actn));
        })
      ),
    { dispatch: false }
  );

  /**
   * Handles actions to display payment options based on the ticket protection selection
   */
  updateTicketProtectionStatusAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExtrasAction.updateTicketProtectionStatusAction),
      withLatestFrom(this.store.select(selectApplePayCards)),
      filter(([_, applePayCards]) => isFreedomPay(applePayCards)),
      map(([action]) => {
        return updateApplePayAvailabilityWrapperAction({ status: !action.payload });
      })
    )
  );

  /**
   * @param actions$ The action stream.
   * @param applePayService Our main service.
   * @param store The store.
   * @param window The window object.
   */
  constructor(
    private actions$: Actions,
    private applePayService: ApplePayService,
    private store: Store<AppState>,
    @Inject(WINDOW) private window: Window,
    private localeService: LocaleService
  ) {}
}
