import { Injectable } from '@angular/core';
import { CanActivateChild } from '@angular/router';
import { createSelector, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { EVENT_DELIVERY_INFO } from 'src/app/shared/enums/application-bridge.enums';
import { sendMessageAction } from '../../core/application-bridge/application-bridge.actions';
import { Delivery, DeliveryOption } from '../../core/parent-config/parent-config.state';
import { routeToWithOptions } from '../../core/routing/routing.actions';
import { selectParentConfigDelivery } from './../../core/parent-config/parent-config.selectors';
import { hideDeliveryViewAction, selectDeliveryMethodAction, ShowDeliveryViewAction, updateDeliveryAmountAction } from './delivery.actions';
import { selectDelivery } from './delivery.selectors';
import { DeliveryState } from './delivery.state';

@Injectable({
  providedIn: 'root',
})
export class SkipDeliveryGuard implements CanActivateChild {
  /**
   * The delivery state
   */
  private deliveryState: DeliveryState;
  /**
   * Delivery options configured
   */
  private delivery: Partial<Delivery>;
  /**
   * Display delivery page
   */
  private showDeliveryPage: boolean;

  /**
   * @param store The store
   */
  constructor(private store: Store<AppState>) {
    this.store
      .pipe(select(createSelector(selectDelivery, selectParentConfigDelivery, (deliveryState, delivery) => ({ deliveryState, delivery }))))
      .subscribe(({ deliveryState, delivery }) => {
        this.deliveryState = deliveryState;
        this.delivery = delivery;
        this.showDeliveryPage = deliveryState.deliveryMethodsConfigured;
      });
  }

  /**
   * Guards route by checking the delivery state
   */
  canActivateChild(): boolean | Observable<boolean> {
    const routeBackWithOptionsAction = routeToWithOptions({ routeParams: ['extras'], options: { replaceUrl: true } });

    if (this.deliveryState.deliveryMethodsConfigured) {
      if (this.displayDelivery(this.delivery.options)) {
        this.store.dispatch(ShowDeliveryViewAction());
        return true;
      } else {
        // Autoselect delivery method
        const deliveryMethod = this.delivery.options[0];
        [
          hideDeliveryViewAction(),
          selectDeliveryMethodAction({ payload: deliveryMethod }),
          updateDeliveryAmountAction({ payload: deliveryMethod.amount }),
          sendMessageAction({
            key: EVENT_DELIVERY_INFO,
            payload: { selectedDeliveryId: deliveryMethod.id },
          }),
          routeBackWithOptionsAction,
        ].forEach((action) => {
          this.store.dispatch(action);
        });
        return false;
      }
    }

    [hideDeliveryViewAction(), routeBackWithOptionsAction].forEach((action) => this.store.dispatch(action));

    return false;
  }

  /**
   * Check to determine whether or not to skip deliver screen
   * @param deliveryMethods delivery methods configured
   */
  displayDelivery(deliveryMethods: DeliveryOption[]): boolean {
    return deliveryMethods.length > 1 || (deliveryMethods.length === 1 && deliveryMethods[0].collectAddress);
  }
}
