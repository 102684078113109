import { SafeHtml } from '@angular/platform-browser';
import { isObject } from 'lodash-es';
import { AuthorizeResponse } from 'src/app/core/api/responses/authorize';
import { LocaleService } from 'src/app/core/locale/locale.service';
import { LineItemDetails, User } from 'src/app/core/parent-config/parent-config.state';
import { AlternatePayment, PaymentMethodCode } from 'src/app/core/payment-configuration/payment-configuration.model';
import { ExtrasState } from 'src/app/feature/extras/extras.state';
import {
  ERROR_CODE_AVS_FAILED,
  ERROR_CODE_CARD_DECLINED,
  ERROR_CODE_CVV_FAILED,
  ERROR_CODE_GENERIC_CODE,
  ERROR_CODE_INVALID_CREDIT_CARD,
} from 'src/app/shared/enums/error-code.enums';
import { UpliftError, UpliftInsuranceType } from 'src/app/shared/types/uplift';
import { dateToString, getDate } from 'src/app/shared/utilities/date.utils';
import { toCents } from 'src/app/shared/utilities/number.utils';
import { joinStrings, returnOrDefaultString } from 'src/app/shared/utilities/string.utils';
import { validString } from 'src/app/shared/utilities/types.utils';
import { TicketProtectionState } from '../extras/extras.state';

export interface OrderItems {
  addons: UpliftAddOn[];
  amount: number;
  lineItems: Partial<LineItemDetails>[];
  ticketInsurance: Partial<TicketProtectionState>;
}

/**
 * Returns uplift's event-tickets information
 * @see {@link https://docs.uplift.com/v1/docs/high-level-schema-overview#eventtickets}
 * @param userInfo user info
 * @param order items used to build order
 * @param merchantName Merchant name
 * @param tenantName Tenant name
 * @returns uplift's event tickets information
 */
export function getUpliftEventTicketDetails(params: {
  userInfo: Partial<User>;
  order: OrderItems;
  merchantName: string;
  tenantName: string;
}): UpliftEventTickets {
  const hasValidUserInfo = ['firstName', 'lastName'].every((field) => validString(params.userInfo[field]));
  const eventTicketDetails: UpliftEventTickets = {
    travelers: getUpliftUserInfo(params.userInfo),
    event_tickets: getUpliftEventTicketInformation(params.order.lineItems, params.merchantName, params.order.ticketInsurance),
    add_ons: [...params.order.addons],
    order_amount: toCents(params.order.amount),
    merchant: params.merchantName,
    merchant_field_1: params.tenantName ?? '',
    merchant_field_2: params.merchantName,
  };

  if (hasValidUserInfo) {
    eventTicketDetails.billing_contact = getUpliftBillingContact(params.userInfo);
  }

  return eventTicketDetails;
}

/**
 * Returns user information
 * @see {@link https://docs.uplift.com/docs/resource-objects#personobject}
 * @returns user info
 */
export function getUpliftUserInfo(userInfo: Partial<User>): UpliftPerson[] {
  const upliftPerson = [];
  if (validString(userInfo?.firstName) || validString(userInfo?.lastName)) {
    upliftPerson.push({
      id: 0,
      first_name: returnOrDefaultString(userInfo?.firstName),
      last_name: returnOrDefaultString(userInfo?.lastName),
      ...(userInfo.dateOfBirth ? { date_of_birth: userInfo.dateOfBirth } : null),
    });
  }
  return upliftPerson;
}

/**
 * Returns billing contact information
 * @see {@link https://docs.uplift.com/docs/resource-objects#customercontactobject}
 * @param userInfo user info
 * @returns uplift's billing contact information
 */
export function getUpliftBillingContact(userInfo: Partial<User>): UpliftBillingContact {
  return {
    first_name: returnOrDefaultString(userInfo?.firstName),
    last_name: returnOrDefaultString(userInfo?.lastName),
    phone: returnOrDefaultString(userInfo?.phone),
    email: returnOrDefaultString(userInfo?.email),
    street_address: returnOrDefaultString(joinStrings(', ', userInfo?.address1, userInfo?.address2)),
    city: returnOrDefaultString(userInfo?.city),
    region: returnOrDefaultString(userInfo?.stateProvince),
    country: returnOrDefaultString(userInfo?.country),
    postal_code: returnOrDefaultString(userInfo?.zipPostal),
  };
}

/**
 * Returns event ticket information
 * @see {@link https://docs.uplift.com/docs/resource-objects#eventticketobject}
 * @param lineItems line items
 * @param merchantName Merchant name
 * @param ticketInsurance Insurance details
 * @returns event ticket
 */
export function getUpliftEventTicketInformation(
  lineItems: Partial<LineItemDetails>[],
  merchantName: string = '',
  ticketInsurance: Partial<TicketProtectionState>
): UpliftEventTicket[] {
  const insurance: UpliftInsurance[] = [];
  if (ticketInsurance.isProtected) {
    // price in cents - https://docs.uplift.com/docs/resource-objects#insuranceobject
    insurance.push({ id: '0', types: [UpliftInsuranceType.CANCELLATION], price: toCents(ticketInsurance.quoteAmount) });
  }
  return lineItems.map((item, i) => {
    return {
      event_name: returnOrDefaultString(item?.eventInfo?.name, item?.productName),
      venue_name: merchantName,
      unit_price: toCents(item.unitPrice),
      start_date: getUpliftStartDate(item?.eventInfo?.startDate),
      end_date: getUpliftEndDate(item?.eventInfo?.endDate),
      quantity: item.quantity,
      insurance: i === 0 ? insurance : [], // apply ticket insurance for 1st item only,
    };
  });
}

/**
 * Returns uplift start date
 * @returns start date (YYYYMMDD)
 */
export function getUpliftStartDate(startDate?: string): string {
  if (!validString(startDate)) {
    const today = getDate();
    today.setTime(today.getTime());
    return dateToString(today).replace(/\D/g, '');
  }
  return startDate.replace(/\D/g, '');
}

/**
 * Returns uplift end date
 * @returns end date (YYYYMMDD)
 */
export function getUpliftEndDate(endDate?: string): string {
  if (!validString(endDate)) {
    const date = getDate();
    date.setFullYear(date.getFullYear() + 1);
    return dateToString(date).replace(/\D/g, '');
  }
  return endDate.replace(/\D/g, '');
}

/**
 * Returns Uplift payment configuration
 * @param alternatePayments configured alternate payments
 * @returns uplift alternate payment configuration
 */
export function getUpliftPaymentConfiguration(alternatePayments: AlternatePayment[]): AlternatePayment {
  return alternatePayments.find((altPay) => altPay.paymentMethodCode === PaymentMethodCode.UPLIFT);
}

/**
 * Returns error type for Uplift error reporting method: Uplift.Payments.error()
 * @param errorCode api error code response
 * @returns Uplift reporting error type
 */
export function getUpliftErrorReportType(errorCode: number): UpliftErrorType {
  switch (errorCode) {
    case ERROR_CODE_AVS_FAILED:
    case ERROR_CODE_INVALID_CREDIT_CARD:
    case ERROR_CODE_CVV_FAILED:
      return UpliftError.VALIDATION;
    case ERROR_CODE_CARD_DECLINED:
    case ERROR_CODE_GENERIC_CODE:
    default:
      return UpliftError.FATAL;
  }
}

/**
 * Returns localized error message
 * @param localeService Locale Service
 * @param resp API response
 * @param defaultLocale default locale in case errors values are not found
 * @returns error locale
 */
export function getErrorLocaleFromAuthResponse(localeService: LocaleService, resp: AuthorizeResponse, defaultLocale: string): SafeHtml | string {
  if (isObject(resp)) {
    const { error_code, error_msg } = resp;
    if (validString(error_code)) {
      return localeService.get(`errorcode.${error_code}`);
    } else if (validString(error_msg)) {
      return error_msg;
    }
  }

  return localeService.get(defaultLocale);
}

/**
 * Calculate user selected amount from Extras selection
 * @param extras extras state
 * @returns total
 */
export function sumCartAddons(extras: ExtrasState): number {
  const { ticketProtection, donation } = extras;
  let total = 0;
  if (donation.configured && donation.amount > 0) {
    total += donation.amount;
  }
  if (ticketProtection.isProtected && ticketProtection.quoteAmount) {
    total += ticketProtection.quoteAmount;
  }
  return total;
}
