import { DonationAuthRequest, InsuranceAuthRequest } from './authorize';
import { FeeItemDetails, LineItemDetails } from '../../parent-config/parent-config.state';

import { ApiServiceResponse } from '../api.interface';

export interface CreatePayPalOrderRequest {
  /**
   * The payment merchant id
   */
  merchant_id: number;
  /**
   * The total amount
   */
  amount: number;
  /**
   * The URL where the customer is redirected after the customer approves the payment
   */
  return_url?: string;
  /**
   * The URL where the customer is redirected after the customer cancels the payment
   */
  cancel_url?: string;
  /**
   * Redacts the shipping address from the PayPal site if set to 1. Recommended for digital goods.
   */
  no_shipping_flag: 1 | 0;
  /**
   * Uses the merchant-provided address in PayPal is set to 1
   */
  address_override_flag: 1 | 0;
  /**
   * Array of payment references to be considered as discount amounts
   */
  discount_payment_reference: string[];
  /**
   * The language
   */
  language?: string;
  /**
   * The retail amount
   */
  retail_total?: number;
  /**
   * The total tax amount
   */
  tax_total?: number;
  /**
   * The total fees amount
   */
  fee_total?: number;
  /**
   * The name of the customer the goods are shipping to
   */
  ship_to_name?: string;
  /**
   * The shipping address of the customer
   */
  shipping_address?: PayPalShippingAddress;
  /**
   * The total amount for shipping
   */
  shipping_total?: number;
  /**
   * Line item details
   */
  cartItems: Partial<LineItemDetails>[];
  /**
   * Fees details
   */
  fees: FeeItemDetails[];
  /**
   * Donation details
   */
  donation?: DonationAuthRequest;
  /**
   * Insurance details
   */
  insurance?: InsuranceAuthRequest;
}

export interface PayPalShippingAddress {
  addressLine1: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}

/**
 * The different type of order status
 */
export enum PayPalOrderStatus {
  CREATED = 'CREATED',
  SAVED = 'SAVED',
  APPROVED = 'APPROVED',
  VOIDED = 'VOIDED',
  COMPLETED = 'COMPLETED',
  PAYER_ACTION_REQUIRED = 'PAYER_ACTION_REQUIRED',
}

export interface HATEOASLink {
  href: string;
  rel: string;
  method?: string;
}

export interface CreatePayPalOrderResponse extends ApiServiceResponse {
  /**
   * The paypal order id
   */
  paypal_order_id: string;
  /**
   * PayPal order status
   */
  paypal_order_status: PayPalOrderStatus;
  /**
   * The auth id
   */
  auth_id: number;
  /**
   * The payment reference
   */
  paymentReference: string;
  /**
   * HATEOAS links
   */
  links: HATEOASLink[];
}
