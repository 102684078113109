/**
 * The Delivery state interface
 */
export interface DeliveryState {
  /**
   * The delivery amount for selected delivery method
   */
  deliveryAmount: number;
  /**
   * If the delivery methods are configured
   */
  deliveryMethodsConfigured: boolean;
  /**
   * The selected delivery method
   */
  selectedDeliveryMethod: string;
  /**
   * If the selected delivery method requires address collection
   */
  collectAddress: boolean;
  /**
   * If we can show the delivery view
   */
  showDelivery: boolean;
  /**
   * The user address for selected delivery method
   */
  address?: DemographicsFormData;
}

/**
 * The initial state for delivery
 */
export const initialState: DeliveryState = {
  deliveryAmount: 0,
  deliveryMethodsConfigured: false,
  selectedDeliveryMethod: '',
  collectAddress: false,
  showDelivery: false,
  address: null,
};
