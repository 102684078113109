import { AppState } from 'src/app/app.state';
import { ApplicationConfigState } from './application-config.state';
import { createFeatureSelector } from '@ngrx/store';

export const selectAppConfigs = createFeatureSelector<ApplicationConfigState>('applicationConfig');

export const selectAppConfig = <T extends string>(names: readonly T[]) => {
  return (state: AppState): Record<T, any> => {
    return names.reduce((configs: Record<T, any>, name: string) => {
      configs[name] = state.applicationConfig.configs[name];
      return configs;
    }, {} as Record<T, any>);
  };
};
