import { Injectable } from '@angular/core';
import { toLower } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class LocaleIdService {
  /**
   * The locale ID value.
   */
  private value: string;

  /**
   * This is required as singletons in the root still create a new instance of language service when there is a lazy route
   * as it has it's own DI context.
   */
  // tslint:disable-next-line
  private static singletonInstance: LocaleIdService;

  constructor() {
    if (!LocaleIdService.singletonInstance) {
      LocaleIdService.singletonInstance = this;
    } else {
      return LocaleIdService.singletonInstance;
    }
  }

  /**
   * Returns the value as a string.
   */
  toString() {
    return this.value;
  }

  /**
   * Returns the value as a string.
   */
  toValue() {
    return this.value;
  }

  /**
   * Returns the value as a string.
   */
  valueOf() {
    return this.value;
  }

  /**
   * Make sure they cannot set the property directly..
   */
  set localeId(ignore) {
    throw new Error('you cannot set name');
  }

  /**
   * Returns the locale ID.
   */
  get localeId() {
    return this.value;
  }

  /**
   * Sets the locale ID
   * @param locale The locale to use
   */
  setLocale(locale: string) {
    this.value = locale;
  }

  /**
   * Returns the locale in all lowercase.
   */
  toLowerCase() {
    return toLower(this.value);
  }
}
