import { createReducer, on } from '@ngrx/store';
import { toLower } from 'lodash-es';
import { validObject } from 'src/app/shared/utilities/types.utils';
import { updateGiftCardAvailabilityAction } from '../billing/billing.actions';
import { GiftCard, GiftCardState, initialState } from '../gift-card/gift-card.state';
import {
  hideGiftCardSuccessMsgAction,
  payPartialAmountWithGiftCardSuccessAction,
  payWithGiftCardSuccessAction,
  resetGiftCardStateOnDeliveryAction,
  showGiftCardSuccessMsgAction,
  updateGiftCardBalanceAction,
  updateGiftCardMaxEntriesAction,
  updateGiftCardOnlyPaymentAction,
  updateGiftCardStateFromSessionAction,
} from './gift-card.actions';

const reducer = createReducer(
  initialState,
  on(resetGiftCardStateOnDeliveryAction, (state, action) => ({ ...initialState, maxEntries: state.maxEntries })),
  on(updateGiftCardAvailabilityAction, (state, action) => ({ ...initialState, maxEntries: state.maxEntries })),
  on(updateGiftCardOnlyPaymentAction, (state, action) => ({ ...state, isGiftCardOnlyPayment: action.payload })),
  on(updateGiftCardMaxEntriesAction, (state, action) => ({ ...state, maxEntries: action.payload })),
  on(showGiftCardSuccessMsgAction, (state, action) => ({ ...state, showAddSuccessMsg: true })),
  on(hideGiftCardSuccessMsgAction, (state, action) => ({ ...state, showAddSuccessMsg: false })),
  on(updateGiftCardBalanceAction, (state, action) => {
    const hasGiftCard = state.giftcards.find((card: GiftCard) => {
      return toLower(action.giftCardNumber) === toLower(card.cardNumber);
    });
    if (hasGiftCard) {
      return state;
    }
    const newState = { ...state };
    const { giftCardNumber: cardNumber, balance, pin, currencyCode } = action;
    newState.totalBalance += balance;
    newState.currencyCode = currencyCode;
    newState.giftcards = newState.giftcards.concat({ cardNumber, balance, currencyCode, pin });
    return newState;
  }),
  on(payWithGiftCardSuccessAction, (state, action) => {
    const giftCards = action.payload;
    const updatedCards: GiftCard[] = [];
    state.giftcards.forEach((card) => {
      giftCards.forEach((gc) => {
        if (gc.cardNumber === card.cardNumber) {
          updatedCards.push({
            ...card,
            authId: gc.authId,
            preAuthBalance: gc.preAuthBalance,
            paymentReference: gc.paymentReference,
          });
        }
      });
    });
    return { ...state, giftcards: updatedCards };
  }),
  on(payPartialAmountWithGiftCardSuccessAction, (state, action) => ({ ...state, giftcards: action.payload })),
  on(updateGiftCardStateFromSessionAction, (state, action) => {
    if (validObject(action.payload)) {
      return action.payload;
    }
    return state;
  })
);

export function giftCardReducer(action, state): GiftCardState {
  return reducer(action, state);
}
