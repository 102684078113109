import { Dictionary } from 'src/app/shared/utilities/types.utils';
import {
  APP_CONFIG_AMAZON_PAY,
  APP_CONFIG_ASSETS_PATH,
  APP_CONFIG_DEFAULT_BILLING_VALUE,
  APP_CONFIG_DISABLE_FIELD_PATTERN_VALIDATION,
  APP_CONFIG_DONATION,
  APP_CONFIG_ENABLE_SKIP_PAYMENT_SELECTION,
  APP_CONFIG_MERCHANT_NAME,
  APP_CONFIG_RECAPTCHAV3,
  APP_CONFIG_ROKT,
  APP_CONFIG_SALE_CYCLE_MERCHANT,
  APP_CONFIG_SANDBOX_MODE,
  APP_CONFIG_TICKET_GUARDIAN,
  APP_CONFIG_UPLIFT,
} from 'src/app/shared/enums/application-config.enum';

export interface ApplicationConfigState {
  /**
   * If the application config request is loading.
   */
  loading: boolean;
  /**
   * If the application config request has already been loaded.
   */
  loaded: boolean;
  /**
   * If there was an error in the request to get the configs.
   */
  errorLoading: boolean;
  /**
   * The actual application configs, if loaded.
   */
  configs: ApplicationConfigs;
}

export interface ApplicationConfigs {
  /**
   * Amazon Pay app config
   */
  [APP_CONFIG_AMAZON_PAY]?: AmazonPayApplicationConfig;
  /**
   * Default billing values
   */
  [APP_CONFIG_DEFAULT_BILLING_VALUE]?: BillingDefaultValueConfig;
  /**
   * The location where operations-created images are configured.
   */
  [APP_CONFIG_ASSETS_PATH]?: string;
  /**
   * Donation app config
   */
  [APP_CONFIG_DONATION]?: DonationConfiguration;
  /**
   * Enable bypass for payment selection
   */
  [APP_CONFIG_ENABLE_SKIP_PAYMENT_SELECTION]?: boolean;
  /**
   * Merchant name
   */
  [APP_CONFIG_MERCHANT_NAME]?: string;
  /**
   * Recaptcha v3 app config
   */
  [APP_CONFIG_RECAPTCHAV3]?: RecaptchaV3ConfigState;
  /**
   * ROKT app config
   */
  [APP_CONFIG_ROKT]?: RoktConfiguration;
  /**
   * Use sandbox app config
   */
  [APP_CONFIG_SANDBOX_MODE]?: boolean;
  /**
   * Sale Cycle Merchant Name
   */
  [APP_CONFIG_SALE_CYCLE_MERCHANT]?: string;
  /**
   * Ticket insurance app config
   */
  [APP_CONFIG_TICKET_GUARDIAN]?: TicketGuardianConfiguration;
  /**
   * Ticket insurance app config
   */
  [APP_CONFIG_UPLIFT]?: UpliftConfiguration;
  /**
   * Disable the pattern validation of each form field
   * Accept one or more fields, separated by commas
   */
  [APP_CONFIG_DISABLE_FIELD_PATTERN_VALIDATION]?: string;
}

export interface BillingDefaultValueConfig {
  firstName?: string;
  lastName?: string;
  country?: string;
}

export interface DonationConfiguration {
  /**
   * Round up to the nearest dollar
   */
  enableRounding?: boolean;
  /**
   * Enter custom donation amount
   */
  enableCustomAmount?: boolean;
  /**
   * List of static donation amounts
   */
  amounts?: number[];
  /**
   * Round to the next multiple.
   */
  roundingMultiple?: number;
  /**
   * Seperate donation authorization from purchase
   */
  splitDonation: boolean;
  /**
   * Min Donation Amount
   */
  minDonationAmount?: number;
  /**
   * Max Donation Amount
   */
  maxDonationAmount?: number;
}

export interface PayPalApplicationConfig {
  clientId: string;
  locale?: string;
  currencyCode?: string;
  fundingSources?: PayPalFundingSourceConfiguration[];
  additionalStyles?: Dictionary<string>;
}

export interface AmazonPayApplicationConfig {
  /**
   * Amazon Pay public key id
   */
  publicKeyId: string;
  /**
   * Amazon Pay merchant id
   */
  merchantId: string;
  /**
   * Amazon Pay script region
   */
  region: string;
  /**
   * Amazon Pay session language
   */
  locale?: string;
  /**
   * Amazon Pay session legder currency
   */
  ledgerCurrency?: string;
  /**
   * Amazon Pay button color
   */
  buttonColor?: AmazonPaySDKBtnColor;
}

export interface PayPalFundingSourceConfiguration {
  source: string;
  style?: Partial<PayPalFundingSourceStyle>;
}

export interface UpliftConfiguration {
  apiKey: string;
  upliftId: string;
}

export interface PayPalFundingSourceStyle {
  color: string;
  label: string;
  period: number;
}

export interface RecaptchaV3ConfigState {
  /**
   * Recaptcha's domain Url
   */
  useGlobalDomain?: boolean;
  /**
   * Enable/Disable Recaptcha
   */
  enabled: boolean;
  /**
   * Recaptcha's token
   */
  key: string;
}

export interface RoktConfiguration {
  accountId: string;
  enable: boolean;
  identifier: {
    paymentMethodPage: string;
    creditCardPage: string;
  };
}

export const initialState: ApplicationConfigState = {
  loaded: false,
  loading: false,
  errorLoading: false,
  configs: {},
};

export interface OptimizelyApplicationConfig {
  /**
   * Optimizely enabled
   */
  enable: boolean;
  /**
   * Optimizely public key id
   */
  projectId: string;
}
