import { Injectable } from '@angular/core';
import { CanActivateChild, Router } from '@angular/router';
import { Store, createSelector, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { selectIsComplimentaryOrder } from 'src/app/core/parent-config/parent-config.selectors';
import { TicketGuardianConfig } from 'src/app/pages/extras/extras.component';
import { validArray, validString } from 'src/app/shared/utilities/types.utils';
import { routeTo, routeToWithOptions } from '../../core/routing/routing.actions';
import { selectDelivery } from '../delivery/delivery.selectors';
import { DeliveryState } from '../delivery/delivery.state';
import { selectParentConfigDelivery } from './../../core/parent-config/parent-config.selectors';
import { selectShowExtrasPage } from './extras.selectors';

@Injectable({
  providedIn: 'root',
})
export class ExtrasGuard implements CanActivateChild {
  /**
   * The selected app configs.
   */
  private appConfigs: {
    ticketGuardian?: TicketGuardianConfig;
  };
  /**
   * Delivery state
   */
  private deliveryState: DeliveryState;
  /**
   * Has multiple delivery method options
   */
  private hasMultipleDeliveryOptions: boolean;
  /**
   * Show Extras page
   */
  private showExtrasPage: boolean;
  /**
   * Order with 0 dollar amount
   */
  private isComplimentaryOrder: boolean;

  /**
   * @param store The store
   * @param deliveryGuard The delivery guard
   */
  constructor(private store: Store<AppState>, private activeRoute: Router) {
    this.store
      .pipe(
        select(
          createSelector(
            selectParentConfigDelivery,
            selectDelivery,
            selectShowExtrasPage,
            selectIsComplimentaryOrder,
            (delivery, deliveryState, canShowExtrasPage, isComplimentaryOrder) => ({
              delivery,
              deliveryState,
              canShowExtrasPage,
              isComplimentaryOrder,
            })
          )
        )
      )
      .subscribe(({ delivery, deliveryState, canShowExtrasPage, isComplimentaryOrder }) => {
        this.deliveryState = deliveryState;
        this.hasMultipleDeliveryOptions = validArray(delivery?.options) && delivery?.options?.length > 1;
        this.isComplimentaryOrder = isComplimentaryOrder;
        this.showExtrasPage = canShowExtrasPage;
      });
  }

  /**
   * Guards route by checking the delivery and ticket protection configuration
   */
  canActivateChild(): boolean | Observable<boolean> {
    const showDelivery = this.showDeliveryPage();

    if (this.isComplimentaryOrder) {
      this.store.dispatch(routeTo({ payload: ['select'] }));
      return false;
    }
    if (showDelivery) {
      // Set delivery as first page
      this.store.dispatch(routeToWithOptions({ routeParams: ['delivery'], options: { replaceUrl: true } }));
      return false;
    }

    if (!this.showExtrasPage) {
      // Having multiple delivery options means we need to render delivery page, so we cannot replace the url
      this.store.dispatch(routeToWithOptions({ routeParams: ['payment-options'], options: { replaceUrl: !this.hasMultipleDeliveryOptions } }));
    }

    return this.showExtrasPage;
  }

  /**
   * Display delivery page by delivery configuration
   * Note: When navigating backwards, if the extras page errors, we want to skip it.
   */
  showDeliveryPage(): boolean {
    const { showDelivery, selectedDeliveryMethod } = this.deliveryState;
    const hasSelectedDeliveryMethod = validString(selectedDeliveryMethod);

    return showDelivery && (!hasSelectedDeliveryMethod || !this.showExtrasPage) && !this.activeRoute.url.includes('delivery');
  }
}
