import { createAction, props } from '@ngrx/store';
import { AuthorizeRequest } from 'src/app/core/api/responses/authorize';
import { UpliftProviderReference } from './payment-options.state';

export enum PaymentOptionsActions {
  InitializeUplift = '[Payment Options Resolve] Initialize Uplift',
  InitializeUpliftWithConfiguration = '[Payment Options Monthly Component] Initialize Uplift with configuration',
  RetrieveMonthlyPaymentToken = '[Payment Options Monthly Component] Retrieve Monthly Payment Token',
  DeselectMonthlyPayment = '[Payment Options Monthly Component] Deselect Payment Option',
  UpdateUpliftPaymentReady = '[Payment Options Effects] Update Uplift Payment Ready',
  UpdateMonthlyPaymentAvailability = '[Payment Options Effects] Update Monthly Payment Availability',
  UpdatePaymentTokenAvailability = '[Payment Options Effects] Update Payment Token Availability',
  UpdatePaymentOptionsAvailability = '[Payment Options Guard] Update Payment Options Availability',
  AuthorizeUpliftTransaction = '[CyberSource Uplift] Authorize Uplift Transaction',
}

/**
 * The action to initialize uplift.
 */
export const initializeUpliftAction = createAction(PaymentOptionsActions.InitializeUplift);
/**
 * The action to initialize uplift with configs.
 */
export const initializeUpliftWithConfigsAction = createAction(PaymentOptionsActions.InitializeUpliftWithConfiguration, props<{ containerId: string }>());

/**
 * The action to authorize uplift payment
 */
export const authorizeUpliftPaymentAction = createAction(
  PaymentOptionsActions.AuthorizeUpliftTransaction,
  props<{ payload: AuthorizeRequest<UpliftProviderReference> }>()
);

/**
 * The action to update if uplift payment is ready
 */
export const updateUpliftPaymentReadyAction = createAction(PaymentOptionsActions.UpdateUpliftPaymentReady, props<{ ready: boolean }>());

/**
 * The action to update monthly payment availability
 */
export const updateMonthlyPaymentAvailabilityAction = createAction(PaymentOptionsActions.UpdateMonthlyPaymentAvailability, props<{ available: boolean }>());

/**
 * The action to retrieve monthly payment token
 */
export const retrieveMonthlyPaymentTokenAction = createAction(PaymentOptionsActions.RetrieveMonthlyPaymentToken);

/**
 * The action to deselect uplift payment
 */
export const deselectMonthlyPayment = createAction(PaymentOptionsActions.DeselectMonthlyPayment);

/**
 * The action to update uplift token availability
 */
export const updateMonthlyPaymentTokenAvailabilityAction = createAction(PaymentOptionsActions.UpdatePaymentTokenAvailability, props<{ available: boolean }>());

/*
 * The action to update payment options availability
 */
export const updatePaymentOptionsAvailabilityAction = createAction(PaymentOptionsActions.UpdatePaymentOptionsAvailability, props<{ available: boolean }>());
