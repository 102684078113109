import { validObject } from './types.utils';

/**
 * Set attributes for HTML element
 * @param el HTML element
 * @param attributes element attributes
 */
export const setAttributes = (el: HTMLElement, attributes: { [k: string]: any } = {}): void => {
  if (validObject(attributes)) {
    for (const [key, value] of Object.entries(attributes)) {
      if (typeof value !== 'function') {
        el.setAttribute(key, value);
      }
    }
  }
};
