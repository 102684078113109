import { InjectionToken } from '@angular/core';

export const WINDOW = new InjectionToken<Window>('WINDOW');

/**
 * Used to inject the Window object.
 * @returns The window object
 */
export function windowFactory(): Window {
  return window;
}
