import { createAction, props } from '@ngrx/store';
import { DeleteTokenRequest, DeleteTokenResponse } from 'src/app/core/api/responses/delete-token';
import { PayWithTokenRequest } from 'src/app/core/api/responses/pay-with-token';

export enum StoredWalletActionTypes {
  PayWithStoredToken = '[Select Payment Screen] Pay With Stored Token',
  DeleteStoredToken = '[Stored Wallet] Delete Token',
  DeleteStoredTokenSuccess = '[Stored Wallet] Delete Token Success',
  InitializeGiftCardSplitPayment = '[Stored Wallet Effects] Initialize Gift Card Split Payment With Stored Token',
  SplitPaymentWithGiftCardSuccess = '[Gift Card Effects] Gift Card Split Payment With Stored Wallet Success',
}
/**
 * Request to delete a stored wallet item.
 */
export const payWithStoredTokenAction = createAction(StoredWalletActionTypes.PayWithStoredToken, props<{ payload: PayWithTokenRequest }>());

/**
 * Request to delete a stored wallet item.
 */
export const deleteStoredTokenAction = createAction(StoredWalletActionTypes.DeleteStoredToken, props<{ payload: DeleteTokenRequest }>());

/**
 * A stored wallet item was successfully deleted.
 */
export const deleteStoredTokenSuccessAction = createAction(StoredWalletActionTypes.DeleteStoredTokenSuccess, props<{ payload: DeleteTokenResponse }>());

/**
 * Initiate split payment with gift card
 */
export const initializeGiftCardSplitPaymentWithStoredWalletAction = createAction(
  StoredWalletActionTypes.InitializeGiftCardSplitPayment,
  props<{ amount: number; payload: PayWithTokenRequest }>()
);

/**
 * Gift card split payment success
 */
export const giftCardSplitPaymentWithStoredWalletSuccessAction = createAction(
  StoredWalletActionTypes.SplitPaymentWithGiftCardSuccess,
  props<{ payload: PayWithTokenRequest }>()
);
