// Full documentation: https://accesso.atlassian.net/wiki/spaces/SF/pages/44952846784/accessoPay+Application+Configs

export const APP_CONFIG_ADYEN_CLIENT_KEY = 'adyenClientKey';
export const APP_CONFIG_ADYEN_PAYMENT_METHOD_RESPONSE = 'adyenPaymentMethodResponse';
export const APP_CONFIG_AMAZON_PAY = 'amazon_pay';
export const APP_CONFIG_APPLY_APP_LAYOUT_COLOR = 'applyAppLayoutColor';
export const APP_CONFIG_ASSETS_PATH = 'assetsPath';
export const APP_CONFIG_CYBERS_SESSION_TIMEOUT = 'cybersource_session_timeout';
export const APP_CONFIG_DEFAULT_BILLING_VALUE = 'default_billing_value';
export const APP_CONFIG_DEFAULT_COUNTRY_CODE = 'default_country_code';
export const APP_CONFIG_DEFAULT_PHONE_CODE = 'default_phone_code';
export const APP_CONFIG_DISABLE_COPY_TO_BILLING = 'disable_copy_to_billing';
export const APP_CONFIG_DISABLE_MIN_PHONE_LENGTH = 'disableMinPhoneLength';
export const APP_CONFIG_DISABLE_ZIP_VALIDATION = 'disable_zip_code_validation';
export const APP_CONFIG_DISABLE_FIELD_PATTERN_VALIDATION = 'disable_field_pattern_validation';
export const APP_CONFIG_DONATION = 'donation';
export const APP_CONFIG_DONATION_BANNER = 'donation_banner_image';
export const APP_CONFIG_ENHANCED_PHONE_NUMBER = 'enhancedPhoneNumber';
export const APP_CONFIG_ENABLE_SKIP_PAYMENT_SELECTION = 'enableSkipPaymentSelection';
export const APP_CONFIG_FULL_DEMOGRAPHIC_PAYMENT_METHOD = 'fullDemographicPaymentMethods';
export const APP_CONFIG_HIDE_CREDITCARD_HEADER = 'hide_creditcard_header';
export const APP_CONFIG_HIDE_EXTRAS_HEADER = 'hideExtrasHeader';
export const APP_CONFIG_HIT_CALLBACK_TIMEOUT = 'hitCallbackTimeout';
export const APP_CONFIG_MERCHANT_NAME = 'merchant_name';
export const APP_CONFIG_OVERRIDE_MBWAY_MAX_AMOUNT = 'overrideMbWayMaxAmount';
export const APP_CONFIG_OVERRIDE_POSTAL_REGEX = 'override_postal_regex';
export const APP_CONFIG_OPTIMIZELY = 'optimizely';
export const APP_CONFIG_PAYPAL = 'paypal';
export const APP_CONFIG_PIN_CODE_VALIDATION = 'pinCodeValidation';
export const APP_CONFIG_POLL_INTERVAL = 'poll_interval';
export const APP_CONFIG_PREFERRED_COUNTRY_CODES = 'preferredCountryCodes';
export const APP_CONFIG_PREFERRED_PHONE_COUNTRY_CODES = 'preferredPhoneCountryCodes';
export const APP_CONFIG_QR_CODE_EXPIRY = 'qr_code_expiry';
export const APP_CONFIG_RECAPTCHAV3 = 'recaptchav3';
export const APP_CONFIG_REQUIRE_STORE_CVV = 'require_stored_cvv';
export const APP_CONFIG_ROKT = 'rokt';
export const APP_CONFIG_SALE_CYCLE_MERCHANT = 'saleCycleMerchant';
export const APP_CONFIG_SANDBOX_MODE = 'sandbox_mode';
export const APP_CONFIG_SHOW_CLEAR_PAY_LOGO = 'showClearPayLogo';
export const APP_CONFIG_TICKET_GUARDIAN = 'ticketGuardian';
export const APP_CONFIG_UPLIFT = 'uplift';
