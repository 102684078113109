import { createAction, props } from '@ngrx/store';
import { AmazonPayCompleteSessionRequest } from 'src/app/core/api/responses/complete-amazon-pay-session';
import { AmazonPayGenerateSessionRequest } from 'src/app/core/api/responses/generate-amazon-pay-session';

export enum AmazonPayActions {
  MountAmazonPayButton = '[Select Component] Mount Amazon Pay Button',
  InitializeAmazonPay = '[Amazon Pay Service] Init Amazon Pay Session',
  UpdateAmazonPaySession = '[Amazon Pay Effects] Update Amazon Pay Session',
  LoadAmazonPaySession = '[Amazon Pay Effects] Load Session Details To Amazon Pay Window',
  OpenAmazonPayWindowAction = '[Amazon Pay Effects] Open Amazon Pay Window',
  CancelAmazonPaySession = '[Amazon Pay Service] Cancel Amazon Pay Session',
  CompleteAmazonPaySession = '[Amazon Pay Service] Complete Amazon Pay Session',
  InitializeGiftCardSplitPayment = '[Amazon Pay Service] Initialize GiftCard Split Payment',
  SplitPaymentWithGiftCardSuccess = '[Amazon Pay Service] Gift Card Split Payment With Amazon Pay Success',
}

/**
 * Mount Amazon Pay button to DOM
 */
export const mountAmazonPayButtonAction = createAction(AmazonPayActions.MountAmazonPayButton, props<{ elementId: string }>());

/**
 * Initialize Amazon Pay session
 */
export const initializeAmazonPayAction = createAction(AmazonPayActions.InitializeAmazonPay, props<{ payload: AmazonPayGenerateSessionRequest }>());

/**
 * Update Amazon Pay session
 */
export const updateAmazonPaySessionAction = createAction(AmazonPayActions.UpdateAmazonPaySession);

/**
 * Open Amazon Pay window
 */
export const openAmazonPayWindowAction = createAction(AmazonPayActions.OpenAmazonPayWindowAction);

/**
 * Load Amazon Pay session details to popup window
 */
export const loadAmazonPaySessionAction = createAction(AmazonPayActions.LoadAmazonPaySession);

/**
 * Cancel Amazon Pay session
 */
export const cancelAmazonPaySessionAction = createAction(AmazonPayActions.CancelAmazonPaySession);

/**
 * Complete Amazon Pay session
 */
export const completeAmazonPaySessionAction = createAction(AmazonPayActions.CompleteAmazonPaySession, props<{ payload: any }>());

/**
 * Initiate split payment with gift card
 */
export const initializeGiftCardSplitPaymentWithAmazonPayAction = createAction(
  AmazonPayActions.InitializeGiftCardSplitPayment,
  props<{ amount: number; payload: any }>()
);

/**
 * Gift card split payment success
 */
export const giftCardSplitPaymentWithAmazonPaySuccessAction = createAction(
  AmazonPayActions.SplitPaymentWithGiftCardSuccess,
  props<{ payload: AmazonPayCompleteSessionRequest }>()
);
