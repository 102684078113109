import { AppState } from 'src/app/app.state';
import { StoredWalletItem } from './stored-wallet.state';
import { createSelector } from '@ngrx/store';
import { selectEnvironment } from 'src/app/core/environment/environment.selectors';
import { selectGlobalState } from 'src/app/app.selectors';
import { selectMerchantId } from 'src/app/core/client-configuration/client-configuration.selectors';
import { selectParentConfig } from 'src/app/core/parent-config/parent-config.selectors';
import { selectDelivery } from '../../delivery/delivery.selectors';
/**
 * Selects the stored wallet state
 * @param state The application state
 */
export const selectStoredWallet = createSelector(selectGlobalState, (state: AppState): StoredWalletItem[] => state.storedWallet);

/**
 * Returns the main information needed for the stored wallet effects
 */
export const storedWalletEffectsSelector = createSelector(
  selectStoredWallet,
  selectMerchantId,
  selectParentConfig,
  selectEnvironment,
  selectDelivery,
  (storedWallet, merchantId, parentConfig, environment, delivery) => ({
    storedWallet,
    merchantId,
    parentConfig,
    environment,
    delivery,
  })
);
