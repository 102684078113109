/**
 * Standard Events
 */
export const EVENT_PAYMENT_COMPLETE = 'PaymentComplete';
export const EVENT_PAYMENT_FAILED = 'PaymentFailed';
export const EVENT_PAYMENT_CANCELLED = 'PaymentCancelled';
export const EVENT_PAYMENT_SELECTED = 'PaymentSelected';
export const EVENT_CANCEL = 'Cancel';
export const EVENT_INITIALIZED = 'Initialized';
export const EVENT_INITIALIZE_FAILED = 'InitializeFailed';
export const EVENT_DELIVERY_INFO = 'DeliveryInformation';
export const EVENT_ROUTE_CHANGE = 'RouteChanged';
export const EVENT_BILLING_DEMOGRAPHICS = 'BillingDemographics';
export const EVENT_AUTHORIZING = 'Authorizing';
export const EVENT_SET_HEIGHT = 'setHeight';

/**
 * Notification events
 */
export const EVENT_SHOW_CONFIRM_DIALOG = 'showConfirmMessage';
export const EVENT_DIALOG_BUTTON_CLICKED = 'dialogButtonClicked';

/**
 * ApplePay Events
 */
export const EVENT_APPLEPAY_CHECK = 'checkForApplePay';
export const EVENT_APPLEPAY_ON_AVAILABLE = 'applePayAvailability';
export const EVENT_APPLEPAY_START = 'applePayStartSession';
export const EVENT_APPLEPAY_VALIDATE_MERCHANT = 'applePayValidateMerchant';
export const EVENT_APPLEPAY_AUTHORIZED = 'applePayAuthorized';
export const EVENT_APPLEPAY_CANCEL = 'applePayCancel';
export const EVENT_APPLEPAY_PAYMENT_RESPONSE = 'applePayPaymentResponse';
export const EVENT_APPLEPAY_VALIDATE_RESPONSE = 'applePayValidateResponse';

/**
 * Payment related events
 */
export const EVENT_PAYMENT_REDIRECT = 'PaymentRedirect';
export const EVENT_CLEAR_QUERY_PARAMS = 'ClearQueryParams';

/**
 * Donation related events
 */
export const EVENT_DONATION_OFFER = 'DonationOffer';
export const EVENT_DONATION_SELECT = 'DonationSelect';
export const EVENT_DONATION_DECLINE = 'DonationDecline';
/**
 * Insurance related events
 */
export const EVENT_INSURANCE_OFFER = 'InsuranceOffer';
export const EVENT_INSURANCE_SELECT = 'InsuranceSelect';
export const EVENT_INSURANCE_DECLINE = 'InsuranceDecline';

/**
 * Behavior events
 */
export const EVENT_SCROLL_TO_TOP = 'scrollToTop';
