import { createSelector } from '@ngrx/store';
import { selectCartAddonTotal } from 'src/app/feature/extras/extras.selectors';
import { selectCartId, selectLineItemsAndFees, selectParentConfig, selectParentConfigAmount } from 'src/app/core/parent-config/parent-config.selectors';
import { selectEnvironmentInfo } from 'src/app/core/environment/environment.selectors';
import { Amount, BaseRequest, Cart, CartItem, Customer } from 'src/app/core/api/api.models';

/**
 * Selects the amount from the parent config and cart addon total.
 */
const selectAmount = createSelector(selectParentConfigAmount, selectCartAddonTotal, (cartTotal, extrasTotal): Amount => {
  return {
    cartTotal,
    extrasTotal,
  };
});

/**
 * Selects the customer information from the parent configuration.
 */
const selectCustomer = createSelector(selectParentConfig, (parentConfig): Customer => {
  const { user } = parentConfig.config;
  return {
    id: user.customerId || '',
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    emailAddress: user.email || '',
    phoneNumber: user.phone || '',
  };
});

/**
 * Selects the cart from the state.
 */
const selectCart = createSelector(selectLineItemsAndFees, selectCartId, (lineItemsAndFees, cartId): Cart => {
  const { cartItems, fees } = lineItemsAndFees;
  return {
    cartId,
    cartItems: cartItems as CartItem[],
    fees,
  };
});

/**
 * Returns an API BaseRequest object based on the provided selectors.
 */
export const selectBaseRequest = createSelector(
  selectAmount,
  selectCustomer,
  selectCart,
  selectEnvironmentInfo,
  (amount, customer, cart, environmentInfo): BaseRequest => {
    return {
      recurringType: 'none',
      amount,
      customer,
      cart,
      environmentInfo,
      authorizationExpiry: 2880,
      paymentIndex: 1,
    };
  }
);
