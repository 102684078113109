import { Injectable, Inject } from '@angular/core';
import { take, filter } from 'rxjs/operators';
import { WINDOW } from '../injection-token/window/window';
import { ActivatedRoute, Params } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AcLoggerService {
  public log: (...args: any) => void;
  public warn: (...args: any) => void;
  public error: (...args: any) => void;
  private enabled = false;
  private window: Window;

  constructor(@Inject(WINDOW) window: any, private route: ActivatedRoute) {
    // See why we have to do this: https://github.com/angular/angular/issues/12631
    this.window = window as Window;

    this.setEnabled(this.window);

    this.log = this.createLogger('log');
    this.warn = this.createLogger('warn');
    this.error = this.createLogger('error');
  }

  /**
   * Find out if logging is enabled.
   */
  isEnabled(): boolean {
    return this.enabled;
  }

  /**
   * Creates a logger function. Will return noop if not enabled.
   * @param type The type of console function to use.
   * @returns The function to use as a logger
   */
  createLogger(type: 'log' | 'warn' | 'error'): (...args: any) => void {
    return (...args: any): void => {
      if (!this.enabled || !console || !console[type]) {
        return;
      }
      console[type].apply(console, args);
    };
  }

  /**
   * Handles setting the `enabled` flag if its local development or the url param _d is set to true.
   */
  public setEnabled(window: Window, forceLogging: boolean = false): void {
    // If they want to force logging programatically
    if (forceLogging) {
      this.enabled = true;
      return;
    }

    // Always enable logging for local development
    if (window.location.href.includes('localhost')) {
      this.enabled = true;
      // Check the query parameters for `_d`
    } else {
      this.route.queryParams
        .pipe(
          // Filter is needed because of this issue: https://github.com/angular/angular/issues/12157
          filter((params: Params) => Object.keys(params).length > 0),
          take(1)
        )
        .subscribe((params) => {
          this.enabled = params['_d'] === 'true';
        });
    }
  }
}
