import { Component, DEFAULT_CURRENCY_CODE, HostBinding, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { selectDisplayApp, selectDisplaySpinner } from './app.selectors';
import { AppState } from './app.state';
import { selectIsAuthorizing } from './core/session/session.selectors';
import { selectParentCurrencyCode, selectShowNewUnifiedDesignSpinner } from './core/parent-config/parent-config.selectors';

/**
 * This class is the base class everything gets rendered off of.
 */
@Component({
  selector: 'ap-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: DEFAULT_CURRENCY_CODE,
      useFactory: (store: Store<AppState>) => {
        let currencyCode: string = '';
        store.select(selectParentCurrencyCode).subscribe((code) => (currencyCode = code));
        return currencyCode || undefined;
      },
      deps: [Store],
    },
  ],
})
export class AppComponent {
  /**
   * Bind out class to the main element.
   */
  @HostBinding('class') hostClasses = 'ap-app';
  /**
   * If we're in the process of authorizing a purchase
   */
  isAuthorizing$: Observable<boolean>;
  /**
   * If we should actually display the application.
   */
  displayApp$: Observable<boolean>;
  /**
   * If we should display the full-page spinner.
   */
  displaySpinner$: Observable<boolean>;
  /**
   * If we should use the new Unified Design spinner
   */
  useNewSpinner$: Observable<boolean>;
  /**
   * The application version
   */
  version: string = environment.appVersion;

  /**
   * @param store The global store
   * @param title The title service
   */
  constructor(store: Store<AppState>, title: Title) {
    title.setTitle('Accesso Pay');

    this.isAuthorizing$ = store.select(selectIsAuthorizing);
    this.displayApp$ = store.select(selectDisplayApp);
    this.displaySpinner$ = store.select(selectDisplaySpinner);
    this.useNewSpinner$ = store.select(selectShowNewUnifiedDesignSpinner);
  }
}
