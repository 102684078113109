import { createReducer, on } from '@ngrx/store';
import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import { ERROR_CODE_RESPONSE_NOT_OBJECT } from 'src/app/shared/enums/error-code.enums';
import { validObject } from 'src/app/shared/utilities/types.utils';
import { staticApiResponseAction } from '../api/api.actions';
import { isApi } from '../api/api.utilities';
import { ApplicationConfig, GetApplicationConfigResponse } from '../api/responses/get-application-config';
import { requestStaticDataAction, staticDataLoadedFailureAction } from '../static-data/static-data.actions';
import { ApplicationConfigState, ApplicationConfigs, initialState } from './application-config.state';

const { GET_APPLICATION_CONFIG } = ApiRequestType;

const reducer = createReducer(
  initialState,
  on(staticDataLoadedFailureAction, (state, action) => ({ ...state, loading: false, loaded: true, errorLoading: true })),
  on(requestStaticDataAction, (state, action) => ({
    ...state,
    loaded: false,
    loading: true,
    errorLoading: false,
  })),
  on(staticApiResponseAction, (state, action) => {
    if (!isApi(action, GET_APPLICATION_CONFIG)) {
      return state;
    }

    const statusOk = action.isOk;
    const newState = {
      loaded: true,
      loading: false,
      errorLoading: !statusOk,
      configs: {},
    };

    if (statusOk) {
      return { ...newState, configs: { ...parseSettings(action.response as GetApplicationConfigResponse) } };
    } else if (!validObject(action.response)) {
      return { ...newState, error_code: ERROR_CODE_RESPONSE_NOT_OBJECT, error_msg: 'Unexpected response' };
    } else {
      const { error_code, error_msg, detail } = action.response;

      return { ...newState, error_code, error_msg: detail || error_msg };
    }
  })
);

/**
 * Our main reducer for application config feature
 * @param state The current state
 * @param action The action being triggered
 */
export function applicationConfigReducer(state, action): ApplicationConfigState {
  return reducer(state, action);
}

/**
 * Parses the application config settings to actual types
 * @param response The configs
 * @returns The parsed application configs
 */
function parseSettings(response: GetApplicationConfigResponse): ApplicationConfigs {
  return response.reduce((acc: ApplicationConfigs, curr: ApplicationConfig) => {
    try {
      acc[curr.name] = JSON.parse(curr.value);
    } catch (ignore) {
      acc[curr.name] = curr.value;
    }
    return acc;
  }, {});
}
