import { Dictionary } from 'src/app/shared/utilities/types.utils';
import { ApiStatus } from '../api/api.interface';
import { InsuranceAuthResponse } from '../api/responses/authorize';

export interface PostMessage {
  /**
   * The type of message.
   */
  type: string;
  /**
   * The native session id. This is used for debugging.
   */
  nativeSessionId: string;
  /**
   * The actual payload configured for the `type`.
   */
  payload?: Dictionary<any>;
}

export interface PaymentFailed {
  /**
   * The error code, if any.
   */
  errorCode?: number | string;
  /**
   * The human-readable reason it errored, if any.
   */
  reason?: string;
  /**
   * The details of the error, if any.
   */
  details?: string;
}

export interface UserInfo {
  /**
   * The user's email address.
   */
  email: string;
  /**
   * The user's first name.
   */
  firstName: string;
  /**
   * The user's last name
   */
  lastName: string;
  /**
   * The user's middle initial
   */
  middleInitial?: string;
  /**
   * The user's phone number
   */
  phone: string;
  /**
   * The users phone country code
   */
  phoneCountryCode?: string;
  /**
   * The users phone number region code.
   */
  phoneRegionCode?: string;
  /**
   * The user's address
   */
  address1: string;
  /**
   * The user's address 2 such as suite, etc.
   */
  address2: string;
  /**
   * The user's state/province.
   */
  stateProvince: string;
  /**
   * The user's country
   */
  country: string;
  /**
   * The user's city
   */
  city: string;
  /**
   * The user's zip/postal code.
   */
  zipPostal: string;
}

export enum PaymentType {
  ADYEN_MOBILE_PAY = 'mobilepay',
  AFTERPAY = 'afterpay',
  ALIPAY = 'alipay',
  ALIPAY_HK = 'alipayhk',
  AMAZON_PAY = 'amazonpay',
  APPLE_PAY = 'applepay',
  BANCONTACT_DESKTOP = 'bancontactdesktop',
  BANCONTACT_MOBILE = 'bancontactmobile',
  CREDIT_CARD = 'creditcard',
  GIFT_CARD = 'giftcard',
  GIROPAY = 'giropay',
  GRABPAY = 'grabpay',
  GOOGLE_PAY = 'googlepay',
  IDEAL = 'ideal',
  KAKAO_PAY = 'kakaopay',
  KLARNA_PAY_NOW = 'klarnapaynow',
  KLARNA_PAY_OVER_TIME = 'klarnapayovertime',
  KOREA_CYBER_PAYMENTS = 'koreancyberpayments',
  LINE_PAY = 'linepay',
  MB_WAY = 'mbway',
  NAVER_PAY = 'naverpay',
  PAYPAL = 'paypal',
  SEPADD = 'sepadirectdebit',
  SOFORT = 'sofort',
  TTB_QR_CODE = 'ttbqrcode',
  UPLIFT = 'uplift',
  WALLET = 'wallet',
  WECHAT_PAY = 'wechatpay',
  TMBTHANACHART = 'tmbthanachart',
  TESCO_VOUCHER = 'tescovoucher',
}

export interface AuthorizedPaymentInfo {
  /**
   * The amount charged for current payment method
   */
  amount?: number;
  /**
   * The payment type
   */
  method: PaymentType;
  /**
   * Approval code from the issuer's bank.
   */
  approval_code?: string;
  /**
   * The new paymentReference we receive regarding a transaction. This is the ref the parent application will use to capture funds.
   */
  authId: string;
  /**
   * The new paymentReference we receive regarding a transaction.
   */
  paymentReference?: string;
  /**
   * Card expiry date
   */
  expire_date?: string;
  /**
   * The cards maked pan
   */
  maskedPan?: string;
  /**
   * The legacy auth id and payment merchant id. This is mainly for legacy applications like Shopland e-commerce.
   */
  legacy: {
    /**
     * The authorization ID. The parent application will use this to capture funds.
     */
    authId: number | string;
    /**
     * The payment merchant ID. The parent application will use this to capture funds.
     */
    paymentMerchantId: number | string;
    /**
     * The payment method code
     */
    paymentMethodCode?: string;
    /**
     * The payment provider name
     */
    paymentProviderName?: string;
    /**
     * The raw card brand of the payment type
     */
    rawCardBrand?: string;
  };
  /**
   * The last four of the credit card, if any.
   */
  lastFour?: string;
  /**
   * The credit card type, if any.
   */
  cardType?: string;
  /**
   * If authorization is pending
   */
  awaitingAuthorization?: boolean;
  /**
   * Donation authorization response
   */
  donation?: DonationAuthResponse;
  /**
   * insurance authorization response
   */
  insurance?: InsuranceAuthResponse;
}

export interface PaymentComplete extends AuthorizedPaymentInfo {
  /**
   * Information about the user, if any.
   */
  user?: Partial<{
    /**
     * User billing info
     */
    billing: Partial<UserInfo>;
    /**
     * User delivery info
     */
    delivery: Partial<UserInfo>;
  }>;
}

export interface PaymentInfo extends AuthorizedPaymentInfo {
  billing: Partial<UserInfo>;
}

export interface SplitPaymentComplete {
  paymentInfo: PaymentInfo[];
  delivery?: Partial<UserInfo>;
}

export interface DonationAuthResponse {
  /**
   * Donation authorized amount
   */
  donationAmount: number;
  /**
   * Donation authorize status
   */
  paymentStatus: ApiStatus;
  /**
  /**
   * Donation used split merchant accounts
   */
  splitDonation?: boolean;
  /**
   * Donation transaction reference
   */
  donationTxnReference?: string;
  /**
   * Donation approval code
   */
  donationApprovalCode?: string;
}
