import { Action, createReducer, on } from '@ngrx/store';
import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import { staticApiResponseAction } from '../api/api.actions';
import { isApi } from '../api/api.utilities';
import { requestStaticDataAction, staticDataLoadedFailureAction } from '../static-data/static-data.actions';
import {
  clearPaymentMethodAction,
  selectPaymentMethodAction,
  updatePaymentConfigurationAction,
  updatePaymentConfigurationWithConfiguredPaymentsAction,
} from './payment-configuration.actions';
import { PaymentConfigurationState, initialState } from './payment-configuration.state';

const { GET_PAYMENT_CONFIGURATION } = ApiRequestType;

const updatePaymentConfiguration = (state, action) => ({ ...state, configuredPayments: action.configuredPayments });

const reducer = createReducer(
  initialState,
  on(staticDataLoadedFailureAction, (state, action) => ({ ...state, loading: false, loaded: true, errorLoading: true })),
  on(requestStaticDataAction, (state, action) => ({ ...state, loading: true, loaded: false, errorLoading: false })),
  on(selectPaymentMethodAction, (state, { code }) => ({ ...state, selectedPaymentMethodCode: code })),
  on(clearPaymentMethodAction, (state, action) => ({ ...state, selectedPaymentMethodCode: null })),
  on(staticApiResponseAction, (state, action) => {
    if (!isApi(action, GET_PAYMENT_CONFIGURATION)) {
      return state;
    }

    return { ...state, loaded: true, loading: false, errorLoading: !action.isOk };
  }),
  on(updatePaymentConfigurationAction, updatePaymentConfiguration),
  on(updatePaymentConfigurationWithConfiguredPaymentsAction, updatePaymentConfiguration)
);

/**
 * Our main reducer for application config feature
 * @param state The current state
 * @param action The action being triggered
 */
export function PaymentConfigurationReducer(state: PaymentConfigurationState, action: Action): PaymentConfigurationState {
  return reducer(state, action);
}
