import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { DialogModule } from '@angular/cdk/dialog';

@NgModule({
  declarations: [ConfirmDialogComponent],
  exports: [ConfirmDialogComponent],
  imports: [CommonModule, DialogModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ConfirmDialogModule {}
