import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { map, mergeMap, tap } from 'rxjs/operators';
import { ERROR_CODE_INVALID_CONFIGURATION } from 'src/app/shared/enums/error-code.enums';
import { validString } from 'src/app/shared/utilities/types.utils';
import { getRouteParamsFromUrl } from 'src/app/shared/utilities/url.utils';
import { initializeFailedAction } from '../initialize/initialize.actions';
import { DOCUMENT } from '../injection-token/document/document';
import { WINDOW } from '../injection-token/window/window';
import { LocaleIdService } from '../locale/locale-id.service';
import { fetchLocaleFile } from '../locale/locale.actions';
import { getDefaultLocale } from '../locale/locale.utils';
import { setLanguage, updateClientConfiguration } from './client-configuration.actions';
import { ClientConfigurationState } from './client-configuration.state';

/**
 * Handles client configuration. Things like merchant id, tenantName, language.
 */
@Injectable()
export class ClientConfigurationEffects {
  /**
   * Kickstart our client configuration parsing.
   */
  initializeClientConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchLocaleFile),
      tap(({ payload: locale }) => {
        this.language = locale;
      }),
      map(({ payload: locale }) => updateClientConfiguration({ payload: this.getConfiguration() }))
    )
  );

  /**
   * Handles request to update the client configuration. Things like setting the default
   * language get handled here.
   */
  updateClientConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateClientConfiguration),
      mergeMap(({ payload }) => {
        const language = payload.language ?? getDefaultLocale();
        const actions: Action[] = [setLanguage({ payload: language })];
        // If we don't have an tenantName or merchant id, this is invalid.
        if (payload.error) {
          actions.push(
            initializeFailedAction({
              errorCode: ERROR_CODE_INVALID_CONFIGURATION,
              reason: 'Invalid client configuration',
              metaData: {
                config: JSON.stringify(payload),
              },
            })
          );
        }

        return actions;
      })
    )
  );

  /**
   * Handles request to set a specific language.
   */
  setLanguage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setLanguage),
        tap((action) => {
          this.localeId.setLocale(action.payload);
        })
      ),
    { dispatch: false }
  );

  /**
   * Language/Locale used to load application
   */
  private language: string = '';

  /**
   *
   * @param window
   * @param document
   * @param actions$
   * @param localeId
   */
  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    private actions$: Actions,
    private localeId: LocaleIdService
  ) {}

  /**
   * Returns the parent url
   * @param url The referrer url to parse
   */
  getParentHostname(url: string): string {
    if (validString(url)) {
      const el = this.document.createElement('a');
      el.href = url;
      return `${el.protocol}//${el.host}`;
    }

    return '';
  }

  /**
   * Returns the initial configuration when initializing the application
   */
  getConfiguration(): ClientConfigurationState {
    const routeParams = getRouteParamsFromUrl(this.window);
    const [tenantName, merchantId] = routeParams;
    const count = routeParams.length;
    const config: ClientConfigurationState = {
      error: false,
      found: false,
      tenantName,
      language: this.language,
      merchantId,
      originUrl: '',
      parentHostname: this.getParentHostname(this.document.referrer),
    };

    if (count > 1) {
      if (count > 2) {
        config.error = false;
        config.found = true;
      } else {
        config.error = false;
        config.found = true;
        config.language = '';
      }
      return config;
    }

    config.error = true;
    config.found = false;
    config.tenantName = '';
    config.language = '';
    config.merchantId = '';

    return config;
  }
}
