import { createReducer, on } from '@ngrx/store';
import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import { staticApiResponseAction } from '../api/api.actions';
import { isApi } from '../api/api.utilities';
import { loadedParentConfigAction } from '../parent-config/parent-config.actions';
import { initializeFailedAction } from './initialize.actions';
import { InitializeState, initialState } from './initialize.state';
import { isInitialized } from './initialize.utils';

const reducer = createReducer(
  initialState,
  on(staticApiResponseAction, (state, action): InitializeState => {
    const { isOk } = action;
    let newState;

    if (isApi(action, ApiRequestType.GET_APPLICATION_CONFIG)) {
      newState = { ...state, applicationConfigLoaded: isOk };
    } else if (isApi(action, ApiRequestType.GET_PAYMENT_CONFIGURATION)) {
      newState = { ...state, paymentConfiguration: isOk };
    } else if (isApi(action, ApiRequestType.GET_APPLICATION_LOCALE)) {
      newState = { ...state, applicationLocalesLoaded: isOk };
    } else if (isApi(action, ApiRequestType.GET_ENVIRONMENT)) {
      newState = { ...state, environmentLoaded: isOk };
    }

    if (newState) {
      newState.initialized = isInitialized(newState);
      return newState;
    }

    return state;
  }),
  on(loadedParentConfigAction, (state, action): InitializeState => {
    const newState = {
      ...state,
      parentConfiguration: true,
    };

    newState.initialized = isInitialized(newState);

    return newState;
  }),
  on(initializeFailedAction, (state) => ({ ...state, initialized: true, initializeFailed: true }))
);

export function initializeReducer(action, state): InitializeState {
  return reducer(action, state);
}
