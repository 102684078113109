import { BillingAddress } from 'src/app/core/api/responses/initiate-alt-pay';
import { UserInfo } from 'src/app/core/application-bridge/application-bridge.models';
import { createCountryTelCodeHashMap } from 'src/app/feature/dynamic-form/dynamic-form.utils';
import { DynamicFormControl } from 'src/app/feature/dynamic-form/input/dynamic-form-control';
import { CountriesProperties } from 'src/app/feature/dynamic-form/utilities/countries-props.models';
import { APP_CONFIG_DISABLE_FIELD_PATTERN_VALIDATION, APP_CONFIG_OVERRIDE_POSTAL_REGEX } from '../enums/application-config.enum';
import { validObject, validString } from './types.utils';
import { joinStrings, returnOrDefaultString, stringToArray } from './string.utils';
import { User } from 'src/app/core/parent-config/parent-config.state';
import { DynamicFormValidation } from './form.validation.util';

export interface DemographicOptions {
  /**
   * add required validator to phone field
   */
  isPhoneRequired?: boolean;
  /**
   * return the lastName field first
   */
  lastNameFirst?: boolean;
  /**
   * Countries options for phoneCountryCode value
   */
  countriesOptionsByTelCode?: CountriesProperties[];
  /**
   * Countries options for Country field
   */
  countriesOptions?: CountriesProperties[];
  /**
   * defines phone validator minLength to 1
   */
  disableMinPhoneLength?: boolean;
  /**
   * defines phone validator minLength to given value
   */
  phoneMinLength?: string;
  /**
   * return all userInfo fields
   */
  getCompleteControls?: boolean;
  /**
   * disable phoneCountryCode select field
   */
  disableTelCode?: boolean;
  /**
   * Enable Copy To Billing checkbox field
   */
  enableCopyToBilling?: boolean;
  /**
   * App config for default countryCode
   */
  defaultCountryCode?: string;
}

export interface PhoneCountryControl {
  value: string;
  options: CountriesProperties[];
}
export interface PhoneCountryDefaultValues {
  phoneRegionCode: string;
  defaultCountryCode: string;
}

/**
 * Returns the demographics layout, if given getCompleteControls also give countriesOptions
 */
export function getFullDemographicLayout(
  values: Partial<UserInfo> = {},
  {
    isPhoneRequired = true,
    lastNameFirst = false,
    countriesOptionsByTelCode = [],
    countriesOptions = [],
    disableMinPhoneLength = false,
    phoneMinLength = '10',
    getCompleteControls = false,
    disableTelCode = false,
    enableCopyToBilling = false,
    defaultCountryCode,
  }: DemographicOptions,
  appConfigs: { [key: string]: any } = {}
): DynamicFormControl<string | boolean>[][] {
  const disableFieldValList = appConfigs[APP_CONFIG_DISABLE_FIELD_PATTERN_VALIDATION];
  const firstNameRegEx = getPatternVal(disableFieldValList, 'firstName', `regex:${DynamicFormValidation.getName()}`);
  const lastNameRegEx = getPatternVal(disableFieldValList, 'lastName', `regex:${DynamicFormValidation.getName()}`);
  const emailRegEx = getPatternVal(disableFieldValList, 'email', 'email');
  const cityRegEx = getPatternVal(disableFieldValList, 'city', `regex:${DynamicFormValidation.getCity()}`);
  const zipRegEx = getPatternVal(disableFieldValList, 'zipPostal', 'zip');
  const zipCountryVal = getPatternVal(disableFieldValList, 'zipPostal', 'zip:country');

  const firstName = new DynamicFormControl({
    autocomplete: 'cc-given-name',
    controlType: 'text',
    key: 'firstName',
    label: 'common.firstname',
    placeholder: 'common.firstname',
    sizeXs: 50,
    size: 50,
    validators: `required;maxLength:32;${firstNameRegEx}`,
    value: values?.firstName || '',
    cssClass: 'padding--15-right',
  });
  const lastName = new DynamicFormControl({
    autocomplete: 'cc-family-name',
    controlType: 'text',
    key: 'lastName',
    label: 'common.lastname',
    placeholder: 'common.lastname',
    sizeXs: 50,
    size: 50,
    validators: `required;maxLength:32;${lastNameRegEx}`,
    value: values?.lastName || '',
  });
  const email = new DynamicFormControl({
    autocomplete: 'email',
    key: 'email',
    label: 'common.email',
    controlType: 'text',
    size: 100,
    placeholder: 'common.email',
    validators: `required;${emailRegEx}`,
    type: 'text',
    value: values?.email || '',
  });
  const phone = new DynamicFormControl({
    autocomplete: 'mobile tel-national',
    key: 'phone',
    label: 'common.phone',
    controlType: 'phone',
    placeholder: 'common.phone',
    options: countriesOptionsByTelCode || [],
    size: 100,
    validators: `minLength:${disableMinPhoneLength ? '1' : phoneMinLength};maxLength:15${isPhoneRequired ? ';required' : ''}`,
    transform: 'numbersOnly',
    type: 'tel',
    value: values?.phone || '',
    telCode: getDefaultPhoneOptionValue(
      { value: values?.phoneCountryCode, options: countriesOptionsByTelCode },
      {
        defaultCountryCode,
        phoneRegionCode: values?.phoneRegionCode,
      }
    ),
    ...(disableTelCode && { disableTelCode: true }),
  });

  const controls: DynamicFormControl<string | boolean>[][] = [[lastNameFirst ? lastName : firstName, lastNameFirst ? firstName : lastName]];

  if (getCompleteControls) {
    const country = new DynamicFormControl({
      autocomplete: 'billing country',
      key: 'country',
      label: 'address.country',
      size: 100,
      controlType: 'select',
      placeholder: 'address.country',
      options: countriesOptions || [],
      validators: 'required',
      value: values?.country || '',
    });

    const addressFields = [
      [
        new DynamicFormControl({
          autocomplete: 'billing address-line1',
          controlType: 'text',
          key: 'address1',
          label: 'address.address1',
          size: 100,
          placeholder: 'address.address1',
          validators: 'required;maxLength:50',
          value: values?.address1 || '',
        }),
      ],
      [
        new DynamicFormControl({
          autocomplete: 'billing address-line2',
          controlType: 'text',
          key: 'address2',
          size: 100,
          label: 'address.address2',
          placeholder: 'address.address2',
          validators: 'maxLength:50',
          value: values?.address2 || '',
        }),
      ],
    ];

    const municipalFields = [
      [
        new DynamicFormControl({
          autocomplete: 'billing address-level2',
          key: 'city',
          label: 'address.city',
          controlType: 'text',
          placeholder: 'address.city',
          size: 100,
          validators: `required;maxLength:50;${cityRegEx}`,
          value: values?.city || '',
        }),
      ],
      [
        new DynamicFormControl({
          autocomplete: 'billing address-level1',
          controlType: 'state',
          countryKey: 'country',
          key: 'state',
          label: 'address.state',
          placeholder: 'address.state',
          size: 50,
          validators: 'maxLength:50',
          value: values?.stateProvince || '',
        }),
        new DynamicFormControl({
          autocomplete: 'billing postal-code',
          controlType: 'text',
          key: 'zip',
          label: 'common.zip',
          placeholder: 'common.zipplaceholder',
          size: 50,
          validators: `required;${zipRegEx}`,
          formValidators: zipCountryVal,
          value: values?.zipPostal || '',
          regexOverride: appConfigs[APP_CONFIG_OVERRIDE_POSTAL_REGEX],
        }),
      ],
    ];

    controls.push(...addressFields, [country], ...municipalFields);
  }

  controls.push([email], [phone]);

  if (enableCopyToBilling) {
    const copyToBilling = new DynamicFormControl({
      key: 'copyToBilling',
      label: 'delivery.copytobilling',
      controlType: 'checkbox',
      value: false,
    });

    controls.push([copyToBilling]);
  }

  return controls;
}

/**
 * function to transform keys to build the billingAddress object
 * @param demographics the demographics object to extract the data
 * @returns
 */
export function buildBillinDemographics(demographics: Partial<DemographicsFormData>): BillingAddress {
  return {
    houseNumberOrName: `${demographics.firstName} ${demographics.lastName}` || '',
    ...((validString(demographics.address1) || validString(demographics.address2)) && { street: demographics.address1 || demographics.address2 }),
    ...(demographics.city && { city: demographics.city }),
    ...(demographics.country && { country: demographics.country }),
    ...(demographics.zip && { postalCode: demographics.zip }),
    ...(demographics.state && { stateOrProvince: demographics.state }),
  };
}

/**
 * Formats form data properties to match User information properties.
 * @param demographics the demographics object to extract the data
 * @returns
 */
export function formatDemographicsAsUserData(demographics: DemographicsFormData): UserInfo {
  if (validObject(demographics)) {
    const {
      copyToBilling,
      zip: zipPostal = '',
      state: stateProvince = '',
      telCode: phoneCountryCode = '',
      regionCode: phoneRegionCode = '',
      address2 = '',
      ...address
    } = demographics;

    return {
      ...address,
      address2,
      zipPostal,
      stateProvince,
      phoneCountryCode,
      phoneRegionCode,
    };
  }

  return null;
}

/**
 * validate if the given value exist into the field options, if shared dial code function use region/country code
 * implement the verified flag to use into the dynamic form component
 * @param value string dial code value
 * @param options avaliable options in the control
 * @param param2 default values
 */
export const getDefaultPhoneOptionValue = (
  { value = '', options = [] }: PhoneCountryControl,
  { phoneRegionCode, defaultCountryCode }: PhoneCountryDefaultValues
): CountriesProperties | string => {
  const telephoneMap = createCountryTelCodeHashMap(options as CountriesProperties[]);
  const matchOptions = telephoneMap.get(value) || [];
  let selectedOption: CountriesProperties;
  if (matchOptions.length > 1) {
    // if shared dial code, use the region/country to select the right value
    const defaultRegion = phoneRegionCode || defaultCountryCode;
    const filteredMatchOptions = matchOptions.find((country) => country.code === defaultRegion);
    selectedOption = filteredMatchOptions ? filteredMatchOptions : matchOptions[0];
  } else {
    selectedOption = matchOptions[0];
  }
  return selectedOption ? { ...selectedOption, verified: true } : '';
};

/**
 * Finds the first control based on a type
 * @param controls The controls to check through
 * @param type The type to check against
 */
export function getControlType(controls: DynamicFormControl<any>[][], type: string): DynamicFormControl<any> {
  for (const row of controls) {
    for (const control of row) {
      if (control.controlType === type) {
        return control;
      }
    }
  }
}

/**
 * Build the Cardholder request object from demographics or user info
 * @param demographics
 * @param user
 * @returns
 */
export function formatDemographicsAsUserDataToCardHolder(demographics: Partial<DemographicsFormData> = {}, user: Partial<User> = {}): CardholderInfo {
  return {
    customer_id: user.customerId || '',
    f_name: returnOrDefaultString(demographics.firstName, user.firstName),
    m_name: returnOrDefaultString(demographics.middleInitial, user.middleInitial),
    l_name: returnOrDefaultString(demographics.lastName, user.lastName),
    address: returnOrDefaultString(joinStrings(', ', demographics.address1, demographics.address2), joinStrings(', ', user.address1, user.address2)),
    city: returnOrDefaultString(demographics.city, user.city),
    country: returnOrDefaultString(demographics.country, user.country),
    state: returnOrDefaultString(demographics.state, user.stateProvince),
    zip: returnOrDefaultString(demographics.zip, user.zipPostal),
    email: returnOrDefaultString(demographics.email, user.email),
    phone_number: returnOrDefaultString(
      joinStrings(' ', demographics.telCode ? '+' + demographics.telCode : '', demographics.phone),
      joinStrings(' ', user.phoneCountryCode ? '+' + user.phoneCountryCode : '', user.phone)
    ),
  };
}

/**
 * Build the User request object from Cardholder info
 * @param cardholder
 * @returns  User request object
 */
export function formatCardHolderAsUserDataToDemographics(cardholder: Partial<CardholderInfo> = {}): Partial<User> {
  return {
    customerId: cardholder.customer_id || '',
    firstName: returnOrDefaultString(cardholder.f_name),
    middleInitial: returnOrDefaultString(cardholder.m_name),
    lastName: returnOrDefaultString(cardholder.l_name),
    address1: returnOrDefaultString(cardholder.address),
    city: returnOrDefaultString(cardholder.city),
    country: returnOrDefaultString(cardholder.country),
    stateProvince: returnOrDefaultString(cardholder.state),
    zipPostal: returnOrDefaultString(cardholder.zip),
    email: returnOrDefaultString(cardholder.email),
  };
}

/**
 * Get pattern validation if not disabled by app config
 * @param fields Fields in disabled validation config
 * @param name Field's name
 * @param pattern Returned pattern if not disabled
 * @returns Pattern for field's validation
 */
export const getPatternVal = (fields: string, name: string, pattern: string): string => {
  return !canDisablePatternVal(fields, name) ? pattern : '';
};

/**
 * Check app config to see if a field
 * should have pattern validation
 * @param list List of fields in disabled validation config
 * @param fieldName Field's name
 * @returns Boolean flag if a field's pattern validation is disabled
 */
export const canDisablePatternVal = (list: string, fieldName: string): boolean => {
  const validations = stringToArray((list ?? '').toUpperCase().replace(/\s/g, ''), ',');
  return validations.includes(fieldName.toUpperCase());
};
