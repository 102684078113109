export enum MobileDevices {
  IPHONE = 'iPhone',
  IPOD = 'iPod',
  ANDROID = 'Android',
  WINDOWS = 'IEMobile|Windows',
}

export enum TabletDevices {
  IPAD = 'iPad',
  TABLET = 'tablet',
  Nexus = 'Nexus.One',
  FOLIO = 'FOLIO',
  KINDLE = 'Kindle',
  ANDROID = 'Android',
  WINDOWS = 'Windows.NT',
}

export enum TvDevices {
  APPLE_TV = 'AppleTV',
  GOOGLE_TV = 'GoogleTV',
  SMART_TV = 'SmartTV',
  INTERNET_TV = 'Internet.TV',
  NETCAST = 'NetCast',
  NET_TV = 'NETTV',
  BOXEE = 'boxee',
  KYLO = 'Kylo',
  ROKU = 'Roku',
  DLNADOC = 'DLNADOC',
  CE_HTML = 'CE-HTML',
  XBOX = 'Xbox',
  PLAYSTATION = 'PlayStation',
  WII = 'Wii',
}
export enum DesktopDevices {
  WINDOWS = 'Windows.(NT|XP|ME|9|10)',
  MAC = 'Mac|Macintosh',
  LINUX = 'Linux',
}

export enum DeviceType {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
  TV = 'tv',
}
