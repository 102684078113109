import { ValidatorFn, Validators } from '@angular/forms';
import { assign, isString } from 'lodash-es';
import { Observable } from 'rxjs';
import { guid } from 'src/app/shared/utilities/guid.utils';
import { toInt } from 'src/app/shared/utilities/number.utils';
import { isNumeric, validObject, validString } from 'src/app/shared/utilities/types.utils';
import { DateCollectionKeyEnum } from '../date-collection/date-collection.component';
import { CrossFieldValidatorFn, DynamicFormTransforms } from '../dynamic-form.component';
import { CountriesProperties } from '../utilities/countries-props.models';
import { crossFieldZipCodeValidator } from '../validators/cross-field-zip-code.validator';
import { emailMatchValidator } from '../validators/email-match.validator';
import { emailValidator } from '../validators/email.validator';
import { numericOnlyValidator } from '../validators/numeric-only-validator';
import { zipCodeValidator } from '../validators/zip-code-validator';
import { ControlType, DynamicFormControlOptions, DynamicFormDropdownOption } from './dynamic-form-control.models';
import { returnOrDefaultString } from 'src/app/shared/utilities/string.utils';

export const defaultOptions = {
  key: '',
  iconPrefix: '',
  iconSuffix: '',
  label: '',
  hideLabel: false,
  hint: '',
  size: null,
  placeholder: '',
  controlType: 'text' as ControlType,
  type: 'text',
};

export class DynamicFormControl<T> {
  autocomplete?: string;
  value: T;
  id: string;
  hint: string;
  key: string;
  iconPrefix: string;
  iconSuffix: string;
  label: string;
  hideLabel: boolean;
  hideOnDisabled?: boolean;
  placeholder: string;
  cssClass?: string;
  controlType: ControlType;
  maxLength: number;
  maxLengthHint = false;
  size: number;
  sizeXs?: number;
  dateCollectionUse: DateCollectionKeyEnum[];
  creditCardExpiration?: boolean;
  minAge: number;
  minDate: string | Date;
  maxAge: number;
  maxDate: string | Date;
  countryKey?: string;
  telCode?: string | CountriesProperties;
  regionCode?: string;
  type: string;
  transform: string;
  validators: string;
  formValidators: string;
  compiledValidators: ValidatorFn[];
  compiledFormValidators: (CrossFieldValidatorFn | ValidatorFn)[];
  compiledTransforms: DynamicFormTransforms;
  options: DynamicFormDropdownOption[] | CountriesProperties[] = [];
  asyncOptions?: Observable<DynamicFormDropdownOption[] | CountriesProperties[]>;
  regexOverride?: any;

  constructor(options: Partial<DynamicFormControlOptions<T>> = { ...defaultOptions }) {
    assign(this, { id: guid(), ...defaultOptions }, options);
    this.compiledValidators = this.compileValidators(options.validators);
    this.compiledFormValidators = this.compileFormValidators(options.formValidators);
    this.compiledTransforms = this.compileTransforms(options.transform);
  }

  compileFormValidators(validators?: string): ValidatorFn[] {
    const compiled = [];

    if (!isString(validators)) {
      return compiled;
    }

    return validators
      .split(';')
      .filter(validString)
      .reduce((acc: ValidatorFn[], part: string): ValidatorFn[] => {
        const [key, value] = part.split(':');
        switch (key) {
          case 'zip':
            acc.push(crossFieldZipCodeValidator(this.key, value, this.regexOverride));
            break;
          case 'emailMatch':
            acc.push(emailMatchValidator(this.key, value));
            break;
        }

        return acc;
      }, compiled);
  }

  compileTransforms(transform?: string): DynamicFormTransforms {
    const compiled = {};

    if (!isString(transform)) {
      return compiled;
    }

    return transform
      .split(',')
      .filter(validString)
      .reduce((acc: DynamicFormTransforms, part: string): DynamicFormTransforms => {
        const [key, value] = part.split(':');

        switch (key) {
          case 'decimal':
            acc.decimal = isNumeric(value) ? parseInt(value, 10) : 2;
            break;
          case 'numbersOnly':
            acc.numbersOnly = true;
            break;
          case 'monthYear':
            acc.monthYear = true;
            break;
        }

        return acc;
      }, compiled);
  }

  /**
   * Compiles validators. The param passed in is a comma-delimited list of validators to use.
   * Example: required,min:3,max:10,pattern:regex;minLength:5
   * @param validators The validators to compile
   */
  compileValidators(validators: string): ValidatorFn[] {
    const compiled = [];

    if (!isString(validators)) {
      return compiled;
    }

    return validators
      .split(';')
      .filter(validString)
      .reduce((acc: ValidatorFn[], part: string): ValidatorFn[] => {
        const [key, value] = part.split(':');

        switch (key) {
          case 'required':
            acc.push(Validators.required);
            break;
          case 'numeric':
            acc.push(numericOnlyValidator(value === 'positive'));
            break;
          case 'min':
          case 'minLength':
          case 'max':
          case 'maxLength':
            const val = toInt(value);
            if (val > 0) {
              acc.push(Validators[key](val));

              if (key === 'maxLength') {
                this.maxLength = val;
              }
            }
            break;
          case 'email':
            acc.push(emailValidator());
            break;
          case 'regex':
            if (validString(value)) {
              acc.push(Validators.pattern(new RegExp(value, 'i')));
            }
            break;
          case 'maxLengthHint':
            this.maxLengthHint = true;
            break;
          case 'zip':
            acc.push(zipCodeValidator(returnOrDefaultString(value), validObject(this.regexOverride) ? this.regexOverride : null));
            break;
        }

        return acc;
      }, compiled);
  }
}
