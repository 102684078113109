import { createAction, props } from '@ngrx/store';
import { StaticDataResponse } from './static-data.model';

export enum StaticDataActions {
  RequestStaticData = '[Static Data Effects] Request Static Data',
  StaticDataLoadedSuccess = '[Static Data Effects] Static Data Loaded',
  StaticDataLoadedFailure = '[Static Data Effects] Static Data Loading Failed',
  StaticDataInjected = '[Static Data Effects] Static Data Injected',
}

/**
 * The action to request static data
 */
export const requestStaticDataAction = createAction(StaticDataActions.RequestStaticData);

/**
 * The action for when static data is loaded successfullly
 */
export const staticDataLoadedSuccessAction = createAction(StaticDataActions.StaticDataLoadedSuccess, props<{ payload: StaticDataResponse['data'] }>());

/**
 * The action for when static data failed to load
 */
export const staticDataLoadedFailureAction = createAction(StaticDataActions.StaticDataLoadedFailure);

/**
 * The action for when static data is injected
 */
export const staticDataInjectedAction = createAction(StaticDataActions.StaticDataInjected);
