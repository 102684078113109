import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiServiceResponse, ApiStatus, Response } from './api.interface';
import { apiResponse, deleteApiRequest, getApiRequest, postApiRequest } from './api.actions';
import { isObject, toString } from 'lodash-es';
import { mergeMap, switchMap } from 'rxjs/operators';

import { AcLoggerService } from '../logger/logger.service';
import { Dictionary } from 'src/app/shared/utilities/types.utils';
import { ApiService } from './api.service';
import { AppState } from 'src/app/app.state';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

const { FAILED } = ApiStatus;

/**
 * Handles all of the API requests and parsing the responses.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiEffects {
  /**
   * Handles all requests to GET requests to our backend API
   */
  getApiRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getApiRequest),
      mergeMap((action) => {
        const { params, requestType, headers, resources } = action;
        return this.apiService
          .get<ApiServiceResponse>(requestType, params, headers, resources)
          .pipe(switchMap(this.handleResponse.bind(this, requestType, params, {}, resources, {})));
      })
    )
  );

  /**
   * Handles all requests to POST requests to our backend API
   */
  postApiRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postApiRequest),
      mergeMap((action) => {
        const { params, requestType, headers, body, resources, callbackData } = action;
        return this.apiService
          .post<ApiServiceResponse>(requestType, body, params, headers, resources)
          .pipe(switchMap(this.handleResponse.bind(this, requestType, params, body, resources, callbackData)));
      })
    )
  );

  /**
   * Handles all requests to DELETE requests to our backend API
   */
  deleteApiRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteApiRequest),
      mergeMap((action) => {
        const { params, requestType, headers, body, resources } = action;

        return this.apiService
          .delete<ApiServiceResponse>(requestType, body, params, headers, resources)
          .pipe(switchMap(this.handleResponse.bind(this, requestType, params, body, resources, {})));
      })
    )
  );

  /**
   * Handles response
   * @param requestType request type
   * @param params request params
   * @param body request body
   * @param resources resources
   * @param res response
   * @param callbackData data used for any callbacks
   */
  handleResponse(
    requestType: string,
    params: Dictionary<any>,
    body: Dictionary<any>,
    resources: string[],
    callbackData: any,
    res: Response<ApiServiceResponse>
  ) {
    if (!isObject(res)) {
      this.logger.error(`[Api Error] response was not an object`, res);

      return [
        apiResponse({
          isOk: false,
          status: ApiStatus.FAILED,
          requestType,
          response: {
            error_code: '-1',
            error_msg: 'Response from backend was not an object',
            request_type: requestType,
          },
          params,
          body,
          resources,
        }),
      ];
    }

    const { status, response } = res;

    return [
      apiResponse({
        isOk: status === ApiStatus.OK,
        status,
        requestType,
        response,
        params,
        body,
        resources,
        callbackData,
      }),
    ];
  }

  /**
   * Builds a standard error response object
   * @param requestType The request type
   * @param err The error we'll be stringifying
   */
  buildStandardErrorResponse(requestType: string, err: any): ApiServiceResponse {
    return {
      error_code: '-1',
      error_msg: toString(err),
      request_type: requestType,
      status: FAILED,
    };
  }

  constructor(private actions$: Actions, private apiService: ApiService, private logger: AcLoggerService, store: Store<AppState>) {}
}
