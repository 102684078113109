import { Action } from '@ngrx/store';
import { isNumber } from 'lodash-es';
import { ApiStatus, Response } from 'src/app/core/api/api.interface';
import { DonationAuthRequest, InsuranceAuthRequest } from 'src/app/core/api/responses/authorize';
import { EWalletAuthorizeRequest } from 'src/app/core/api/responses/ewallet-authorize';
import { SVCPreAuthRequest, SVCPreAuthResponse } from 'src/app/core/api/responses/svc-pre-auth';
import { LineItemsAndFees } from 'src/app/core/parent-config/parent-config.utils';
import { formatDemographicsAsUserDataToCardHolder } from 'src/app/shared/utilities/demographics.utils';
import { convertDonationResponse } from 'src/app/shared/utilities/donation.utils';
import { convertInsuranceResponse } from 'src/app/shared/utilities/insurance.utils';
import { isBetween, toDecimal } from 'src/app/shared/utilities/number.utils';
import { validString } from 'src/app/shared/utilities/types.utils';
import { payPartialAmountWithGiftCardSuccessAction, payWithGiftCardAction, payWithGiftCardSuccessAction } from './gift-card.actions';
import { AmountType, GiftCard, MAX_NUM_GIFT_CARDS, MIN_NUM_GIFT_CARDS } from './gift-card.state';

/**
 * Check if every svc is preauthorized
 * @param giftcards
 * @returns
 */
export function hasPreAuthGiftCards(giftcards: GiftCard[]): boolean {
  return giftcards.every((gc) => isNumber(gc.authId));
}
/**
 * Check if a pre-authorized failed
 * @param giftcards
 * @returns
 */
export function hasFailedSvcPreAuth(apiResponses: Response<SVCPreAuthResponse>[]): boolean {
  return apiResponses.some(({ status }) => status === ApiStatus.FAILED);
}

/**
 * Return gift cards that need to be preauthorized
 * @param giftcards
 * @returns
 */
export function filterNonPreAuthGiftCards(giftcards: GiftCard[]): GiftCard[] {
  return giftcards.filter((card) => !isNumber(card.authId));
}

/**
 * Return preauthorized gift cards
 * @param giftcards
 * @returns
 */
export function filterPreAuthGiftCards(giftcards: GiftCard[]): GiftCard[] {
  return giftcards.filter((card) => isNumber(card.authId) || (card?.paymentReference && card?.preAuthBalance));
}

/**
 * Get total sum of preauthorize gift cards
 * @param preAuthGiftCards
 * @returns
 */
export function sumPreAuthorizedGiftCardAmount(preAuthGiftCards: GiftCard[]): number {
  return preAuthGiftCards.reduce((authAmount, card) => toDecimal(authAmount + card.preAuthBalance, 2), 0);
}

/**
 * Calculate cart amount for svc pre-auth
 * @param preAuthAmount
 * @param donationAmount
 * @param insuranceAmount
 * @returns
 */
export function calcPreAuthCartAmount(preAuthAmount: number = 0, donationAmount: number = 0, insuranceAmount: number = 0): number {
  let total = preAuthAmount;
  if (donationAmount > 0) {
    total -= donationAmount;
  }
  if (insuranceAmount > 0) {
    total -= insuranceAmount;
  }
  return Math.max(toDecimal(total, 2), 0);
}

/**
 * Build request for svc-preauth
 * @param data
 * @returns request body
 */
export function buildPreAuthRequestData({
  card = {} as any,
  merchantId,
  donation = {} as DonationAuthRequest,
  insurance = {} as InsuranceAuthRequest,
  lineItems = {} as LineItemsAndFees,
  billingDemographics,
  user = {},
}): SVCPreAuthRequest {
  const { cartItems, fees } = lineItems as LineItemsAndFees;
  const { card_number, pin, amount } = card;

  const requestData: SVCPreAuthRequest = {
    amount,
    card_number,
    merchant_id: merchantId,
    ...(pin && { pin }),
    ...(cartItems?.length && { cartItems }),
    ...(fees?.length && { fees }),
    ...(billingDemographics && {
      CARDHOLDER: formatDemographicsAsUserDataToCardHolder(billingDemographics, user),
    }),
  };

  if (card.insurance > 0) {
    requestData.insurance = { ...insurance, premium_amount: card.insurance };
    requestData.insurance_quote_token = insurance.insurance_quote_token;
  }

  if (card.donation > 0) {
    requestData.donation = { ...donation, donation_amount: card.donation };
  }

  return requestData;
}

/**
 * Build the amount data for giftcards.
 * @param data contanining giftCardsAdded, orderAmount, donation data
 * @returns an array of gift card data and updated amount types
 */
export function buildGiftCardAmountData(data: { amountTypes: AmountType[]; giftCardsAdded: GiftCard[] }): {
  giftCardData: GiftCard[];
  amountTypes: AmountType[];
} {
  let { amountTypes, giftCardsAdded } = data;
  let giftCardData = [];

  giftCardsAdded.forEach((card) => {
    if (amountTypes.some((amtType) => amtType.amount > 0)) {
      let amtArrItem = { card_number: card.cardNumber, pin: card.pin };
      let cardBalance = card.balance;
      amountTypes.forEach((item) => {
        if (cardBalance > 0) {
          const amount = cardBalance >= item.amount ? item.amount : cardBalance;
          amtArrItem = { ...amtArrItem, [item.type]: amount };
          amountTypes = updateTypeAmount(amountTypes, item.type, toDecimal(item.amount - amount, 2));
          cardBalance = toDecimal(cardBalance - amount, 2);
        }
      });
      giftCardData.push(amtArrItem);
    }
  });

  return { giftCardData, amountTypes };
}

/**
 * Build a payload for partial amount with Gift Card
 * @param data containing payload and amountTypes
 * @returns updated payload
 */
export function buildPartialAmountWithGiftCardPayload(data: { payload: any; amountTypes: AmountType[] }): any {
  const amountData = data.amountTypes.find((item) => item.type === 'amount');
  const donationData = data.amountTypes.find((item) => item.type === 'donation');
  const insuranceData = data.amountTypes.find((item) => item.type === 'insurance');
  const amount = amountData.amount;
  const donation = donationData?.amount ? { ...data.payload.donation, donation_amount: donationData.amount } : null;
  const insurance = insuranceData?.amount ? { ...data.payload.insurance, premium_amount: insuranceData.amount } : null;
  return data.payload.eWalletRequest
    ? {
        ...data.payload,
        eWalletRequest: {
          ...data.payload.eWalletRequest,
          amount,
          donation,
          insurance,
        } as EWalletAuthorizeRequest,
      }
    : { ...data.payload, amount, donation, insurance };
}

/**
 * Build an array of amount types
 * @param data containing orderAmount and donation
 * @returns an array of amount types
 */
export function buildAmountTypeArr(data: { orderAmount: number; donationAmount?: number; insuranceAmount?: number }): AmountType[] {
  const { orderAmount, donationAmount, insuranceAmount } = data;
  const amounts = [{ type: 'amount', amount: orderAmount }];
  if (insuranceAmount) {
    amounts.push({ type: 'insurance', amount: insuranceAmount });
  }

  if (donationAmount) {
    amounts.push({ type: 'donation', amount: donationAmount });
  }

  return amounts;
}

/**
 * Update the type's amount in an array
 * @param arr an array of amount types
 * @param type a type of an amount needed to update
 * @param amount a value to update
 * @returns a new array of amount types
 */
export function updateTypeAmount(arr = [], type: string, amount: number): any[] {
  return arr.map((item) => (item.type === type ? { ...item, amount } : item));
}

/**
 * Map the latest preauthorized gift cards from response
 * @param apiResponses
 * @param nonPreAuthGiftCards
 * @returns
 */
export function mapPreAuthResponses(apiResponses: Response<SVCPreAuthResponse>[], nonPreAuthGiftCards: GiftCard[]): GiftCard[] {
  return apiResponses.map(({ status, response }, index) => {
    const matchingGiftCardForResponse = nonPreAuthGiftCards[index];
    if (status === ApiStatus.OK) {
      const { pre_auth_balance, balance, paymentReference, auth_id: authId, card_reference_code, currency } = response;
      return {
        cardNumber: matchingGiftCardForResponse.cardNumber,
        authId,
        balance,
        currencyCode: currency,
        paymentReference: paymentReference || card_reference_code,
        preAuthBalance: pre_auth_balance,
        amount: calcPreAuthCartAmount(pre_auth_balance, response.donation?.donation_amount, response.insurance?.insurance_amount),
        ...(response.donation && { donation: convertDonationResponse(response.donation) }),
        ...(response.insurance && { insurance: convertInsuranceResponse(response.insurance) }),
      };
    }
  });
}

/**
 * Evaluate what flow continue after auth SVC
 * @param params required params for evaluate what actions should follow
 * @returns next action
 */
export function getFinishAuthSvcAction({ lastAction, preAuthGiftCards, amountTypes }): Action {
  return lastAction.type === payWithGiftCardAction.type
    ? payWithGiftCardSuccessAction({ payload: preAuthGiftCards })
    : payPartialAmountWithGiftCardSuccessAction({
        payload: preAuthGiftCards,
        callback: {
          action: lastAction.callback.action,
          payload: buildPartialAmountWithGiftCardPayload({ payload: lastAction.callback.payload, amountTypes }),
        },
      });
}

/**
 * search for a giftcard that have insurance amount, if not return the first element
 * the returned element will be removed from the given array
 * @param giftCardData giftcards to auth
 * @returns the firts giftcard with insurance or the first element
 */
export function getfirstGiftcardToAuth(giftCardData: GiftCard[]) {
  let firstCard,
    insuranceFirstPartialPayment = giftCardData.findIndex((card) => card.insurance);
  if (insuranceFirstPartialPayment > 0) {
    firstCard = giftCardData.splice(insuranceFirstPartialPayment, 1)[0];
  } else {
    firstCard = giftCardData.shift();
  }
  return firstCard;
}

/**
 * Get applied amount so the applied balance is not more than total.
 * @param total
 * @param balance
 * @returns
 */
export function calculateAppliedAmount(total: number, balance: number): number {
  const diff = Math.abs(Math.min(total - balance, 0));
  return toDecimal(balance - diff, 2);
}

/**
 * Get the last 4 character of a string
 * @param str
 * @returns
 */
export function getLastFour(str: string): string {
  return validString(str) ? str.substring(str.length - 4) : '';
}

/**
 * Replace card numbers with 12 asterisks
 * @param cardNum
 * @returns
 */
export function maskCardNumber(cardNum: string): string {
  if (!validString(cardNum)) {
    throw new Error('Invalid card number');
  }

  return `*${getLastFour(cardNum)}`;
}

/**
 * Use value to calculate appropriate number of gift cards allowed
 * @param entriesAllowed
 * @returns Max number of gift cards allowed
 */
export function calcMaxNumOfGiftCards(entriesAllowed: number): number {
  return Number.isInteger(entriesAllowed) && isBetween(entriesAllowed, MIN_NUM_GIFT_CARDS, MAX_NUM_GIFT_CARDS) ? entriesAllowed : MAX_NUM_GIFT_CARDS;
}
