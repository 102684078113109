import { createAction, props } from '@ngrx/store';

export enum InitializeActionTypes {
  InitializeStart = '[App Initialize] Start',
  InitializeFailed = '[App Initialize] Failed',
  InitializeFinished = '[App Initialize] Finished',
}

/**
 * Tells the application we're starting the initialize phase
 */
export const initializeStart = createAction(InitializeActionTypes.InitializeStart);

/**
 * Tells the application we've finished the initialization phase
 */
export const initializeFinishedAction = createAction(InitializeActionTypes.InitializeFinished);

/**
 * Tells the application we've failed the initialization phase
 */
export const initializeFailedAction = createAction(
  InitializeActionTypes.InitializeFailed,
  props<{
    errorCode: number;
    reason: string;
    redirectTo?: string[];
    metaData?: { [key: string]: string };
  }>()
);
