import Bugsnag from '@bugsnag/js';
import { validObject, validString } from './types.utils';
import { Observable, throwError } from 'rxjs';
import { setAttributes } from './element.utils';

export interface AddScriptOptions {
  [k: string]: any;
  subresourceIntegrity?: string;
  onLoad?: Function;
}

/**
 * Adds a script to the <head> section
 * @param url The url to load
 * @param async If you want it to be async or not
 * @param options The script options
 */
export function addScript(url, async = true, options: AddScriptOptions = {}): Observable<string> {
  if (!validString(url)) {
    return throwError(() => new Error('invalid script url'));
  }

  return new Observable((subscriber) => {
    const { id } = options;

    if (validString(id) && document.getElementById(id)) {
      subscriber.next(id);
      subscriber.complete();
      return;
    }

    const scriptEl: HTMLScriptElement = document.createElement('script');
    setAttributes(scriptEl, options);

    if (async) {
      scriptEl.async = true;
    }

    if (id) {
      scriptEl.id = id;
    }

    if (validString(options.subresourceIntegrity)) {
      scriptEl.integrity = options.subresourceIntegrity;
    }

    if (validString(options.crossOrigin)) {
      scriptEl.crossOrigin = options.crossOrigin;
    }

    scriptEl.onload = () => {
      subscriber.next(id);
      subscriber.complete();
    };
    scriptEl.onerror = (error) => {
      Bugsnag.notify(new Error('Failed to load resource'), (event) => {
        event.addMetadata('Resource', {
          error,
          file: url,
          options,
        });
      });
      subscriber.error(new Error('Failed to load resource'));
      subscriber.complete();
    };
    scriptEl.onabort = (error) => {
      Bugsnag.notify(new Error('Resource aborted'), (event) => {
        event.addMetadata('Resource', {
          error,
          file: url,
          options,
        });
      });
      subscriber.error(new Error('Resource aborted'));
      subscriber.complete();
    };

    scriptEl.src = url;

    document.head.appendChild(scriptEl);
  });
}
