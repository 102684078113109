import Bugsnag from '@bugsnag/js';
import { createReducer, on } from '@ngrx/store';
import { validString } from 'src/app/shared/utilities/types.utils';
import { setParentConfig } from '../parent-config/parent-config.actions';
import { staticDataLoadedFailureAction } from '../static-data/static-data.actions';
import { setLanguage, setOriginUrl, updateClientConfiguration } from './client-configuration.actions';
import { ClientConfigurationState, initialState } from './client-configuration.state';

const reducer = createReducer(
  initialState,
  on(staticDataLoadedFailureAction, (state, action) => ({ ...state, error: true })),
  on(updateClientConfiguration, (state, { payload }) => {
    const config = Bugsnag.getMetadata('ClientConfig');
    config.tenantName = payload.tenantName;
    config.merchantId = payload.merchantId;
    config.language = payload.language;
    Bugsnag.addMetadata('ClientConfig', config);

    return { ...payload };
  }),
  on(setOriginUrl, (state, action) => ({ ...state, originUrl: action.payload })),
  on(setLanguage, (state, action) => {
    if (validString(action.payload)) {
      return { ...state, language: action.payload };
    }
    return state;
  }),
  on(setParentConfig, (state, action) => {
    const { originUrl } = action.payload;
    if (validString(originUrl)) {
      return { ...state, originUrl };
    }

    return state;
  })
);

export function clientConfigurationReducer(state, action): ClientConfigurationState {
  return reducer(state, action);
}
