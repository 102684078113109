import { createAction, props } from '@ngrx/store';
import { PaymentComplete, SplitPaymentComplete } from '../application-bridge/application-bridge.models';

export enum AnalyticsActionTypes {
  CHECKOUT_STEP_ACTION = '[Analytics] Set Checkout Step',
  SEND_EVENT = '[Analytics] Send Event',
  SEND_TO_GTAG = '[Analytics] Gtag',
  SEND_TO_OPTIMIZELY = '[Analytics] Optimizely',
  PAYMENT_COMPLETE_ACK = '[Analytics] Acknowledge Payment Complete',
}

export enum AnalyticsCheckoutStep {
  DELIVERY_METHOD_SLECTION_PAGE = 1,
  EXTRAS_PAGE = 2,
  PAYMENT_SELECTION_PAGE = 3,
  SELECT_PAYMENT = 4,
  STANDARD_CREDIT_CARD_PAGE = 5,
  ALTERNATE_PAYMENT_PAGE = 5,
}

export const sendToGtag = createAction(
  AnalyticsActionTypes.SEND_TO_GTAG,
  props<{
    params: any[];
  }>()
);

export const sendToOptimizelyAction = createAction(
  AnalyticsActionTypes.SEND_TO_OPTIMIZELY,
  props<{
    event: OptimizelyEvent;
  }>()
);

export const setCheckoutStepAction = createAction(
  AnalyticsActionTypes.CHECKOUT_STEP_ACTION,
  props<{
    step: AnalyticsCheckoutStep;
    option?: string;
    value?: number;
    callback?: () => void;
  }>()
);

export const sendAnalyticsEventAction = createAction(
  AnalyticsActionTypes.SEND_EVENT,
  props<{
    action: string;
    category?: string;
    label?: string;
    value?: string;
    nonInteraction?: boolean;
  }>()
);

export const sendPaymentComplete = createAction(AnalyticsActionTypes.PAYMENT_COMPLETE_ACK, props<{ payload: PaymentComplete | SplitPaymentComplete }>());
