import { ApiServiceResponse } from '../api.interface';

/**
 * Session state
 */
export enum SessionState {
  REDIRECT = 'REDIRECT',
  INITIALIZE = 'INITIALIZE',
}
export interface GetAccessoPaySessionResponse extends ApiServiceResponse {
  /**
   * The session token passed in the request
   */
  sessionKey: string;
  /**
   * The session payload data
   */
  payload: string;
  /**
   * The session state
   */
  sessionState: SessionState;
}
