import { Action, createAction, props } from '@ngrx/store';

import { NavigationExtras } from '@angular/router';

/**
 * Our routing action types.
 */
export enum RoutingActionTypes {
  GoBack = '[Routing] Go Back',
  RouteTo = '[Routing] Route To',
  RouteToWithOptions = '[Routing] Route To With Options',
  RouteToWithOptionsInZone = '[Routing] Route To With Options In Ng Zone',
  FullRouteTo = '[Routing] Full Route To',
  ClearRedirectPaymentQueryParams = '[Redirect Payment Result] Clear Alternate Payment Query Params',
}

/**
 * Does a relative route to /{tenantName}/{merchantId}/{language}/.
 * @param * string or numberd params.
 * @returns RouteTo
 */
export const routeTo = createAction(RoutingActionTypes.RouteTo, props<{ payload: (string | number)[] }>());

/**
 * Does a relative route to /{tenantName}/{merchantId}/{language}/.
 * @param * string or numberd params.
 * @returns RouteTo
 */
export const routeToWithOptions = createAction(RoutingActionTypes.RouteToWithOptions, props<{ options: NavigationExtras; routeParams: (string | number)[] }>());

/**
 * Does a relative route to /{tenantName}/{merchantId}/{language}/.
 * Routes by re-entering into Angular zone
 * @param * string or numberd params.
 * @returns RouteTo
 */
export const routeToWithOptionsInZone = createAction(
  RoutingActionTypes.RouteToWithOptionsInZone,
  props<{ options: NavigationExtras; routeParams: (string | number)[] }>()
);

/**
 * Does a full route to. You must provide all arguments.
 * @param * string or numberd params.
 * @returns FullRouteTo
 */
export const fullRouteTo = createAction(RoutingActionTypes.FullRouteTo, props<{ payload: (string | number)[] }>());

/**
 * Goes back to previous page
 */
export const goBack = createAction(RoutingActionTypes.GoBack);

/**
 * Clear query params from the url
 */
export const clearRedirectPaymentQueryParamsAction = createAction(RoutingActionTypes.ClearRedirectPaymentQueryParams, props<{ params: string[] }>());
