import { DeliveryState, initialState } from './delivery.state';
import {
  ShowDeliveryViewAction,
  clearDeliveryAddressDetailsAction,
  hideDeliveryViewAction,
  selectDeliveryMethodAction,
  updateDeliveryAction,
  updateDeliveryAmountAction,
  updateDeliveryDetailsAction,
} from './delivery.actions';
import { createReducer, on } from '@ngrx/store';
import { validArray, validString } from 'src/app/shared/utilities/types.utils';

import { isObject } from 'lodash-es';
import { returnOrDefaultString } from 'src/app/shared/utilities/string.utils';
import { setParentConfig } from 'src/app/core/parent-config/parent-config.actions';

const reducer = createReducer(
  initialState,
  on(updateDeliveryAction, (state, action) => ({ ...state, deliveryMethodsConfigured: validArray(action.payload) })),
  on(selectDeliveryMethodAction, (state, action) => {
    const { payload } = action;
    return { ...state, selectedDeliveryMethod: payload.id, collectAddress: payload.collectAddress };
  }),
  on(updateDeliveryDetailsAction, (state, action) => ({ ...state, address: action.payload })),
  on(clearDeliveryAddressDetailsAction, (state) => {
    const newState = { ...state };
    if (newState.hasOwnProperty('address')) {
      delete newState.address;
    }
    return newState;
  }),
  on(hideDeliveryViewAction, (state) => ({ ...state, showDelivery: false })),
  on(ShowDeliveryViewAction, (state) => ({ ...state, showDelivery: true })),
  on(updateDeliveryAmountAction, (state, action) => ({ ...state, deliveryAmount: action.payload })),
  on(setParentConfig, (state, action) => {
    const { delivery } = action.payload;
    const address = delivery?.address;
    const isConfigured = validArray(delivery?.options);
    const deliveryMethods = delivery?.options || [];
    const isRenderRequired = deliveryMethods.length > 1 || (deliveryMethods.length === 1 && deliveryMethods[0].collectAddress);

    const newState = {
      ...state,
      collectAddress: isObject(address) && Object.keys(address).some((key) => validString(address[key])),
      deliveryMethodsConfigured: isConfigured,
      selectedDeliveryMethod: delivery?.defaultSelectedId,
      showDelivery: isConfigured && isRenderRequired,
    };

    if (isObject(address)) {
      const { firstName, lastName, middleInitial, country, address1, address2, city, stateProvince, zipPostal, email, phone, phoneCountryCode } = address;

      newState.address = {
        valid: [firstName, lastName, country, address1, city, zipPostal, email].every(validString),
        firstName: returnOrDefaultString(firstName),
        lastName: returnOrDefaultString(lastName),
        middleInitial: returnOrDefaultString(middleInitial),
        address1: returnOrDefaultString(address1),
        address2: returnOrDefaultString(address2),
        city: returnOrDefaultString(city),
        state: returnOrDefaultString(stateProvince),
        country: returnOrDefaultString(country),
        zip: returnOrDefaultString(zipPostal),
        email: returnOrDefaultString(email),
        phone: returnOrDefaultString(phone),
        telCode: returnOrDefaultString(phoneCountryCode),
      };
    }

    return newState;
  })
);

export function deliveryReducer(state, action): DeliveryState {
  return reducer(state, action);
}
