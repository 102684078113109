import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Injectable } from '@angular/core';
import { PayPalService } from './paypal.service';
import { map } from 'rxjs/operators';
import { mountPayPalButtonsAction } from './paypal.actions';

@Injectable()
export class PayPalEffects {
  /**
   * Configures PayPal
   */
  mountPayPal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(mountPayPalButtonsAction),
        map((action) => this.paypalService.configurePayPal(action.elementId))
      ),
    { dispatch: false }
  );

  /**
   * @param actions$ The action stream.
   * @param paypalService Our main service.
   * @param store The store.
   */
  constructor(private actions$: Actions, private paypalService: PayPalService) {}
}
