export const IS_IN_IFRAME = parent !== window;

export interface WindowConfig {
  url: string;
  width?: number;
  height?: number;
  windowName?: string;
  windowFeatures?: Array<string>;
}
/**
 * Opens a new popup window
 * @see {@link https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen stackoverflow}
 * @param win window
 * @param config new window configuration
 */
export function openPopupWindow(win: Window, config: WindowConfig): Window {
  const { url, windowName, width, height, windowFeatures } = config;
  const features = [...(windowFeatures || []), 'popup', `width=${width}`, `height=${height}`].join(',');

  return win.open(url, windowName, features);
}
