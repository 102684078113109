import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ClientConfigurationState } from './client-configuration.state';

/**
 * Selects the environment state
 * @param state The application state
 * @returns The environment state
 */
export const selectClientConfiguration = createFeatureSelector<ClientConfigurationState>('clientConfiguration');

/**
 * Selects the tenantName from the client configuration state.
 */
export const selectTenantName = createSelector(selectClientConfiguration, (state: ClientConfigurationState): string => state.tenantName);

/**
 * Selects the merchant id from the client configuration state.
 */
export const selectMerchantId = createSelector(selectClientConfiguration, (state: ClientConfigurationState) => state.merchantId);

/**
 * Selects the language from the client configuration state.
 */
export const selectLanguage = createSelector(selectClientConfiguration, (state: ClientConfigurationState) => state.language);
/**
 * Selects the parent host name from the client configuration state.
 */
export const selectParentHostName = createSelector(selectClientConfiguration, (state: ClientConfigurationState) => state.parentHostname);
