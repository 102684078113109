import { registerLocaleData } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { delay, map } from 'rxjs';
import { filter, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { WINDOW } from 'src/app/core/injection-token/window/window';
import { AcLoggerService } from 'src/app/core/logger/logger.service';
import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import { EVENT_INITIALIZE_FAILED } from 'src/app/shared/enums/application-bridge.enums';
import { ERROR_CODE_INVALID_LOCALE, ERROR_CODE_SERVICE_FAILED } from 'src/app/shared/enums/error-code.enums';
import { validString } from 'src/app/shared/utilities/types.utils';
import { getRouteParamsFromUrl } from 'src/app/shared/utilities/url.utils';
import { staticApiResponseAction } from '../api/api.actions';
import { sendMessageAction } from '../application-bridge/application-bridge.actions';
import { setLanguage } from '../client-configuration/client-configuration.actions';
import { selectClientConfiguration } from '../client-configuration/client-configuration.selectors';
import { environmentLoadedSuccess } from '../environment/environment.actions';
import { initializeFailedAction, initializeStart } from '../initialize/initialize.actions';
import { NotificationDialogType } from '../notification/dialog/confirm-dialog/confirm-dialog.component';
import { showNotificationAction } from '../notification/notification.actions';
import { staticDataInjectedAction } from '../static-data/static-data.actions';
import {
  fallbackToDefaultLocaleAction,
  fetchLocaleFile,
  fetchLocaleFileSuccess,
  loadLocaleFileAction,
  localeFailureAction,
  verifyLocaleSupportAction,
} from './locale.actions';
import { findSupportedLocale, getDefaultLocale, getSupportedLocaleList, importAngularLocale, LocaleImportDetail, localeToCLDR } from './locale.utils';

const LOCALE_PREFIX = '[Application Locale]';

/**
 * Handles any sort of requesting of locale-related information.
 */
@Injectable()
export class LocaleEffects {
  /**
   * Handles watching the initializeStart action to start our locale config parsing.
   */
  getLocaleConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initializeStart),
      switchMap(() => {
        const locale = getRouteParamsFromUrl(this.window)[2] ?? getDefaultLocale();

        if (locale === 'en-US') {
          return [fetchLocaleFile({ payload: locale })];
        } else {
          return [verifyLocaleSupportAction({ payload: localeToCLDR(locale) })];
        }
      })
    )
  );

  /**
   * Verify locale is supported, or check is part of locale is usable.
   */
  verifyLocaleSupport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(verifyLocaleSupportAction),
      switchMap(({ payload: clientLocale }) =>
        getSupportedLocaleList().pipe(
          findSupportedLocale(clientLocale),
          tap((supportedLocale) => {
            if (supportedLocale && supportedLocale !== clientLocale) {
              this.logger.warn(`${LOCALE_PREFIX} Using "${supportedLocale}" because "${clientLocale}" is not supported`);
            }
          }),
          map((supportedLocale) => {
            if (!supportedLocale) {
              return localeFailureAction({ locale: clientLocale });
            }

            return supportedLocale !== clientLocale
              ? fallbackToDefaultLocaleAction({ payload: supportedLocale })
              : fetchLocaleFile({ payload: supportedLocale });
          })
        )
      )
    )
  );

  /**
   * Fetch the global locale file.
   */
  fetchLocaleFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchLocaleFile),
      mergeMap(({ payload: locale }) => {
        const actions: Action[] = [setLanguage({ payload: locale })];
        if (locale === 'en-US') {
          actions.push(fetchLocaleFileSuccess());
        } else {
          actions.push(loadLocaleFileAction({ locale }));
        }
        return actions;
      })
    )
  );

  /**
   * Loads the global locale file.
   */
  loadLocale$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadLocaleFileAction),
      switchMap(({ locale: supportedLocale }) => importAngularLocale(supportedLocale)),
      tap(({ localeData }: LocaleImportDetail) => registerLocaleData(localeData)),
      map(({ locale }: LocaleImportDetail) => fetchLocaleFileSuccess())
    )
  );

  /**
   * Handles failure to find a suitable locale.
   */
  localeFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(localeFailureAction),
      delay(100), // Allow time for pay.js to register callback events
      switchMap(({ locale }) => [
        showNotificationAction({
          buttonLabel: 'Close', // Locales have not been loaded.
          dialogType: NotificationDialogType.GENERAL,
          initiator: 'Initialize Module',
          message: `Application locale is not supported: ${locale}`,
          onClose: () =>
            this.store.dispatch(
              sendMessageAction({
                key: EVENT_INITIALIZE_FAILED,
                payload: {
                  errorCode: ERROR_CODE_INVALID_LOCALE,
                  reason: `Invalid application locale: ${locale}`,
                },
              })
            ),
        }),
      ])
    )
  );

  /**
   * Reloads the entire application with default locale
   */
  defaultLocaleFallback$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fallbackToDefaultLocaleAction),
        map((action) => {
          const [tenantName, merchantId] = getRouteParamsFromUrl(this.window);
          const location = this.window.location;
          const currentPath = location.pathname.substring(1).split('/');
          let path = currentPath.slice(3).join('/');

          if (validString(path)) {
            path = `/${path}`;
          }

          const completePath = `/${tenantName}/${merchantId}/${action.payload + path + location.search + location.hash}`;
          location.href = location.origin + completePath;
        })
      ),
    { dispatch: false }
  );

  /**
   * Fetches users default browser language
   */
  fetchFallbackLocale$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(environmentLoadedSuccess),
        withLatestFrom(this.store.select(selectClientConfiguration)),
        map(([action, clientConfig]) => {
          if (!validString(clientConfig.language)) {
            this.store.dispatch(fallbackToDefaultLocaleAction({ payload: getDefaultLocale() }));
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Dispatches action when static data is injected
   */
  staticDataInjected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(staticApiResponseAction),
      filter(({ requestType }): boolean => requestType === ApiRequestType.GET_APPLICATION_LOCALE),
      filter(({ isOk }): boolean => isOk),
      map(() => staticDataInjectedAction())
    )
  );

  /**
   * Handle Application Locale failure
   */
  fetchAppLocalesFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(staticApiResponseAction),
      filter(({ requestType }): boolean => requestType === ApiRequestType.GET_APPLICATION_LOCALE),
      filter(({ isOk }): boolean => !isOk),
      map((resp) =>
        initializeFailedAction({
          errorCode: ERROR_CODE_SERVICE_FAILED,
          reason: 'Failed to load application locales',
          metaData: {
            locale: this.localeId ?? 'unknown',
          },
        })
      )
    )
  );

  /**
   * @param actions$ The actions stream.
   * @param window The window object
   */
  constructor(
    private actions$: Actions,
    @Inject(WINDOW) private window: Window,
    @Inject(LOCALE_ID) private localeId,
    private store: Store<AppState>,
    protected logger: AcLoggerService
  ) {}
}
