import { Dictionary } from 'src/app/shared/utilities/types.utils';

export interface Locales {
  /**
   * Holds all sections.
   */
  [section: string]: Dictionary<string>;
}

export interface LocaleState {
  /**
   * If there was an error in the request to get the locales.
   */
  errorLoading: boolean;
  /**
   * Are locales loaded
   */
  loaded: boolean;
  /**
   * Are we currently loading locales?
   */
  loading: boolean;
  /**
   * Are we loading angular's locale files?
   */
  loadingFiles: boolean;
  /**
   * The actual locale state.
   */
  locales: Locales;
  /**
   * Set any error details
   */
  [key: string]: any;
}

export const initialState: LocaleState = {
  errorLoading: false,
  loaded: false,
  loading: false,
  loadingFiles: false,
  locales: {},
};
