import { createAction, props } from '@ngrx/store';
import { EWalletAuthorizeRequest } from 'src/app/core/api/responses/ewallet-authorize';

export enum ApplePayActions {
  CancelApplePayTransaction = '[ApplePay Payment Sheet] Cancel ApplePay Transaction',
  InitiateApplePayTransaction = '[Select Page] Initiate ApplePay Transaction',
  InitializeGiftCardSplitPayment = '[Apple Pay Service] Initialize Gift Card Split Payment With Apple Pay',
  SplitPaymentWithGiftCardSuccess = '[Gift Card Effects] Gift Card Split Payment With Apple Pay Success',
  EWalletAuthorize = '[Apple Pay Service] EWallet Authorization',
}

/**
 * Initiate ApplePay transaction request
 */
export const initiateApplePayTransactionAction = createAction(ApplePayActions.InitiateApplePayTransaction);

/**
 * Cancel ApplePay transaction request
 */
export const cancelApplePayTransactionAction = createAction(ApplePayActions.CancelApplePayTransaction);

/**
 * Initiate ewallet authorization
 */
export const initiateApplePayEWalletAuthorizationAction = createAction(
  ApplePayActions.EWalletAuthorize,
  props<{
    payload: {
      eWalletRequest: EWalletAuthorizeRequest;
      callbackData: { evt: ApplePayPaymentAuthorizedEvent; authFromParent: boolean; session?: ApplePaySessionInstance };
    };
  }>()
);

/**
 * Initiate split payment with gift card
 */
export const initializeGiftCardSplitPaymentWithApplePayAction = createAction(
  ApplePayActions.InitializeGiftCardSplitPayment,
  props<{
    amount: number;
    payload: {
      eWalletRequest: EWalletAuthorizeRequest;
      callbackData: { evt: ApplePayPaymentAuthorizedEvent; authFromParent: boolean; session?: ApplePaySessionInstance };
    };
  }>()
);

/**
 * Gift card split payment success
 */
export const giftCardSplitPaymentWithApplePaySuccessAction = createAction(
  ApplePayActions.SplitPaymentWithGiftCardSuccess,
  props<{
    payload: {
      eWalletRequest: EWalletAuthorizeRequest;
      callbackData: { evt: ApplePayPaymentAuthorizedEvent; authFromParent: boolean; session?: ApplePaySessionInstance };
    };
  }>()
);
