import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { extrasReducer } from './extras.reducer';
import { ExtrasEffects } from './extras.effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('extras', extrasReducer), EffectsModule.forFeature([ExtrasEffects])],
})
export class ExtrasModule {}
