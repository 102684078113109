export interface SessionState {
  /**
   * The API session id.
   */
  id: string;
  /**
   * If we're currently authorizing
   */
  authorizing: boolean;
}

export const initialState: SessionState = {
  id: '',
  authorizing: false,
};
