import { PaymentMethod } from 'src/app/core/api/responses/get-payment-configuration';
import { PaymentProviderType } from 'src/app/core/payment-configuration/payment-configuration.model';
import { normalize } from 'src/app/shared/utilities/string.utils';

/**
 * Determine if a set of payment methods contains freedom pay
 * @param cards Payment Methods
 * @returns is freedom pay
 */
export const isFreedomPay = (cards: PaymentMethod[] = []): boolean => {
  return cards.some((card) => normalize(card.paymentProviderName) === normalize(PaymentProviderType.FreedomPay));
};
