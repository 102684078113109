import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { getIntlCollator } from 'src/app/core/locale/locale.utils';
import { removeDupes } from 'src/app/shared/utilities/array.utils';
import { toInt } from 'src/app/shared/utilities/number.utils';
import { validArray, validObject, validString } from 'src/app/shared/utilities/types.utils';
import { CountriesProperties } from './utilities/countries-props.models';
import { countriesWithZipRequired } from './utilities/validator.utils';

interface SortedCountriesObject {
  list: CountriesProperties[];
  preferredCountriesFound: number;
}

export function markFormAsDirty(formGroup: UntypedFormGroup) {
  formGroup.markAllAsTouched();
  const { controls } = formGroup;

  Object.keys(controls).forEach((key) => controls[key].markAsDirty());
}

/**
 * Applies validators to control if condition is met. Validators will be removed if false.
 * @param control The form controls to add or remove validators
 * @param condition The condition to apply validators.
 * @param validators The list of validators to apply
 */
export const setControlValidators = (control: AbstractControl, condition: boolean, validators: Array<ValidatorFn>): void => {
  if (condition) {
    control.setValidators(validators);
  } else {
    control.clearValidators();
    //Cleans up any lingering errors
    control.setErrors(null);
  }
  /**
   * https://angular.io/api/forms/AbstractControl#clearValidators
   * When you add or remove a validator at run time, you must call updateValueAndValidity() for the new validation to take effect.
   */
  control.updateValueAndValidity();
};

/**
 * This method sorts the countries list by country name
 * @param countries Unsorted array of countries
 * @param localeId locale id to be used for reference
 * @returns Alphabetically sorted array of countries
 */
export const sortCountriesByName = (countries: CountriesProperties[], localeId: string): CountriesProperties[] => {
  if (!validArray(countries)) {
    return [];
  }

  const collator = getIntlCollator(localeId);
  return [...countries].sort((a, b) => {
    if (!validString(a.name) || !validString(b.name)) {
      return 0;
    }

    try {
      if (collator) {
        return collator(a.name, b.name);
      }
    } catch {
      // Do Nothing
    }
    return a.name.localeCompare(b.name);
  });
};

/**
 * This function moves the preferred codes at the beginning of the array
 * @param listToSorting List to sorting
 * @param preferredValues Array of values
 * @param locale The country property used to compared to the preferred value
 * @returns Array of values with preferred at the front
 */
export const sortCountryListWithPreferredValues = (listToSorting: CountriesProperties[], preferredValues: string[]): SortedCountriesObject => {
  const ret = { list: [], preferredCountriesFound: 0 };

  if (!validArray(listToSorting)) {
    return ret;
  }

  let list = listToSorting;
  let countriesFound = [];
  const countriesMap = createCountryHashMap(list);
  const sortedPreferredValues = sortPreferredValuesByAsc(preferredValues).reverse(); // Reversed so top value is placed at top of list in next step
  const hasPhoneCode = sortedPreferredValues.some(toInt);
  const telCodeMap = hasPhoneCode ? createCountryTelCodeHashMap(list) : new Map();

  sortedPreferredValues.forEach((preferredCode: string) => {
    const isPhoneCode = toInt(preferredCode);
    const foundPreferredCountry = isPhoneCode ? [...telCodeMap.get(preferredCode)] : [countriesMap.get(preferredCode)];

    countriesFound = removeDupes([...foundPreferredCountry, ...countriesFound]);
    list = removeDupes([...countriesFound, ...list]) as CountriesProperties[]; // Relying on Object memory reference to remove duplicates
  });

  ret.list = list;
  ret.preferredCountriesFound = countriesFound.length;

  return ret;
};

/**
 * This function sorts the array of preferred values in ascending order
 * @param values The array of values that will be sorted
 * @returns Sorted array of values
 */
export const sortPreferredValuesByAsc = (values: string[]): string[] => {
  if (!validArray(values)) {
    return [];
  }

  return [...values].sort((first, second) => first.localeCompare(second));
};

/**
 * This method creates an ES6 Map of countries by ISO2 code
 * @param countriesArr The list of countries
 * @returns ES6 Map of countries with  ISO2 code as key
 */
export const createCountryHashMap = (countriesArr: CountriesProperties[]): Map<string, CountriesProperties> => {
  if (!validArray(countriesArr) || !countriesArr[0]?.hasOwnProperty('code')) {
    return new Map();
  }

  return new Map(
    countriesArr.map((country) => {
      return [country.code, country];
    })
  );
};

/**
 * This method creates an ES6 Map of countries by dial code
 * @param countriesArr The list of countries
 * @returns ES6 Map of countries with dial code as key
 */
export const createCountryTelCodeHashMap = (countriesArr: CountriesProperties[]): Map<string, CountriesProperties[]> => {
  const telCodeMap = new Map();
  if (!validArray(countriesArr) || !countriesArr[0]?.hasOwnProperty('countryCode')) {
    return telCodeMap;
  }

  countriesArr.forEach((country) => telCodeMap.set(country.countryCode, [country, ...(telCodeMap.get(country.countryCode) || [])]));

  return telCodeMap;
};

/**
 * This function validate the preferred country codes
 * @param countries array countries
 * @param preferredCodesArray This array contains the preferred codes
 * @returns Array of validated values
 */
export const validatePreferredValues = (countries: CountriesProperties[], preferredValuesArray: string[]): string[] => {
  if (!validArray(countries) || !validArray(preferredValuesArray)) {
    return [];
  }
  const preferredValues = [...new Set(preferredValuesArray)]; // Remove dupes before iterating
  const hasPhoneCodes = preferredValues.some(Number); // check for dial codes

  const countriesMap = createCountryHashMap(countries);
  const telCodeMap = hasPhoneCodes ? createCountryTelCodeHashMap(countries) : new Map();

  return preferredValues.reduce((acc, value) => {
    const isPhoneCode = toInt(value);
    const isValidPreferredValue = isPhoneCode ? telCodeMap.has(value) : countriesMap.has(value);
    return isValidPreferredValue ? [...acc, value] : acc;
  }, []);
};

/**
 * Function that receive an array from Observable with the countries list and retrieves the list with preferred country code at the top
 * @param countryList Array of country list
 * @param preferredValues Array of values
 * @param locale locale used for comparing text
 * @returns Sorted list and number of preferred values found
 */
export const validateAndSortCountriesByPreferredValues = (
  countryList: CountriesProperties[],
  preferredValues: string[],
  locale: string
): SortedCountriesObject => {
  const ret = {
    list: countryList,
    preferredCountriesFound: 0,
  };

  if (validArray(countryList)) {
    const sortedCountries = (ret.list = sortCountriesByName(countryList, locale));
    preferredValues = validatePreferredValues(countryList, preferredValues);

    return preferredValues.length ? sortCountryListWithPreferredValues(sortedCountries, preferredValues) : ret;
  }
  return ret;
};

/**
 * Function that searches for a list of provided country codes.
 * Returns full country list if no values are found.
 * @param countryList Array of country list
 * @param codesToFind Array of country codes to find
 * @returns Countries found
 */
export const findCountries = (countryList: CountriesProperties[], codesToFind: string[]): CountriesProperties[] => {
  if (!validArray(countryList) || !validArray(codesToFind)) {
    return countryList;
  }

  const countryMap = createCountryHashMap(countryList);
  const countriesFound = [];

  codesToFind.forEach((countryCode: string) => {
    if (countryMap.has(countryCode)) {
      countriesFound.push(countryMap.get(countryCode));
    }
  });

  return countriesFound.length ? countriesFound : countryList;
};

/**
 * Find out if a specific country requires zip code.
 * @param code The country code to check
 * @returns Has found a country with required zip
 */
export const isCountryWithZipRequired = (code: string): boolean => {
  return countriesWithZipRequired.includes(code);
};

/**
 * Find out if a specific country has regions configured.
 * @param code The country code to check.
 * @returns Has matched a country with required province/region
 */
export const isCountryWithRegion = (code: string): boolean => {
  return ['AU', 'CA', 'MX', 'US'].includes(code);
};

/**
 * This creates a string to display selected country
 * @param country country option
 * @returns Formatted display
 */
export const formatDialCodeLabel = (country: CountriesProperties): string => {
  return validObject(country) ? `${country.name} +${country.countryCode}` : '';
};
