import { Inject, Injectable } from '@angular/core';
import { Store, createSelector } from '@ngrx/store';
import { get, isFunction, isObject } from 'lodash-es';
import { filter, take } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { sendAnalyticsEventAction } from 'src/app/core/analytics/analytics.actions';
import { ApiResponse } from 'src/app/core/api/api.interface';
import { ApiService } from 'src/app/core/api/api.service';
import { isResponseOK } from 'src/app/core/api/api.utilities';
import { BoolNumType, EWalletAuthorizeRequest, EWalletAuthorizeResponse } from 'src/app/core/api/responses/ewallet-authorize';
import { InitiateApplePaySessionRequest, InitiateApplePaySessionResponse } from 'src/app/core/api/responses/initiate-applepay-session';
import { sendMessageAction } from 'src/app/core/application-bridge/application-bridge.actions';
import { AuthorizedPaymentInfo, PaymentFailed, PaymentType } from 'src/app/core/application-bridge/application-bridge.models';
import { selectAppConfig } from 'src/app/core/application-config/application-config.selectors';
import { BillingDefaultValueConfig } from 'src/app/core/application-config/application-config.state';
import { WINDOW } from 'src/app/core/injection-token/window/window';
import { AcLoggerService } from 'src/app/core/logger/logger.service';
import { selectParentConfig } from 'src/app/core/parent-config/parent-config.selectors';
import { ParentConfigState } from 'src/app/core/parent-config/parent-config.state';
import { getLineItemsAndFees } from 'src/app/core/parent-config/parent-config.utils';
import { ConfiguredPayments, WalletPayment } from 'src/app/core/payment-configuration/payment-configuration.model';
import { selectConfiguredPayments } from 'src/app/core/payment-configuration/payment-configuration.selectors';
import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import {
  EVENT_APPLEPAY_CHECK,
  EVENT_APPLEPAY_START,
  EVENT_APPLEPAY_VALIDATE_RESPONSE,
  EVENT_PAYMENT_FAILED,
} from 'src/app/shared/enums/application-bridge.enums';
import { APP_CONFIG_DEFAULT_BILLING_VALUE, APP_CONFIG_MERCHANT_NAME } from 'src/app/shared/enums/application-config.enum';
import { BillingDefaultValue } from 'src/app/shared/enums/billing.enums';
import {
  ERROR_CODE_EWALLET_AUTHORIZE_PAYMENT_DECLINED,
  ERROR_CODE_RESPONSE_NOT_OBJECT,
  ERROR_CODE_SERVICE_FAILED,
} from 'src/app/shared/enums/error-code.enums';
import { selectPaymentAmount, selectPaymentAmountWithDonation } from 'src/app/shared/selectors/configuration.selectors';
import { getPaymentConfigurationForWalletPaymentsByRawCardBrand } from 'src/app/shared/utilities/credit-cards.utils';
import { convertDonationResponse } from 'src/app/shared/utilities/donation.utils';
import { convertInsuranceResponse, formatInsuranceRequest } from 'src/app/shared/utilities/insurance.utils';
import { toDecimal, toInt } from 'src/app/shared/utilities/number.utils';
import { joinStrings, returnOrDefaultString } from 'src/app/shared/utilities/string.utils';
import { validArray, validObject, validString } from 'src/app/shared/utilities/types.utils';
import { IS_IN_IFRAME } from 'src/app/shared/utilities/window.utils';
import { selectTicketInsuranceQuoteToken, selectTicketProtection } from '../../extras/extras.selectors';
import { gatherPaymentDetailsForApplePayAction, updateBillingDemographicsAction } from '../billing.actions';
import { selectDonation } from './../../extras/extras.selectors';
import { DonationState, TicketProtectionState } from './../../extras/extras.state';
import { cancelApplePayTransactionAction, initiateApplePayEWalletAuthorizationAction } from './applepay.actions';
import { mapCreditCardToApplePayNetwork, sanitizeApplePayAuthEvent } from './applepay.utils';

export const APPLE_PAY_VERSION = 2;
const ERROR_PREFIX = '[ApplePayService]';
@Injectable({
  providedIn: 'root',
})
export class ApplePayService {
  /**
   * Default merchant name
   */
  private merchantName = 'accesso Pay';
  /**
   * Configured payments
   */
  private applePayCards: ConfiguredPayments['applePayCards'] = [];
  /**
   * If apple pay is available or not.
   */
  private applePayAvailability = false;
  /**
   * If we've initialized the class.
   */
  private initialized = false;
  /**
   * Parent Config state
   */
  private parentConfigState: ParentConfigState;
  /**
   * Donation State
   */
  private donation: DonationState;
  /**
   * The amount to be paid through ApplePay
   */
  private paymentAmount: number;
  /**
   * Payment amount plus donation amount
   */
  private paymentAmountWithDonation: number;
  /**
   * Default billing information app config
   */
  private defaultBillingConfig: BillingDefaultValueConfig = {};
  /**
   * The quote token for ticket insurance
   */
  private ticketInsuranceQuoteToken: string;
  /**
   * Insurance details
   */
  insurance?: TicketProtectionState;
  /**
   * Payment admin url app config
   */
  private paymentAdminUrl: string = '';
  /**
   * The public variable for finding out if apple pay is available.
   */
  get isAvailable() {
    return this.applePayAvailability;
  }
  /**
   * The public variable for finding out if the class is initialized.
   */
  get isInitialized() {
    return this.initialized;
  }

  /**
   * @param store The store
   * @param window The window object
   * @param logger The logging service
   * @param apiService The ApiService
   */

  constructor(private store: Store<AppState>, @Inject(WINDOW) private window: Window, private logger: AcLoggerService, private apiService: ApiService) {
    store
      .select(
        createSelector(
          selectParentConfig,
          selectDonation,
          selectPaymentAmount,
          selectPaymentAmountWithDonation,
          selectTicketProtection,
          selectTicketInsuranceQuoteToken,
          (parentConfig, donation, paymentAmount, paymentAmountWithDonation, ticketProtection, quoteToken) => {
            return { parentConfig, donation, paymentAmount, paymentAmountWithDonation, ticketProtection, quoteToken };
          }
        )
      )
      .subscribe(({ parentConfig, donation, paymentAmount, paymentAmountWithDonation, ticketProtection, quoteToken }) => {
        this.parentConfigState = parentConfig;
        this.insurance = ticketProtection;
        this.ticketInsuranceQuoteToken = quoteToken;
        this.donation = donation;
        this.paymentAmount = paymentAmount;
        this.paymentAmountWithDonation = paymentAmountWithDonation;
      });
    store
      .select(selectAppConfig([APP_CONFIG_MERCHANT_NAME]))
      .pipe(
        filter((config) => validString(config.merchant_name)),
        take(1)
      )
      .subscribe((config) => {
        this.merchantName = config.merchant_name;
      });
    store
      .select(selectAppConfig([APP_CONFIG_DEFAULT_BILLING_VALUE]))
      .pipe(
        filter((config) => validObject(config[APP_CONFIG_DEFAULT_BILLING_VALUE])),
        take(1)
      )
      .subscribe((config) => {
        this.defaultBillingConfig = config[APP_CONFIG_DEFAULT_BILLING_VALUE] || {};
      });
    store
      .select(selectConfiguredPayments)
      .pipe(
        filter(({ applePayCards = [] }) => !!applePayCards.length),
        take(1)
      )
      .subscribe(({ applePayCards }) => {
        this.applePayCards = applePayCards;
      });
  }

  /**
   * If we're in an iframe or not
   */
  inIframe(): boolean {
    return IS_IN_IFRAME;
  }

  /**
   * Initializes the class.
   */
  init(): void {
    if (this.inIframe()) {
      this.queryParentForAvailability();
    } else {
      this.checkWindowForAvailability();
    }
  }

  /**
   * Set if the class should be considered initialized.
   * @param bool If the class has been initialized or not.
   */
  setInitialized(bool: boolean): void {
    this.initialized = bool;
  }

  /**
   * Set if apple pay is available or not.
   * @param bool If it's available or not
   */
  setAvailability(bool: boolean): void {
    this.applePayAvailability = bool;
  }

  /**
   * Queries the parent window (if we're in an iframe), to see if apple pay is available.
   */
  queryParentForAvailability(): void {
    this.store.dispatch(
      sendMessageAction({
        key: EVENT_APPLEPAY_CHECK,
        payload: {
          version: APPLE_PAY_VERSION,
        },
      })
    );
  }

  /**
   * Checks to see if the ApplePaySession is available and configured on the users computer when not in an iframe.
   */
  checkWindowForAvailability(): void {
    try {
      const applePaySession = (this.window as any).ApplePaySession as ApplePaySession;
      this.applePayAvailability = isFunction(applePaySession) && applePaySession.supportsVersion(APPLE_PAY_VERSION) && applePaySession.canMakePayments();
      this.setInitialized(true);
    } catch {
      // Do nothing
    }
  }

  /**
   * Returns payment request for Apple Pay transaction
   */
  getPaymentRequest(): ApplePayPaymentRequest {
    let amountToPay = this.donation.splitDonation ? this.paymentAmount : this.paymentAmountWithDonation;
    if (!this.insurance?.splitInsurance && this.ticketInsuranceQuoteToken) {
      amountToPay = toDecimal(this.insurance?.quoteAmount + amountToPay, 2);
    }

    return {
      countryCode: 'US',
      currencyCode: this.parentConfigState.config.currencyCode || 'USD',
      lineItems: [],
      merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit'],
      requiredBillingContactFields: ['name', 'postalAddress'],
      requiredShippingContactFields: ['email', 'phone'],
      supportedNetworks: this.creditCardsToCardNetworks(this.applePayCards),
      total: {
        amount: amountToPay.toFixed(2),
        label: this.merchantName || 'accesso Pay',
        type: 'final',
      },
    };
  }

  /**
   * Turns our credit cards into apple pay card networks
   * @param creditCards The cards to convert
   */
  creditCardsToCardNetworks(creditCards: WalletPayment[]): ApplePayNetworkType[] {
    return creditCards
      .filter((card) => validString(card.cardBrandName))
      .map((card): string => card.cardBrandName)
      .map(mapCreditCardToApplePayNetwork)
      .filter(validString);
  }

  /**
   * Initiates Apple Pay transaction
   */
  initiateTransaction(): void {
    if (!this.applePayAvailability || !validArray(this.applePayCards)) {
      return;
    }

    const paymentRequest = this.getPaymentRequest();

    if (this.inIframe()) {
      return this.store.dispatch(
        sendMessageAction({
          key: EVENT_APPLEPAY_START,
          payload: {
            paymentRequest,
            version: APPLE_PAY_VERSION,
          },
        })
      );
    }
    const sess = new ((this.window as any).ApplePaySession as ApplePaySession)(APPLE_PAY_VERSION, paymentRequest);

    this.addEvents(sess, paymentRequest);
    /**
     * Begins the merchant validation process.
     */
    sess.begin();
  }

  /**
   * Add ApplePay Session Events
   * @param session ApplePaySession
   * @param paymentRequest Payment Request
   */
  addEvents(session: ApplePaySessionInstance, paymentRequest: ApplePayPaymentRequest): void {
    const updatedTotal = { newTotal: paymentRequest.total };
    /**
     * An event handler that is automatically called when the payment UI is dismissed.
     */
    session.oncancel = (): void => this.store.dispatch(cancelApplePayTransactionAction());
    /**
     * An event handler that is called when the payment sheet is displayed.
     * @param evt ApplePayValidateMerchantEvent
     */
    session.onvalidatemerchant = (evt): void => {
      this.apiService
        .post<InitiateApplePaySessionResponse>(ApiRequestType.INITIATE_APPLEPAY_SESSION, this.getInitiateApplePaySessionRequest(evt))
        .pipe(take(1))
        .subscribe((data) => {
          const responseData = get(data, 'response.data');
          if (isResponseOK(get(data, 'status')) && validString(responseData)) {
            session.completeMerchantValidation(JSON.parse(responseData));
          } else {
            this.logger.error(`${ERROR_PREFIX} status not ok or response data not a valid string`);
            session.oncancel();
          }
        });
    };
    /**
     * An event handler that is called when a shipping method is selected.
     */
    session.onshippingmethodselected = (): void => {
      session.completeShippingMethodSelection({
        newTotal: paymentRequest.total,
        status: ((this.window as any).ApplePaySession as ApplePaySession).STATUS_SUCCESS,
      });
    };
    /**
     * An event handler that is called when a shipping contact is selected in the payment sheet.
     */
    session.onshippingcontactselected = (): void => session.completeShippingContactSelection(updatedTotal);
    /**
     * An event handler that is called when a new payment method is selected.
     */
    session.onpaymentmethodselected = (): void => session.completePaymentMethodSelection(updatedTotal);
    /**
     * An event handler that is called when the user has authorized the Apple Pay payment with Touch ID, Face ID, or passcode.
     * @param evt ApplePayPaymentAuthorizedEvent
     */
    session.onpaymentauthorized = (evt: ApplePayPaymentAuthorizedEvent): void => {
      const authEvent = sanitizeApplePayAuthEvent(evt);
      this.store.dispatch(
        initiateApplePayEWalletAuthorizationAction({
          payload: {
            eWalletRequest: this.getAuthorizeRequest(authEvent.payment),
            callbackData: {
              evt: authEvent,
              authFromParent: false,
              session,
            },
          },
        })
      );
    };
  }

  /**
   * Handles the backend api's success response to us when trying to authorize a card
   * @param paymentData The payment data from Google
   * @param response The response data from our backend gateway
   */
  handleAuthorizeResponseSuccess(paymentData: ApplePayPayment, response: EWalletAuthorizeResponse): void {
    const { billingContact = {} as ApplePayPaymentContact, shippingContact } = paymentData;
    const paymentConfiguration = getPaymentConfigurationForWalletPaymentsByRawCardBrand(this.applePayCards, response.card_type_code);
    const addressLines = validArray(billingContact.addressLines) ? billingContact.addressLines : [];
    const updateBillingAddressAction = updateBillingDemographicsAction({
      payload: {
        valid: true,
        firstName: returnOrDefaultString(billingContact.givenName, this.defaultBillingConfig.firstName, BillingDefaultValue.FirstName),
        lastName: returnOrDefaultString(billingContact.familyName, this.defaultBillingConfig.lastName, BillingDefaultValue.LastName),
        address1: addressLines?.[0],
        address2: joinStrings(', ', ...addressLines?.slice(1)),
        city: billingContact.locality,
        country: returnOrDefaultString(billingContact.countryCode, this.defaultBillingConfig.country, BillingDefaultValue.Country),
        state: billingContact.administrativeArea,
        zip: billingContact.postalCode,
        email: shippingContact.emailAddress,
        phone: shippingContact.phoneNumber,
      },
    });
    const payload: AuthorizedPaymentInfo = {
      amount: response.amount,
      method: PaymentType.APPLE_PAY,
      approval_code: response.approval_code,
      authId: response.paymentReference,
      expire_date: response.card_expire_date,
      legacy: {
        authId: response.auth_id,
        paymentMerchantId: this.applePayCards[0].paymentMerchantId,
        paymentMethodCode: paymentConfiguration.paymentMethodCode,
        paymentProviderName: paymentConfiguration.paymentProviderName,
        rawCardBrand: paymentConfiguration.rawCardBrand,
      },
      lastFour: response.card_last_four,
      cardType: response.card_type_code,
    };

    if (validObject(response.donation)) {
      payload.donation = convertDonationResponse(response.donation);
    }

    if (validObject(response?.insurance)) {
      payload.insurance = convertInsuranceResponse(response.insurance);
    }

    [
      updateBillingAddressAction,
      sendAnalyticsEventAction({
        action: 'pay',
        category: 'apple pay',
        label: 'payment complete',
        nonInteraction: true,
      }),
      gatherPaymentDetailsForApplePayAction({ payload }),
    ].forEach((action) => this.store.dispatch(action));
  }

  /**
   * Handles the backend api's failed response to us when trying to authorize a card
   * @param data The response data from our backend gateway
   */
  handleAuthorizeResponseFailed(data: ApiResponse<EWalletAuthorizeResponse>): void {
    const response = data;
    const payload: PaymentFailed = {};

    if (!isObject(response)) {
      payload.errorCode = ERROR_CODE_RESPONSE_NOT_OBJECT;
      payload.reason = 'Backend response is invalid';
      payload.details = typeof response;
    } else if (validString(response.error_msg)) {
      payload.errorCode = ERROR_CODE_SERVICE_FAILED;
      payload.reason = response.error_msg;
    } else {
      payload.errorCode = ERROR_CODE_EWALLET_AUTHORIZE_PAYMENT_DECLINED;
      payload.details = response.approval_code;
      payload.reason = response.approval_text;
    }

    [
      sendAnalyticsEventAction({
        action: 'pay',
        category: 'apple pay',
        label: 'payment failed',
        nonInteraction: true,
      }),
      sendMessageAction({
        key: EVENT_PAYMENT_FAILED,
        payload,
      }),
    ].forEach((action) => this.store.dispatch(action));
  }

  /**
   * An event handler that is called when the payment sheet is displayed for parent.
   * @param evt ApplePayValidateMerchantEvent
   */
  onParentValidateMerchant(evt: ApplePayValidateMerchantEvent): void {
    this.apiService
      .post<InitiateApplePaySessionResponse>(ApiRequestType.INITIATE_APPLEPAY_SESSION, this.getInitiateApplePaySessionRequest(evt))
      .pipe(take(1))
      .subscribe((res) => {
        const responseData = get(res, 'response.data');
        if (isResponseOK(res.status) && validString(responseData)) {
          this.store.dispatch(
            sendMessageAction({
              key: EVENT_APPLEPAY_VALIDATE_RESPONSE,
              payload: JSON.parse(responseData),
            })
          );
        } else {
          this.logger.error(`${ERROR_PREFIX} status not ok or response data not a valid string`);
          this.store.dispatch(
            sendMessageAction({
              key: EVENT_APPLEPAY_CHECK,
            })
          );
        }
      });
  }

  /**
   * Returns the request for authorizing the stored card from Apple Pay
   * @param paymentData The payment data returned from ApplePay's API.
   */
  getAuthorizeRequest(paymentData: ApplePayPayment): EWalletAuthorizeRequest {
    const { config } = this.parentConfigState;
    const { cartItems, fees } = getLineItemsAndFees(this.parentConfigState);
    const { billingContact = {} as ApplePayPaymentContact, shippingContact, token } = paymentData;
    const addressLines = validArray(billingContact.addressLines) ? billingContact.addressLines : [];
    const reqObj: EWalletAuthorizeRequest = {
      CARDHOLDER: {
        f_name: billingContact.givenName,
        l_name: billingContact.familyName,
        address: addressLines?.length ? joinStrings(' ', ...addressLines) : '',
        city: billingContact.locality,
        country: billingContact.countryCode,
        state: billingContact.administrativeArea,
        zip: billingContact.postalCode,
        email: shippingContact.emailAddress,
        phone_number: shippingContact.phoneNumber,
        customer_id: config?.user?.customerId || '',
      },
      EWALLET_INFO: {
        encrypted_payment_data: JSON.stringify(token),
        wallet_type: 'ApplePay',
      },
      amount: this.paymentAmount,
      merchant_id: this.applePayCards[0].paymentMerchantId,
      ignore_avs: 0,
      ignore_cvv: 0,
      cartItems,
      fees,
      recurring_flag: toInt(config.recurring) as BoolNumType,
      ...(config.recurring && { recurring_type: 'recurring' }),
    };

    if (validString(this.ticketInsuranceQuoteToken)) {
      reqObj.insurance_quote_token = this.ticketInsuranceQuoteToken;
      reqObj.insurance = formatInsuranceRequest(this.insurance);
    }

    if (this.donation.amount > 0) {
      reqObj.donation = {
        donation_amount: this.donation.amount,
        split_donation: this.donation.splitDonation,
      };
    }

    return reqObj;
  }

  /**
   * An event handler for parent that is called when the user has authorized the Apple Pay payment with Touch ID, Face ID, or passcode.
   * @param evt ApplePayPaymentAuthorizedEvent
   */
  onParentPaymentAuthorized(evt: ApplePayPaymentAuthorizedEvent): void {
    const authEvent = sanitizeApplePayAuthEvent(evt);
    this.store.dispatch(
      initiateApplePayEWalletAuthorizationAction({
        payload: {
          eWalletRequest: this.getAuthorizeRequest(authEvent.payment),
          callbackData: {
            evt: authEvent,
            authFromParent: true,
          },
        },
      })
    );
  }

  /**
   * Returns request object for InitiateApplePaySession request
   * @param evt ApplePay Validate Merchant Event
   */
  getInitiateApplePaySessionRequest(evt: ApplePayValidateMerchantEvent): InitiateApplePaySessionRequest {
    return {
      display_name: this.merchantName || 'accesso Pay',
      merchant_id: this.applePayCards[0].paymentMerchantId,
      source_domain: evt.host || this.window.location.hostname,
      target_url: evt.validationURL,
    };
  }
}
