import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectHasCartItems, selectIsComplimentaryOrder, selectParentConfig } from 'src/app/core/parent-config/parent-config.selectors';
import { ParentConfig } from 'src/app/core/parent-config/parent-config.state';
import { validString } from 'src/app/shared/utilities/types.utils';
import { sumCartAddons } from '../payment-options/payment-options.utils';
import { DonationState, ExtrasState, TicketProtectionState } from './extras.state';

/**
 * Selects the extras state
 * @param state The application state
 * @returns The extras state
 */
export const selectExtras = createFeatureSelector<ExtrasState>('extras');

/**
 * Selects the state to determine if ticket protection option is configured
 * @returns The extras method selected
 */
export const selectTicketProtection = createSelector(selectExtras, (state: ExtrasState): TicketProtectionState => state.ticketProtection);

/**
 * Selects the state to determine if ticket protection option is configured
 * @returns The extras method selected
 */
export const selectShowTicketProtection = createSelector(
  selectTicketProtection,
  selectParentConfig,
  selectHasCartItems,
  ({ configured, error }: TicketProtectionState, { config: parentConfig = {} as ParentConfig }, hasCartItems): boolean =>
    configured && !error && !parentConfig.recurring && !parentConfig.disableFeature?.ticketInsurance && hasCartItems
);

/**
 * Selects the sum of all add ons by user
 * @returns status of ticket protection
 */
export const selectCartAddonTotal = createSelector(selectExtras, (state: ExtrasState): number => sumCartAddons(state));

/**
 * Selects the user selection status of ticket protection
 * @returns status of ticket protection
 */
export const selectTicketInsuranceIsProtected = createSelector(selectTicketProtection, ({ isProtected }: TicketProtectionState): boolean => isProtected);

/**
 * Selects the ticket protection quote token to determine if ticket protection is opted in
 * @returns The insurance quote token when opted in
 */
export const selectTicketInsuranceQuoteToken = createSelector(selectTicketProtection, ({ isProtected, quoteToken }: TicketProtectionState): string => {
  return isProtected && validString(quoteToken) ? quoteToken : '';
});
/**
 * Selects the ticket protection unfiltered quote token which does not consider user selection
 * @returns The insurance quote token
 */
export const selectTicketInsuranceRawQuoteToken = createSelector(selectTicketProtection, ({ quoteToken = '' }: TicketProtectionState): string => {
  return quoteToken;
});

/**
 * Selects the ticket protection state amount
 * @returns The insurance quote token when opted in
 */
export const selectTicketInsuranceQuoteAmount = createSelector(selectTicketProtection, ({ isProtected, quoteAmount }: TicketProtectionState): number => {
  return isProtected && quoteAmount > 0 ? quoteAmount : 0;
});

/**
 * Selects the state of donation for configuration and updated amount
 * @returns The extras method selected
 */
export const selectDonation = createSelector(selectExtras, (state: ExtrasState): DonationState => state.donation);

/**
 * Selects the state to determine if donation option is configured
 * @returns The extras method selected
 */
export const selectShowDonation = createSelector(selectExtras, selectParentConfig, ({ donation }: ExtrasState, { config = {} as ParentConfig }): boolean => {
  return donation.configured && !(donation.splitDonation && config.recurring) && !config.disableFeature?.donation;
});

/**
 * Selects the state to determine if extras page can be rendered
 * @returns The extras method selected
 */
export const selectShowExtrasPage = createSelector(
  selectShowTicketProtection,
  selectShowDonation,
  selectIsComplimentaryOrder,
  (showTicketProctection, showDonation, isComplimentaryOrder): boolean => {
    return !isComplimentaryOrder && (showTicketProctection || showDonation);
  }
);

/**
 * Selects the state to determine if actions can be continued
 * @returns The continued state
 */
export const selectCanContinued = createSelector(selectExtras, (state: ExtrasState): boolean => {
  const obj = { donation: state.donation, ticketProtection: state.ticketProtection };
  return Object.keys(obj).every((key) => !!obj[key].continued);
});

/**
 * Selects the state to determine if alternate payment should be hidden
 */
export const selectHideAlternatePayments = createSelector(selectExtras, ({ donation, ticketProtection }: ExtrasState): boolean => {
  const hasSplitDonation = donation.splitDonation && donation.amount > 0;
  const hasSplitInsurance = ticketProtection.splitInsurance && ticketProtection.isProtected && ticketProtection.quoteAmount > 0;
  return hasSplitDonation || hasSplitInsurance;
});
