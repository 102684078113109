import { CommonModule } from '@angular/common';
import { ConfirmDialogModule } from './dialog/confirm-dialog/confirm-dialog.module';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { NotificationEffects } from './notification.effects';
import { CustomDialogModule } from './dialog/custom-dialog/custom-dialog.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, EffectsModule.forFeature([NotificationEffects]), ConfirmDialogModule, CustomDialogModule],
})
export class NotificationModule {}
