/**
 * All values are derived from API docs
 * {@link https://developer.amazon.com/fr/docs/amazon-pay-apb-checkout/add-the-amazon-pay-button.html Amazon Pay SDK docs}
 */

/**
 * Amazon Pay Product Type value.
 * We only support digital tickets for this payment method
 */
export const PAY_ONLY = 'PayOnly' as AmazonPaySDKProductType;

/**
 * Amazon Pay Placement value
 */
export const CHECKOUT = 'Checkout' as AmazonPaySDKPlacement;

/**
 * Amazon Regions for sdk script according to api docs
 * {@link https://developer.amazon.com/fr/docs/amazon-pay-apb-checkout/add-the-amazon-pay-button.html#1-add-the-amazon-pay-script Amazon Pay docs}
 */
export enum AmazonPayRegion {
  /** US region */
  NA = 'na',
  /** EU region */
  EU = 'eu',
  /** JP region */
  FE = 'fe',
}

/**
 * Amazon Pay ledger currency
 */
export enum AmazonPayLedgerCurrency {
  NA = 'USD',
  EU = 'EUR',
  UK = 'GBP',
  FE = 'JPY',
}

/**
 * Langages supported by Amazon Pay per region
 * The first language in the array is the default language
 * for the region
 */
export const AmazonPayLocales = {
  NA: ['en_US'],
  EU: ['en_GB', 'de_DE', 'fr_FR', 'it_IT', 'es_ES'],
  FE: ['ja_JP'],
};
