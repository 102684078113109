import { ApiDeleteRequestOptions, ApiGetRequestOptions, ApiPostRequestOptions } from './api-base.models';
import { ApiResponse, DefaultProperties, Response } from './api.interface';
import { Observable, Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { catchApiError, isApiPaymentRequest, mapResponse } from './api.utilities';
import { cloneDeep, toLower } from 'lodash-es';
import { filter, map, take, tap } from 'rxjs/operators';

import { AppState } from 'src/app/app.state';
import { Dictionary } from 'src/app/shared/utilities/types.utils';
import { HttpClient } from '@angular/common/http';
import { ParentConfigState } from '../parent-config/parent-config.state';
import { apiConfigurationSelector } from './api.selectors';
import { selectParentSessionId } from '../parent-config/parent-config.selectors';
import { isNumeric, validString } from 'src/app/shared/utilities/types.utils';

export class ApiServiceBase {
  /**
   * The gateway URL
   */
  private gatewayUrl: string;
  /**
   * The global gateway URL
   */
  private globalGatewayUrl: string;
  /**
   * The configured merchant ID
   */
  private merchantId: string;
  /**
   * The current API session id
   */
  private sessionId: string;
  /**
   * The configured language
   */
  private language: string;
  /**
   * The parent session id
   */
  private parentSessionId = '';
  /**
   * The parent configuration state
   */
  private parentConfig: ParentConfigState;
  /**
   * Our subject to emit responses on. This is useful in components that need to know about failed responses that are dispatched through effects.
   */
  private dispatchApiResponse = new Subject<ApiResponse<any>>();
  /**
   * An observable that you can subscribe to to receive responses.
   */
  onResponse$: Observable<string> = this.dispatchApiResponse.asObservable();

  /**
   * @param http Our http client for making requests
   * @param store The global store
   */
  constructor(private http: HttpClient, store: Store<AppState>) {
    store.pipe(select(apiConfigurationSelector)).subscribe(({ gatewayUrl, merchantId, sessionId, language, parentConfig }) => {
      this.gatewayUrl = `${gatewayUrl}`;
      this.merchantId = merchantId;
      this.sessionId = sessionId;
      this.language = language;
      this.parentConfig = parentConfig;
    });

    store.pipe(select(selectParentSessionId), filter(validString), take(1)).subscribe((sessionId) => {
      this.parentSessionId = sessionId;
    });
  }

  /**
   * Does a get request against the gateway
   * @param requestType The request type
   * @param params The url params
   * @param headers The request headers
   * @param resources The resources
   */
  get<T>(requestType: string, params: Dictionary<any> = {}, headers: Dictionary<any> = {}, resources: string[] = []): Observable<Response<T>> {
    const { gatewayUrl } = this;
    const requestUrl = this.getRequestUrl(requestType, gatewayUrl, resources);
    const reqParams = { requestType, ...this.getDefaultPropsToAppend(), ...params };
    const req = new ApiGetRequestOptions(reqParams, headers);
    return this.http.get<ApiResponse<T>>(requestUrl, req).pipe(
      catchApiError<T>(),
      map(mapResponse),
      tap(() => {
        this.dispatchApiResponse.next(requestType);
      })
    );
  }

  /**
   * Does a post request against the gateway
   * @param requestType The request type
   * @param body The post body
   * @param params The post params
   * @param headers The request headers
   * @param resources The resources
   */
  post<T>(
    requestType: string,
    body: Dictionary<any> = {},
    params: Dictionary<any> = {},
    headers: Dictionary<any> = {},
    resources: string[] = []
  ): Observable<Response<T>> {
    const { gatewayUrl } = this;
    const requestUrl = this.getRequestUrl(requestType, gatewayUrl, resources);
    const reqBody = this.transform(body, requestType);
    const reqParams = { requestType, ...this.getDefaultPropsToAppend(), ...params };
    const request = new ApiPostRequestOptions(reqParams, headers, reqBody);

    return this.http.post<ApiResponse<T>>(requestUrl, request.body, { params: request.params, headers: request.headers }).pipe(
      catchApiError<T>(),
      map(mapResponse),
      tap(() => {
        this.dispatchApiResponse.next(requestType);
      })
    );
  }
  /**
   * Does a delete request against the gateway
   * @param requestType The request type
   * @param body The post body
   * @param params The post params
   * @param headers The request headers
   * @param resources The resources
   */
  delete<T>(
    requestType: string,
    body: Dictionary<any> = {},
    params: Dictionary<any> = {},
    headers: Dictionary<any> = {},
    resources: string[] = []
  ): Observable<Response<T>> {
    const { gatewayUrl } = this;
    const requestUrl = this.getRequestUrl(requestType, gatewayUrl, resources);
    const reqParams = { requestType, ...this.getDefaultPropsToAppend(), ...params };
    const request = new ApiDeleteRequestOptions(reqParams, headers, body);

    return this.http
      .request<ApiResponse<T>>('delete', requestUrl, {
        body: request.body,
        headers: request.headers,
        params: request.params,
      })
      .pipe(
        catchApiError<T>(),
        map(mapResponse),
        tap((response) => {
          this.dispatchApiResponse.next(response);
        })
      );
  }

  /**
   * Returns the request url for the request type
   * @param requestType request type
   * @param url request url
   * @param resources resource values
   */
  getRequestUrl(requestType: string, url: string, resources: string[]): string {
    const req = toLower(requestType);
    switch (req) {
      case 'getpaymentconfiguration':
        return `${url}/payment-merchants/${this.merchantId}/payment-configurations`;
      case 'getapplicationconfigs':
        return `${url}/application-configs`;
      case 'getapplicationlocale':
        return `${url}/application-locales`;
      case 'retrievetokens':
      case 'deletetoken':
        return `${url}/payment-tokens`;
      case 'getaccessopaysession':
        return `${url}/accesso-pay-sessions/${resources[0]}`;
      case 'registerinsurancequote':
        return `${url}/register-insurance-quote`;
      case 'createpaypalorder':
        return `${url}/paypal-createorder`;
      case 'updatepaypalstatus':
        return `${url}/paypal-updatestatus`;
      case 'createpaypalbillingagreement':
        return `${url}/paypal-createbillingagreementtoken`;
      case 'createpaypalbillingagreementorder':
        return `${url}/paypal-createbillingagreementorder`;
      case 'svcgetbalance':
        return `${url}/svc-getbalance`;
      case 'svcpreauth':
        return `${url}/svc-preauth`;
      default:
        return `${url}/${req}`;
    }
  }

  /**
   * Returns the default properties to append
   */
  getDefaultPropsToAppend(): DefaultProperties {
    const { sessionId, language, merchantId, parentSessionId } = this;
    const defaultProps: DefaultProperties = {
      applicationId: 1504,
      __requester: 'AccessoPay',
      language,
      accessoPayMerchantId: merchantId,
      parent_session_id: parentSessionId,
    };

    if (validString(sessionId)) {
      defaultProps.session_id = sessionId;
    }
    return defaultProps;
  }

  /**
   * Appends special properties to specific requests.
   * @param request The api request to send
   */
  transform(params: Dictionary<any>, requestType: string): Dictionary<any> {
    const { orderId, taxReferenceId } = this.parentConfig?.config;
    const newRequestParams = cloneDeep(params);
    const isPaymentRequest = isApiPaymentRequest(requestType);

    if (!isPaymentRequest) {
      return newRequestParams;
    }

    if (validString(orderId) || isNumeric(orderId)) {
      newRequestParams.order_id = orderId;
    }

    if (taxReferenceId) {
      newRequestParams.taxReferenceId = taxReferenceId;
    }

    return newRequestParams;
  }
}
