import { UntypedFormGroup } from '@angular/forms';
import { CrossFieldValidatorFn } from '../dynamic-form.component';
import { validateZipField } from '../utilities/validator.utils';

export function crossFieldZipCodeValidator(zipFieldName: string, countryFieldName: string, regexOverrideByCountry = null): CrossFieldValidatorFn {
  const crossFieldZipCodeValidatorFn = (formGroup: UntypedFormGroup): CrossFieldValidatorFn => {
    const zipControl = formGroup.get(zipFieldName);
    const countryControl = formGroup.get(countryFieldName);

    if (!zipControl) {
      return;
    }

    validateZipField(zipControl, countryControl?.value, regexOverrideByCountry);

    (crossFieldZipCodeValidatorFn as any).sourceFieldName = zipFieldName;
    (crossFieldZipCodeValidatorFn as any).modifierFieldName = countryFieldName;
    (crossFieldZipCodeValidatorFn as any).isZipCodeValidator = true;
    return;
  };

  crossFieldZipCodeValidatorFn.sourceFieldName = zipFieldName;
  crossFieldZipCodeValidatorFn.modifierFieldName = countryFieldName;
  crossFieldZipCodeValidatorFn.isZipCodeValidator = true;

  return crossFieldZipCodeValidatorFn;
}
