import { createReducer, on } from '@ngrx/store';
import { isObject, toLower } from 'lodash-es';
import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import { ERROR_CODE_RESPONSE_NOT_OBJECT } from 'src/app/shared/enums/error-code.enums';
import { validObject } from 'src/app/shared/utilities/types.utils';
import { staticApiResponseAction } from '../api/api.actions';
import { isApi } from '../api/api.utilities';
import { LocaleEntry } from '../api/responses/get-application-locale';
import { requestStaticDataAction, staticDataLoadedFailureAction } from '../static-data/static-data.actions';
import { fetchLocaleFile, fetchLocaleFileSuccess } from './locale.actions';
import { initialState } from './locale.state';

const { isArray } = Array;

const { GET_APPLICATION_LOCALE } = ApiRequestType;

export const localeReducer = createReducer(
  initialState,
  on(staticDataLoadedFailureAction, (state, action) => ({ ...state, loading: false, loaded: true, errorLoading: true })),
  on(fetchLocaleFile, (state) => ({ ...state, loadingFiles: true })),
  on(fetchLocaleFileSuccess, (state) => ({ ...state, loadingFiles: false })),
  on(requestStaticDataAction, (state, action) => ({ ...state, loading: true, loaded: false })),
  on(staticApiResponseAction, (state, action) => {
    if (!isApi(action, GET_APPLICATION_LOCALE)) {
      return state;
    }

    const response: LocaleEntry[] = action.response;
    const newState = {
      ...state,
      loading: false,
      loaded: true,
      errorLoading: !action.isOk,
      locales: { ...state.locales },
    };

    if (action.isOk && isArray(response)) {
      response.forEach((data: LocaleEntry) => {
        const { section, name, value } = data;
        const sectionLowerCased = toLower(section);
        if (!isObject(newState.locales[sectionLowerCased])) {
          newState.locales[sectionLowerCased] = {};
        }
        newState.locales[sectionLowerCased][toLower(name)] = value;
      });
    } else if (!validObject(response)) {
      return { ...newState, error_code: ERROR_CODE_RESPONSE_NOT_OBJECT, error_msg: 'Unexpected response' };
    } else {
      const { error_code, error_msg, detail } = action.response;
      return { ...newState, error_code, error_msg: detail || error_msg };
    }

    return newState;
  })
);
