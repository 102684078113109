const accentedChars = 'ÇĞİÖŞÜıÀÁÂÃÄÅÆßÈÉÊËÌÍÎÏÐÑÒÓÔÕØÙÚÛÝŸÞ';

export class DynamicFormValidation {
  static getName(): string {
    return `^[-,'‘’ʻ?.()${accentedChars}A-Z\\s]+$`;
  }

  static getCity(): string {
    return `^[-()${accentedChars}A-Z0-9\\s]+$`;
  }

  static getEmail(): RegExp {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
  }
}
