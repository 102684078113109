import { InitializeState } from './initialize.state';

/**
 * Checks the state to see if everything is finished initializing.
 * @param state The initialized state
 * @returns If we're initialized or not.
 */
export const isInitialized = ({ initializeFailed, initialized, ...configStates }: InitializeState): boolean => {
  return initialized || initializeFailed || Object.values(configStates).every((hasLoaded) => hasLoaded);
};
