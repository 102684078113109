import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, createSelector } from '@ngrx/store';
import { isBoolean, isFunction } from 'lodash-es';
import { ObservableInput } from 'rxjs';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { AnalyticsActionTypes, sendPaymentComplete } from 'src/app/core/analytics/analytics.actions';
import { postApiRequest } from 'src/app/core/api/api.actions';
import { selectAppConfigs } from 'src/app/core/application-config/application-config.selectors';
import { initializeFinishedAction } from 'src/app/core/initialize/initialize.actions';
import { WINDOW } from 'src/app/core/injection-token/window/window';
import { showPageSpinner } from 'src/app/core/page-spinner/page-spinner.actions';
import { selectParentConfig } from 'src/app/core/parent-config/parent-config.selectors';
import { DisableFeature } from 'src/app/core/parent-config/parent-config.state';
import { getLineItemsAndFees } from 'src/app/core/parent-config/parent-config.utils';
import { AlternatePayment } from 'src/app/core/payment-configuration/payment-configuration.model';
import { selectConfiguredPayments } from 'src/app/core/payment-configuration/payment-configuration.selectors';
import * as RoutingActions from 'src/app/core/routing/routing.actions';
import { selectHideAlternatePayments, selectShowTicketProtection } from 'src/app/feature/extras/extras.selectors';
import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import { normalize } from 'src/app/shared/utilities/string.utils';
import { validArray, validObject, validString } from 'src/app/shared/utilities/types.utils';
import { hideAlternatePaymentsAction, updateAlternatePaymentAvailabilityAction, updateGiftCardAvailabilityAction } from '../billing/billing.actions';
import * as ExtrasAction from './extras.actions';

@Injectable({
  providedIn: 'root',
})
export class ExtrasEffects {
  /**
   * @param actions$ Our actions stream
   * @param window Window
   * @param store Store
   */
  constructor(private actions$: Actions, @Inject(WINDOW) private window: Window, private store: Store<AppState>) {}

  /**
   * Handles actions to register ticket guardian impression
   */
  registerImpression$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExtrasAction.registerTicketGuardianImpressionAction),
      mergeMap((action) => {
        const { quoteToken, merchantId } = action;
        return [
          showPageSpinner({ initiator: 'Extras Register Ticket Insurance Impression' }),
          postApiRequest({
            requestType: ApiRequestType.REGISTER_INSURANCE_QUOTE,
            body: {
              merchant_id: merchantId,
              quote_token: quoteToken,
            },
          }),
        ];
      })
    )
  );

  /**
   * Handles action to destroy the ticket protection widget
   */
  destroyTicketProtectionWidget$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ExtrasAction.destroyTicketProtectionWidgetAction, sendPaymentComplete),
        filter((action) =>
          [ExtrasAction.ExtrasActionTypes.DestroyTicketProtectionWidget, AnalyticsActionTypes.PAYMENT_COMPLETE_ACK].includes(action.type as any)
        ),
        withLatestFrom(this.store.select(selectShowTicketProtection)),
        map(([action, showTicketProtection]) => {
          const ticketGuardian = (this.window as any).tg;

          if (!ticketGuardian || !showTicketProtection) {
            return;
          }

          ticketGuardian('clearSession');
          if (isFunction(ticketGuardian.destroy)) {
            ticketGuardian.destroy();
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Handles actions to display payment options based on the ticket protection selection
   */
  updateTicketProtectionStatusAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExtrasAction.updateTicketProtectionStatusAction),
      withLatestFrom(
        this.store.select(
          createSelector(selectHideAlternatePayments, selectConfiguredPayments, (hideAltPayments, configuredPayments) => ({
            hideAltPayments,
            alternatePayments: configuredPayments?.alternatePayments || [],
            giftCard: configuredPayments?.giftCard,
          }))
        )
      ),
      mergeMap(([action, { hideAltPayments, alternatePayments, giftCard }]): ObservableInput<Action> => {
        const actions = [];

        if (hideAltPayments) {
          actions.push(hideAlternatePaymentsAction());
        } else {
          actions.push(
            ...alternatePayments.map(showAvailableAltPaymentMethods),
            updateGiftCardAvailabilityAction({ status: validObject(giftCard) && giftCard.active })
          );
        }
        return actions;
      })
    )
  );
  /**
   * Handles actions to display payment options based on the ticket protection selection
   */
  updateDonationSelectionAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExtrasAction.updateDonationSelectionAction),
      withLatestFrom(
        this.store.select(
          createSelector(selectHideAlternatePayments, selectConfiguredPayments, (hideAltPayments, configuredPayments) => ({
            hideAltPayments,
            alternatePayments: configuredPayments?.alternatePayments || [],
          }))
        )
      ),
      mergeMap(([action, { hideAltPayments, alternatePayments }]): ObservableInput<Action> => {
        const actions = [];

        if (hideAltPayments) {
          actions.push(hideAlternatePaymentsAction());
        } else {
          actions.push(...alternatePayments.map(showAvailableAltPaymentMethods));
        }

        return actions;
      })
    )
  );

  /**
   * Handles continued action
   */
  handleContinued$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExtrasAction.handleContinuedAction),
      mergeMap((_) => [ExtrasAction.resetContinuedAction(), RoutingActions.routeTo({ payload: ['payment-options'] })])
    )
  );

  /**
   * Handle feature configs
   */
  enableFeatures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initializeFinishedAction),
      withLatestFrom(
        this.store.select(createSelector(selectAppConfigs, selectParentConfig, ({ configs }, parentConfig) => ({ appConfigs: configs, parentConfig })))
      ),
      mergeMap(([_, { appConfigs, parentConfig }]) => {
        const { donation, ticketGuardian } = appConfigs;
        const { disableFeature = {} as DisableFeature } = parentConfig.config;
        const actions: Action[] = [];

        if (!disableFeature.donation && validObject(donation)) {
          // Enable checkout donation
          actions.push(
            ExtrasAction.updateDonationConfigAction({
              payload: {
                configured: donation?.enableCustomAmount || donation?.enableRounding || validArray(donation.amounts),
                splitDonation: isBoolean(donation.splitDonation) && donation.splitDonation,
              },
            })
          );
        }

        if (!disableFeature.ticketInsurance && validObject(ticketGuardian)) {
          // Enable ticket insurance
          const hasCartItems = validArray(getLineItemsAndFees(parentConfig).cartItems);
          actions.push(ExtrasAction.updateTicketProtectionAvailabilityAction({ payload: hasCartItems && validString(ticketGuardian.apiKey) }));
          if (isBoolean(ticketGuardian.splitInsurance)) {
            actions.push(ExtrasAction.updateTicketProtectionSplitInsuranceAction({ payload: ticketGuardian.splitInsurance }));
          }
        }

        return actions;
      })
    )
  );
}

/**
 * Find active alternate payments to update state.
 * @param altPayment Our actions stream
 */
const showAvailableAltPaymentMethods = (altPayment: AlternatePayment): Action => {
  if (altPayment.active && validString(altPayment.paymentMethodName)) {
    return updateAlternatePaymentAvailabilityAction({ payload: normalize(altPayment.paymentMethodName) });
  }
};
