import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { EnvironmentEffects } from './environment.effects';
import { StoreModule } from '@ngrx/store';
import { environmentReducer } from './environment.reducer';

@NgModule({
  declarations: [],
  imports: [CommonModule, EffectsModule.forFeature([EnvironmentEffects]), StoreModule.forFeature('environment', environmentReducer)],
})
export class EnvironmentModule {}
