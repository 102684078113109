import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EVENT_DIALOG_BUTTON_CLICKED, EVENT_SCROLL_TO_TOP, EVENT_SHOW_CONFIRM_DIALOG } from 'src/app/shared/enums/application-bridge.enums';
import { filter, map, take } from 'rxjs/operators';
import { receiveMessageAction, sendMessageAction } from '../application-bridge/application-bridge.actions';

import { AppState } from 'src/app/app.state';
import { ConfirmDialogComponent, DialogData, NotificationCloseAction } from './dialog/confirm-dialog/confirm-dialog.component';
import { IS_IN_IFRAME } from 'src/app/shared/utilities/window.utils';
import { Injectable, NgZone } from '@angular/core';
import { guid } from 'src/app/shared/utilities/guid.utils';
import { isFunction } from 'lodash-es';
import { closeAdyenCustomNotificationAction, showAdyenCustomNotificationAction, showNotificationAction } from './notification.actions';
import { selectParentConfig } from '../parent-config/parent-config.selectors';
import { validObject } from 'src/app/shared/utilities/types.utils';
import { Styles } from '../parent-config/parent-config.state';
import { CustomDialogComponent } from './dialog/custom-dialog/custom-dialog.component';
import { Dialog, DialogRef } from '@angular/cdk/dialog';

interface ButtonClicked {
  messageId: string;
  clicked: NotificationCloseAction;
}

interface Notification {
  id: string;
  onClose?: (cliked: NotificationCloseAction) => any;
}

/**
 * Handles all of the API requests and parsing the responses.
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationEffects {
  /**
   * Holds pending notifications that haven't been closed.
   */
  private notifications: Notification[] = [];
  /**
   * The queued notifications that are pending to be sent after the current one closes.
   */
  private notificationsToSend: Action[] = [];
  /**
   * Holds reference for our custom dialog notification
   */
  private customDialogRef: DialogRef<CustomDialogComponent>;

  /**
   * Handles the event from the parent applicaton when they close the modal.
   */
  notificationClosed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(receiveMessageAction),
        filter((action) => action.key === EVENT_DIALOG_BUTTON_CLICKED),
        map((action) => {
          const { messageId, clicked } = action.message.payload as ButtonClicked;
          this.notifyRecipient(messageId, clicked);
        })
      ),
    { dispatch: false }
  );

  /**
   * Handles displaying custom dialog notification
   */
  showCustomNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showAdyenCustomNotificationAction),
        map((action) => {
          this.customDialogRef = this.dialog.open(CustomDialogComponent, {
            data: action.id,
            disableClose: true,
          });

          if (IS_IN_IFRAME) {
            // Give time for Adyen component to load before scrolling
            setTimeout(() => {
              this.store.dispatch(sendMessageAction({ key: EVENT_SCROLL_TO_TOP }));
            }, 300);
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Handles closing custom dialog notification
   */
  closeCustomNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(closeAdyenCustomNotificationAction),
        map(() => this.customDialogRef.close())
      ),
    { dispatch: false }
  );

  /**
   * Handles the action when we want to show a confirmation dialog.
   */
  showNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showNotificationAction),
        map((action) => {
          if (this.notifications.length) {
            return this.notificationsToSend.push(action);
          }

          const id = guid();
          const { dialogType, buttonLabelCancel, buttonLabel, message, onClose } = action;

          const payload: Partial<DialogData> = {
            dialogType,
            buttonLabelCancel,
            buttonLabel,
            id,
            message,
          };

          this.notifications.push({
            id,
            onClose,
          });

          if (IS_IN_IFRAME) {
            this.store
              .select(selectParentConfig)
              .pipe(take(1))
              .subscribe((parentConfig) => {
                if (validObject(parentConfig.config?.style)) {
                  const styles: Styles = parentConfig.config.style;
                  payload.modalStyles = {
                    modalBackgroundColor: styles.modalBackgroundColor || '',
                    buttonStyles: styles.buttons,
                    textColor: styles?.layout?.textColor || '',
                    labelColor: styles?.layout?.labelColor || '',
                  };
                }
              });
            this.store.dispatch(
              sendMessageAction({
                key: EVENT_SHOW_CONFIRM_DIALOG,
                payload,
              })
            );
          } else {
            this.ngZone.runTask(() => {
              this.dialog
                .open(ConfirmDialogComponent, {
                  data: payload,
                  closeOnNavigation: true,
                  disableClose: true,
                })
                .closed.pipe(take(1))
                .subscribe((clicked: NotificationCloseAction) => {
                  this.notifyRecipient(id, clicked);
                });
            });
          }
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private store: Store<AppState>, private dialog: Dialog, private ngZone: NgZone) {}

  /**
   * Notify the recipient of the button the user clicked.
   * @param messageId The message ID to find.
   * @param clicked The type of button clicked. Either OK or CANCEL
   */
  notifyRecipient(messageId: string, clicked: NotificationCloseAction): void {
    const index = this.notifications.findIndex((notification) => {
      return notification.id === messageId;
    });

    if (index > -1) {
      const notify = this.notifications[index];
      if (isFunction(notify.onClose)) {
        notify.onClose(clicked);
      }

      // remove it
      this.notifications.splice(index, 1);

      if (this.notificationsToSend.length) {
        this.store.dispatch(this.notificationsToSend.shift());
      }
    }
  }
}
