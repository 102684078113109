import { DeviceType } from './environment.enums';
import { DeviceInfo, EnvironmentResponse } from './environment.models';
const { MOBILE } = DeviceType;

export interface EnvironmentState extends EnvironmentResponse {
  /**
   * If the environment failed to load.
   */
  errorLoading: boolean;
  /**
   * If the environment has loaded.
   */
  loaded: boolean;
  /**
   * The user ip address
   */
  ipAddress: string;
  /**
   * Application build number
   */
  build: string;
  /**
   * The User-Agent
   */
  device?: DeviceInfo;
}

export const initialState: EnvironmentState = {
  errorLoading: false,
  loaded: false,
  build: '',
  environmentName: '',
  domain: '',
  gatewayUrl: '',
  isProduction: false,
  ipAddress: null,
  subDomainBase: '',
  subDomainPrefix: '',
  subDomainSuffix: '',
  device: {
    isMobile: true,
    isTablet: false,
    isDesktop: false,
    type: MOBILE,
    platform: '',
    agent: '',
    language: '',
    colorDepth: 0,
    screenHeight: 0,
    screenWidth: 0,
    javaEnabled: false,
    timeZone: '',
    browser: {
      os: '',
      os_version: '',
      browser: '',
      browser_version: '',
    },
  },
};
