import { InjectionToken } from '@angular/core';

export const DOCUMENT = new InjectionToken<Document>('DOCUMENT');

/**
 * Used to inject the document object.
 * @returns The Document object
 */
export function documentFactory(): Document {
  return document;
}
