import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { ERROR_CODE_MERCHANT_FAILED_TO_LOAD } from 'src/app/shared/enums/error-code.enums';
import { staticApiResponseAction } from '../api/api.actions';
import { initializeFailedAction } from '../initialize/initialize.actions';
import { ApiRequestType } from './../../shared/enums/api.enums';

@Injectable({
  providedIn: 'root',
})
export class ApplicationConfigEffects {
  /**
   * @param actions$ Our actions stream
   */
  constructor(private actions$: Actions, private store: Store<AppState>) {}

  /**
   * Handles errors fetching app configs
   */
  getAppConfigFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(staticApiResponseAction),
      filter(({ requestType, isOk }) => !isOk && requestType === ApiRequestType.GET_APPLICATION_CONFIG),
      map((action) =>
        initializeFailedAction({
          errorCode: ERROR_CODE_MERCHANT_FAILED_TO_LOAD,
          reason: 'Failed to load application configuration',
        })
      )
    )
  );
}
