<main class="padding--16">
  <router-outlet *ngIf="displayApp$ | async"></router-outlet>
</main>
<ng-container *ngIf="isAuthorizing$ | async; else regularSpinner">
  <div class="spinner--container" fxLayoutAlign="center" gdAlignColumns="center" fxFill>
    <div class="spinner--authorizing">
      <ac-spinner></ac-spinner>
    </div>
    <div>{{'common.authorizingnotice' | locale }}</div>
  </div>
</ng-container>
<ng-template #regularSpinner>
  <div *ngIf="displaySpinner$ | async" class="spinner--container" fxLayoutAlign="center" gdAlignColumns="center" fxFill>
    <ng-container *ngIf="useNewSpinner$ | async; else oldSpinner">
      <gap-linear-progress></gap-linear-progress>
    </ng-container>
    <ng-template #oldSpinner>
      <ac-spinner></ac-spinner>
    </ng-template>
  </div>
</ng-template>
<ap-custom-styles></ap-custom-styles>
<div id="ap--version-id" class="display--none" aria-hidden="true">Version: {{ version }}</div>
