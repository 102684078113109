import { AbstractControl } from '@angular/forms';
import { ZIP_CODE_BY_COUNTRY_REGEX } from './zip-code-by-country';
import { returnOrDefaultString } from 'src/app/shared/utilities/string.utils';
import { validObject, validString } from 'src/app/shared/utilities/types.utils';

const countryIDs = Object.keys(ZIP_CODE_BY_COUNTRY_REGEX);

export const countriesWithZipRequired = ['AU', 'CA', 'GB', 'NZ', 'US'];

interface RegexOverride {
  [key: string]: string;
}

export const getRegExp = (str: string = ''): RegExp => new RegExp(`^${str.replace(/\\\\d/g, 'd')}$`, 'i');

export const getZipRegex = (country, regexOverride = ''): RegExp => getRegExp(returnOrDefaultString(regexOverride, ZIP_CODE_BY_COUNTRY_REGEX[country]));

export const isValidZip = (zipValue: string, regexOverride: RegexOverride = {}): boolean => {
  const hasOverride = validObject(regexOverride);
  return countryIDs.some((country: string) => getZipRegex(country, hasOverride && regexOverride[country]).test(zipValue));
};

export const isValidZipForCountry = (zipValue: string, country, regexOverride: RegexOverride = null): boolean => {
  return getZipRegex(country, returnOrDefaultString(validObject(regexOverride) && regexOverride[country])).test(zipValue);
};

export const validateZipField = (control: AbstractControl, requiredCountry: string = '', regexOverride: RegexOverride = null): void => {
  if (!control) {
    return;
  }
  const zipValue = returnOrDefaultString(control?.value).trim().toUpperCase();

  if (!validString(requiredCountry) || requiredCountry === 'ZZ') {
    control.setErrors(!isValidZip(zipValue, regexOverride) ? { zipCode: true } : null);
  } else {
    if (!isValidZipForCountry(zipValue, requiredCountry, regexOverride) && (countriesWithZipRequired.includes(requiredCountry) || control.value)) {
      control.setErrors({
        match: requiredCountry,
        zipCode: true,
      });
    }
  }

  return;
};
