import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'ap-custom-dialog',
  template: `<div [id]="data"></div>`,
  styleUrls: ['./custom-dialog.component.scss'],
})
export class CustomDialogComponent {
  /**
   * Constructor
   * @param data dialog data
   */
  constructor(@Inject(DIALOG_DATA) public data: string) {}
}
