import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { setRedirectPaymentsQueryParamsAction } from 'src/app/feature/billing/alternate-payments/alternate-payments.actions';
import { routeToRedirectResultAction } from 'src/app/feature/billing/billing.actions';
import { updateGiftCardStateFromSessionAction } from 'src/app/feature/gift-card/gift-card.actions';
import { initialState as giftCardInitialState } from 'src/app/feature/gift-card/gift-card.state';
import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import { ERROR_CODE_INVALID_CONFIGURATION, ERROR_CODE_INVALID_SESSION_TOKEN, ERROR_CODE_MISSING_TOKEN } from 'src/app/shared/enums/error-code.enums';
import { returnOrDefaultString } from 'src/app/shared/utilities/string.utils';
import { validArray, validObject, validString } from 'src/app/shared/utilities/types.utils';
import { getQueryParamsFromUrl } from 'src/app/shared/utilities/url.utils';
import { IS_IN_IFRAME } from 'src/app/shared/utilities/window.utils';
import { apiResponse, getApiRequest } from '../api/api.actions';
import { responseRequestType } from '../api/api.utilities';
import { GetAccessoPaySessionResponse, SessionState } from '../api/responses/get-accesso-pay-session';
import { environmentLoadedSuccess } from '../environment/environment.actions';
import { initializeFailedAction } from '../initialize/initialize.actions';
import { DOCUMENT } from '../injection-token/document/document';
import { WINDOW } from '../injection-token/window/window';
import { updateConfiguredPaymentsAction } from '../payment-configuration/payment-configuration.actions';
import { hasRedirectPaymentsQueryParams } from '../payment-configuration/payment-configuration.utils';
import { REDIRECT_PAYMENT_QUERY_PARAMS } from '../routing/routing.enums';
import { TemporaryLoggerService } from '../temporary-logger/temporary-logger.service';
import { fetchParentConfig, loadedParentConfigAction, setParentConfig, setParentConfigErrorAction } from './parent-config.actions';
import { setBugnsnagClientConfig } from './parent-config.utils';

@Injectable({
  providedIn: 'root',
})
export class ParentConfigEffects {
  /**
   * Define Bugsnag metadata
   */
  setParentConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setParentConfig, setParentConfigErrorAction),
      tap(({ payload, sessionId }) => {
        setBugnsnagClientConfig(sessionId, payload.user?.customerId);
      }),
      map(() => loadedParentConfigAction())
    )
  );

  /**
   * Handles errors with parent configs
   */
  setParentConfigError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setParentConfigErrorAction),
      map(({ errorCode, sessionId = '', payload }) =>
        initializeFailedAction({
          errorCode: errorCode || ERROR_CODE_INVALID_CONFIGURATION,
          reason: 'Invalid parent configs',
          metaData: {
            sessionId,
            parentConfig: `${JSON.stringify(payload)}`,
          },
        })
      )
    )
  );

  /**
   * Verifying session token when application is rendered outside of an iframe.
   * We need the gateway url before we can verify session id.
   * Query parameter "t" and "acsop_t" have the same behaviors.
   * Query param `t` is the InitializeAccessoPaySession token to fetch params when loading accesso Pay through a native application.
   * Query param "acsop_t" is used to indicate a return from payment redirection and to reinitialize a session.
   */
  verifySessionToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(environmentLoadedSuccess),
      switchMap((action) => {
        const queryParams = getQueryParamsFromUrl(this.window) ?? {};
        const sessionToken = returnOrDefaultString(queryParams.t, queryParams[REDIRECT_PAYMENT_QUERY_PARAMS.REDIRECT_SESSION_TOKEN]);
        const actions: Action[] = [];

        if (sessionToken.length) {
          actions.push(fetchParentConfig({ payload: sessionToken }));

          if (hasRedirectPaymentsQueryParams(queryParams)) {
            actions.push(setRedirectPaymentsQueryParamsAction({ payload: queryParams }));
          }
        } else if (!IS_IN_IFRAME) {
          // Fail when session token is missing
          actions.push(
            initializeFailedAction({
              errorCode: ERROR_CODE_MISSING_TOKEN,
              reason: 'The URL is missing the required token query param.',
              redirectTo: ['session-expired'],
              metaData: {
                queryParams: this.window.location.search,
                insideIframe: 'false',
              },
            })
          );
        }

        return actions;
      })
    )
  );

  /**
   * Use session token fetch session data from our backend API
   */
  fetchParentConfigByToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchParentConfig),
      map(({ payload }) =>
        getApiRequest({
          requestType: ApiRequestType.GET_ACCESSO_PAY_SESSION,
          resources: [payload],
        })
      )
    )
  );

  /**
   * Handles receiving a response for the GetAccessoPaySession
   */
  parentConfigResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(apiResponse),
      filter(responseRequestType(ApiRequestType.GET_ACCESSO_PAY_SESSION)),
      mergeMap(({ isOk, response, resources }) => {
        const actions: Action[] = [];

        if (isOk) {
          const { payload, sessionKey, sessionState } = response as GetAccessoPaySessionResponse;
          const resourceId: string = validArray(resources) && resources[0];

          if (validString(payload) && sessionKey === resourceId) {
            const parsedPayload = JSON.parse(payload);
            const giftCardState = validObject(parsedPayload?.giftCardState) ? parsedPayload.giftCardState : giftCardInitialState;
            const parentConfigVal = validObject(parsedPayload?.parentConfig) ? parsedPayload.parentConfig : parsedPayload;
            actions.push(
              setParentConfig({ payload: parentConfigVal }),
              updateGiftCardStateFromSessionAction({ payload: giftCardState }),
              updateConfiguredPaymentsAction()
            );

            // returning from redirect payment
            if (sessionState === SessionState.REDIRECT) {
              actions.push(routeToRedirectResultAction());
            }
          }
        } else {
          actions.push(
            initializeFailedAction({
              errorCode: ERROR_CODE_INVALID_SESSION_TOKEN,
              reason: `Invalid session token`,
              redirectTo: ['session-expired'],
              metaData: {
                context: 'GetAccessoPaySession request failed',
                sessionKey: (response?.sessionKey ?? '') as GetAccessoPaySessionResponse['sessionKey'],
              },
            })
          );
        }

        return actions;
      })
    )
  );

  /**
   * Modify application theme with css variables
   */
  overrideCssVariables$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setParentConfig),
        filter(({ payload }) => !!payload.cssVariables),
        map(({ payload }) => {
          try {
            const stylesConfig = payload?.cssVariables;
            Object.keys(stylesConfig).forEach((key) => {
              if (typeof this.document.documentElement?.style?.setProperty === 'function') {
                // need to remove this validation for simple-checkout since we already have the ajv validation
                if (typeof stylesConfig[key] === 'string') {
                  this.document.documentElement.style.setProperty(`--${key}`, stylesConfig[key]);
                }
              }
            });
          } catch (ignoreError) {}
        })
      ),
    { dispatch: false }
  );

  /**
   * @param actions$ Our actions stream
   */
  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    private actions$: Actions,
    private tempLogger: TemporaryLoggerService
  ) {}
}
