import { Injectable } from '@angular/core';
import { CanActivateChild, Router } from '@angular/router';
import { createSelector, select, Store } from '@ngrx/store';
import { AppState } from 'src/app/app.state';
import { validString } from 'src/app/shared/utilities/types.utils';
import { routeTo } from '../../core/routing/routing.actions';
import { selectShowExtrasPage, selectShowTicketProtection, selectTicketInsuranceRawQuoteToken } from './extras.selectors';

@Injectable({
  providedIn: 'root',
})
export class ExtrasSelectionGuard implements CanActivateChild {
  /**
   * Show Ticket Guardian
   */
  private showExtrasPage: boolean;

  /**
   * @param store The store
   * @param deliveryGuard The delivery guard
   */
  constructor(private store: Store<AppState>, private activeRoute: Router) {
    this.store
      .pipe(
        select(
          createSelector(
            selectShowExtrasPage,
            selectShowTicketProtection,
            selectTicketInsuranceRawQuoteToken,
            (showExtrasPage, showTicketProtection, insuranceQuoteToken) => ({
              canShowExtrasPage: showExtrasPage && showTicketProtection && !validString(insuranceQuoteToken),
              insuranceQuoteToken,
              showTicketProtection,
            })
          )
        )
      )
      .subscribe(({ canShowExtrasPage, showTicketProtection }) => {
        this.showExtrasPage = canShowExtrasPage;
      });
  }

  /**
   * Guards route by checking the delivery and extras configuration
   */
  canActivateChild(): boolean {
    if (this.showExtrasPage) {
      this.store.dispatch(routeTo({ payload: ['extras'] }));
      return false;
    }

    return true;
  }
}
