import { ConfiguredPayments } from './payment-configuration.model';

export interface PaymentConfigurationState {
  /**
   * If we're loading the payment configurations
   */
  loading: boolean;
  /**
   * If the payment configuration loaded correctly.
   */
  loaded: boolean;
  /**
   * If there was an error loading.
   */
  errorLoading: boolean;
  /**
   * The selected payment code
   */
  selectedPaymentMethodCode?: string;
  /**
   * The actual configured payments.
   */
  configuredPayments: ConfiguredPayments;
}

export const initialState: PaymentConfigurationState = {
  loaded: false,
  loading: false,
  errorLoading: false,
  selectedPaymentMethodCode: null,
  configuredPayments: null,
};
