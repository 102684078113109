/* eslint-disable no-console */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createSelector, select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { selectEnvironment } from '../environment/environment.selectors';
import { EnvironmentState } from '../environment/environment.state';
import { selectParentSessionId } from '../parent-config/parent-config.selectors';

@Injectable({
  providedIn: 'root'
})
export class TemporaryLoggerService {
  /**
   * The parent session ID
   */
  private parentSessionId: string;

  /**
   * The request url to log to node
   */
  private url: string;

  /**
   * Our constructor
   * @param store The global store
   */
  constructor(private store: Store<AppState>, private http: HttpClient) {
    this.store.pipe(
      select(
        createSelector(
          selectParentSessionId,
          selectEnvironment,
          (sessionId, env) => {
            return {
              env,
              sessionId,
            };
          }
        )
      )
    ).subscribe(({ env, sessionId }) => {
      this.parentSessionId = sessionId;
      this.url = this.buildUrl(env);
    });
  }

  /**
   * Log information to the
   * @param title The title of the log
   * @param msg The log message
   */
  log(title: string, msg: string): void {
    if (!this.url) {
      return;
    }

    this.http.post(this.url, {
      m: msg,
      t: `accessoPay: ${title}`,
      s: this.parentSessionId,
    }).pipe(
      catchError((err, obs) => of({}))
    ).subscribe();
  }

  /**
   * Builds our url for posting messages to
   * @param env The environment state
   * @returns The url
   */
  private buildUrl(env: EnvironmentState): string {
    if (!env.loaded) {
      return '';
    }

    const { domain, subDomainSuffix } = env;

    // We need to send the request to node
    return `https://v5store.secure${subDomainSuffix}.${domain}/api/log`;
  }
}
