import { createAction } from '@ngrx/store';

export enum AdPlacementActions {
  CreateLauncher = '[Ad Placement Service] Create Rokt Launcher',
}

/**
 * Initialize ad placement launcher
 */
export const createAdPlacementLauncherAction = createAction(AdPlacementActions.CreateLauncher);
