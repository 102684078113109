import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { OptimizelyService } from './optimizely.service';

@Injectable({
  providedIn: 'root',
})
export class OptimizelyResolve implements Resolve<boolean> {
  /**
   * Our constructor
   * @param amazonPayService Optimizely Service
   */
  constructor(private store: Store<AppState>, private optimizely: OptimizelyService) {}

  resolve(): Observable<boolean> | boolean {
    return this.optimizely.loadScript();
  }
}
