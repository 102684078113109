import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { StoredWalletEffects } from './stored-wallet.effects';
import { storedWalletReducer } from './stored-wallet.reducer';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('storedWallet', storedWalletReducer), EffectsModule.forFeature([StoredWalletEffects])],
})
export class StoredWalletModule {}
