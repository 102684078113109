import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, createSelector, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import {
  updateAlternatePaymentAvailabilityAction,
  updateCreditCardAvailabilityAction,
  updateCreditCardAvailabilityWithConfiguredPaymentsAction,
  updateGiftCardAvailabilityAction,
} from 'src/app/feature/billing/billing.actions';
import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import { ERROR_CODE_MERCHANT_FAILED_TO_LOAD } from 'src/app/shared/enums/error-code.enums';
import { normalize } from 'src/app/shared/utilities/string.utils';
import { validArray, validObject, validString } from 'src/app/shared/utilities/types.utils';
import { staticApiResponseAction } from '../api/api.actions';
import { initializeFailedAction, initializeFinishedAction } from '../initialize/initialize.actions';
import { selectParentConfig } from '../parent-config/parent-config.selectors';
import {
  loadPaymentConfigurationAction,
  updateConfiguredPaymentsAction,
  updatePaymentConfigurationAction,
  updatePaymentConfigurationWithConfiguredPaymentsAction,
} from './payment-configuration.actions';
import { ConfiguredPayments } from './payment-configuration.model';
import { selectConfiguredPayments } from './payment-configuration.selectors';
import { buildPaymentConfigurationModel, updateMerchant } from './payment-configuration.utils';

const merchantAndParentInfoSelector = createSelector(selectParentConfig, selectConfiguredPayments, (parentConfig, configuredPayments) => {
  return {
    parentConfig: parentConfig.config,
    configuredPayments,
  };
});

@Injectable()
export class PaymentConfigurationEffects {
  /**
   * @param actions$ Our stream of actions.
   * @param store The store itself.
   */
  constructor(private actions$: Actions, private store: Store<AppState>) {}

  /**
   * Listens for the payment configuration request from backend.
   */
  paymentConfigurationResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(staticApiResponseAction),
      filter(({ requestType, isOk }): boolean => isOk && requestType === ApiRequestType.GET_PAYMENT_CONFIGURATION),
      map(({ response }) => loadPaymentConfigurationAction({ paymentConfigResponse: response }))
    )
  );

  /**
   * Handle payment configuration failure on initializaiton
   */
  failedPaymentConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(staticApiResponseAction),
      filter(({ requestType, isOk }): boolean => !isOk && requestType === ApiRequestType.GET_PAYMENT_CONFIGURATION),
      map((action) =>
        initializeFailedAction({
          errorCode: ERROR_CODE_MERCHANT_FAILED_TO_LOAD,
          reason: `Failed to load payment configuration`,
          redirectTo: ['invalid'],
        })
      )
    )
  );

  /**
   * Listens for the payment configuration request from backend.
   */
  loadPaymentConfiguration$ = createEffect(() =>
    combineLatest([this.actions$.pipe(ofType(loadPaymentConfigurationAction)), this.actions$.pipe(ofType(initializeFinishedAction))]).pipe(
      withLatestFrom(this.store.select(selectParentConfig)),
      mergeMap(([[{ paymentConfigResponse }], parentConfigs]) => {
        const { disablePayments, recurring } = parentConfigs.config;
        const configuredPayments: ConfiguredPayments = buildPaymentConfigurationModel(paymentConfigResponse, disablePayments, recurring);
        const actions: Action[] = [updateCreditCardAvailabilityAction({ status: validArray(configuredPayments.creditCards) })];

        // Update the merchant
        configuredPayments.alternatePayments.forEach((alternatePayment) => {
          if (alternatePayment.active && validString(alternatePayment.paymentMethodName)) {
            actions.push(updateAlternatePaymentAvailabilityAction({ payload: normalize(alternatePayment.paymentMethodName) }));
          }
        });
        actions.push(
          updateGiftCardAvailabilityAction({ status: validObject(configuredPayments.giftCard) && configuredPayments.giftCard.active }),
          updatePaymentConfigurationAction({ configuredPayments })
        );

        return actions;
      })
    )
  );

  /**
   * Listens for the request to update merchant details
   */
  updatePaymentConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateConfiguredPaymentsAction),
      withLatestFrom(this.store.select(merchantAndParentInfoSelector)),
      filter(([action, { parentConfig }]) => parentConfig.recurring || validObject(parentConfig.disablePayments)),
      mergeMap(([action, { parentConfig, configuredPayments }]) => {
        const updatedConfigs: ConfiguredPayments = updateMerchant(configuredPayments, parentConfig.disablePayments, parentConfig.recurring);

        return [
          updatePaymentConfigurationWithConfiguredPaymentsAction({ configuredPayments: updatedConfigs }),
          updateCreditCardAvailabilityWithConfiguredPaymentsAction({ status: validArray(updatedConfigs.creditCards) }),
        ];
      })
    )
  );
}
