import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import Bugsnag from '@bugsnag/js';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { toLower } from 'lodash-es';
import { DOCUMENT } from '../injection-token/document/document';
import { WINDOW } from '../injection-token/window/window';
import { filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { OpitmizelyMessageType, OptimizelyCustomEvent } from 'src/app/feature/external/optimizely/optimizely.enums';
import { OptimizelyService } from 'src/app/feature/external/optimizely/optimizely.service';
import {
  EVENT_AUTHORIZING,
  EVENT_CANCEL,
  EVENT_PAYMENT_CANCELLED,
  EVENT_PAYMENT_COMPLETE,
  EVENT_PAYMENT_FAILED,
} from 'src/app/shared/enums/application-bridge.enums';
import { toCents } from 'src/app/shared/utilities/number.utils';
import { validArray } from 'src/app/shared/utilities/types.utils';
import { sendMessageAction } from '../application-bridge/application-bridge.actions';
import { PaymentComplete, SplitPaymentComplete } from '../application-bridge/application-bridge.models';
import { ClientConfigurationState } from '../client-configuration/client-configuration.state';
import { environmentLoadedSuccess } from '../environment/environment.actions';
import { ParentConfig } from '../parent-config/parent-config.state';
import { TemporaryLoggerService } from '../temporary-logger/temporary-logger.service';
import { sendAnalyticsEventAction, sendPaymentComplete, sendToGtag, sendToOptimizelyAction, setCheckoutStepAction } from './analytics.actions';
import { analyticsSelector } from './analytics.selectors';
import { selectPaymentAmountWithExtras } from 'src/app/feature/billing/billing.selectors';

@Injectable()
export class AnalyticsEffects {
  /**
   * The parent configuration
   */
  private parentConfig: ParentConfig;
  /**
   * The client configuration state
   */
  private clientConfig: ClientConfigurationState;
  /**
   * The delivery amount
   */
  private deliveryAmount: number;

  /**
   * Loads the the analytics script and sets the config.
   */
  setupAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(environmentLoadedSuccess),
      map(({ payload }) => {
        const document = this.getDocument();
        const script = document.createElement('script');
        const id = `UA-177455020-${payload.isProduction ? '1' : '2'}`;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
        document.head.appendChild(script);

        return sendToGtag({
          params: [
            'config',
            id,
            {
              anonymize_ip: true,
              send_page_view: false,
              custom_map: {
                dimension1: 'd_tenantName',
                dimension2: 'd_merchantId',
                dimension3: 'd_language',
              },
            },
          ],
        });
      })
    )
  );

  /**
   * Handles sending events to GA.
   */
  sendEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendAnalyticsEventAction),
      map(({ action, category, label, value, nonInteraction }) => {
        return sendToGtag({
          params: [
            'event',
            action,
            {
              event_category: category,
              event_label: label,
              value,
              non_interaction: nonInteraction,
            },
          ],
        });
      })
    )
  );

  /**
   * Allows us to send a checkout step to GA
   */
  setCheckoutStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setCheckoutStepAction),
      map((action) => {
        return sendToGtag({
          params: [
            'event',
            'set_checkout_option',
            {
              checkout_step: action.step,
              checkout_option: action.option,
              value: action.value,
              event_callback: action.callback,
            },
          ],
        });
      })
    )
  );

  /**
   * Watches for a payment complete acknowledge event and sends the event.
   */
  paymentComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendPaymentComplete),
      tap(({ payload }) => {
        try {
          const isSplitPayment = validArray((payload as SplitPaymentComplete).paymentInfo);
          const transactionAuthId = isSplitPayment
            ? (payload as SplitPaymentComplete).paymentInfo.map((payInfo) => payInfo.authId).join(',')
            : (payload as PaymentComplete).authId;

          this.tempLogger.log('accesso Pay payment complete', `received - ${transactionAuthId}`);
        } catch (err1) {
          this.tempLogger.log('accesso Pay payment complete catch 1', err1.toString());

          try {
            Bugsnag.notify(err1);
          } catch (err2) {
            this.tempLogger.log('accesso Pay payment complete catch 2', err2.toString());
            // ignore it if we can't log
          }
        }
      }),
      mergeMap((action) => {
        const { payload } = action;
        const {
          clientConfig: { tenantName, merchantId, language },
          parentConfig,
        } = this;
        const totalAmount = this.deliveryAmount + parentConfig.amount;
        const isSplitPayment = validArray((payload as SplitPaymentComplete).paymentInfo);
        const transactionAuthId = isSplitPayment
          ? (payload as SplitPaymentComplete).paymentInfo.map((payInfo) => payInfo.authId).join(',')
          : (payload as PaymentComplete).authId;

        return [
          sendMessageAction({
            key: EVENT_PAYMENT_COMPLETE,
            payload,
          }),
          sendToGtag({
            params: [
              'event',
              'purchase',
              {
                transaction_id: toLower(`${tenantName}-${merchantId}-${language}-${transactionAuthId}-${Date.now()}`),
                affiliation: toLower(`${tenantName}-${merchantId}-${language}`),
                value: totalAmount.toFixed(2),
                currency: parentConfig.currencyCode || 'USD',
              },
            ],
          }),
        ];
      })
    )
  );

  /**
   *  Calls optimizely with the specific parameters.
   * */

  optimizely$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendMessageAction),
      filter(() => this.optimizely.isEnabled),
      withLatestFrom(this.store.select(selectPaymentAmountWithExtras)),
      switchMap(([{ key, payload = {} }, amount]) => {
        const event: OptimizelyEvent = {
          type: OpitmizelyMessageType.EVENT,
          eventName: '' as any,
          tags: {
            revenue: toCents(amount),
          },
        };

        switch (key) {
          case EVENT_AUTHORIZING:
            event.eventName = OptimizelyCustomEvent.PAYMENT_AUTH;
            break;
          case EVENT_CANCEL:
          case EVENT_PAYMENT_CANCELLED:
            event.eventName = OptimizelyCustomEvent.PAYMENT_CANCEL;
            break;
          case EVENT_PAYMENT_FAILED:
            event.eventName = OptimizelyCustomEvent.PAYMENT_FAIL;
            break;
          case EVENT_PAYMENT_COMPLETE:
            event.eventName = OptimizelyCustomEvent.PAYMENT_SUCCESS;
            event.tags = { ...event.tags, paymentMethod: payload.method };
            break;
          default:
            return [];
        }

        return [sendToOptimizelyAction({ event })];
      })
    )
  );

  /**
   * Calls the gtag with the specific parameters.
   */
  gtag$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sendToGtag),
        map((action) => {
          const { gtag } = this.window;
          gtag.apply(gtag, action.params);
        })
      ),
    { dispatch: false }
  );

  /**
   *  Sends the optimizely events
   * */
  sendOptimizelyEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sendToOptimizelyAction),
        map(({ event }) => {
          this.optimizely.sendEvent(event);
        })
      ),
    { dispatch: false }
  );

  /**
   * @param document The document object
   * @param actions$ The actions stream.
   */
  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    private actions$: Actions,
    private store: Store<AppState>,
    private tempLogger: TemporaryLoggerService,
    private optimizely: OptimizelyService,
    private router: Router
  ) {
    // Add page views to GA
    router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      let page = event.urlAfterRedirects.split('?').shift();
      const parts = page.split('/');

      // 4 because we should always start with a `/` which will add an empty string at the begining.
      if (parts.length >= 4) {
        page = `/:tenantName/:merchantId/:language/${parts.slice(4).join('/')}`;
      }

      const { tenantName, merchantId, language } = this.clientConfig;

      this.window.gtag('event', 'page_view', {
        page_path: page,
        d_tenantName: tenantName,
        d_merchantId: merchantId,
        d_language: language,
      });
    });

    this.store.pipe(select(analyticsSelector)).subscribe(({ parentConfig, clientConfig, deliveryAmount }) => {
      this.parentConfig = parentConfig.config;
      this.clientConfig = clientConfig;
      this.deliveryAmount = deliveryAmount;
    });
  }

  /**
   * Returns the document object.
   */
  private getDocument(): Document {
    return this.document;
  }
}
