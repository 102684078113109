import { Dictionary } from 'src/app/shared/utilities/types.utils';

/**
 * Base properties all request and responses must contain
 */
export interface BaseProperties {
  /**
   * The backend session ID used for tracking requests from the same user. This is used for debugging purposes.
   */
  session_id?: string;
}

/**
 * The different type of `status` values
 */
export enum ApiStatus {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  OK = 'OK',
}

/**
 * The base properties a response may have.
 */
export interface ApiServiceResponse extends Dictionary<any>, BaseProperties {
  /**
   * The status of the response.
   */
  status?: ApiStatus;

  /* tslint:disable */
  /**
   * The error code from the API
   */
  error_code?: string;

  /**
   * The actual human readable error message
   */
  error_msg?: string;

  /**
   * The error tite
   */
  title?: string;

  /**
   * The error detail
   */
  detail?: string;
  /* tslint:disable */
}

/**
 * Default properties that will be added to every request
 */
export interface DefaultProperties {
  /**
   * The application id
   */
  applicationId: number;
  /**
   * The requestor
   */
  __requester: string;
  /**
   * The language
   */
  language: string;
  /**
   * The accessoPay merchant id
   */
  accessoPayMerchantId: string;
  /**
   * The parent session id
   */
  parent_session_id: string;
  /**
   * The session id of accessoPay
   */
  session_id?: string;
}

/**
 * Helper interface for declaring a response
 */
export type ApiResponse<T> = ApiServiceResponse & T;

/**
 * Final Api Response
 */
export interface Response<T> {
  /**
   * The actual response
   */
  response: T;
  /**
   * The status of the response
   */
  status: ApiStatus;
}
