import { NgModule } from '@angular/core';
import { BillingModule } from './billing/billing.module';
import { DeliveryModule } from './delivery/delivery.module';
import { ExtrasModule } from './extras/extras.module';
import { GiftCardModule } from './gift-card/gift-card.module';
import { PaymentOptionsModule } from './payment-options/payment-options.module';
import { TmbThanachartModule } from './tmbthanachart/tmbthanachart.module';
import { WebExperimentationModule } from './web-experimentation/web-experimentation.module';
import { PaymentModule } from './payment/payment.module';

@NgModule({
  declarations: [],
  imports: [DeliveryModule, TmbThanachartModule, BillingModule, ExtrasModule, GiftCardModule, PaymentOptionsModule, WebExperimentationModule, PaymentModule],
})
export class FeatureModule {}
