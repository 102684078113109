import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import { ERROR_CODE_MERCHANT_FAILED_TO_LOAD } from 'src/app/shared/enums/error-code.enums';
import { staticApiResponseAction } from '../api/api.actions';
import { ApiStatus } from '../api/api.interface';
import { updateClientConfiguration } from '../client-configuration/client-configuration.actions';
import { ClientConfigurationState } from '../client-configuration/client-configuration.state';
import { initializeFailedAction } from '../initialize/initialize.actions';
import { TemporaryLoggerService } from '../temporary-logger/temporary-logger.service';
import { staticDataLoadedFailureAction, staticDataLoadedSuccessAction } from './static-data.actions';
import { StaticDataResponse } from './static-data.model';

@Injectable()
export class StaticDataEffects {
  /**
   * Fetches static data
   */
  loadStaticData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateClientConfiguration),
      filter(({ payload }) => payload.found),
      mergeMap(({ payload }) =>
        this.fetchStaticData(payload).pipe(
          map(({ data }: StaticDataResponse) => staticDataLoadedSuccessAction({ payload: data })),
          catchError((err: HttpErrorResponse) => {
            try {
              Bugsnag.notify(new Error(`Failed to initialize`), (event) => {
                event.addMetadata('Initialize Error', { errorCode: err.statusText, reason: err.message });
              });

              this.tempLogger.log('Initialization Failure', err.message);
            } catch (ignoreError) {}

            return of(staticDataLoadedFailureAction());
          })
        )
      )
    )
  );

  /**
   * Injects static data into the store
   */
  injectStaticData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(staticDataLoadedSuccessAction),
      mergeMap(({ payload }) => {
        const { GetEnvironment, GetApplicationConfigs, GetApplicationLocale, GetPaymentConfiguration } = payload;
        return [
          staticApiResponseAction({
            status: ApiStatus.OK,
            isOk: true,
            requestType: ApiRequestType.GET_ENVIRONMENT,
            response: GetEnvironment,
          }),
          staticApiResponseAction({
            status: GetPaymentConfiguration.status,
            isOk: GetPaymentConfiguration.status === ApiStatus.OK,
            requestType: ApiRequestType.GET_PAYMENT_CONFIGURATION,
            response: GetPaymentConfiguration.response,
          }),
          staticApiResponseAction({
            status: GetApplicationConfigs.status,
            isOk: GetApplicationConfigs.status === ApiStatus.OK,
            requestType: ApiRequestType.GET_APPLICATION_CONFIG,
            response: GetApplicationConfigs.response,
          }),
          staticApiResponseAction({
            status: GetApplicationLocale.status,
            isOk: GetApplicationLocale.status === ApiStatus.OK,
            requestType: ApiRequestType.GET_APPLICATION_LOCALE,
            response: GetApplicationLocale.response,
          }),
        ];
      })
    )
  );

  /**
   * Sends initialization failure on static data failure
   */
  staticDataFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(staticDataLoadedFailureAction),
      map(() =>
        initializeFailedAction({
          errorCode: ERROR_CODE_MERCHANT_FAILED_TO_LOAD,
          reason: `Merchant doesn't exist or failed to load`,
        })
      )
    )
  );

  /**
   * @param actions$ Our stream of actions.
   * @param store The store itself.
   * @param http The http client
   */
  constructor(private actions$: Actions, private store: Store<AppState>, private http: HttpClient, private tempLogger: TemporaryLoggerService) {}

  /**
   * Grabs the configuration data
   * @param clientConfig Client Configuration state
   * @returns An observable of the static data response
   */
  fetchStaticData({ tenantName, merchantId, language }: ClientConfigurationState): Observable<StaticDataResponse> {
    const url = `php/static-data/`;
    return this.http.get<StaticDataResponse>(url, { params: { requestType: 'GetStaticData', tenantName, merchantId, language } });
  }
}
