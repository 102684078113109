import { createSelector } from '@ngrx/store';
import { selectDeliveryAmount } from 'src/app/feature/delivery/delivery.selectors';
import { selectClientConfiguration } from '../client-configuration/client-configuration.selectors';
import { selectParentConfig } from '../parent-config/parent-config.selectors';

export const analyticsSelector = createSelector(
  selectParentConfig,
  selectClientConfiguration,
  selectDeliveryAmount,
  (parentConfig, clientConfig, deliveryAmount) => {
    return {
      parentConfig,
      clientConfig,
      deliveryAmount,
    };
  }
);
