import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { TmbThanachartEffects } from './tmbthanachart.effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, EffectsModule.forFeature([TmbThanachartEffects])],
})
export class TmbThanachartModule {}
