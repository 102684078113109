let GUID = 0;

/**
 * Returns a unique number every time it's called.
 *
 * PLEASE NOTE: This should NOT be used as a unique id with backend as it is not guaranteed to be unique across customers.
 * This is simply unique within a running applications.
 */
export function guid(): string {
  return `ap-guid-${GUID++}`;
}
