import { DonationAuthResponse } from './../../../core/application-bridge/application-bridge.models';
import { Enrollment3DSResponse } from 'src/app/core/api/responses/enrollment-3ds';
import { Validate3DSResponse } from 'src/app/core/api/responses/validate-3ds';
import { AuthorizedPaymentInfo, PaymentType } from 'src/app/core/application-bridge/application-bridge.models';
import { CreditCard } from 'src/app/core/payment-configuration/payment-configuration.model';
import { TokenType } from 'src/app/shared/enums/billing.enums';
import { validObject, validString } from 'src/app/shared/utilities/types.utils';
import { convertDonationResponse } from 'src/app/shared/utilities/donation.utils';
import { convertInsuranceResponse } from 'src/app/shared/utilities/insurance.utils';

/**
 * Returns the mapped data to make a 3DS Authorize request
 * @param enrollmentInfo api response
 * @param cardDetails googlepay card
 * @param cardConfig configuration for payment type
 * @returns formatted payload
 */
export function map3DSDataToAuthorizeRequest(
  apiResponse: Enrollment3DSResponse | Validate3DSResponse,
  cardDetails: ThreeDSData['cardDetails'],
  cardConfig: CreditCard
): AuthorizedPaymentInfo {
  const { amount, approval_code, auth_id, card_type_code, paymentReference: authId, donation, insurance } = apiResponse;
  const { cardData, paymentMerchantId } = cardDetails;
  const { code, token, tokenType } = cardData;
  const rawCardBrand = code ?? card_type_code;
  const { paymentProviderName, paymentMethodCode } = cardConfig;

  const basePayload: Partial<AuthorizedPaymentInfo> = {
    amount,
    approval_code,
    authId,
    legacy: {
      authId: auth_id,
      paymentMerchantId,
      paymentMethodCode,
      paymentProviderName,
      rawCardBrand,
    },
  };

  if (validObject(donation)) {
    basePayload.donation = convertDonationResponse(donation);
  }
  if (validObject(insurance)) {
    basePayload.insurance = convertInsuranceResponse(insurance);
  }

  switch (tokenType) {
    case TokenType.GOOGLE_PAY:
      return {
        ...basePayload,
        method: PaymentType.GOOGLE_PAY,
      } as AuthorizedPaymentInfo;
    default:
      const { year, month } = cardData;
      const { maskedPan } = token;

      return {
        ...basePayload,
        method: PaymentType.CREDIT_CARD,
        maskedPan,
        expire_date: `${year.substring(2)}${month}`,
        lastFour: validString(maskedPan) ? maskedPan.substring(maskedPan.length - 4) : '',
        cardType: rawCardBrand,
      } as AuthorizedPaymentInfo;
  }
}

/**
 * Determines if 3DS payment details has required information
 * @param cardData 3DS card information
 */
export function has3DSTransactionRequirements(cardData: ThreeDSData['cardDetails']['cardData']): boolean {
  const { tokenType, token } = cardData;
  const isValidToken = validObject(token);

  switch (tokenType) {
    case TokenType.GOOGLE_PAY:
      return isValidToken;
    case TokenType.CREDIT_CARD:
      return isValidToken && validString(cardData.token.transientTokenJwt);
    default:
      return false;
  }
}
