import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { AcLoggerService } from 'src/app/core/logger/logger.service';
import { WINDOW } from 'src/app/core/injection-token/window/window';
import { filter, take } from 'rxjs/operators';
import { getQueryParams } from 'src/app/shared/utilities/url.utils';
import { AppState } from '../../app.state';
import { TemporaryLoggerService } from '../temporary-logger/temporary-logger.service';
import { initializeFinishedAction, initializeStart } from './initialize.actions';
import { InitializeEffects } from './initialize.effects';
import { initializeReducer } from './initialize.reducer';
import { selectInitializeState } from './initialize.selectors';

interface PossibleInitialQueryParams {
  t?: string;
  _d?: string;
}

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('initialize', initializeReducer), EffectsModule.forFeature([InitializeEffects])],
  providers: [
    {
      deps: [AcLoggerService, Store, WINDOW, TemporaryLoggerService],
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
    },
  ],
})
export class InitializeModule {}

/**
 * Initializes the application
 * @param logger Injecting logger so it can set the debug query param flag if needed
 * @param store The global store
 * @param window The window object
 */
export function initializeApp(logger: AcLoggerService, store: Store<AppState>, window: Window, tempLogger: TemporaryLoggerService) {
  const queryParams = getQueryParams(window.location.search);
  logger.setEnabled(window, queryParams.get('_d') === 'true');

  return () =>
    new Promise<void>((resolve) => {
      store.dispatch(initializeStart());

      store
        .select(selectInitializeState)
        .pipe(
          filter(({ initialized }) => initialized),
          take(1)
        )
        .subscribe(({ initializeFailed }) => {
          if (!initializeFailed) {
            store.dispatch(initializeFinishedAction());
          }
          resolve();
        });
    });
}
