import { createReducer, on } from '@ngrx/store';
import { fetchParentConfig, setParentConfig, setParentConfigErrorAction } from './parent-config.actions';
import { ParentConfigState, initialState } from './parent-config.state';
import { convertPayload } from './parent-config.utils';

const reducer = createReducer(
  initialState,
  on(fetchParentConfig, (state) => ({ ...state, loaded: false })),
  on(setParentConfigErrorAction, (state, { payload, sessionId, errorCode }) => ({
    ...state,
    loaded: true,
    errorLoading: true,
    config: payload,
    configErrorCode: errorCode,
    sessionId,
  })),
  on(
    setParentConfig,
    (state, { payload, sessionId }): ParentConfigState => ({
      ...state,
      loaded: true,
      errorLoading: false,
      config: convertPayload(payload),
      sessionId,
    })
  )
);

/**
 * Our main reducer for parent config feature
 * @param state The current state
 * @param action The action being triggered
 */
export function parentConfigReducer(state, action): ParentConfigState {
  return reducer(state, action);
}
