export interface ClientConfigurationState {
  /**
   * If the configuration errored.
   */
  error: boolean;
  /**
   * If the configuration was found and it's all good.
   */
  found: boolean;
  /**
   * The configured tenant.
   */
  tenantName: string;
  /**
   * The configured language.
   */
  language: string;
  /**
   * The configured merchant id.
   */
  merchantId: string;
  /**
   * The parent's hostname.
   */
  parentHostname: string;
  /**
   * The origin URL.
   */
  originUrl: string;
}

export const initialState: ClientConfigurationState = {
  error: false,
  found: false,
  tenantName: '',
  language: '',
  merchantId: '',
  parentHostname: '',
  originUrl: '',
};
