import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { validString } from 'guest-app-ui';
import { filter, finalize, interval, merge, mergeMap, Observable, retry, share, Subject, switchMap, take, takeUntil, takeWhile, tap, timer } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { APP_CONFIG_POLL_INTERVAL } from 'src/app/shared/enums/application-config.enum';
import { selectAppConfig } from '../../application-config/application-config.selectors';

interface PollParams {
  /**
   * observable to execute
   */
  observable: Observable<any>;
  /**
   * the timeout in ms to finalize the poll
   */
  timeout?: number;
  /**
   * any specific contidion to finish the poll
   */
  condition?: (data?: any, unsubcriber?: Subject<void>) => boolean;
  /**
   * any specific funtion to execute when the poll end
   */
  callback?: (data?: any) => void;
}

@Injectable()
export class PollingService {
  /**
   * So we can unsubscribe from observables
   */
  private destroyed$: Subject<void>;
  /**
   * the delay time in ms for the poll subject
   */
  private interval = 4000;

  /**
   * Create instance of the service
   * @param store
   */
  constructor(private store: Store<AppState>) {
    store
      .select(selectAppConfig([APP_CONFIG_POLL_INTERVAL]))
      .pipe(
        filter((config) => validString(config.merchant_name)),
        take(1)
      )
      .subscribe((config) => {
        this.interval = config[APP_CONFIG_POLL_INTERVAL];
      });
  }

  /**
   * Creates an observable that starts an interval after a specified delay, it executes the observable param
   * @param object containing observable request, timeout, condition, callback
   * @returns observable result
   */
  poll({ observable, timeout = 90000, condition = (data, unsubscriber) => false, callback = (data) => {} }: PollParams): Observable<any> {
    let lastValue;
    this.destroyed$ = new Subject();
    return timer(0, this.interval).pipe(
      mergeMap(() => observable),
      retry(),
      share(),
      tap((data) => (lastValue = data)),
      takeUntil(merge(this.destroyed$, interval(timeout))),
      takeWhile((data) => !condition(data, this.destroyed$)),
      finalize(() => callback(lastValue))
    );
  }

  /**
   * Stop polling
   */
  stopPolling() {
    this.destroyed$?.next();
    this.destroyed$?.complete();
    this.destroyed$ = null;
  }

  /**
   * Check if polling service instance has been destroyed
   * @returns is detroyed
   */
  isDetroyed(): boolean {
    return this.destroyed$ === null;
  }
}
