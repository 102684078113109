<div class="container">
  <div [innerHTML]="data.message"></div>
  <div class="text--center margin--15-top">
    <gap-button gap-color="secondary" gap-type="outlined">
      <button *ngIf="data.dialogType === 'CONFIRM'" (click)="onClick(actionType.CANCEL)">{{ data.buttonLabelCancel }}</button>
    </gap-button>
    <gap-button gap-color="secondary" gap-type="raised">
      <button (click)="onClick(actionType.OK)">{{ data.buttonLabel }}</button>
    </gap-button>
  </div>
  <div class="session text--center font--xs margin--15-top">ID:{{ sessionId$ | async }}</div>
</div>
