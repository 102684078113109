import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map } from 'rxjs/operators';
import { EVENT_AUTHORIZING } from 'src/app/shared/enums/application-bridge.enums';
import { Injectable } from '@angular/core';
import { sendMessageAction } from 'src/app/core/application-bridge/application-bridge.actions';
import { setAuthorizingAction } from './session.actions';

@Injectable({
  providedIn: 'root',
})
export class SessionEffects {
  /**
   * Handles making payment with stored token
   */
  setAuthorizing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setAuthorizingAction),
      filter((action) => action.authorizing),
      map(() => {
        return sendMessageAction({ key: EVENT_AUTHORIZING });
      }),
    )
  );

  /**
   * @param actions$ Our actions stream
   */
  constructor(private actions$: Actions) {}
}
