import { Injectable, NgZone } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { distinctUntilKeyChanged, filter, map, tap } from 'rxjs/operators';
import { validArray, validString } from 'src/app/shared/utilities/types.utils';
import { clearRedirectPaymentQueryParamsAction, fullRouteTo, goBack, routeTo, routeToWithOptions, routeToWithOptionsInZone } from './routing.actions';

import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.state';
import { EVENT_ROUTE_CHANGE } from 'src/app/shared/enums/application-bridge.enums';
import { sendMessageAction } from '../application-bridge/application-bridge.actions';
import { selectClientConfiguration } from '../client-configuration/client-configuration.selectors';
import { ClientConfigurationState } from '../client-configuration/client-configuration.state';

@Injectable({
  providedIn: 'root',
})
export class RoutingEffects {
  /**
   * The tenant name
   */
  tenantName: string;
  /**
   * The merchant id
   */
  merchantId: string;
  /**
   * The language
   */
  language: string;

  /**
   * Handles routing to a different state.
   */
  routeTo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routeTo),
        tap((action) => {
          this.router.navigate([this.tenantName, this.merchantId, this.language, ...action.payload.filter(validString)]);
        })
      ),
    { dispatch: false }
  );

  /**
   * Handles the ability to route with specific options.
   */
  routeToWithOptions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routeToWithOptions),
        tap((action) => {
          let { routeParams } = action;
          routeParams = routeParams.filter(validString);

          if (!validArray(routeParams) || (routeParams.length === 1 && routeParams[0] === '/')) {
            routeParams = ['account'];
          }

          this.router.navigate([this.tenantName, this.merchantId, this.language, ...routeParams], action.options);
        })
      ),
    { dispatch: false }
  );

  /**
   * Handles the ability to route with specific options by re-entering Angular zone.
   */
  routeToWithOptionsInZone$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routeToWithOptionsInZone),
        tap((action) => {
          let { routeParams } = action;
          routeParams = routeParams.filter(validString);

          if (!validArray(routeParams) || (routeParams.length === 1 && routeParams[0] === '/')) {
            routeParams = ['delivery'];
          }

          this.zone.run(() => this.router.navigate([this.tenantName, this.merchantId, this.language, ...routeParams], action.options));
        })
      ),
    { dispatch: false }
  );

  /**
   * Does a full replace of the entire url.
   */
  fullRouteTo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fullRouteTo),
        map(({ payload = [] }) => payload.filter(validString)),
        filter((payload) => validArray(payload)),
        tap((payload) => {
          this.router.navigate(payload);
        })
      ),
    { dispatch: false }
  );

  /**
   * Goes back a page.
   */
  goBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(goBack),
        tap(() => this.location.back())
      ),
    { dispatch: false }
  );

  /**
   * This effect is triggered when you want to remove query parameters and replace the url.
   */
  clearRedirectPaymentQueryParamsAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clearRedirectPaymentQueryParamsAction),
        tap(({ params }) => {
          const extras: NavigationExtras = {
            queryParams: params.reduce((acc, key) => {
              acc[key] = null;

              return acc;
            }, {}),
            queryParamsHandling: 'merge',
            replaceUrl: true,
          };

          this.router.navigate([], extras);
        })
      ),
    { dispatch: false }
  );

  /**
   * @param actions$ The action stream.
   * @param store The store
   * @param router The router service
   * @param location The location service
   */
  constructor(private actions$: Actions, store: Store<AppState>, private router: Router, private location: Location, private zone: NgZone) {
    store.select(selectClientConfiguration).subscribe((client: ClientConfigurationState) => {
      this.tenantName = client?.tenantName;
      this.merchantId = client?.merchantId;
      this.language = client?.language;
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilKeyChanged<NavigationEnd>('urlAfterRedirects')
      )
      .subscribe((navigation: NavigationEnd) => {
        const pathArray =
          navigation.urlAfterRedirects[0] === '/' ? navigation.urlAfterRedirects.substring(1).split('/') : navigation.urlAfterRedirects.split('/');
        const [tenant, merchant, language, ...path] = pathArray;
        store.dispatch(sendMessageAction({ key: EVENT_ROUTE_CHANGE, payload: path }));
      });
  }
}
