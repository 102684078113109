import { Inject, Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { Action, Store, createSelector } from '@ngrx/store';
import { get, isObject, isString, toLower } from 'lodash-es';
import { Observable, from } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { sendAnalyticsEventAction } from 'src/app/core/analytics/analytics.actions';
import { Response } from 'src/app/core/api/api.interface';
import { ApiService } from 'src/app/core/api/api.service';
import { EWalletAuthorizeRequest, EWalletAuthorizeResponse } from 'src/app/core/api/responses/ewallet-authorize';
import { InitiateGooglePayTxnResponse } from 'src/app/core/api/responses/initiate-google-pay-txn';
import { sendMessageAction } from 'src/app/core/application-bridge/application-bridge.actions';
import { AuthorizedPaymentInfo, PaymentType } from 'src/app/core/application-bridge/application-bridge.models';
import { selectAppConfig } from 'src/app/core/application-config/application-config.selectors';
import { BillingDefaultValueConfig } from 'src/app/core/application-config/application-config.state';
import { WINDOW } from 'src/app/core/injection-token/window/window';
import { LocaleService } from 'src/app/core/locale/locale.service';
import { AcLoggerService } from 'src/app/core/logger/logger.service';
import { DialogData, NotificationDialogType } from 'src/app/core/notification/dialog/confirm-dialog/confirm-dialog.component';
import { showNotificationAction } from 'src/app/core/notification/notification.actions';
import { hidePageSpinner } from 'src/app/core/page-spinner/page-spinner.actions';
import { selectParentConfig } from 'src/app/core/parent-config/parent-config.selectors';
import { ParentConfigState } from 'src/app/core/parent-config/parent-config.state';
import { getLineItemsAndFees } from 'src/app/core/parent-config/parent-config.utils';
import { ConfiguredPayments, PaymentMethodCode, PaymentProviderType, WalletPayment } from 'src/app/core/payment-configuration/payment-configuration.model';
import { selectConfiguredPayments } from 'src/app/core/payment-configuration/payment-configuration.selectors';
import { routeTo } from 'src/app/core/routing/routing.actions';
import { setAuthorizingAction } from 'src/app/core/session/session.actions';
import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import { EVENT_PAYMENT_CANCELLED, EVENT_PAYMENT_FAILED } from 'src/app/shared/enums/application-bridge.enums';
import { BillingDefaultValue, TokenType } from 'src/app/shared/enums/billing.enums';
import { selectPaymentAmount } from 'src/app/shared/selectors/configuration.selectors';
import { getPaymentConfigurationForWalletPaymentsByRawCardBrand, getPaymentMerchantIdBy } from 'src/app/shared/utilities/credit-cards.utils';
import { convertDonationResponse } from 'src/app/shared/utilities/donation.utils';
import { convertInsuranceResponse, formatInsuranceRequest } from 'src/app/shared/utilities/insurance.utils';
import { toDecimal, toInt } from 'src/app/shared/utilities/number.utils';
import { toObservable } from 'src/app/shared/utilities/observable.utils';
import { addScript } from 'src/app/shared/utilities/script-loader.utils';
import { joinStrings, normalize, returnOrDefaultString } from 'src/app/shared/utilities/string.utils';
import { validObject, validString } from 'src/app/shared/utilities/types.utils';
import { selectTicketInsuranceQuoteToken, selectTicketProtection } from '../../extras/extras.selectors';
import { DonationState, TicketProtectionState } from '../../extras/extras.state';
import { gatherPaymentDetailsForGooglePayAction, initializeGooglePayAction, updateBillingDemographicsAction } from '../billing.actions';
import { selectUseSandbox } from '../billing.selectors';
import { APP_CONFIG_DEFAULT_BILLING_VALUE, APP_CONFIG_MERCHANT_NAME } from './../../../shared/enums/application-config.enum';
import { selectPaymentAmountWithDonation } from './../../../shared/selectors/configuration.selectors';
import { selectDonation } from './../../extras/extras.selectors';
import {
  initiateAdyenGooglePay3DSTransactionAction,
  initiateCybersourceGooglePay3DSTransactionAction,
  initiateGooglePayEWalletAuthorizationAction,
} from './google-pay.actions';
import { mapCreditCardToGooglePayNetwork, mapGooglePayNetworkToAccessoPaymentTypeName, sanitizeGooglePayPaymentData } from './google-pay.utils';

interface GooglePayMerchantInfo {
  identifier: string;
  jwt: string;
  vendor: string;
}
const ERROR_PREFIX = '[GooglePayService]';
@Injectable({
  providedIn: 'root',
})
export class GooglePayService {
  private googlePayCards: ConfiguredPayments['googlePayCards'] = [];
  private merchantName = 'accesso Pay';
  private googlePayAvailability = false;
  private initialized = false;
  private client: google.payments.api.PaymentsClient;
  private merchantInfo: GooglePayMerchantInfo;
  private parentConfig: ParentConfigState;
  private paymentAmount: number;
  /**
   * Payment amount plus donation amount
   */
  private paymentAmountWithDonation: number;
  /**
   * Donation State
   */
  private donation: DonationState;
  /**
   * Default billing information app config
   */
  private defaultBillingConfig: BillingDefaultValueConfig = {};
  /**
   * The quote token for ticket insurance
   */
  private ticketInsuranceQuoteToken: string;
  /**
   * Insurance details
   */
  insurance?: TicketProtectionState;
  /**
   * Sandbox mode
   */
  useSandbox: boolean = false;

  get isAvailable() {
    return this.googlePayAvailability;
  }

  get isInitialized() {
    return this.initialized;
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    private store: Store<AppState>,
    private apiService: ApiService,
    private logger: AcLoggerService,
    private localeService: LocaleService
  ) {
    store
      .select(selectConfiguredPayments)
      .pipe(
        filter((configuredPayments) => validObject(configuredPayments)),
        take(1)
      )
      .subscribe(({ googlePayCards = [] }) => {
        this.googlePayCards = googlePayCards;
      });

    store
      .select(selectAppConfig([APP_CONFIG_MERCHANT_NAME, APP_CONFIG_DEFAULT_BILLING_VALUE]))
      .pipe(
        filter((appConfigs) => validObject(appConfigs)),
        take(1)
      )
      .subscribe(({ [APP_CONFIG_MERCHANT_NAME]: merchantName, [APP_CONFIG_DEFAULT_BILLING_VALUE]: defaultBillingConfig = {} }) => {
        this.merchantName = merchantName;
        this.defaultBillingConfig = defaultBillingConfig;
      });

    store
      .select(
        createSelector(
          selectParentConfig,
          selectDonation,
          selectPaymentAmount,
          selectPaymentAmountWithDonation,
          selectTicketProtection,
          selectTicketInsuranceQuoteToken,
          selectUseSandbox,
          (parentConfig, donation, paymentAmount, paymentAmountWithDonation, ticketProtection, quoteToken, useSandbox) => {
            return { parentConfig, donation, paymentAmount, paymentAmountWithDonation, ticketProtection, quoteToken, useSandbox };
          }
        )
      )
      .subscribe(({ parentConfig, donation, paymentAmount, paymentAmountWithDonation, ticketProtection, quoteToken, useSandbox }) => {
        this.parentConfig = parentConfig;
        this.donation = donation;
        this.paymentAmount = paymentAmount;
        this.paymentAmountWithDonation = paymentAmountWithDonation;
        this.insurance = ticketProtection;
        this.ticketInsuranceQuoteToken = quoteToken;
        this.useSandbox = useSandbox;
      });
  }

  /**
   * Find out if we have google pay credit cards we can use
   */
  hasGooglePayCards(): boolean {
    return this.googlePayCards?.length > 0;
  }

  /**
   * Returns the payment provider name eg: Cybersource, Adyen
   */
  getPaymentProviderName(): string {
    return this.googlePayCards?.[0]?.paymentProviderName;
  }

  /**
   * Set the value of the initialized flag
   * @param bool True or false
   */
  setInitialized(bool: boolean) {
    this.initialized = bool;
  }

  /**
   * Set the value of the availability flag
   * @param bool True or false
   */
  setAvailability(bool: boolean) {
    this.googlePayAvailability = bool;
  }

  /**
   * Initialize the service
   */
  initialize(): Observable<boolean> {
    if (this.hasGooglePayCards()) {
      return addScript('https://pay.google.com/gp/p/js/pay.js', true, { id: 'ap-google-pay' }).pipe(
        switchMap(() => this.initializeGooglePay(this.googlePayCards))
      );
    }
    return toObservable(false);
  }

  /**
   * Initializes a transaction.
   * @throws {Error} when matching accesso card type is not found
   */
  initiateTransaction(): void {
    this.client
      .loadPaymentData(this.getPaymentRequest() as google.payments.api.PaymentDataRequest)
      .then((paymentData: google.payments.api.PaymentData) => {
        const sanitizedPaymentData = sanitizeGooglePayPaymentData(paymentData);
        const { paymentMethodData } = sanitizedPaymentData;
        const { billingAddress, cardNetwork } = paymentMethodData.info;
        const user = this.parentConfig.config.user || {};
        const name = this.splitFirstAndLastName(billingAddress.name);
        let paymentConfiguration: WalletPayment;

        this.store.dispatch(setAuthorizingAction({ authorizing: true }));

        try {
          const accessoPayPaymentName = normalize(mapGooglePayNetworkToAccessoPaymentTypeName(cardNetwork));
          paymentConfiguration = this.googlePayCards.find((gp) => normalize(gp.cardBrandName) === accessoPayPaymentName);

          if (!paymentConfiguration) {
            throw new Error(`unable to find wallet payment matching card name ${accessoPayPaymentName}`);
          }
        } catch (error) {
          this.logger.error(`${ERROR_PREFIX} ${error}`);
          [
            sendMessageAction({
              key: EVENT_PAYMENT_FAILED,
              payload: {},
            }),
            showNotificationAction({
              buttonLabel: this.localeService.get('common.close'),
              dialogType: NotificationDialogType.GENERAL,
              initiator: 'Google Pay: unkown card type',
              message: this.localeService.get('errorcode.120'),
            }),
            hidePageSpinner({ initiator: 'Google Pay unsupported card' }),
          ].forEach((action) => this.store.dispatch(action));
          return;
        }

        const { enabled3ds, paymentProviderName } = paymentConfiguration;
        let phoneNumberArr: string[] = [];

        try {
          phoneNumberArr = billingAddress.phoneNumber.match(/^(\+\d{1,5})?[- ]?((?:\(\d{1,3}\))?[- ]?(?:(?:\d+[- ]?)+))$/).slice(1);
        } catch (error) {
          Bugsnag.notify(new Error(`GooglePay Invalid Phone Number`), (event) => {
            event.addMetadata('GooglePay', {
              message: `Invalid Phone Number ${billingAddress.phoneNumber.replace(/\d/g, 'x')}`,
            });
          });
        }

        if (enabled3ds) {
          const userInfo = {
            valid: true,
            firstName: returnOrDefaultString(name.first, user?.firstName),
            middleInitial: returnOrDefaultString(user?.middleInitial),
            lastName: returnOrDefaultString(name.last, user?.lastName),
            address1: returnOrDefaultString(billingAddress.address1, user?.address1),
            address2: returnOrDefaultString(joinStrings(', ', billingAddress.address2, billingAddress.address3), user?.address2),
            city: returnOrDefaultString(billingAddress.locality, user?.city),
            country: returnOrDefaultString(billingAddress.countryCode, user?.country),
            state: returnOrDefaultString(billingAddress.administrativeArea, user?.stateProvince),
            zip: returnOrDefaultString(billingAddress.postalCode, user?.zipPostal),
            email: returnOrDefaultString(sanitizedPaymentData.email, user?.email),
            phone: returnOrDefaultString(phoneNumberArr[1]?.replace(/\s/g, ''), user?.phone),
            telCode: returnOrDefaultString(phoneNumberArr[0], user?.phoneCountryCode),
          };
          const threeDSActions: Action[] = [updateBillingDemographicsAction({ payload: userInfo })];

          switch (paymentProviderName) {
            case PaymentProviderType.Adyen:
              threeDSActions.push(
                initiateAdyenGooglePay3DSTransactionAction({
                  payload: {
                    CARD: { token_response: JSON.stringify(paymentMethodData) },
                    CARDHOLDER: {
                      customer_id: this.parentConfig?.config?.user?.customerId || '',
                      f_name: returnOrDefaultString(name.first, user?.firstName),
                      m_name: returnOrDefaultString(user?.middleInitial),
                      l_name: returnOrDefaultString(name.last, user?.lastName),
                      address: returnOrDefaultString(
                        joinStrings(', ', billingAddress.address1, billingAddress.address2, billingAddress.address3),
                        joinStrings(', ', user?.address1, user?.address2)
                      ),
                      city: returnOrDefaultString(billingAddress.locality, user?.city),
                      country: returnOrDefaultString(billingAddress.countryCode, user?.country),
                      state: returnOrDefaultString(billingAddress.administrativeArea, user?.stateProvince),
                      zip: returnOrDefaultString(billingAddress.postalCode, user?.zipPostal),
                      email: returnOrDefaultString(sanitizedPaymentData.email, user?.email),
                      phone_number: returnOrDefaultString(
                        billingAddress.phoneNumber,
                        joinStrings(' ', user?.phoneCountryCode ? '+' + user.phoneCountryCode : '', user?.phone)
                      ),
                    },
                    paymentType: PaymentMethodCode.GOOGLE_PAY,
                    merchantId: paymentConfiguration.paymentMerchantId,
                  },
                })
              );
              break;
            case PaymentProviderType.Cybersource:
              threeDSActions.push(
                initiateCybersourceGooglePay3DSTransactionAction({
                  payload: {
                    demographics: userInfo,
                    cardDetails: {
                      cardData: {
                        token: paymentMethodData,
                        tokenType: TokenType.GOOGLE_PAY,
                      },
                      paymentMerchantId: paymentConfiguration.paymentMerchantId,
                    },
                  },
                })
              );
              break;
            default:
              break;
          }

          threeDSActions.forEach((action) => this.store.dispatch(action));
          return;
        }

        this.store.dispatch(
          initiateGooglePayEWalletAuthorizationAction({
            payload: { eWalletRequest: this.getAuthorizeRequest(sanitizedPaymentData), callbackData: sanitizedPaymentData },
          })
        );
      })
      .catch((err: google.payments.api.PaymentsError) => {
        this.initiatePaymentFailure(err);
      });
  }

  /**
   * Google provides a single string with all parts of the name.
   * @param name The full name including first/m/last
   */
  splitFirstAndLastName(name = ''): { first: string; last: string } {
    const nameParts = (isString(name) ? name : '').split(' ');

    return {
      last: nameParts.splice(nameParts.length - 1)[0],
      first: nameParts.join(' '),
    };
  }

  /**
   * Find out if we have merchant info loaded.
   */
  hasMerchantInfo(): boolean {
    return isObject(this.merchantInfo);
  }

  /**
   * Sets the transaction's merchant information
   * @param response The Initiate Google Pay Transaction response
   */
  setMerchantInfo(response: InitiateGooglePayTxnResponse): void {
    this.merchantInfo = {
      identifier: response.google_pay_identifier,
      jwt: response.jwt,
      vendor: response.vendor_merchant_id,
    };
  }

  /**
   * Fetches the merchant information and JWT to use with the google api.
   */
  fetchMerchantInfo(): Observable<Response<InitiateGooglePayTxnResponse>> {
    return this.apiService.post<InitiateGooglePayTxnResponse>(ApiRequestType.INITIATE_GOOGLEPAY_TXN, {
      merchant_id: getPaymentMerchantIdBy(this.googlePayCards, 'paymentProviderName', this.getPaymentProviderName()),
      store_origin_url: this.window.location.hostname,
    });
  }

  /**
   * Handles the backend api's response to us trying to authorize a card
   * @param paymentData The payment data from Google
   * @param response The response data from our backend gateway
   */
  handleAuthorizeResponse(paymentData: google.payments.api.PaymentData, response: EWalletAuthorizeResponse): void {
    const { store } = this;
    const { paymentMethodData } = paymentData;
    const { billingAddress } = paymentMethodData.info;
    const user = this.parentConfig.config.user || {};
    const name = this.splitFirstAndLastName(billingAddress.name);
    let phoneNumberArr: string[] = [];
    try {
      phoneNumberArr = billingAddress.phoneNumber.match(/^(\+\d{1,5})?[- ]?((?:\(\d{1,3}\))?[- ]?(?:(?:\d+[- ]?)+))$/).slice(1);
    } catch (error) {
      Bugsnag.notify(new Error(`GooglePay Invalid Phone Number`), (event) => {
        event.addMetadata('GooglePay', {
          message: `Invalid Phone Number ${billingAddress.phoneNumber.replace(/\d/g, 'x')}`,
        });
      });
    }
    const paymentConfiguration = getPaymentConfigurationForWalletPaymentsByRawCardBrand(this.googlePayCards, response.card_type_code);
    const updateBillingAddressAction = updateBillingDemographicsAction({
      payload: {
        valid: true,
        firstName: returnOrDefaultString(name.first, user?.firstName, this.defaultBillingConfig.firstName, BillingDefaultValue.FirstName),
        middleInitial: returnOrDefaultString(user?.middleInitial),
        lastName: returnOrDefaultString(name.last, user?.lastName, this.defaultBillingConfig.lastName, BillingDefaultValue.LastName),
        address1: returnOrDefaultString(billingAddress.address1, user?.address1),
        address2: returnOrDefaultString(joinStrings(', ', billingAddress.address2, billingAddress.address3), user?.address2),
        city: returnOrDefaultString(billingAddress.locality, user?.city),
        country: returnOrDefaultString(billingAddress.countryCode, user?.country, this.defaultBillingConfig.country, BillingDefaultValue.Country),
        state: returnOrDefaultString(billingAddress.administrativeArea, user?.stateProvince),
        zip: returnOrDefaultString(billingAddress.postalCode, user?.zipPostal),
        email: returnOrDefaultString(paymentData.email, user?.email),
        phone: returnOrDefaultString(phoneNumberArr[1]?.replace(/\s/g, ''), user?.phone),
        telCode: returnOrDefaultString(phoneNumberArr[0], user?.phoneCountryCode),
      },
    });
    const payload: AuthorizedPaymentInfo = {
      amount: response.amount,
      method: PaymentType.GOOGLE_PAY,
      approval_code: response.approval_code,
      authId: response.paymentReference,
      legacy: {
        authId: response.auth_id,
        paymentMerchantId: getPaymentMerchantIdBy(this.googlePayCards, 'paymentProviderName', this.getPaymentProviderName()),
        paymentMethodCode: paymentConfiguration.paymentMethodCode,
        paymentProviderName: paymentConfiguration.paymentProviderName,
        rawCardBrand: paymentConfiguration.rawCardBrand,
      },
      lastFour: response.card_last_four,
      cardType: response.card_type_code,
    };

    if (validObject(response.donation)) {
      payload.donation = convertDonationResponse(response.donation);
    }

    if (validObject(response?.insurance)) {
      payload.insurance = convertInsuranceResponse(response.insurance);
    }

    [
      updateBillingAddressAction,
      sendAnalyticsEventAction({
        action: 'pay',
        category: 'google pay',
        label: 'payment complete',
        nonInteraction: true,
      }),
      hidePageSpinner({ initiator: 'google pay authorize complete' }),
      gatherPaymentDetailsForGooglePayAction({ payload }),
    ].forEach((action) => store.dispatch(action));
  }

  /**
   * Handles a payment failure from Google Pay
   * @param error The error received from Google
   */
  private initiatePaymentFailure(error: google.payments.api.PaymentsError) {
    this.logger.error(`[GooglePay] initiate payment error: ${JSON.stringify(error)}`);
    const { localeService } = this;
    const actions: Action[] = [hidePageSpinner({ initiator: 'google pay canceled' })];

    const notificationAction: DialogData = {
      buttonLabel: localeService.get('common.close'),
      dialogType: NotificationDialogType.GENERAL,
      initiator: 'Google Pay: payment initiation',
      message: '',
    };

    const paymentFailedAction = sendMessageAction({ key: EVENT_PAYMENT_FAILED, payload: {} });

    const analyticsFailedEvent = sendAnalyticsEventAction({
      action: 'pay',
      category: 'google pay',
      label: 'payment failed',
      nonInteraction: true,
    });

    switch (error.statusCode) {
      case 'DEVELOPER_ERROR':
      case 'MERCHANT_ACCOUNT_ERROR':
      case 'BUYER_ACCOUNT_ERROR':
        notificationAction.message = localeService.get(`googlepay.${toLower(error.statusCode)}`);
        actions.push(paymentFailedAction, analyticsFailedEvent, showNotificationAction(notificationAction), routeTo({ payload: ['select'] }));
        break;
      case 'INTERNAL_ERROR':
        notificationAction.message = localeService.get(`googlepay.${toLower(error.statusCode)}`);
        actions.push(paymentFailedAction, analyticsFailedEvent, showNotificationAction(notificationAction), routeTo({ payload: ['select'] }));
        Bugsnag.notify(new Error(`GooglePay Internal Error`), (event) => {
          event.addMetadata('GooglePay', {
            code: error.statusCode,
            message: error.statusMessage,
          });
        });
        break;
      case 'CANCELED':
        actions.push(
          sendMessageAction({ key: EVENT_PAYMENT_CANCELLED }),
          sendAnalyticsEventAction({
            action: 'pay',
            category: 'google pay',
            label: 'payment canceled',
            nonInteraction: true,
          }),
          routeTo({ payload: ['select'] })
        );
        break;
      default:
        notificationAction.message = localeService.get(`googlepay.unkown_error`);
        actions.push(paymentFailedAction, analyticsFailedEvent, showNotificationAction(notificationAction));

        Bugsnag.notify(new Error(`GooglePay Unknown Error`), (event) => {
          event.addMetadata('GooglePay', {
            code: error.statusCode,
            message: error.statusMessage,
          });
        });
        break;
    }
    actions.forEach((action) => this.store.dispatch(action));
  }

  /**
   * Returns the request for authorizing the stored card from Google Pay
   * @param paymentData The payment data returned from Google Pay's API.
   */
  private getAuthorizeRequest(paymentData: google.payments.api.PaymentData): EWalletAuthorizeRequest {
    const { config } = this.parentConfig;
    const { cartItems, fees } = getLineItemsAndFees(this.parentConfig);
    const { paymentMethodData } = paymentData;
    const { billingAddress } = paymentMethodData.info;
    const name = this.splitFirstAndLastName(billingAddress.name);

    const reqObj: EWalletAuthorizeRequest = {
      CARDHOLDER: {
        f_name: name.first,
        l_name: name.last,
        address: joinStrings(' ', billingAddress.address1, billingAddress.address2, billingAddress.address3),
        city: billingAddress.locality,
        country: billingAddress.countryCode,
        state: billingAddress.administrativeArea,
        zip: billingAddress.postalCode,
        email: paymentData.email,
        phone_number: billingAddress.phoneNumber,
        customer_id: config?.user?.customerId || '',
      },
      EWALLET_INFO: {
        encrypted_payment_data: JSON.stringify(paymentMethodData),
        wallet_type: 'GooglePay',
      },
      amount: this.paymentAmount,
      merchant_id: this.googlePayCards[0].paymentMerchantId,
      ignore_avs: 0,
      ignore_cvv: 0,
      ...(config.recurring && { recurring_type: 'recurring' }),
      recurring_flag: toInt(config.recurring) as 0 | 1,
      cartItems,
      fees,
      sandbox_mode: this.useSandbox,
    };

    if (validString(this.ticketInsuranceQuoteToken)) {
      reqObj.insurance_quote_token = this.ticketInsuranceQuoteToken;
      reqObj.insurance = formatInsuranceRequest(this.insurance);
    }

    if (this.donation.amount > 0) {
      reqObj.donation = {
        donation_amount: this.donation.amount,
        split_donation: this.donation.splitDonation,
      };
    }

    return reqObj;
  }

  /**
   * Turns our credit cards into google pay card networks
   * @param creditCards The cards to convert
   */
  creditCardsToCardNetworks(creditCards: WalletPayment[]): google.payments.api.CardNetwork[] {
    return creditCards
      .filter((card) => validString(card.cardBrandName))
      .map((card): string => card.cardBrandName)
      .map(mapCreditCardToGooglePayNetwork)
      .filter(validString);
  }

  /**
   * Returns the payment request to use with Google Pay
   */
  private getPaymentRequest() {
    return {
      emailRequired: true,
      merchantInfo: this.getMerchantInfo(),
      transactionInfo: this.getTransactionData(),
      ...this.getBaseData(this.googlePayCards),
    };
  }

  private getBaseData(googlePayCards: ConfiguredPayments['googlePayCards']) {
    return {
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: this.getAllowedPaymentMethods(googlePayCards),
      environment: this.useSandbox ? 'TEST' : 'PRODUCTION',
    };
  }

  private getMerchantInfo() {
    return {
      authJwt: this.merchantInfo.jwt,
      merchantId: this.merchantInfo.identifier,
      merchantName: this.merchantName || 'accesso Pay',
      merchantOrigin: this.window.location.hostname,
    };
  }

  private getTransactionData(): google.payments.api.TransactionInfo {
    let amountToPay = this.donation.splitDonation ? this.paymentAmount : this.paymentAmountWithDonation;
    if (!this.insurance.splitInsurance && this.ticketInsuranceQuoteToken) {
      amountToPay = toDecimal(this.insurance.quoteAmount + amountToPay, 2);
    }

    return {
      currencyCode: this.parentConfig.config.currencyCode || 'USD',

      totalPrice: amountToPay.toFixed(2),
      totalPriceStatus: 'FINAL',
    };
  }

  private initializeGooglePay(googlePayCards: ConfiguredPayments['googlePayCards']): Observable<boolean> {
    if (!isObject(get(this.window, 'google.payments.api'))) {
      this.setInitialized(true);
      return toObservable(true);
    }

    this.client = new google.payments.api.PaymentsClient({
      environment: this.useSandbox ? 'TEST' : 'PRODUCTION',
    });

    return from(this.client.isReadyToPay(this.getBaseData(googlePayCards))).pipe(
      map((response: google.payments.api.IsReadyToPayResponse) => {
        this.setInitialized(true);

        if (response.result) {
          this.setAvailability(true);
          this.store.dispatch(initializeGooglePayAction());
        }

        return true;
      })
    );
  }

  /**
   * Returns the allowed payment methods for the google pay request.
   * @param creditCards The cards available to use
   */
  private getAllowedPaymentMethods(creditCards: WalletPayment[]): google.payments.api.IsReadyToPayPaymentMethodSpecification[] {
    return [
      {
        parameters: {
          allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
          allowedCardNetworks: this.creditCardsToCardNetworks(creditCards),
          billingAddressParameters: {
            format: 'FULL',
            phoneNumberRequired: true,
          },
          billingAddressRequired: true,
        },
        tokenizationSpecification: {
          parameters: {
            gateway: normalize(this.getPaymentProviderName()),
            gatewayMerchantId: this.merchantInfo?.vendor,
          },
          type: 'PAYMENT_GATEWAY',
        },
        type: 'CARD',
      },
    ];
  }
}
