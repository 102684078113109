import { createReducer, on } from '@ngrx/store';
import { initialState, PaymentState } from './payment.state';
import { addPaymentAction } from './payment.actions';

const reducer = createReducer(
  initialState,
  on(addPaymentAction, (state, action) => ({
    ...state,
    payments: [...state.payments, action.payment],
  }))
);

export function paymentReducer(state, action): PaymentState {
  return reducer(state, action);
}
