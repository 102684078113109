import { PaymentMethodCode, PaymentProviderType } from 'src/app/core/payment-configuration/payment-configuration.model';
import { createAction, props } from '@ngrx/store';

import { AdyenEnrollmentInfo } from './credit-card/adyen-3ds.service';
import { AdyenPaymentState } from 'src/app/pages/select/alternate-payments/adyen-web-ui/adyen-web-ui.component';
import { AuthorizeRequest } from 'src/app/core/api/responses/authorize';
import { CreateAccessoPaySessionRequest } from 'src/app/core/api/responses/create-accesso-pay-session';
import { Dictionary } from 'src/app/shared/utilities/types.utils';
import { PaymentType } from 'src/app/core/application-bridge/application-bridge.models';

export enum AdyenActionTypes {
  AuthorizeAdyenNon3DSAction = '[Credit Card Screen] Authorize Adyen Non-3DS',
  InitiateAdyen3DSTransaction = '[Credit Card Screen] Initiate Adyen 3DS Transaction',
  InitiateAdyen3DSRedirect = '[Credit Card Screen] Initiate Adyen 3DS Redirect',
  ConfirmAdyen3DSRedirect = '[Credit Card Screen] Confirm Adyen 3DS Redirect',
  PrepareForRedirect = '[Adyen 3DS Service] Prepare for redirect',
  InitializeGiftCardSplitPayment = '[Billing Effects] Initialize Gift Card Split Payment With Adyen 3DS',
  SplitPaymentWithGiftCardSuccess = '[Gift Card Effects] Gift Card Split Payment With Adyen 3DS Success',
  HandleAdyenWebComponentAdditionalDetails = '[Adyen Web Component] Handle Additional Details',
  InitializeGiftCardAdyenWebComponentSplitPayment = '[Billing Effects] Initialize Gift Card Split Payment With Adyen Web Component',
  SplitPaymentWithGiftCardAdyenWebComponentSuccess = '[Gift Card Effects] Gift Card Split Payment With Adyen Web Component Success',
}

/**
 * The action to authorize non 3ds credit card transaction
 */
export const authorizeAdyenNon3dsTransactionAction = createAction(AdyenActionTypes.AuthorizeAdyenNon3DSAction, props<{ payload: AuthorizeRequest }>());

/**
 * The action to intialize Adyen 3DS Redirect
 */
export const initiateAdyen3DSRedirectAction = createAction(
  AdyenActionTypes.InitiateAdyen3DSRedirect,
  props<{
    payload: {
      amount?: number;
      authId: number;
      method: 'GET' | 'POST';
      paymentReference: string;
      url: string;
      paymentMethodCode: PaymentMethodCode;
      redirectParams?: Adyen3DSRedirectParams | Dictionary<string>;
    };
  }>()
);

/**
 * The action to intialize Adyen 3DS transaction
 */
export const initiateAdyen3DSTransactionAction = createAction(
  AdyenActionTypes.InitiateAdyen3DSTransaction,
  props<{
    payload: {
      CARDHOLDER: CardholderInfo;
      CARD: CreditCardInfo;
      paymentType: PaymentMethodCode;
      merchantId: number;
    };
  }>()
);

/**
 * The action to confirm 3ds redirection
 */
export const confirmAdyen3DSRedirectAction = createAction(
  AdyenActionTypes.ConfirmAdyen3DSRedirect,
  props<{
    amount?: number;
    url: string;
    method: string;
    paymentReference: string;
    authId: number;
    paymentMethodCode: PaymentMethodCode;
    redirectParams?: Adyen3DSRedirectParams | Dictionary<string>;
  }>()
);

/**
 * The action to prepare for Adyen 3DS redirection
 */
export const prepareForAdyen3DSRedirectAction = createAction(
  AdyenActionTypes.PrepareForRedirect,
  props<{ payload: { sessionRequest: CreateAccessoPaySessionRequest; callbackData: AdyenEnrollmentInfo } }>()
);

/**
 * The action to intialize gift card split payment with Adyen 3DS transaction
 */
export const initializeGiftCardSplitPaymentWithAdyen3DSAction = createAction(
  AdyenActionTypes.InitializeGiftCardSplitPayment,
  props<{ amount: number; payload: { sessionRequest: CreateAccessoPaySessionRequest; callbackData: AdyenEnrollmentInfo } }>()
);

/**
 * The action to indicate gift card split payment success with Adyen 3DS transaction
 */
export const giftCardSplitPaymentWithAdyen3DSSuccessAction = createAction(
  AdyenActionTypes.SplitPaymentWithGiftCardSuccess,
  props<{ payload: { sessionRequest: CreateAccessoPaySessionRequest; callbackData: AdyenEnrollmentInfo } }>()
);

/**
 * The action to handle Adyen web component's additional details
 */
export const handleAdyenAdditionalDetailsAction = createAction(
  AdyenActionTypes.HandleAdyenWebComponentAdditionalDetails,
  props<{
    payload: {
      additionalData: AdyenPaymentState;
      paymentMethod: PaymentType;
      paymentData: {
        paymentMerchantId: number;
        paymentMethodCode: PaymentMethodCode;
        authId: number;
        paymentReference: string;
        paymentProviderName: PaymentProviderType;
        rawCardBrand: string;
      };
    };
  }>()
);

/**
 * The action to intialize gift card split payment with Adyen Web Components transaction
 */
export const initializeGiftCardSplitPaymentWithAdyenWebComponentAction = createAction(
  AdyenActionTypes.InitializeGiftCardAdyenWebComponentSplitPayment,
  props<{
    amount: number;
    payload: {
      additionalData: AdyenPaymentState;
      paymentData: {
        paymentMerchantId: number;
        paymentMethodCode: PaymentMethodCode;
        authId: number;
        paymentReference: string;
        paymentProviderName: PaymentProviderType;
        rawCardBrand: string;
      };
    };
  }>()
);

/**
 * The action to indicate gift card split payment success with Adyen Wen Component transaction
 */
export const giftCardSplitPaymentWithAdyenWebComponentSuccessAction = createAction(
  AdyenActionTypes.SplitPaymentWithGiftCardAdyenWebComponentSuccess,
  props<{
    payload: {
      additionalData: AdyenPaymentState;
      paymentData: {
        paymentMerchantId: number;
        paymentMethodCode: PaymentMethodCode;
        authId: number;
        paymentReference: string;
        paymentProviderName: PaymentProviderType;
        rawCardBrand: string;
      };
    };
  }>()
);
