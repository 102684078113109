import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { AmazonPayEffects } from './amazon-pay.effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, EffectsModule.forFeature([AmazonPayEffects])],
})
export class AmazonPayModule {}
