import { createFeatureSelector, createSelector } from '@ngrx/store';
import { validString } from 'guest-app-ui';
import { selectAppConfig } from 'src/app/core/application-config/application-config.selectors';
import { selectHasCartItems, selectIsComplimentaryOrder, selectRecurring } from 'src/app/core/parent-config/parent-config.selectors';
import { APP_CONFIG_UPLIFT } from 'src/app/shared/enums/application-config.enum';
import { selectBillingState } from '../billing/billing.selectors';
import { PaymentOptionsState } from './payment-options.state';

/**
 * Selects the payment options state
 * @param state The application state
 * @returns The payment options state
 */
export const selectPaymentOptionsState = createFeatureSelector<PaymentOptionsState>('paymentOptions');

/**
 * Selects the state to determine if payment options are configured
 */
export const selectShowPaymentOptions = createSelector(selectPaymentOptionsState, (state: PaymentOptionsState): boolean => state.canDisplayPaymentOptions);

/**
 * Selects the state to determine if uplift configured
 */
export const selectIsUpliftEnabled = createSelector(
  selectHasCartItems,
  selectAppConfig([APP_CONFIG_UPLIFT]),
  selectBillingState,
  (hasCartItems, { uplift = {} }, billing): boolean => hasCartItems && billing.uplift && validString(uplift.apiKey) && validString(uplift.upliftId)
);

/**
 * Selects the state to determine if payment options page display
 */
export const selectShowUplift = createSelector(
  selectIsComplimentaryOrder,
  selectRecurring,
  selectIsUpliftEnabled,
  (isComplimentaryOrder, isRecurring, showUplift): boolean => showUplift && !isComplimentaryOrder && !isRecurring
);
