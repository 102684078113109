import { isString, toLower } from 'lodash-es';
import { validString } from './types.utils';

/**
 * Uses just alphanumeric characters.
 * @param str The string to use
 */
export function normalize(str: string): string {
  if (isString(str)) {
    return toLower(str.replace(/[^\w\d]/g, ''));
  }
  return '';
}

/**
 * Joins multiple strings by a separator
 * @param separator The separator to join them by
 * @param args An array of strings. The last property is the separator.
 */
export function joinStrings(separator: string, ...args: string[]): string {
  return args.filter(validString).join(separator);
}

/**
 * Returns a valid string from the passed in values, if no valid string is found return the default value
 * @param params The values to test.
 */
export function returnOrDefaultString(...params: string[]): string {
  return params.find(validString) || '';
}

/**
 * This function transforms a string to array
 * @param stringToConvert The string that will be convert to array
 * @param delimiter Value used to split string.
 * @returns Array of strings
 */
export const stringToArray = (stringToConvert: string, delimiter: string): string[] => {
  if (validString(stringToConvert) && isString(delimiter)) {
    return stringToConvert.split(delimiter);
  }
  return [];
};

/**
 * This function resolves a placeholder in a string with a value
 * @param stringToReplace The string that has a placeholder to be resolved
 * @param placeholder Value used to split string.
 * @param value Value used to replace the placeholder.
 * @returns A new string
 */
export const replaceString = (stringToReplace: string, placeholder: string, value: string): string => {
  if ([stringToReplace, placeholder, value].every((str) => validString(str) && isString(str))) {
    return stringToReplace.replace(placeholder, value);
  }
  return stringToReplace;
};

/**
 * Removes invalid characters and extras spaces from input string.
 * Only allow letters, dashes, apostrophes, and dots
 * @param dirtyStr
 * @returns sanitized string
 */
export const sanitizeNameInput = (dirtyStr: string): string => {
  if (validString(dirtyStr)) {
    const cleanString = removeHTMLEntities(dirtyStr).replace(/[^a-zA-Z\._'\-\s]/gi, '');
    return removeExtraWhitespace(cleanString);
  } else {
    return '';
  }
};

/**
 * Remove extra white spaces from string. Keep single spaces.
 * @param text
 * @returns string
 */
export const removeExtraWhitespace = (text: string): string => {
  return text.replace(/\s{2,}/g, ' ').trim();
};

/**
 * Remove HTML character references from string
 * @param text
 * @returns new string
 */
export const removeHTMLEntities = (text: string): string => {
  const entities = ['amp', 'apos', '#[a-zA-Z0-9]', 'lt', 'gt', 'nbsp', 'quot'];
  return entities.reduce((string, pattern) => string.replace(new RegExp(`(&|%)${pattern}(.*?);{1,}`, 'g'), ''), text);
};
