import { Inject, Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isArray, isObject, isString } from 'lodash-es';
import { filter, map } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import {
  EVENT_APPLEPAY_CHECK,
  EVENT_APPLEPAY_ON_AVAILABLE,
  EVENT_APPLEPAY_VALIDATE_MERCHANT,
  EVENT_CANCEL,
  EVENT_INITIALIZED,
  EVENT_PAYMENT_COMPLETE,
  EVENT_ROUTE_CHANGE,
  EVENT_SCROLL_TO_TOP,
  EVENT_SET_HEIGHT,
} from 'src/app/shared/enums/application-bridge.enums';
import { standardizeCreditCardType } from 'src/app/shared/utilities/credit-cards.utils';
import { hasModal } from 'src/app/shared/utilities/modal.utils';
import { Dictionary, validString } from 'src/app/shared/utilities/types.utils';
import { objectToQueryParams } from 'src/app/shared/utilities/url.utils';
import { IS_IN_IFRAME } from 'src/app/shared/utilities/window.utils';
import { WINDOW } from '../injection-token/window/window';
import { AcLoggerService } from '../logger/logger.service';
import { setParentConfig, setParentConfigErrorAction } from '../parent-config/parent-config.actions';
import { selectParentConfig, selectParentSessionId } from '../parent-config/parent-config.selectors';
import { ParentConfig } from '../parent-config/parent-config.state';
import { getConfigErrors, isValidConfig } from '../parent-config/parent-config.utils';
import { routeToWithOptions, routeToWithOptionsInZone } from '../routing/routing.actions';
import { TemporaryLoggerService } from '../temporary-logger/temporary-logger.service';
import { receiveMessageAction, sendMessageAction } from './application-bridge.actions';
import { PaymentType, PostMessage } from './application-bridge.models';
import { TYPE_INITIALIZE } from './message-types';

export const MESSAGE_PREFIX = 'accessoPay::';

const BOOTSTRAP_MESSAGES = '__ACESSOPAY_INITIALIZE_VARS__';

const IGNORE_TEMP_LOGGER_KEYS = [
  EVENT_ROUTE_CHANGE,
  EVENT_APPLEPAY_CHECK,
  EVENT_APPLEPAY_ON_AVAILABLE,
  EVENT_APPLEPAY_VALIDATE_MERCHANT,
  EVENT_SCROLL_TO_TOP,
  EVENT_INITIALIZED,
  EVENT_SET_HEIGHT,
];

/**
 * The application bridge is responsible for communicating with the parent application. This can be either a native
 * application or it could be the parent iframe. Sending requests to this effect will handle targeting the correct
 * way to communicate with them.
 */
@Injectable()
export class ApplicationBridgeEffects {
  /**
   * If the parent application is an iOS device.
   */
  private isIOS = false;
  /**
   * If the parent application is an Android device.
   */
  private isAndroid = false;
  /**
   * The backend service's session ID.
   */
  private sessionId: string;
  /**
   * The URL to postMessage back to
   */
  private postMessageUrl = '*';
  /**
   * The URL to redirect to after payment complete / cancel
   */
  private redirectUrl: string;

  /**
   * Handles sending messages to the correct parent application.
   */
  sendMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sendMessageAction),
        map((action) => {
          const { isIOS, isAndroid } = this;
          const message = {
            type: isIOS || isAndroid ? action.key : getType(action.key),
            payload: this.transformPayload(action.key, action.payload),
            accessoPaySessionId: this.sessionId,
          };

          if (validString(this.redirectUrl) && [EVENT_PAYMENT_COMPLETE, EVENT_CANCEL].includes(action.key)) {
            if (action.key === EVENT_PAYMENT_COMPLETE) {
              if (action.payload.method === PaymentType.APPLE_PAY) {
                this.store.dispatch(
                  routeToWithOptionsInZone({ routeParams: ['payment-complete'], options: { queryParams: { authId: action.payload.authId } } })
                );
              } else {
                this.store.dispatch(routeToWithOptions({ routeParams: ['payment-complete'], options: { queryParams: { authId: action.payload.authId } } }));
              }
            } else if (action.key === EVENT_CANCEL) {
              const queryParams = objectToQueryParams({
                action: action.key,
              });
              this.window.open(`${this.redirectUrl}?${queryParams}`, '_self');
            }
          } else {
            Bugsnag.leaveBreadcrumb(`sent message type: ${message.type}`);
            this.sendTempLog(action.key, 'send message action', `message type: ${message.type}`);
            this.window.parent.postMessage(message, this.postMessageUrl);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    @Inject(WINDOW) private window: Window,
    private store: Store<AppState>,
    private actions$: Actions,
    private logger: AcLoggerService,
    private tempLogger: TemporaryLoggerService
  ) {
    store
      .select(selectParentConfig)
      .pipe(filter((parentConfig): boolean => validString(parentConfig.config.paymentStatusBaseUrl)))
      .subscribe((parentConfig) => {
        this.redirectUrl = parentConfig.config?.paymentStatusBaseUrl;
      });

    store.select(selectParentSessionId).subscribe((sessionId) => (this.sessionId = sessionId));

    if (IS_IN_IFRAME) {
      this.window.addEventListener('message', (event) => {
        this.parseMessageEvent(event);
      });

      // Handles setting the height of the iframe so they don't have to scroll inside of it and will instead scroll the page.
      setInterval(() => {
        if (this.canUpdateHeight()) {
          const { clientHeight, scrollTop } = window.document.body;
          this.window.parent.postMessage(
            {
              type: getType(EVENT_SET_HEIGHT),
              payload: clientHeight + scrollTop,
            },
            this.postMessageUrl
          );
        }
      }, 200);
    }

    if (isArray(window[BOOTSTRAP_MESSAGES])) {
      window[BOOTSTRAP_MESSAGES].forEach((data) => {
        this.parseMessageEvent({ data } as MessageEvent);
      });
      window[BOOTSTRAP_MESSAGES] = null;
    }
  }

  /**
   * Parses messages received from the parent application. We only deal with ones that are prefixed
   * with our specified prefix.
   * @param event The event to parse
   */
  parseMessageEvent(event: MessageEvent): void {
    const data: PostMessage = event.data;

    if (!isObject(data) || !isString(data.type) || !data.type.includes(MESSAGE_PREFIX)) {
      return;
    }

    this.logger.warn('[Application Bridge] Message Received', data);

    if (data.type === getType(TYPE_INITIALIZE)) {
      Bugsnag.leaveBreadcrumb('Received parent configs');

      const parentConfigs = data.payload as ParentConfig;
      const { nativeSessionId = '' } = data;
      const sessionId = validString(nativeSessionId) ? nativeSessionId : validString(parentConfigs?.sessionId) ? parentConfigs.sessionId : '';

      if (isValidConfig(parentConfigs)) {
        this.store.dispatch(setParentConfig({ payload: parentConfigs, sessionId }));
      } else {
        const errorCode = getConfigErrors(parentConfigs);
        this.store.dispatch(setParentConfigErrorAction({ payload: parentConfigs, sessionId, errorCode }));
      }

      return;
    }

    this.store.dispatch(receiveMessageAction({ key: data.type.slice(MESSAGE_PREFIX.length), message: data }));
  }

  /**
   * Modifies the payload prior to sending it.
   * @param key The event key
   * @param payload The payload for the event
   */
  transformPayload(key: string, payload: Dictionary<any>): Dictionary<any> {
    switch (key) {
      case EVENT_PAYMENT_COMPLETE:
        if (validString(payload.cardType)) {
          return {
            ...payload,
            cardType: standardizeCreditCardType(payload.cardType),
          };
        }
        return payload;
      default:
        return payload;
    }
  }

  /**
   * Logs to our message event to the temp logger
   * @param key The message key
   * @param title The message title
   * @param message the message
   */
  private sendTempLog(key: string, title: string, message: string): void {
    if (IGNORE_TEMP_LOGGER_KEYS.includes(key)) {
      return;
    }
    this.tempLogger.log(title, message);
  }

  private canUpdateHeight(): boolean {
    return !hasModal();
  }
}

/**
 * Returns the prefixed event type
 * @param eventType The type of event
 * @returns The prefixed event type
 */
export function getType(eventType): string {
  return `${MESSAGE_PREFIX}${eventType}`;
}
