import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { environment } from 'src/environments/environment';
import { selectEnvironmentInfo, selectEnvironmentBuild } from '../../environment/environment.selectors';

@Injectable()
export class EnvironmentInfoInterceptor implements HttpInterceptor {
  private environmentInfo;
  private version = environment.appVersion ?? '';

  /**
   * load the environment select info after we get the ipAdress value
   * @param store The global store
   */
  constructor(private store: Store<AppState>) {
    this.store
      .select(createSelector(selectEnvironmentInfo, selectEnvironmentBuild, (enviromentInfo, buildNum) => ({ enviromentInfo, buildNum })))
      .subscribe(({ enviromentInfo, buildNum }) => {
        this.environmentInfo = enviromentInfo;
        this.version = buildNum;
      });
  }

  /**
   * Intercepts HTTP communication and and filter the post request to add the environment info
   * @param req The HTTP request object
   * @param next The HTTP handler
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method.toLowerCase() === 'post') {
      const params = (req.params ? req.params : new HttpParams()).set('v', this.version);
      req = req.clone({
        params,
        body: { ...req.body, environmentInfo: this.environmentInfo },
      });
    }

    return next.handle(req);
  }
}
