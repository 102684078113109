import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GiftCardEffects } from './gift-card.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { giftCardReducer } from './gift-card.reducer';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('giftCard', giftCardReducer), EffectsModule.forFeature([GiftCardEffects])],
})
export class GiftCardModule {}
