import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/app.state';
import { validString } from 'src/app/shared/utilities/types.utils';
import { DeviceInfo } from './environment.models';
import { EnvironmentState } from './environment.state';
import { EnvironmentInfo } from '../api/api.models';

const APP_VERSION = '2';

/**
 * Selects the environment state
 * @param state The application state
 * @returns The environment state
 */
export const selectEnvironment = (state: AppState): EnvironmentState => state.environment;

/**
 * Selects the gateway url from the environment state.
 * @returns The gateway URL
 */
export const selectGatewayUrl = createSelector(selectEnvironment, (state: EnvironmentState): string => state.gatewayUrl);

/**
 * Selects isProduction values from the environment state.
 */
export const selectIsProduction = createSelector(selectEnvironment, (state: EnvironmentState): boolean => !!state.isProduction);

/**
 * Selects the user ip address from the environment state.
 * @returns The ip address
 */
export const selectUserIp = createSelector(selectEnvironment, (state: EnvironmentState): string => state.ipAddress);

/**
 * Selects the user device information from the environment state.
 * @returns The device information
 */
export const selectDeviceInfo = createSelector(selectEnvironment, (state: EnvironmentState): DeviceInfo => state.device);

/**
 * Selects is mobile device state
 * @returns The is mobile device state
 */
export const selectIsMobile = createSelector(selectDeviceInfo, (deviceInfo: DeviceInfo): boolean => deviceInfo.isMobile || deviceInfo.isTablet);

/**
 * Selects the user ip address from the environment state.
 * @returns The ip address
 */
export const selectIpAddress = createSelector(selectEnvironment, (state: EnvironmentState): string => state.ipAddress);

/**
 * Selects browser/device info
 * @returns The browser info from state
 */
export const selectEnvironmentInfo = createSelector(selectEnvironment, ({ ipAddress, device }: EnvironmentState): EnvironmentInfo => {
  const timezoneOffset = new Date().getTimezoneOffset();
  const { os, os_version, browser, browser_version } = device.browser;

  return {
    deviceType: device.type,
    deviceOS: os,
    deviceChannel: 'Browser',
    deviceOSVersion: os_version,
    browserName: browser,
    browserVersion: browser_version,
    browserJavaEnabled: device.javaEnabled,
    browserJavaScriptEnabled: true,
    browserLanguage: device.language,
    browserColorDepth: device.colorDepth,
    browserScreenHeight: device.screenHeight,
    browserScreenWidth: device.screenWidth,
    browserTimeZone: device.timeZone,
    timezoneOffset: timezoneOffset,
    acceptHeader: '*/*',
    userAccept: '*/*',
    userAgent: device.agent,
    ipAddress,
  };
});

/**
 * Selects Application build number
 */
export const selectEnvironmentBuild = createSelector(selectEnvironment, ({ build }: EnvironmentState) => {
  return validString(build) && build.split('.').length > 1 ? build : APP_VERSION;
});
