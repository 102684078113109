import { AnalyticsModule } from './analytics/analytics.module';
import { ApiModule } from './api/api.module';
import { ApplicationBridgeModule } from './application-bridge/application-bridge.module';
import { ApplicationConfigModule } from './application-config/application-config.module';
import { ClientConfigurationModule } from './client-configuration/client-configuration.module';
import { CommonModule } from '@angular/common';
import { EnvironmentModule } from './environment/environment.module';
import { LocaleModule } from './locale/locale.module';
import { PaymentConfigurationModule } from './payment-configuration/payment-configuration.module';
import { NgModule } from '@angular/core';
import { NotificationModule } from './notification/notification.module';
import { PageSpinnerModule } from './page-spinner/page-spinner.module';
import { ParentConfigModule } from './parent-config/parent-config.module';
import { RoutingModule } from './routing/routing.module';
import { SessionModule } from './session/session.module';
import { StaticDataModule } from './static-data/static-data.module';
import { InjectionTokenModule } from './injection-token/injection-token.module';

@NgModule({
  declarations: [],
  imports: [
    InjectionTokenModule,
    AnalyticsModule,
    ApiModule,
    ApplicationConfigModule,
    ClientConfigurationModule,
    CommonModule,
    EnvironmentModule,
    StaticDataModule,
    LocaleModule,
    PaymentConfigurationModule,
    PageSpinnerModule,
    RoutingModule,
    SessionModule,
    ApplicationBridgeModule,
    ParentConfigModule,
    NotificationModule,
  ],
})
export class CoreModule {}
