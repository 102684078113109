import { isString, padStart, toString } from 'lodash-es';
import { toInt } from './number.utils';

/**
 * Returns how old someone is based on today
 * @param date The date to check against
 * @param baseDate The date to judge against
 */
export function calculateAge(date: Date, baseDate: Date = new Date()): number {
  const selectedDate = new Date(date.getTime());
  selectedDate.setHours(12, 0, 0, 0);

  baseDate.setHours(12, 0, 0, 0);

  const ageDate = new Date(baseDate.getTime() - selectedDate.getTime());

  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

/**
 * Returns a Date object from a string that's in the format YYYY-MM-DD
 * @param dateInput The string to parse. Must be in format YYYY-MM-DD
 */
export function getDateFromInput(dateInput: string | Date): Date | null {
  if (dateInput instanceof Date) {
    const newDate = new Date(dateInput.getTime());
    newDate.setHours(12, 0, 0, 0);
    return newDate;
  }

  if (!isString(dateInput)) {
    return null;
  }

  const dateParts = dateInput.split('-');

  if (dateParts.length !== 3) {
    return null;
  }
  const [year, month, day] = dateParts;
  return new Date(toInt(year), toInt(month) - 1, toInt(day), 12, 0, 0, 0);
}

/**
 * Converts date to string
 * @param date date object
 */
export function dateToString(date: Date): string {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${year}-${padStart(toString(month), 2, '0')}-${padStart(toString(day), 2, '0')}`;
}

/**
 * Returns current date
 */
export function getDate(): Date {
  return new Date();
}
