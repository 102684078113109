import { createAction, props } from '@ngrx/store';

export enum WebExperimentActions {
  LoadOptimizelyFeatureController = '[Web Experiment Service] Load Optimizely Feature Controller',
}

/**
 * Load Optimizely experimental controls
 */
export const loadOptimizelyFeatureControllerAction = createAction(
  WebExperimentActions.LoadOptimizelyFeatureController,
  props<{ payload: { [key: string]: boolean } }>()
);
