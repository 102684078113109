import { AnalyticsDirective } from './analytics.directive';
import { AnalyticsEffects } from './analytics.effects';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [AnalyticsDirective],
  exports: [AnalyticsDirective],
  imports: [EffectsModule.forFeature([AnalyticsEffects])],
})
export class AnalyticsModule {}
