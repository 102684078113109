import { Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { map, switchMap, tap } from 'rxjs/operators';
import { EVENT_INITIALIZE_FAILED, EVENT_INITIALIZED } from 'src/app/shared/enums/application-bridge.enums';
import { validString } from 'src/app/shared/utilities/types.utils';
import { sendMessageAction } from '../application-bridge/application-bridge.actions';
import { fullRouteTo, routeTo } from '../routing/routing.actions';
import { initializeFailedAction, initializeFinishedAction } from './initialize.actions';

@Injectable()
export class InitializeEffects {
  /**
   * Triggered when initialization is completed
   */
  onInitialized$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initializeFinishedAction),
      tap(() => {
        Bugsnag.leaveBreadcrumb('App loaded');
      }),
      map(() =>
        sendMessageAction({
          key: EVENT_INITIALIZED,
        })
      )
    )
  );

  /**
   * Triggered when initialization failed
   */
  onInitializeFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initializeFailedAction),
      tap(({ errorCode, reason, metaData = {} }) => {
        try {
          Bugsnag.notify(new Error(`Failed to initialize`), (event) => {
            event.addMetadata('Initialize Error', {
              ...metaData,
              errorCode,
              reason,
            });
          });
        } catch (ignore) {}
      }),
      switchMap(({ errorCode, reason, redirectTo = [] }) => {
        const actions: Action[] = [
          sendMessageAction({
            key: EVENT_INITIALIZE_FAILED,
            payload: { errorCode, reason },
          }),
        ];

        const path = redirectTo.length && redirectTo.filter(validString);
        const replaceFullPath = !path || ['invalid', 'not-found'].some((errorPage) => path.includes(errorPage));

        if (replaceFullPath) {
          actions.push(fullRouteTo({ payload: !path ? ['invalid'] : path }));
        } else {
          actions.push(routeTo({ payload: path }));
        }
        return actions;
      })
    )
  );

  /**
   * The constructor
   * @param actions$ Our actions stream
   */
  constructor(private actions$: Actions) {}
}
