import { PageSpinnerState, initialPageSpinnerState } from './page-spinner.state';

import { Action } from '@ngrx/store';
import { InitializeActionTypes } from '../initialize/initialize.actions';
import { PageSpinnerActions } from './page-spinner.actions';

export function pageSpinnerReducer(state: PageSpinnerState = initialPageSpinnerState, action: Action): PageSpinnerState {
  const ngrxAction = '@ngrx/router-store';
  switch (action.type) {
    case PageSpinnerActions.Show:
    case `${ngrxAction}/request`:
    case InitializeActionTypes.InitializeStart:
      return { show: true };
    case PageSpinnerActions.Hide:
    case `${ngrxAction}/cancel`:
    case `${ngrxAction}/error`:
    case `${ngrxAction}/navigated`:
      return { show: false };
    default:
      return state;
  }
}
