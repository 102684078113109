import { AbstractControl, ValidatorFn } from '@angular/forms';
import { DynamicFormValidation } from 'src/app/shared/utilities/form.validation.util';

import { validString } from 'src/app/shared/utilities/types.utils';

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): { email: boolean } | null => {
    if (!validString(control.value)) {
      return null;
    }

    if (new RegExp(DynamicFormValidation.getEmail()).test(control.value)) {
      return null;
    }

    return {
      email: true,
    };
  };
}
