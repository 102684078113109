/**
 * The possible payment provider types
 */
export enum PaymentProviderType {
  Adyen = 'Adyen',
  AmazonPay = 'AmazonPay',
  Cybersource = 'Cybersource',
  FreedomPay = 'FreedomPay',
  KakaoPay = 'KakaoPay',
  LinePay = 'LinePay',
  Paypal = 'PayPal',
  StoredValue = 'StoredValue',
  TmbThanachart = 'TMBThanachart Bank',
  Tesco = 'Tesco',
}

/**
 * Payment method codes supported
 */
export enum PaymentMethodCode {
  ADYEN_MOBILE_PAY = 'MPY',
  AFTERPAY = 'AFT',
  ALIPAY = 'ALI',
  ALIPAY_HK = 'ALH',
  AMAZON_PAY = 'AZP',
  APPLE_PAY = 'APL',
  BANCONTACT_DESKTOP = 'BCMC',
  BANCONTACT_MOBILE = 'MRM',
  CREDIT_CARD = 'CCD',
  GIROPAY = 'GPY',
  GRABPAY = 'GRP',
  GOOGLE_PAY = 'GGL',
  IDEAL = 'IDL',
  KAKAO_PAY = 'KAK',
  KOREA_CYBER_PAYMENTS = 'KCP',
  KLARNA_PAY_NOW = 'KPN',
  KLARNA_PAY_OVER_TIME = 'KPT',
  LINE_PAY = 'LNP',
  NAVER_PAY = 'NVP',
  MB_WAY = 'MBW',
  PAYPAL = 'PPA',
  SEPADD = 'SDD',
  SOFORT = 'SFT',
  TTB_QR_CODE = 'TTB',
  SVC = 'SVC',
  UPLIFT = 'UPL',
  WECHAT_PAY = 'WCP',
  TESCO = 'TCO',
}

/**
 * The Payment method partial
 */
export interface PaymentMethodPartial {
  id: number;
  paymentMethodName: string;
  paymentProviderName: PaymentProviderType;
  paymentMethodCode: PaymentMethodCode;
  paymentMerchantId: number;
  merchantId: number;
  cardBrandName: string;
  cardBrandCode: string;
  enabled3ds: boolean;
  ignoreAvs: boolean;
  ignoreCvv: boolean;
  rawCardBrand: string;
  active: boolean;
  displayOrder: number;
  cardImageFileName: string;
  paymentConfigId: number;
}

/**
 * Credit card model
 */
export interface CreditCard extends PaymentMethodPartial {
  paymentMethodCode: PaymentMethodCode.CREDIT_CARD;
}

/**
 * Wallet Payment model
 */
export interface WalletPayment extends PaymentMethodPartial {
  paymentMethodCode: PaymentMethodCode.APPLE_PAY | PaymentMethodCode.GOOGLE_PAY;
}

/**
 * Alternate Payments model
 */
export interface AlternatePayment extends PaymentMethodPartial {
  paymentMethodCode:
    | PaymentMethodCode.ADYEN_MOBILE_PAY
    | PaymentMethodCode.AFTERPAY
    | PaymentMethodCode.ALIPAY
    | PaymentMethodCode.ALIPAY_HK
    | PaymentMethodCode.AMAZON_PAY
    | PaymentMethodCode.BANCONTACT_MOBILE
    | PaymentMethodCode.BANCONTACT_DESKTOP
    | PaymentMethodCode.GIROPAY
    | PaymentMethodCode.GRABPAY
    | PaymentMethodCode.IDEAL
    | PaymentMethodCode.KAKAO_PAY
    | PaymentMethodCode.KOREA_CYBER_PAYMENTS
    | PaymentMethodCode.LINE_PAY
    | PaymentMethodCode.MB_WAY
    | PaymentMethodCode.NAVER_PAY
    | PaymentMethodCode.PAYPAL
    | PaymentMethodCode.SEPADD
    | PaymentMethodCode.SOFORT
    | PaymentMethodCode.TTB_QR_CODE
    | PaymentMethodCode.UPLIFT
    | PaymentMethodCode.WECHAT_PAY;
}

/**
 * Gift Card model
 */
export interface GiftCardPayment extends PaymentMethodPartial {
  paymentMethodCode: PaymentMethodCode.SVC;
}

export interface ConfiguredPayments {
  /**
   * The apple pay credit cards.
   */
  applePayCards: WalletPayment[];
  /**
   * All available credit cards.
   */
  creditCards: CreditCard[];
  /**
   * All google pay credit cards.
   */
  googlePayCards: WalletPayment[];
  /**
   * All available payment types.
   */
  alternatePayments: AlternatePayment[];
  /**
   * Gift Card payment
   */
  giftCard: GiftCardPayment;
}
