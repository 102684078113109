import { createAction, props } from '@ngrx/store';

export enum PageSpinnerActions {
  Show = '[Page Spinner] Show',
  Hide = '[Page Spinner] Hide',
}

export const showPageSpinner = createAction(PageSpinnerActions.Show, props<{ initiator?: string }>());

export const hidePageSpinner = createAction(PageSpinnerActions.Hide, props<{ initiator?: string }>());
