import { createAction, props } from '@ngrx/store';
import { PaymentMethod } from '../api/responses/get-payment-configuration';
import { ConfiguredPayments } from './payment-configuration.model';

export enum PaymentConfigurationActionTypes {
  ClearSelectedPaymentMethod = '[Payment Selection] Clear Selected Payment Method',
  UpdatePaymentConfiguration = '[Payment Configuration Response] Update Payment Configuration',
  LoadPaymentConfiguration = '[Payment Configuration Effect ] Load Payment Configuration',
  UpdatePaymentConfigurationWithConfiguredPayments = '[Payment Configuration Effects] Update Payment Configuration',
  UpdateConfiguredPayments = '[Parent Config Effects] Update Configured Payments',
  SetSelectedPaymentMethod = '[Payment Selection] Set Selected Payment Method',
  VerifySessionToken = '[Payment Config Effects] Verify Session Token',
}

/**
 * The action to update payment configuration with selected payment
 */
export const selectPaymentMethodAction = createAction(PaymentConfigurationActionTypes.SetSelectedPaymentMethod, props<{ code: string }>());

/**
 * The action to clear selected payment
 */
export const clearPaymentMethodAction = createAction(PaymentConfigurationActionTypes.ClearSelectedPaymentMethod);

/**
 * Updates payment configuration from payment configuration response
 */
export const updatePaymentConfigurationAction = createAction(
  PaymentConfigurationActionTypes.UpdatePaymentConfiguration,
  props<{ configuredPayments: ConfiguredPayments }>()
);
/**
 * Updates payment configuration from payment configuration response
 */
export const loadPaymentConfigurationAction = createAction(
  PaymentConfigurationActionTypes.LoadPaymentConfiguration,
  props<{ paymentConfigResponse: PaymentMethod[] }>()
);

/**
 * Update configured payments with configured payments
 */
export const updatePaymentConfigurationWithConfiguredPaymentsAction = createAction(
  PaymentConfigurationActionTypes.UpdatePaymentConfigurationWithConfiguredPayments,
  props<{ configuredPayments: ConfiguredPayments }>()
);

/**
 * Updates configured payments from config set through parent config effects
 */
export const updateConfiguredPaymentsAction = createAction(PaymentConfigurationActionTypes.UpdateConfiguredPayments);
