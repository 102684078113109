import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InitializeState } from './initialize.state';

/**
 * Selects the initialize state
 * @param state The initialize state
 * @returns The initialize state
 */
export const selectInitializeState = createFeatureSelector<InitializeState>('initialize');

/**
 * Selects the boolean for if we're initialized or not.
 * @returns boolean
 */
export const selectIsInitialized = createSelector(selectInitializeState, (state: InitializeState): boolean => state.initialized);
