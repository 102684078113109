import { normalize, sanitizeNameInput } from 'src/app/shared/utilities/string.utils';
import { validString } from 'src/app/shared/utilities/types.utils';

/**
 * Map card to network
 * @param name
 * @returns
 */
export function mapCreditCardToGooglePayNetwork(name: string): google.payments.api.CardNetwork {
  switch (normalize(name)) {
    case 'americanexpress':
    case 'amex':
      return 'AMEX';
    case 'discovercard':
    case 'discover':
      return 'DISCOVER';
    case 'jcb':
      return 'JCB';
    case 'mastercard':
      return 'MASTERCARD';
    case 'visa':
      return 'VISA';
    case 'interac':
      return 'INTERAC';
    default:
  }
}

/**
 * Returns the name of the accesso card payment based on google pay's network.
 * @param googlePayNetwork googlepay card
 * @throws {Error} when card not supported
 * @returns accesso card name
 */
export function mapGooglePayNetworkToAccessoPaymentTypeName(googlePayNetwork: google.payments.api.CardNetwork): string {
  if (!validString(googlePayNetwork)) {
    return '';
  }

  switch (normalize(googlePayNetwork)) {
    case 'amex':
      return 'AMERICAN EXPRESS';
    case 'visa':
      return 'VISA';
    case 'discover':
      return 'DISCOVER';
    case 'jcb':
      return 'JCB';
    case 'mastercard':
      return 'MASTERCARD';
    case 'interac':
      return 'INTERAC';
    default:
      throw new Error(`unable to map google pay to accesso card type ${googlePayNetwork}`);
  }
}

/**
 * Sanitize certain fields for Google Pay event
 * @param paymentData
 * @returns
 */
export function sanitizeGooglePayPaymentData(paymentData: google.payments.api.PaymentData): google.payments.api.PaymentData {
  return {
    ...paymentData,
    paymentMethodData: {
      ...paymentData.paymentMethodData,
      info: {
        ...paymentData.paymentMethodData.info,
        billingAddress: {
          ...paymentData.paymentMethodData.info.billingAddress,
          name: sanitizeNameInput(paymentData.paymentMethodData?.info?.billingAddress.name ?? ''),
        },
      },
    },
  };
}
