import { HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { get, toString } from 'lodash-es';
import { Dictionary } from 'src/app/shared/utilities/types.utils';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import { returnOrDefaultString } from 'src/app/shared/utilities/string.utils';
import { validObject } from 'src/app/shared/utilities/types.utils';
import { ApiResponse, ApiStatus, Response } from './api.interface';

/**
 * The API action interface
 */
interface ApiAction {
  /**
   * The request type being made or receiving.
   */
  requestType: string;
}

/**
 * Used as a pipe when sending an api request to catch any sort of errors that may happen.
 */
export function catchApiError<T>(): (err?: any) => Observable<ApiResponse<T>> {
  return catchError((errorObj) => {
    if (errorObj instanceof HttpErrorResponse && validObject(errorObj.error)) {
      const { error } = errorObj;
      return of({
        status: ApiStatus.FAILED,
        detail: returnOrDefaultString(error?.detail, error?.error_msg, error?.message),
        title: returnOrDefaultString(error?.title, error?.error_code, error?.error),
      } as ApiResponse<T>);
    }
    return of({
      status: ApiStatus.FAILED,
      error_msg: toString(errorObj),
    } as ApiResponse<T>);
  });
}

/**
 * Maps ApiResponse<T> to Response<T>
 * @param response api response
 */
export function mapResponse<T>(response: ApiResponse<T>): Response<T> {
  if (!get(response, 'status')) {
    return { response, status: ApiStatus.OK };
  }
  const { status, ...res } = response;
  return { response: res as T, status };
}

/**
 * Returns the HttpHeaders
 * @param headersToAppend headers to append
 */
export function appendHeaders(headersToAppend: Dictionary<any> = {}): HttpHeaders {
  let headers = new HttpHeaders();
  Object.keys(headersToAppend).forEach((key) => {
    headers = headers.append(key, headersToAppend[key]);
  });
  return headers;
}

/**
 * Returns the HttpParams
 * @param paramsToAppend params to append
 */
export function appendParams(paramsToAppend: Dictionary<any> = {}): HttpParams {
  let params = new HttpParams();
  Object.keys(paramsToAppend).forEach((key) => {
    params = params.append(key, paramsToAppend[key]);
  });
  return params;
}

/**
 * Returns array of params in key value format
 * @param params The http params
 */
export function getParams(params: HttpParams): object[] {
  const paramsArr = [];
  params.keys().forEach((key) => {
    paramsArr.push({ [key]: params.get(key) });
  });
  return paramsArr;
}

/**
 * Returns array of headers in key value format
 * @param headers The http headers
 */
export function getHeaders(headers: HttpHeaders): object[] {
  const headersArr = [];
  headers.keys().forEach((key) => {
    headersArr.push({ [key]: headers.get(key) });
  });
  return headersArr;
}

/**
 * Filters effects for the
 * @param requestType The request type to filter by
 */
export function responseRequestType(requestType: string): (a) => boolean {
  return (action) => action.requestType === requestType;
}
/**
 * Filters effects by module
 * @param module The module to filter by
 */
export function responseByModule(module: string): (a) => boolean {
  return (action) => action?.params?.module === module;
}

/**
 * Find out if the action is of a specific type of request.
 * @param action The action to check
 * @param requestType The request type to check
 */
export function isApi(action: ApiAction, requestType: ApiRequestType): boolean {
  return action.requestType === requestType;
}

/**
 * Find out if the response is OK by checking the status
 * @param status staus of the api call
 */
export function isResponseOK(status: string): boolean {
  return status === ApiStatus.OK;
}

/**
 * Find out if the http request is for a payment.
 * @param requestType The request type to check
 */
export function isApiPaymentRequest(requestType: string): boolean {
  return [
    ApiRequestType.AUTHORIZE,
    ApiRequestType.ADYEN_ENROLLMENT_CHECK_3DS,
    ApiRequestType.ENROLLMENT_CHECK_3DS,
    ApiRequestType.EWALLET_AUTHORIZE,
    ApiRequestType.PAY_WITH_TOKEN,
    ApiRequestType.VALIDATE_3DS,
  ].includes(requestType as ApiRequestType);
}
