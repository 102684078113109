import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createSelector, Store } from '@ngrx/store';
import { filter, map, Observable, switchMap, tap, withLatestFrom } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { sendPaymentComplete } from 'src/app/core/analytics/analytics.actions';
import { apiResponse, postApiRequest } from 'src/app/core/api/api.actions';
import { responseByModule, responseRequestType } from 'src/app/core/api/api.utilities';
import { PollingService } from 'src/app/core/api/polling/polling.service';
import { InsuranceAuthResponse } from 'src/app/core/api/responses/authorize';
import { InitiateAltPayResponse } from 'src/app/core/api/responses/initiate-alt-pay';
import { UpdateAltPayRequest, UpdateAltPayResponse } from 'src/app/core/api/responses/update-alt-pay';
import { DonationAuthResponse, PaymentComplete, PaymentType, SplitPaymentComplete } from 'src/app/core/application-bridge/application-bridge.models';
import { selectIsMobile } from 'src/app/core/environment/environment.selectors';
import { LocaleService } from 'src/app/core/locale/locale.service';
import { NotificationDialogType } from 'src/app/core/notification/dialog/confirm-dialog/confirm-dialog.component';
import { showNotificationAction } from 'src/app/core/notification/notification.actions';
import { hidePageSpinner } from 'src/app/core/page-spinner/page-spinner.actions';
import { selectLineItemsAndFees } from 'src/app/core/parent-config/parent-config.selectors';
import { routeTo, routeToWithOptions } from 'src/app/core/routing/routing.actions';
import { TemporaryLoggerService } from 'src/app/core/temporary-logger/temporary-logger.service';
import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import { AltPayRedirectDetails, buildDeliveryPayload, formatDemographicsForPaymentComplete } from '../billing/alternate-payments/alternate-payments.utils';
import { selectBillingDemographics } from '../billing/billing.selectors';
import { selectDelivery } from '../delivery/delivery.selectors';
import { DeliveryState } from '../delivery/delivery.state';
import { GiftCardState } from '../gift-card/gift-card.state';
import {
  checkPaymentStatusAction,
  handlePollTimeoutAction,
  initiateTmbThanachartAction,
  pollPaymentStatusAction,
  stopPollingAction,
} from './tmbthanachart.actions';

export interface buildPaymentCompletePayloadParams {
  InitAltPayResponse: InitiateAltPayResponse | UpdateAltPayResponse;
  paymentDetails: Partial<AltPayRedirectDetails>;
  amount?: number;
  giftCardState?: GiftCardState;
  deliveryStatus?: DeliveryState;
  demographics?: Partial<DemographicsFormData>;
  donation?: DonationAuthResponse;
  insurance?: InsuranceAuthResponse;
}

/**
 * The different type of `status` values
 */
export enum TmbThanachartResponseCodeStatus {
  NOT_FOUND = '001',
  ERROR = '999',
  OK = '000',
}

@Injectable({
  providedIn: 'root',
})
export class TmbThanachartEffects {
  /**
   * The constructor
   * @param actions$ Our action observable
   * @param store The store
   * @param localeService The locale service
   * @param pollingService The polling service
   * @param tempLogger Node logger
   */
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private localeService: LocaleService,
    private pollingService: PollingService,
    private tempLogger: TemporaryLoggerService
  ) {}

  /**
   * This effect is triggered when the user clicks on Pay
   * and the card is enrolled for 3DS
   */
  initializeTmbPayment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initiateTmbThanachartAction),
        withLatestFrom(this.store.select(selectIsMobile)),
        map(([action, isMobile]) => {
          const { authId, paymentMerchantId, paymentMethodCode, paymentProvider, qrData } = action.payload;
          this.store.dispatch(
            routeToWithOptions({
              routeParams: ['redirect-info'],
              options: { queryParams: { authId, paymentMerchantId, paymentMethodCode, paymentProvider, qrData, isMobile } },
            })
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * This effect is triggered to check payment status
   */
  checkPaymentStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkPaymentStatusAction),
      withLatestFrom(this.store.select(selectLineItemsAndFees)),
      map(([{ payload }, { cartItems, fees }]) => ({
        payload,
        reqBody: <UpdateAltPayRequest>{
          auth_id: payload?.authId,
          merchant_id: payload?.paymentMerchantId,
          payload: '',
          payment_type: payload?.paymentMethodCode,
          cartItems,
          fees,
        },
      })),
      map((data) => pollPaymentStatusAction(data))
    )
  );

  /**
   * This effect is triggered when updateAltPayment request is responsed, it check the payment status before complete the order
   * If TMB reponse failed we complete the order as pending status, since they not have a pending status
   */
  handlePaymentStatusResp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(apiResponse),
        filter(responseRequestType(ApiRequestType.UPDATE_ALT_PAY)),
        filter(responseByModule(PaymentType.TMBTHANACHART)),
        filter(() => !this.pollingService.isDetroyed()),
        withLatestFrom(
          this.store.select(createSelector(selectBillingDemographics, selectDelivery, (demographics, deliveryStatus) => ({ demographics, deliveryStatus })))
        ),
        tap(([action, { demographics, deliveryStatus }]) => {
          const response = action.response as UpdateAltPayResponse;
          if (action.isOk) {
            const buildPaymentCompletePayloadParams = {
              InitAltPayResponse: { ...response },
              paymentDetails: { ...action.callbackData, paymentType: response.paymentType as PaymentType },
              demographics,
              deliveryStatus,
            };
            if (response.result_code === TmbThanachartResponseCodeStatus.OK) {
              [
                hidePageSpinner({ initiator: 'TmbThanachart updatePayments results' }),
                sendPaymentComplete(buildPaymentCompletePayload(buildPaymentCompletePayloadParams)),
                stopPollingAction({ payload: response }),
              ].forEach((actn) => this.store.dispatch(actn));
            }
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Poll payment status
   */
  pollPaymentStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(pollPaymentStatusAction),
        tap((action) => this.tempLogger.log('Polling starts - params: ', JSON.stringify(action))),
        switchMap((action) =>
          this.pollingService.poll({
            observable: updateAltPay({ store: this.store, reqBody: action.reqBody, payload: action.payload }),
            timeout: action.payload.poll.timeout,
          })
        )
      ),
    { dispatch: false }
  );

  /**
   * Stop polling payment status
   */
  stopPolling$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(stopPollingAction),
        tap(() => this.pollingService.stopPolling()),
        tap((action) => this.tempLogger.log('Polling stops - params: ', JSON.stringify(action)))
      ),
    { dispatch: false }
  );

  /**
   * Handle poll timeout
   */
  handlePollTimeout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(handlePollTimeoutAction),
      tap((action) => this.tempLogger.log('Polling timeout: ', JSON.stringify(action.payload))),
      map((action) =>
        showNotificationAction({
          buttonLabel: this.localeService.get('common.close'),
          dialogType: NotificationDialogType.GENERAL,
          initiator: 'tmbthanachart redirect failure',
          message: this.localeService.get('redirectinfo.pollTimeout'),
          onClose: () => this.store.dispatch(routeTo({ payload: ['select'] })),
        })
      )
    )
  );
}

/**
 * build the payload for the sendPaymentComplete action
 * @param payload prequiered params to build the payload
 * @returns the complete paylaod for complete payment event
 */
function buildPaymentCompletePayload({ InitAltPayResponse, paymentDetails, deliveryStatus, demographics }: buildPaymentCompletePayloadParams): {
  payload: PaymentComplete | SplitPaymentComplete;
} {
  const { paymentType, paymentMerchantId, paymentProvider } = paymentDetails;
  return {
    payload: {
      authId: InitAltPayResponse.paymentReference,
      method: paymentType as PaymentType,
      approval_code: InitAltPayResponse.result_code,
      awaitingAuthorization: InitAltPayResponse.result_code !== TmbThanachartResponseCodeStatus.OK,
      legacy: {
        authId: InitAltPayResponse.auth_id.toString(),
        paymentMerchantId: paymentMerchantId,
        paymentMethodCode: InitAltPayResponse.payment_type,
        rawCardBrand: InitAltPayResponse.payment_type,
        paymentProviderName: paymentProvider,
      },
      user: {
        billing: formatDemographicsForPaymentComplete(demographics),
        ...(deliveryStatus?.deliveryMethodsConfigured &&
          deliveryStatus.address && {
            delivery: buildDeliveryPayload(deliveryStatus.address),
          }),
      },
    },
  };
}

function updateAltPay({ store, reqBody, payload }) {
  return new Observable((observer) => {
    store.dispatch(
      postApiRequest({
        requestType: ApiRequestType.UPDATE_ALT_PAY,
        body: reqBody,
        params: { module: 'tmbthanachart' },
        callbackData: payload,
      })
    );
    observer.next();
    observer.complete();
  });
}
