import { ModuleWithProviders, NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LocaleEffects } from './locale.effects';
import { LocalePipe } from './locale.pipe';
import { StoreModule } from '@ngrx/store';
import { localeReducer } from './locale.reducer';

@NgModule({ declarations: [LocalePipe],
    exports: [LocalePipe], imports: [CommonModule, StoreModule.forFeature('applicationLocale', localeReducer), EffectsModule.forFeature([LocaleEffects])], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class LocaleModule {}
