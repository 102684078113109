import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdPlacementResolve } from './feature/ad-placement/ad-placement.resolve';
import { DeliveryGuard } from './feature/delivery/delivery.guard';
import { SkipDeliveryGuard } from './feature/delivery/skip-delivery.guard';
import { OptimizelyResolve } from './feature/external/optimizely/optimizely.resolver';
import { ScriptsResolver } from './feature/external/scripts/scripts.resolver';
import { ExtrasSelectionGuard } from './feature/extras/extras-selection.guard';
import { ExtrasGuard } from './feature/extras/extras.guard';
import { PaymentOptionsGuard } from './feature/payment-options/payment-options.guard';
import { RecaptchaResolve } from './feature/recaptcha/recaptcha.resolve';

export const NotFoundRoute = {
  path: '**',
  redirectTo: 'not-found',
};

const routes: Routes = [
  {
    path: ':gateway/:merchantId',
    resolve: [ScriptsResolver, OptimizelyResolve],
    children: [
      {
        path: ':language',
        resolve: [AdPlacementResolve, RecaptchaResolve],
        children: [
          {
            path: '',
            redirectTo: 'delivery',
            pathMatch: 'full',
          },
          {
            path: 'delivery',
            canActivateChild: [SkipDeliveryGuard],
            loadChildren: () => import('./pages/delivery/delivery.module').then((mod) => mod.DeliveryModule),
          },
          {
            path: 'extras',
            canActivateChild: [ExtrasGuard],
            loadChildren: () => import('./pages/extras/extras.module').then((mod) => mod.ExtrasModule),
          },
          {
            path: 'redirect-info',
            loadChildren: () => import('./pages/redirect-info/redirect-info.module').then((mod) => mod.RedirectInfoModule),
          },
          {
            path: 'redirect-result',
            canActivateChild: [DeliveryGuard],
            loadChildren: () => import('./pages/redirect/redirect-result.module').then((mod) => mod.RedirectResultModule),
          },
          {
            path: 'payment-options',
            canActivateChild: [PaymentOptionsGuard],
            loadChildren: () => import('./pages/payment-options/payment-options.module').then((mod) => mod.PaymentOptionsModule),
          },
          {
            path: 'payment-complete',
            loadChildren: () => import('./pages/payment-complete/payment-complete.module').then((mod) => mod.PaymentCompleteModule),
          },
          {
            path: 'select',
            canActivateChild: [DeliveryGuard, ExtrasSelectionGuard],
            loadChildren: () => import('./pages/select/select.module').then((mod) => mod.SelectModule),
          },
          {
            path: 'session-expired',
            loadChildren: () => import('./pages/session-expired/session-expired.module').then((mod) => mod.SessionExpiredModule),
          },
          {
            path: 'wallet',
            loadChildren: () => import('./pages/wallet/wallet.module').then((mod) => mod.WalletModule),
          },
          { ...NotFoundRoute },
        ],
      },
    ],
  },
  {
    path: 'invalid',
    loadChildren: () => import('./pages/invalid/invalid.module').then((mod) => mod.InvalidModule),
  },
  {
    path: 'not-found',
    loadChildren: () => import('./pages/not-found/not-found.module').then((mod) => mod.NotFoundModule),
  },
  { ...NotFoundRoute },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
})
export class AppRoutingModule {}
