import { StoredWalletItem, initialState } from './stored-wallet.state';
import { createReducer, on } from '@ngrx/store';

import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import { apiResponse } from 'src/app/core/api/api.actions';
import { deleteStoredTokenSuccessAction } from './stored-wallet.actions';
import { get } from 'lodash-es';
import { validArray } from 'src/app/shared/utilities/types.utils';

const reducer = createReducer(
  initialState,
  on(apiResponse, (state, action) => {
    const tokens = get(action, 'response.TOKEN');
    if (action.isOk && action.requestType === ApiRequestType.RETRIEVE_TOKENS && validArray(tokens)) {
      return tokens.map(createStoredWalletItem);
    }
    return state;
  }),
  on(deleteStoredTokenSuccessAction, (state, action) => {
    const deletedTokens = action.payload.TOKEN.reduce((acc, deletedToken) => {
      acc.push(deletedToken.token);
      return acc;
    }, []);
    return state.reduce((acc, item: StoredWalletItem) => {
      if (!deletedTokens.includes(item.token)) {
        acc.push(item);
      }
      return acc;
    }, []);
  })
);

/**
 * Our global stored wallet reducer.
 * @param state The current state
 * @param action The current action
 */
export function storedWalletReducer(state, action): StoredWalletItem[] {
  return reducer(state, action);
}

/**
 * Creates a stored wallet item for our state.
 * @param storedToken A stored wallet item from the api.
 */
function createStoredWalletItem(storedToken): StoredWalletItem {
  let card = storedToken?.CARD || {};
  let cardholder = storedToken?.CARDHOLDER || {};

  const { address, address2, city, country, first_name, last_name, state, zip } = cardholder;

  return {
    token: storedToken.token,
    card: {
      lastFourDigits: card.card_last_four,
      cardType: card.card_type,
      month: card.expiry_month,
      year: card.expiry_year,
    },
    cardHolder: {
      address,
      address2,
      city,
      country,
      firstName: first_name,
      lastName: last_name,
      state,
      zip,
    },
  };
}
