import { createAction, props } from '@ngrx/store';

export enum LocaleActionTypes {
  FetchLocaleFile = '[Merchant Details Response] Fetch Locale Files',
  FetchLocaleFileOnError = '[App Initialize] Fetch Locale Files On Client Configuration Error',
  FetchLocaleFileSuccess = '[Locale Effects] Fetch Locale File Success',
  FetchLocaleFallback = '[Locale Effects] Fetch Locale File by Language',
  DefaultLocaleFallback = '[Locale Effects] Fallback to default locale',
  LoadingLocale = '[Locale Effects] Loading locale',
  LocaleFailure = '[Locale Effects] Failed to load locale',
  VerifyLocaleSupport = '[Locale Effects] Verify locale support',
}

type Locale = string;

/**
 * Load locale data
 */
export const loadLocaleFileAction = createAction(LocaleActionTypes.LoadingLocale, props<{ locale: Locale }>());
/**
 * Handle Locale failures
 */
export const localeFailureAction = createAction(LocaleActionTypes.LocaleFailure, props<{ locale: Locale }>());

/**
 * Verify locale is supported by application
 */
export const verifyLocaleSupportAction = createAction(LocaleActionTypes.VerifyLocaleSupport, props<{ payload: Locale }>());

/**
 * Fetches a specific locale file for angular
 */
export const fetchLocaleFile = createAction(LocaleActionTypes.FetchLocaleFile, props<{ payload: Locale }>());

/**
 * When the client configuration initialization fails to load the locale file.
 */
export const fetchLocaleFileOnClientConfigurationErrorAction = createAction(LocaleActionTypes.FetchLocaleFileOnError, props<{ payload: string }>());

/**
 * The action for when a locale file downloads completely
 */
export const fetchLocaleFileSuccess = createAction(LocaleActionTypes.FetchLocaleFileSuccess);

/**
 * The action to fallback to default locale
 */
export const fallbackToDefaultLocaleAction = createAction(LocaleActionTypes.DefaultLocaleFallback, props<{ payload: Locale }>());
