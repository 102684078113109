import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ParentConfigEffects } from './parent-config.effects';
import { parentConfigReducer } from './parent-config.reducer';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('parentConfig', parentConfigReducer), EffectsModule.forFeature([ParentConfigEffects])],
})
export class ParentConfigModule {}
