import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PaymentOptionsEffects } from './payment-options.effects';
import { paymentOptionsReducer } from './payment-options.reducer';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('paymentOptions', paymentOptionsReducer), EffectsModule.forFeature([PaymentOptionsEffects])],
})
export class PaymentOptionsModule {}
