import { createAction, props } from '@ngrx/store';

import { EnvironmentResponse, EnvironmentWithClientIp } from './environment.models';

export enum EnvironmentActionTypes {
  EnvironmentLoadedSuccess = '[Environment Effects] Environment Loaded Success',
  EnvironmentLoadedFailure = '[Environment Effects] Environment Loaded Failed',
  SetIpAddress = '[Environment] Set IP Address',
}

/**
 * The action to use when we receive a response from the environment service
 */
export const environmentLoadedSuccess = createAction(EnvironmentActionTypes.EnvironmentLoadedSuccess, props<{ payload: EnvironmentWithClientIp }>());

/**
 * Tell the application that we failed to load the environment
 */
export const environmentLoadedFailure = createAction(EnvironmentActionTypes.EnvironmentLoadedFailure);

/**
 * Set the ip address
 */
export const setIpAddressAction = createAction(EnvironmentActionTypes.SetIpAddress, props<{ ipAddress: string }>());
