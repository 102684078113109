import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.state';
import { SafeHtml } from '@angular/platform-browser';
import { selectParentSessionId } from 'src/app/core/parent-config/parent-config.selectors';
import { ButtonsStyle } from 'src/app/core/parent-config/parent-config.state';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

export enum NotificationDialogType {
  CONFIRM = 'CONFIRM',
  GENERAL = 'GENERAL',
}

export enum NotificationCloseAction {
  OK = 'OK',
  CANCEL = 'CANCEL',
}

export interface DialogData {
  initiator: string;
  dialogType: NotificationDialogType;
  buttonLabel: string | SafeHtml;
  message: string | SafeHtml;
  modalStyles?: {
    modalBackgroundColor: string;
    buttonStyles: ButtonsStyle;
    textColor: string;
    labelColor?: string;
  };
  buttonLabelCancel?: string | SafeHtml;
  id?: string;
  onClose?: (clicked: NotificationCloseAction) => any;
}

@Component({
  selector: 'ap-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmDialogComponent {
  sessionId$ = this.store.select(selectParentSessionId);
  actionType = NotificationCloseAction;

  constructor(@Inject(DIALOG_DATA) public data: DialogData, private store: Store<AppState>, private dialogRef: DialogRef<NotificationCloseAction>) {}

  onClick(type: NotificationCloseAction): void {
    this.dialogRef.close(type);
  }
}
