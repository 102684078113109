import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map } from 'rxjs/operators';
import { updateBillingDemographicsAction } from 'src/app/feature/billing/billing.actions';
import { setParentConfig } from '../../core/parent-config/parent-config.actions';
import { updateDeliveryAction, updateDeliveryDetailsAction } from './delivery.actions';

@Injectable({
  providedIn: 'root',
})
export class DeliveryEffects {
  /**
   * Handles actions to update delivery options
   */
  retrieveDeliveryMethods$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setParentConfig),
      map((action) => {
        const { payload } = action;
        return updateDeliveryAction({ payload: Array.isArray(payload?.delivery?.options) ? payload.delivery.options : [] });
      })
    )
  );

  /**
   * Handles actions to update billing demographics
   */
  copyDemographicsToBilling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDeliveryDetailsAction),
      filter(({ payload }) => payload.copyToBilling && payload.valid),
      map(({ payload = {} as any }) => {
        const { copyToBilling, ...deliveryAddress } = payload;
        return updateBillingDemographicsAction({ payload: deliveryAddress });
      })
    )
  );

  /**
   * @param actions$ Our actions stream
   */
  constructor(private actions$: Actions) {}
}
