import { HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';
import { isFunction, isPlainObject, isUndefined } from 'lodash-es';
import { returnOrDefaultString } from './string.utils';
import { validObject, validString } from './types.utils';

/**
 * Returns a query string build off of an object that has key value pairs.
 * @param data object to convert into a query string
 */
export function objectToQueryParams(data: any): string {
  if (validObject(data)) {
    return Object.keys(data)
      .map((key) => {
        const val = data[key];
        let current = encodeURIComponent(key) + '=';

        if (isPlainObject(val) || Array.isArray(val)) {
          current += encodeURIComponent(JSON.stringify(val));
        } else if (!isFunction(val) && val !== null && !isUndefined(val)) {
          current += encodeURIComponent(val);
        }

        return current;
      })
      .join('&');
  }

  return '';
}

/**
 * Returns the route params of the current url
 * @param window The window object
 */
export function getRouteParamsFromUrl(window: Window): string[] {
  const base: HTMLBaseElement | undefined = window.document.getElementsByTagName('base')[0];
  let pathname = window.location.pathname;

  if (base) {
    const baseHref = base.href;
    if (validString(baseHref)) {
      pathname = pathname.replace(baseHref, '');
    }
  }

  if (pathname.charAt(0) === '/') {
    pathname = pathname.slice(1);
  }

  if (pathname.charAt(pathname.length - 1) === '/') {
    pathname = pathname.slice(0, pathname.length - 1);
  }

  return pathname.split('/');
}

/**
 * Returns the query params from the current url
 * @param window The window object
 */
export function getQueryParamsFromUrl(window: Window): Params {
  const url = returnOrDefaultString(window.location.href);

  if (url.includes('?')) {
    const httpParams = new HttpParams({ fromString: url.split('?')[1] });
    return httpParams.keys().reduce((acc, curr) => {
      acc[curr] = httpParams.get(curr);
      return acc;
    }, {});
  }
  return null;
}

/**
 * Converts Query Parameter string to a URLSearchParams
 * @param params The query param string
 */
export const getQueryParams = (params: string): URLSearchParams => {
  try {
    if (validString(params)) {
      return new URLSearchParams(params);
    }
  } catch (_) {
    // DO NOTHING
  }

  return new URLSearchParams();
};
