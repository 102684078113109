import { DonationAuthAPIResponse, DonationAuthRequest } from 'src/app/core/api/responses/authorize';
import { DonationAuthResponse } from 'src/app/core/application-bridge/application-bridge.models';
import { returnOrDefaultString } from './string.utils';
import { DonationState } from 'src/app/feature/extras/extras.state';
import { toDecimal } from './number.utils';
import { ApiStatus } from 'src/app/core/api/api.interface';
import { DEFAULT_MAX_DONATION } from 'src/app/feature/extras/donation/donation.model';

/**
 * Returns fixed keys for donation payload integration
 * @param response donation auth response from auth request
 */
export function convertDonationResponse(response: DonationAuthAPIResponse): DonationAuthResponse {
  return {
    donationAmount: response.donation_amount,
    paymentStatus: response.payment_status || ApiStatus.PENDING,
    splitDonation: response.split_donation || false,
    donationApprovalCode: returnOrDefaultString(response.approval_code),
    donationTxnReference: returnOrDefaultString(response.gateway_txn_reference),
  };
}

/**
 * Format donation values to include in auth request
 * @param donation donation state
 */
export function formatDonationRequest(donation: DonationState): DonationAuthRequest {
  return {
    donation_amount: donation.amount,
    split_donation: donation.splitDonation,
  };
}

/**
 * Check if custom donation reaches min amount
 * @param input custom donation input.
 * @param minAmount min donation amount
 */
export function reachMinDonationAmt(input: number, minAmount: number | undefined): boolean {
  return input === 0 || input >= (minAmount ?? 0);
}

/**
 * Check if custom donation is under max amount
 * @param input custom donation input.
 * @param maxAmount max donation amount
 */
export function underMaxDonationAmt(input: number, maxAmount: number | undefined): boolean {
  return input <= (maxAmount ?? DEFAULT_MAX_DONATION);
}

/**
 * Calculate the transaction's amount involving donation
 * @param amountDiff the difference of order amount and giftcardBalance
 * @param donation
 * @returns amount and donation request object
 */
export function calculateSplitAmountWithDonation(amountDiff: number, donationState: DonationState): { amount: number; donation: DonationAuthRequest } {
  let amount, donation;
  if (amountDiff < 0) {
    donation = donationState.amount > 0 ? formatDonationRequest({ ...donationState, amount: toDecimal(donationState.amount + amountDiff, 2) }) : null;
    amount = 0;
  } else {
    donation = donationState.amount > 0 ? formatDonationRequest({ ...donationState, amount: donationState.amount }) : null;
    amount = amountDiff;
  }
  return { amount, donation };
}
