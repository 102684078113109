import { createAction, props } from '@ngrx/store';
import { EWalletAuthorizeRequest } from 'src/app/core/api/responses/ewallet-authorize';
import { PaymentMethodCode } from 'src/app/core/payment-configuration/payment-configuration.model';

export enum GooglePayActions {
  InitiateGooglePay = '[Google Pay] Initiate',
  RequestGooglePayAuth = '[Google Pay] Initiate Authorization',
  InitiateAdyenGooglePay3DSTransaction = '[Payment Selection Screen] Initiate Adyen GooglePay 3DS Transaction',
  InitiateCybersourceGooglePay3DSTransaction = '[Payment Selection Screen] Initiate Cybersource GooglePay 3DS Transaction',
  InitializeGiftCardSplitPayment = '[Google Pay Service] Initialize Gift Card Split Payment With Google Pay',
  SplitPaymentWithGiftCardSuccess = '[Gift Card Effects] Gift Card Split Payment With Google Pay Success',
  EWalletAuthorize = '[Google Pay Service] EWallet Authorization',
}

/**
 * Initialize googlepay
 */
export const initiateGooglePay = createAction(GooglePayActions.InitiateGooglePay);

/**
 * Initiate a google pay transaction request
 */
export const initiateAdyenGooglePay3DSTransactionAction = createAction(
  GooglePayActions.InitiateAdyenGooglePay3DSTransaction,
  props<{
    payload: {
      CARDHOLDER: CardholderInfo;
      CARD: CreditCardInfo;
      paymentType: PaymentMethodCode;
      merchantId: number;
    };
  }>()
);

/**
 * Initiate a google pay transaction request
 */
export const initiateCybersourceGooglePay3DSTransactionAction = createAction(
  GooglePayActions.InitiateCybersourceGooglePay3DSTransaction,
  props<{
    payload: ThreeDSData;
  }>()
);

/**
 * Initiate ewallet authorization
 */
export const initiateGooglePayEWalletAuthorizationAction = createAction(
  GooglePayActions.EWalletAuthorize,
  props<{ payload: { eWalletRequest: EWalletAuthorizeRequest; callbackData: google.payments.api.PaymentData } }>()
);

/**
 * Initiate split payment with gift card
 */
export const initializeGiftCardSplitPaymentWithGooglePayAction = createAction(
  GooglePayActions.InitializeGiftCardSplitPayment,
  props<{ amount: number; payload: { eWalletRequest: EWalletAuthorizeRequest; callbackData: google.payments.api.PaymentData } }>()
);

/**
 * Gift card split payment success
 */
export const giftCardSplitPaymentWithGooglePaySuccessAction = createAction(
  GooglePayActions.SplitPaymentWithGiftCardSuccess,
  props<{ payload: { eWalletRequest: EWalletAuthorizeRequest; callbackData: google.payments.api.PaymentData } }>()
);
