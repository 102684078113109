import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Dictionary } from 'src/app/shared/utilities/types.utils';
import { appendHeaders, appendParams } from './api.utilities';

/**
 * The class for creating an API request options using GET
 */
export class ApiGetRequestOptions {
  params: HttpParams;
  headers: HttpHeaders;
  /**
   * @param requestType The request type you'll be making
   * @param params The properties to append to the SERVICE node
   */
  constructor(params: Dictionary<any>, headers: Dictionary<any>) {
    this.params = appendParams(params);
    this.headers = appendHeaders(headers);
  }
}

/**
 * The class for creating an API request options using POST
 */
export class ApiPostRequestOptions {
  params: HttpParams;
  headers: HttpHeaders;
  /**
   * @param requestType The request type you'll be making
   * @param params The properties to append to the SERVICE node
   */
  constructor(params: Dictionary<any>, headers: Dictionary<any>, public body: Dictionary<any>) {
    this.params = appendParams(params);
    this.headers = appendHeaders(headers);
  }
}

/**
 * The class for creating an API request options using DELETE
 */
export class ApiDeleteRequestOptions {
  params: HttpParams;
  headers: HttpHeaders;
  /**
   * @param requestType The request type you'll be making
   * @param params The properties to append to the SERVICE node
   */
  constructor(params: Dictionary<any>, headers: Dictionary<any>, public body: Dictionary<any>) {
    this.params = appendParams(params);
    this.headers = appendHeaders(headers);
  }
}
