import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, switchMap, take } from 'rxjs';
import { DEFAULT_RECAPTCHAV3_DOMAIN_URL, GLOBAL_RECAPTCHAV3_DOMAIN_URL, RecaptchaService } from './recaptcha.service';
import { AcLoggerService } from 'src/app/core/logger/logger.service';
import { toObservable } from 'src/app/shared/utilities/observable.utils';
import { addScript } from 'src/app/shared/utilities/script-loader.utils';
import { AppState } from 'src/app/app.state';
import { Store, select } from '@ngrx/store';
import { selectAppConfig } from 'src/app/core/application-config/application-config.selectors';
import { APP_CONFIG_RECAPTCHAV3 } from 'src/app/shared/enums/application-config.enum';
import { RecaptchaV3ConfigState } from 'src/app/core/application-config/application-config.state';
import Bugsnag from '@bugsnag/js';

@Injectable({
  providedIn: 'root',
})
export class RecaptchaResolve implements Resolve<boolean> {
  /**
   * Indicates whether recaptcha has been loaded
   */
  recaptchaAssetsLoaded = false;

  /**
   * Constructor
   * @param recaptcha Recaptcha service
   * @param logger Logger service
   * @param store Our global store
   */
  constructor(private recaptcha: RecaptchaService, private logger: AcLoggerService, private store: Store<AppState>) {}

  /**
   * This function is always called when this is used as a route resolve. Makes sure we have valid QR Code data
   * prior to the page rendering.
   * @param route The route snapshot.
   * @param state The router state snapshot.
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.recaptchaAssetsLoaded || !this.recaptcha.enabled
      ? toObservable(true)
      : this.store.pipe(
          select(selectAppConfig([APP_CONFIG_RECAPTCHAV3])),
          take(1),
          map((configs) => (configs[APP_CONFIG_RECAPTCHAV3] as RecaptchaV3ConfigState)?.useGlobalDomain),
          switchMap((useGlobalDomain) => this.addScript(!!useGlobalDomain))
        );
  }

  /**
   * Add recaptcha script to DOM
   * @param useGlobalDomain Use recaptcha's global domain url
   * @returns Observable of method's result
   */
  addScript(useGlobalDomain: boolean): Observable<boolean> {
    const url = !!useGlobalDomain ? GLOBAL_RECAPTCHAV3_DOMAIN_URL : DEFAULT_RECAPTCHAV3_DOMAIN_URL;
    return addScript(`${url}/recaptcha/api.js?render=${this.recaptcha.siteKey}`, true, {
      id: 'apay-google-recaptcha-v3-script',
    }).pipe(
      switchMap((_) => {
        this.recaptchaAssetsLoaded = true;
        return toObservable(true);
      }),
      catchError((_) => {
        Bugsnag.notify(`RecaptchaResolve`, (event) => {
          event.addMetadata('Recaptcha', { recaptcha: 'failed to load' });
        });
        return toObservable(true);
      })
    );
  }
}
