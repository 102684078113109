import { normalize, sanitizeNameInput } from 'src/app/shared/utilities/string.utils';

/**
 * Maps acceso's Credit Card names to Apple Pay's Network
 */
export function mapCreditCardToApplePayNetwork(name: string): ApplePayNetworkType {
  switch (normalize(name)) {
    case 'americanexpress':
      return 'amex';
    case 'chinaunionpay':
      return 'chinaUnionPay';
    case 'discovercard':
    case 'discover':
      return 'discover';
    case 'mastercard':
      return 'masterCard';
    case 'visa':
      return 'visa';
    case 'jcb':
      return 'jcb';
    default:
  }
}

/**
 * Sanitize fields for Apple Pay authorize event
 * @param event
 * @returns
 */
export function sanitizeApplePayAuthEvent(event: ApplePayPaymentAuthorizedEvent): ApplePayPaymentAuthorizedEvent {
  return {
    ...event,
    payment: {
      ...event.payment,
      billingContact: {
        ...event.payment.billingContact,
        givenName: sanitizeNameInput(event.payment?.billingContact?.givenName ?? ''),
        familyName: sanitizeNameInput(event.payment?.billingContact?.familyName ?? ''),
      },
      shippingContact: {
        ...event.payment.shippingContact,
        givenName: sanitizeNameInput(event.payment?.shippingContact?.givenName ?? ''),
        familyName: sanitizeNameInput(event.payment?.shippingContact?.familyName ?? ''),
      },
    },
  } as ApplePayPaymentAuthorizedEvent;
}
