import Bugsnag from '@bugsnag/js';
import { DesktopDevices, DeviceType, MobileDevices, TabletDevices, TvDevices } from './environment.enums';
import { DeviceInfo as LibDevice, DeviceType as LibDeviceType } from 'ngx-device-detector';
import { DeviceInfo } from './environment.models';

const { MOBILE, DESKTOP, TV, TABLET } = DeviceType;
/**
 * Creates a regex to match a list of mobile devices
 * @returns Regular Expression to match mobile device
 */
export const getMobileDeviceRegex = (): RegExp => {
  const devicesToMatch = Object.values(MobileDevices).join('|');
  return new RegExp(devicesToMatch, 'i');
};

/**
 * Creates a regex to match a list of tablet devices
 * @returns Regular Expression to match tablet device
 */
export const getTabletDeviceRegex = (): RegExp => {
  const devicesToMatch = Object.values(TabletDevices).join('|');
  return new RegExp(devicesToMatch, 'i');
};

/**
 * Creates a regex to match a list of tv devices
 * @returns Regular Expression to match tv device
 */
export const getTvDeviceRegex = (): RegExp => {
  const devicesToMatch = Object.values(TvDevices).join('|');
  return new RegExp(devicesToMatch, 'i');
};

/**
 * Creates a regex to match a list of desktop devices
 * @returns Regular Expression to match desktop device
 */
export const getDesktopDeviceRegex = (): RegExp => {
  const devicesToMatch = Object.values(DesktopDevices).join('|');
  return new RegExp(devicesToMatch, 'i');
};

/**
 * Determine if browser User-Agent is a tv device
 * @param {string} ua User-Agent string
 * @returns Boolean for User-Agent that matches a TV OS
 */
export const isTvAgent = (ua: string): boolean => (!ua ? false : getTvDeviceRegex().test(ua));

/**
 * Find platform name within given regular expression
 * @param {string} ua User-Agent string.
 * @param {RegExp} regex User-Agent string.
 * @returns String matched from provided User-agent string and regular expression, or null if not matched.
 */
export const matchDevicePlatform = (ua: string, regex: RegExp): string | null => {
  const match = ua.match(regex);
  return match ? match[0].toLocaleLowerCase() : null;
};

/**
 * Interpret User-Agent string
 * Navigator.maxTouchPoints > 1 means it is a touchscreen device
 * https://developer.mozilla.org/en-US/docs/Web/API/Navigator/maxTouchPoints
 * @param {string} ua User-Agent string.
 * @param {number} maxTouchPoints The number of device touch points, used for evualating a touchscreen device.
 * @returns Object representing information parsed from User-Agent string.
 */
export const getDeviceInfo = (deviceInfo: LibDevice, window: Window): DeviceInfo => {
  const ua = deviceInfo.userAgent;
  const supportsMultiTouch = window.navigator?.maxTouchPoints || 0;

  const device: DeviceInfo = {
    isMobile: deviceInfo.deviceType === LibDeviceType.Mobile,
    isTablet: deviceInfo.deviceType === LibDeviceType.Tablet,
    isDesktop: deviceInfo.deviceType === LibDeviceType.Desktop,
    platform: 'unknown',
    type: MOBILE, // Default to a mobile device.
    agent: ua,
    language: window.navigator.language,
    colorDepth: window.screen.colorDepth,
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
    javaEnabled: window.navigator.javaEnabled(),
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  if (!ua) {
    // User agent should exist, but will assume it's a mobile device. Same behavior as ShoplandV5.
    Bugsnag.notify(new Error(`User-Agent not found`), (event) => {
      event.addMetadata('User-Agent', {
        message: `Missing browser User-Agent`,
      });
    });
    return device;
  }

  if (isTvAgent(ua)) {
    device.platform = matchDevicePlatform(device.agent, getTvDeviceRegex()) ?? device.platform;
    device.type = TV;
  } else if (device.isTablet) {
    const isIpadOnSafari = /Macintosh/i.test(ua) && supportsMultiTouch;
    device.platform = (isIpadOnSafari ? 'ipad' : matchDevicePlatform(device.agent, getTabletDeviceRegex())) ?? device.platform;
    device.type = TABLET;
  } else if (device.isMobile) {
    device.platform = matchDevicePlatform(device.agent, getMobileDeviceRegex()) ?? device.platform;
    device.type = MOBILE;
  } else if (device.isDesktop) {
    device.platform = matchDevicePlatform(device.agent, getDesktopDeviceRegex()) ?? device.platform;
    device.type = DESKTOP;
  } else {
    // User-Agent is unidentifiable
    Bugsnag.notify(new Error(`User-Agent type not found`), (event) => {
      event.addMetadata('User-Agent', {
        message: 'Unknown User-Agent',
        userAgent: device.agent,
      });
    });
  }

  device.browser = deviceInfo;

  return device;
};
