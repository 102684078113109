import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GiftCard, GiftCardState } from './gift-card.state';
import { filterNonPreAuthGiftCards, filterPreAuthGiftCards } from './gift-card.utils';

/**
 * Selects the gift card state
 * @param state The application state
 * @returns The gift card state
 */
export const selectGiftCardState = createFeatureSelector<GiftCardState>('giftCard');

/**
 * Selects the gift cards added to the state
 * @returns gift cards
 */
export const selectGiftCards = createSelector(selectGiftCardState, (state: GiftCardState): GiftCard[] => state.giftcards);

/**
 * Selects the preauthorized gift cards
 * @returns gift cards
 */
export const selectPreAuthorizedGiftCards = createSelector(selectGiftCardState, ({ giftcards }: GiftCardState): GiftCard[] =>
  filterPreAuthGiftCards(giftcards)
);

/**
 * Selects the non preauthorized gift cards
 * @returns gift cards
 */
export const selectNonPreAuthorizedGiftCards = createSelector(selectGiftCardState, ({ giftcards }: GiftCardState): GiftCard[] =>
  filterNonPreAuthGiftCards(giftcards)
);

/**
 * Selects the gift card total
 * @returns total balance
 */
export const selectGiftCardTotal = createSelector(selectGiftCardState, (state: GiftCardState): number => state.totalBalance);
