import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { RouteToDirective } from './route-to.directive';
import { RoutingEffects } from './routing.effects';

@NgModule({
  declarations: [RouteToDirective],
  exports: [RouteToDirective],
  imports: [CommonModule],
})
export class RoutingModule {}
