import { createReducer, on } from '@ngrx/store';
import { staticDataLoadedFailureAction } from '../static-data/static-data.actions';
import { environmentLoadedFailure, environmentLoadedSuccess, setIpAddressAction } from './environment.actions';
import { EnvironmentState, initialState } from './environment.state';

const reducer = createReducer(
  initialState,
  on(staticDataLoadedFailureAction, (state, action) => ({ ...state, loading: false, loaded: true, errorLoading: true })),
  on(environmentLoadedFailure, (state, action) => ({ ...state, loading: false, loaded: true, errorLoading: true })),
  on(setIpAddressAction, (state, action) => ({ ...state, ipAddress: action.ipAddress })),
  on(environmentLoadedSuccess, (state, { payload }) => ({ ...payload, loaded: true } as EnvironmentState))
);

export function environmentReducer(state, action): EnvironmentState {
  return reducer(state, action);
}
