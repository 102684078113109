import { Store, select } from '@ngrx/store';

import { AppState } from 'src/app/app.state';
import { CanActivateChild } from '@angular/router';
import { DeliveryState } from './delivery.state';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ShowDeliveryViewAction, hideDeliveryViewAction } from './delivery.actions';
import { routeTo } from '../../core/routing/routing.actions';
import { selectDelivery } from './delivery.selectors';
import { validString } from 'src/app/shared/utilities/types.utils';

@Injectable({
  providedIn: 'root',
})
export class DeliveryGuard implements CanActivateChild {
  /**
   * The delivery state
   */
  private deliveryState: DeliveryState;

  /**
   * @param store The store
   */
  constructor(private store: Store<AppState>) {
    this.store.pipe(select(selectDelivery)).subscribe((deliveryState) => {
      this.deliveryState = deliveryState;
    });
  }

  /**
   * Guards route by checking the delivery state
   */
  canActivateChild(): boolean | Observable<boolean> {
    if (this.deliveryState.deliveryMethodsConfigured) {
      if (validString(this.deliveryState.selectedDeliveryMethod)) {
        if (this.deliveryState.collectAddress) {
          if (!this.deliveryState?.address.valid) {
            [ShowDeliveryViewAction(), routeTo({ payload: ['delivery'] })].forEach((action) => this.store.dispatch(action));
            return false;
          }
        }
        return true;
      }

      [ShowDeliveryViewAction(), routeTo({ payload: ['delivery'] })].forEach((action) => this.store.dispatch(action));

      return false;
    }
    this.store.dispatch(hideDeliveryViewAction());
    return true;
  }
}
