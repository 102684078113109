import { isFunction, isObject } from 'lodash-es';

/**
 * Sorts an array of objects by property
 * @param prop The property to sort by
 * @param modifier Modify the property into something else if you want
 * @returns A Function to allow sorting an iterable
 */
export function sortByProperty(prop: string, modifier?: (value: any) => number) {
  return (a, b): number => {
    if (!a && !b) {
      return 0;
    } else if (!b && isObject(a)) {
      return 1;
    } else if (!a && isObject(b)) {
      return -1;
    } else {
      let aProp = a[prop];
      let bProp = b[prop];

      if (isFunction(modifier)) {
        aProp = modifier(aProp);
        bProp = modifier(bProp);
      }

      return aProp < bProp ? -1 : aProp > bProp ? 1 : 0;
    }
  };
}

/**
 * Removes duplicate elements from arrays passed and merges them into a single array.
 * This method uses ES6 Set to elimate duplicates
 * @param params The values to merge and remove duplicates.
 */
export function removeDupes(...params: any[]) {
  return [...new Set(...params)];
}
