import { createFeatureSelector, createSelector } from '@ngrx/store';
import { validArray, validObject } from 'src/app/shared/utilities/types.utils';
import { ParentConfigState, User } from './parent-config.state';
import { getLineItemsAndFees, isComplimentaryOrder, LineItemsAndFees } from './parent-config.utils';

/**
 * Selects the initialize state
 * @param state The initialize state
 * @returns The initialize state
 */
export const selectParentConfig = createFeatureSelector<ParentConfigState>('parentConfig');

/**
 * selects the parent applications session id
 */
export const selectParentSessionId = createSelector(selectParentConfig, (parentConfig: ParentConfigState): string => {
  return parentConfig.sessionId;
});

/**
 * selects the amount passed
 */
export const selectParentConfigAmount = createSelector(selectParentConfig, ({ config }: ParentConfigState): number => config.amount);

/**
 * Determines if order is has 0 dollar amount
 */
export const selectIsComplimentaryOrder = createSelector(selectParentConfig, ({ config }: ParentConfigState): boolean => isComplimentaryOrder(config.amount));

/**
 * selects the order id passed
 */
export const selectParentConfigOrderId = createSelector(selectParentConfig, ({ config }: ParentConfigState) => config?.orderId || '');

/**
 * selects the configured currency code
 */
export const selectParentCurrencyCode = createSelector(selectParentConfig, ({ config }: ParentConfigState) => config?.currencyCode || '');

/**
 * selects the configured currency code
 */
export const selectParentConfigDelivery = createSelector(selectParentConfig, ({ config }: ParentConfigState) =>
  validObject(config?.delivery) ? config.delivery : null
);

/**
 * selects the return url passed for redirect payments
 */
export const selectPaymentRedirectUrl = createSelector(selectParentConfig, ({ config }: ParentConfigState): string => config.paymentRedirectUrl);

/**
 * selects the user information
 */
export const selectUserInfo = createSelector(selectParentConfig, ({ config }: ParentConfigState): Partial<User> => config.user);

/**
 * Selects the host app name
 */
export const selectHostAppName = createSelector(selectParentConfig, ({ config }: ParentConfigState): string => config?.hostAppName ?? '');

/**
 * selects the recurring state
 */
export const selectRecurring = createSelector(selectParentConfig, ({ config }: ParentConfigState): boolean => !!config?.recurring);

/**
 * selects the line items and fees
 */
export const selectLineItemsAndFees = createSelector(selectParentConfig, (parentConfig: ParentConfigState): LineItemsAndFees => {
  return getLineItemsAndFees(parentConfig);
});

/**
 * Selects validity of cart items
 */
export const selectHasCartItems = createSelector(selectLineItemsAndFees, ({ cartItems }: LineItemsAndFees): boolean => validArray(cartItems));

/**
 * If we should show the new unified design spinner
 */
export const selectShowNewUnifiedDesignSpinner = createSelector(selectParentConfig, ({ config }: ParentConfigState) => {
  return config?.unifiedDesign?.useNewSpinner ?? false;
});

/**
 * Calculates the total cost of line items in the parent configuration.
 *
 * @returns The total cost of line items.
 */
export const selectLineItemsTotal = createSelector(selectParentConfig, (parentConfig: ParentConfigState): number => {
  const data = getLineItemsAndFees(parentConfig);
  return data.cartItems.reduce((acc, item) => {
    const lineItemCost = item.unitPrice + item.taxAmount + item.feeAmount;
    return acc + item.quantity * lineItemCost;
  }, 0);
});

export const selectCartId = createSelector(selectParentConfig, ({ config }: ParentConfigState) => config.cartId);
