import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, createSelector } from '@ngrx/store';
import { Observable, of, zip } from 'rxjs';
import { catchError, debounceTime, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { sendAnalyticsEventAction } from 'src/app/core/analytics/analytics.actions';
import { apiResponse, postApiRequest } from 'src/app/core/api/api.actions';
import { ApiStatus, Response } from 'src/app/core/api/api.interface';
import { ApiService } from 'src/app/core/api/api.service';
import { responseRequestType } from 'src/app/core/api/api.utilities';
import { DonationAuthRequest, InsuranceAuthRequest } from 'src/app/core/api/responses/authorize';
import { SVCPreAuthResponse } from 'src/app/core/api/responses/svc-pre-auth';
import { sendMessageAction } from 'src/app/core/application-bridge/application-bridge.actions';
import { AuthorizedPaymentInfo, PaymentType } from 'src/app/core/application-bridge/application-bridge.models';
import { LocaleService } from 'src/app/core/locale/locale.service';
import { NotificationDialogType } from 'src/app/core/notification/dialog/confirm-dialog/confirm-dialog.component';
import { showNotificationAction } from 'src/app/core/notification/notification.actions';
import { hidePageSpinner, showPageSpinner } from 'src/app/core/page-spinner/page-spinner.actions';
import { setParentConfig } from 'src/app/core/parent-config/parent-config.actions';
import { selectLineItemsAndFees, selectParentCurrencyCode } from 'src/app/core/parent-config/parent-config.selectors';
import { LineItemsAndFees } from 'src/app/core/parent-config/parent-config.utils';
import { GiftCardPayment } from 'src/app/core/payment-configuration/payment-configuration.model';
import { selectGiftCard } from 'src/app/core/payment-configuration/payment-configuration.selectors';
import { routeTo } from 'src/app/core/routing/routing.actions';
import { setAuthorizingAction } from 'src/app/core/session/session.actions';
import {
  giftCardSplitPaymentWithCreditCardSuccessAction,
  initializeGiftCardSplitPaymentWithCreditCardAction,
} from 'src/app/pages/select/creditcard/creditcard.actions';
import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import { EVENT_PAYMENT_FAILED } from 'src/app/shared/enums/application-bridge.enums';
import { formatCardHolderAsUserDataToDemographics } from 'src/app/shared/utilities/demographics.utils';
import { convertDonationResponse, formatDonationRequest } from 'src/app/shared/utilities/donation.utils';
import { convertInsuranceResponse, formatInsuranceRequest } from 'src/app/shared/utilities/insurance.utils';
import { validObject, validString } from 'src/app/shared/utilities/types.utils';
import {
  giftCardSplitPaymentWithAdyen3DSSuccessAction,
  giftCardSplitPaymentWithAdyenWebComponentSuccessAction,
  initializeGiftCardSplitPaymentWithAdyen3DSAction,
  initializeGiftCardSplitPaymentWithAdyenWebComponentAction,
} from '../adyen/adyen.actions';
import {
  giftCardSplitPaymentWithAlternatePaymentsSuccessAction,
  initializeGiftCardSplitPaymentWithAlternatePaymentsAction,
} from '../billing/alternate-payments/alternate-payments.actions';
import { giftCardSplitPaymentWithApplePaySuccessAction, initializeGiftCardSplitPaymentWithApplePayAction } from '../billing/applepay/applepay.actions';
import {
  gatherPaymentDetailsForGiftCardOnlyPaymentAction,
  giftCardSplitPaymentWithCybersource3DSSuccessAction,
  initializeGiftCardSplitPaymentWithCybersource3DSAction,
  updateBillingDemographicsAction,
} from '../billing/billing.actions';
import { selectBillingDemographics } from '../billing/billing.selectors';
import { giftCardSplitPaymentWithGooglePaySuccessAction, initializeGiftCardSplitPaymentWithGooglePayAction } from '../billing/google-pay/google-pay.actions';
import { initializeGiftCardSplitPaymentWithPayPalAction } from '../billing/paypal/paypal.actions';
import {
  giftCardSplitPaymentWithStoredWalletSuccessAction,
  initializeGiftCardSplitPaymentWithStoredWalletAction,
} from '../billing/stored-wallet/stored-wallet.actions';
import { updateTicketProtectionPolicyInfoAction } from '../extras/extras.actions';
import { selectDonation, selectTicketProtection } from '../extras/extras.selectors';
import { giftCardSplitPaymentWithAmazonPaySuccessAction, initializeGiftCardSplitPaymentWithAmazonPayAction } from './../billing/amazon-pay/amazon-pay.actions';
import {
  GiftCardActions,
  authSVCAction,
  fetchGiftCardBalanceAction,
  hideGiftCardSuccessMsgAction,
  payPartialAmountWithGiftCardAction,
  payPartialAmountWithGiftCardSuccessAction,
  payWithGiftCardAction,
  payWithGiftCardFailedAction,
  payWithGiftCardSuccessAction,
  processSvcAuth,
  showGiftCardSuccessMsgAction,
  updateGiftCardBalanceAction,
  updateGiftCardMaxEntriesAction,
} from './gift-card.actions';
import { selectGiftCardState, selectNonPreAuthorizedGiftCards, selectPreAuthorizedGiftCards } from './gift-card.selectors';
import { GiftCard } from './gift-card.state';
import {
  buildAmountTypeArr,
  buildGiftCardAmountData,
  buildPreAuthRequestData,
  calcMaxNumOfGiftCards,
  calcPreAuthCartAmount,
  getFinishAuthSvcAction,
  getfirstGiftcardToAuth,
  hasFailedSvcPreAuth,
  mapPreAuthResponses,
  sumPreAuthorizedGiftCardAmount,
} from './gift-card.utils';

export const giftCardOnlyPaymentSelector = createSelector(
  selectGiftCardState,
  selectGiftCard,
  selectBillingDemographics,
  (giftCardState, giftCardConfig, billingDemographics) => {
    return {
      isGiftCardOnlyPayment: giftCardState.isGiftCardOnlyPayment,
      giftCardConfig,
      billingDemographics,
    };
  }
);

@Injectable()
export class GiftCardEffects {
  updateNumberOfGiftCardEntries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setParentConfig),
      switchMap(({ payload }) => {
        const numGiftCardsAllowed = parseInt(payload?.giftCard?.maxEntries + '');
        return [updateGiftCardMaxEntriesAction({ payload: calcMaxNumOfGiftCards(numGiftCardsAllowed) })];
      })
    )
  );

  /**
   * The effect to fetch balance for gift card
   */
  fetchBalance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchGiftCardBalanceAction),
      withLatestFrom(this.store.select(selectGiftCard)),
      mergeMap(([{ giftCardNumber, pincode }, { paymentMerchantId }]) => {
        return [
          showPageSpinner({ initiator: 'GiftCard - Fetch balance' }),
          postApiRequest({
            requestType: ApiRequestType.SVC_GET_BALANCE,
            body: {
              merchant_id: paymentMerchantId,
              card_number: giftCardNumber,
              ...(pincode && { pin: pincode }),
            },
          }),
        ];
      })
    )
  );

  /**
   * The effect to update gift card available balance
   */
  updateBalance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(apiResponse),
      filter(responseRequestType(ApiRequestType.SVC_GET_BALANCE)),
      withLatestFrom(this.store.select(selectParentCurrencyCode)),
      mergeMap(([action, storeCurrencyCode]) => {
        const actions: Action[] = [hidePageSpinner({ initiator: 'Get SVC Balance response' })];
        const { localeService } = this;
        const { isOk, body, response } = action;
        const { error_code, error_msg, currency: currencyCode, balance } = response;

        // Handle Failed SVC Get Balance
        if (!isOk) {
          const hasErrorCodeMessage = validString(error_code) && localeService.has(`errorcode`);
          const errorLocale = hasErrorCodeMessage ? `errorcode.${error_code}` : 'giftcard.getbalancefailure';
          const errorMessage = validString(error_msg) ? error_msg : localeService.get(errorLocale);

          actions.push(
            showNotificationAction({
              buttonLabel: localeService.get('common.close'),
              dialogType: NotificationDialogType.GENERAL,
              initiator: 'Gift Card - Fetch balance failure',
              message: errorMessage,
            })
          );
          return actions;
        }

        // Handle Currency Mismatch
        const giftCardCurrencyValid = currencyCode === storeCurrencyCode;
        if (!giftCardCurrencyValid) {
          actions.push(
            showNotificationAction({
              buttonLabel: localeService.get('common.close'),
              dialogType: NotificationDialogType.GENERAL,
              initiator: 'Gift Card - Currency mismatch',
              message: localeService.get('giftcard.currencyMismatch'),
            })
          );
          return actions;
        }

        // Handle No Balance
        if (response.balance <= 0) {
          actions.push(
            showNotificationAction({
              buttonLabel: localeService.get('common.close'),
              dialogType: NotificationDialogType.GENERAL,
              initiator: 'Gift Card - No balance',
              message: localeService.get('giftcard.nobalance'),
            })
          );
          return actions;
        }

        actions.push(showGiftCardSuccessMsgAction(), updateGiftCardBalanceAction({ balance, giftCardNumber: body.card_number, currencyCode, pin: body.pin }));
        return actions;
      })
    )
  );

  /**
   * The effect to hide gift card success message
   */
  hideSuccessMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showGiftCardSuccessMsgAction),
      debounceTime(4000),
      map(() => hideGiftCardSuccessMsgAction())
    )
  );

  /**
   * The effect to pay with gift card
   */
  payWithGiftCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(payWithGiftCardAction, payPartialAmountWithGiftCardAction),
      withLatestFrom(
        this.store.select(
          createSelector(
            selectGiftCard,
            selectLineItemsAndFees,
            selectTicketProtection,
            selectBillingDemographics,
            selectPreAuthorizedGiftCards,
            selectNonPreAuthorizedGiftCards,
            (giftCardConfig, lineItemsAndFees, ticketProtection, billingDemographics, preAuthGiftCards, nonPreAuthGiftCards) => ({
              giftCardConfig,
              lineItemsAndFees,
              ticketProtection,
              billingDemographics,
              preAuthBal: sumPreAuthorizedGiftCardAmount(preAuthGiftCards),
              nonPreAuthGiftCards,
            })
          )
        )
      ),
      mergeMap(([action, currentState]) => {
        const amountToPay = action.amount;
        const { giftCardConfig, lineItemsAndFees, billingDemographics, preAuthBal, nonPreAuthGiftCards } = currentState;
        const amountToAuthorize = amountToPay - preAuthBal;
        // append gift card info
        const { giftCardData, amountTypes } = buildGiftCardAmountData({
          amountTypes: buildAmountTypeArr({
            orderAmount: amountToAuthorize,
            donationAmount: action.donation?.donation_amount ?? 0,
            insuranceAmount: action.insurance?.premium_amount ?? 0,
          }),
          giftCardsAdded: nonPreAuthGiftCards,
        });

        const firstCard = getfirstGiftcardToAuth(giftCardData);
        const demographics =
          action.type === GiftCardActions.PayPartialAmountWithGiftCard && action.callback?.payload?.demographics
            ? action.callback?.payload?.demographics
            : billingDemographics;

        const preAuthBody = buildPreAuthRequestData({
          card: firstCard,
          billingDemographics: demographics,
          merchantId: giftCardConfig.paymentMerchantId,
          donation: action.donation,
          insurance: action.insurance,
          lineItems: lineItemsAndFees,
          user:
            action.type === GiftCardActions.PayPartialAmountWithGiftCard && action.callback?.payload?.CARDHOLDER
              ? formatCardHolderAsUserDataToDemographics(action.callback?.payload?.CARDHOLDER)
              : {},
        });

        return this.apiService.post<SVCPreAuthResponse>(ApiRequestType.SVC_PRE_AUTH, preAuthBody).pipe(
          mergeMap(({ status, response }) => {
            if (status === ApiStatus.FAILED) {
              return [payWithGiftCardFailedAction()];
            }
            return [processSvcAuth({ response, giftCardData, demographics, amountTypes, lastAction: action })];
          })
        );
      })
    )
  );

  /**
   * The effect to pre-authorize multiple gift card entries
   */
  processAuthResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(processSvcAuth),
      withLatestFrom(
        this.store.select(
          createSelector(selectGiftCard, selectLineItemsAndFees, selectNonPreAuthorizedGiftCards, (giftCardConfig, lineItemsAndFees, nonPreAuthGiftCards) => ({
            giftCardConfig,
            lineItemsAndFees,
            nonPreAuthGiftCards,
          }))
        )
      ),
      mergeMap(([{ response, giftCardData, demographics, amountTypes, lastAction }, currentState]) => {
        const { giftCardConfig, lineItemsAndFees, nonPreAuthGiftCards } = currentState;
        const { balance, paymentReference, auth_id: authId, card_reference_code, donation, insurance, currency } = response;
        const matchingGiftCardForResponse = nonPreAuthGiftCards[0];
        const policyInfoId = insurance?.policy_info_id;
        const actions: Action[] = policyInfoId ? [updateTicketProtectionPolicyInfoAction({ payload: policyInfoId })] : [];

        const preAuthGiftCards: GiftCard[] = [
          {
            cardNumber: matchingGiftCardForResponse.cardNumber,
            authId,
            balance,
            currencyCode: currency,
            paymentReference: paymentReference || card_reference_code,
            preAuthBalance: response.pre_auth_balance,
            amount: calcPreAuthCartAmount(response.pre_auth_balance, donation?.donation_amount, insurance?.insurance_amount),
            ...(donation && { donation: convertDonationResponse(donation) }),
            ...(insurance && { insurance: convertInsuranceResponse(insurance) }),
          },
        ];

        if (giftCardData.length) {
          const preAuthRequestList = this.compilePostApiRequestListForGiftCards({
            giftCardData: giftCardData,
            giftCardConfiguration: giftCardConfig,
            policyInfoId,
            billingDemographics: demographics,
            donation: lastAction.donation,
            insurance: lastAction.insurance,
            lineItems: lineItemsAndFees,
          });

          actions.push(
            authSVCAction({
              payload: {
                requests: preAuthRequestList,
                nonPreAuthGiftCards,
                lastAction,
                amountTypes,
                preAuthGiftCards,
              },
            })
          );
          return actions;
        }

        actions.push(getFinishAuthSvcAction({ lastAction, preAuthGiftCards, amountTypes }));
        return actions;
      })
    )
  );

  /**
   * The effect to initiate gift card split payments
   */
  paySplitPaymentWithGiftCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        initializeGiftCardSplitPaymentWithCreditCardAction,
        initializeGiftCardSplitPaymentWithStoredWalletAction,
        initializeGiftCardSplitPaymentWithAlternatePaymentsAction,
        initializeGiftCardSplitPaymentWithAdyen3DSAction,
        initializeGiftCardSplitPaymentWithCybersource3DSAction,
        initializeGiftCardSplitPaymentWithGooglePayAction,
        initializeGiftCardSplitPaymentWithApplePayAction,
        initializeGiftCardSplitPaymentWithPayPalAction,
        initializeGiftCardSplitPaymentWithAdyenWebComponentAction,
        initializeGiftCardSplitPaymentWithAmazonPayAction
      ),
      withLatestFrom(
        this.store.select(createSelector(selectDonation, selectTicketProtection, (donation, ticketProtection) => ({ donation, ticketProtection })))
      ),
      mergeMap(([action, { donation, ticketProtection }]) => {
        const payload: any = {
          amount: action.amount,
          callback: {
            action: action.type,
            payload: action.payload,
          },
        };

        if (donation.amount > 0) {
          payload.donation = formatDonationRequest(donation);
        }
        if (ticketProtection.isProtected && ticketProtection.quoteToken) {
          payload.insurance = formatInsuranceRequest(ticketProtection);
        }

        return [setAuthorizingAction({ authorizing: true }), payPartialAmountWithGiftCardAction(payload)];
      })
    )
  );

  /**
   * The effect triggered after the gift card split payment is successful
   */
  paySplitPaymentWithGiftCardSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(payPartialAmountWithGiftCardSuccessAction),
      mergeMap(({ callback }) => {
        const { action: cbAction, payload } = callback;
        const actions: Action[] = [
          sendAnalyticsEventAction({
            action: 'pay',
            category: 'gift card partial',
            label: 'payment complete',
            nonInteraction: true,
          }),
        ];
        switch (cbAction) {
          case initializeGiftCardSplitPaymentWithCreditCardAction.type:
            actions.push(giftCardSplitPaymentWithCreditCardSuccessAction({ payload }));
            break;
          case initializeGiftCardSplitPaymentWithStoredWalletAction.type:
            actions.push(giftCardSplitPaymentWithStoredWalletSuccessAction({ payload }));
            break;
          case initializeGiftCardSplitPaymentWithAlternatePaymentsAction.type:
            actions.push(giftCardSplitPaymentWithAlternatePaymentsSuccessAction({ payload }));
            break;
          case initializeGiftCardSplitPaymentWithAdyen3DSAction.type:
            actions.push(giftCardSplitPaymentWithAdyen3DSSuccessAction({ payload }));
            break;
          case initializeGiftCardSplitPaymentWithGooglePayAction.type:
            actions.push(giftCardSplitPaymentWithGooglePaySuccessAction({ payload }));
            break;
          case initializeGiftCardSplitPaymentWithCybersource3DSAction.type:
            actions.push(giftCardSplitPaymentWithCybersource3DSSuccessAction({ payload }));
            break;
          case initializeGiftCardSplitPaymentWithApplePayAction.type:
            actions.push(giftCardSplitPaymentWithApplePaySuccessAction({ payload }));
            break;
          case initializeGiftCardSplitPaymentWithAdyenWebComponentAction.type:
            actions.push(giftCardSplitPaymentWithAdyenWebComponentSuccessAction({ payload }));
            break;
          case initializeGiftCardSplitPaymentWithAmazonPayAction.type:
            actions.push(giftCardSplitPaymentWithAmazonPaySuccessAction({ payload }));
            break;
        }

        return actions;
      })
    )
  );

  /**
   * The effect to handle gift card payment failure
   */
  payWithGiftCardFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(payWithGiftCardFailedAction),
      mergeMap(() => [
        sendMessageAction({
          key: EVENT_PAYMENT_FAILED,
          payload: {},
        }),
        sendAnalyticsEventAction({
          action: 'pay',
          category: 'gift card',
          label: 'payment failed',
          nonInteraction: true,
        }),
        hidePageSpinner({ initiator: 'GiftCard: PreAuth Failed' }),
        showNotificationAction({
          buttonLabel: this.localeService.get('common.close'),
          dialogType: NotificationDialogType.GENERAL,
          initiator: 'GiftCard: PreAuth Failed',
          message: this.localeService.get('giftcard.preauthfailed'),
          onClose: () => this.store.dispatch(routeTo({ payload: ['select'] })),
        }),
      ])
    )
  );

  /**
   * The effect to complete payment with giftcard
   */
  onGiftCardOnlyPaymentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(payWithGiftCardSuccessAction),
      withLatestFrom(this.store.select(giftCardOnlyPaymentSelector)),
      filter(([action, { isGiftCardOnlyPayment }]) => isGiftCardOnlyPayment),
      mergeMap(([{ payload: preAuthGiftCards }, { giftCardConfig, billingDemographics }]) => {
        const { paymentMerchantId, paymentMethodCode, paymentProviderName, rawCardBrand } = giftCardConfig;

        const payments: AuthorizedPaymentInfo[] = preAuthGiftCards.map((giftCard) => ({
          amount: giftCard.amount,
          method: PaymentType.GIFT_CARD,
          authId: giftCard.paymentReference,
          legacy: {
            authId: giftCard.authId,
            paymentMerchantId,
            paymentMethodCode,
            paymentProviderName,
            rawCardBrand,
          },
          ...(validObject(giftCard.donation) && { donation: giftCard.donation }),
          ...(validObject(giftCard.insurance) && { insurance: giftCard.insurance }),
        }));

        return [
          hidePageSpinner({ initiator: 'Gift Card Payment complete' }),
          sendAnalyticsEventAction({
            action: 'pay',
            category: 'gift card only',
            label: 'payment complete',
            nonInteraction: true,
          }),
          updateBillingDemographicsAction({ payload: { ...billingDemographics, valid: true } }),
          gatherPaymentDetailsForGiftCardOnlyPaymentAction({ payload: payments }),
        ];
      })
    )
  );

  /**
   * The effect to pre-authorize multiple gift card entries
   */
  authMultipleCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authSVCAction),
      mergeMap(({ payload }) => {
        const { requests, nonPreAuthGiftCards, lastAction, amountTypes, preAuthGiftCards } = payload;
        return zip(...requests).pipe(
          map((apiResponses: Response<SVCPreAuthResponse>[]) => {
            if (hasFailedSvcPreAuth(apiResponses)) {
              return payWithGiftCardFailedAction();
            }

            const latestPreAuthGiftCards = [...preAuthGiftCards, ...mapPreAuthResponses(apiResponses, nonPreAuthGiftCards)];

            return getFinishAuthSvcAction({ lastAction, preAuthGiftCards: latestPreAuthGiftCards, amountTypes });
          }),
          catchError(() => of(payWithGiftCardFailedAction()))
        );
      })
    )
  );

  /**
   * GiftCardEffects constructor
   * @param actions$ Our action stream
   * @param store Our store
   * @param localeService LocaleService
   */
  constructor(private actions$: Actions, private store: Store<AppState>, private localeService: LocaleService, private apiService: ApiService) {}

  /**
   *
   * @param giftCardsAdded Gift cards added by the user
   * @param amountToPay cart amount to pay
   * @param giftCardConfiguration gift card configuration
   * @returns List of Gift Card PreAuth requests
   */
  compilePostApiRequestListForGiftCards(data: {
    giftCardData: GiftCard[];
    giftCardConfiguration: GiftCardPayment;
    policyInfoId: string;
    donation?: DonationAuthRequest;
    insurance?: InsuranceAuthRequest;
    lineItems?: LineItemsAndFees;
    billingDemographics?: Partial<DemographicsFormData>;
  }): Observable<Response<SVCPreAuthResponse>>[] {
    const { giftCardData, giftCardConfiguration, donation, insurance, lineItems, billingDemographics, policyInfoId = '' } = data;

    const createSvcPreAuthRequest = (card): Observable<Response<SVCPreAuthResponse>> => {
      const preAuthRequestBody = buildPreAuthRequestData({
        card,
        billingDemographics,
        merchantId: giftCardConfiguration.paymentMerchantId,
        donation: { ...donation },
        insurance: { ...insurance, policy_info_id: policyInfoId },
        lineItems,
      });

      return this.apiService.post<SVCPreAuthResponse>(ApiRequestType.SVC_PRE_AUTH, preAuthRequestBody);
    };

    return giftCardData.map((giftcard) => createSvcPreAuthRequest(giftcard));
  }
}
