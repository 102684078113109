import { NgModule } from '@angular/core';
import { WINDOW, windowFactory } from './window/window';
import { DOCUMENT, documentFactory } from './document/document';

@NgModule({
  declarations: [],
  providers: [
    {
      provide: WINDOW,
      useFactory: windowFactory,
    },
    {
      provide: DOCUMENT,
      useFactory: documentFactory,
    },
  ],
})
export class InjectionTokenModule {}
