import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { ApplePayEffects } from './applepay.effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, EffectsModule.forFeature([ApplePayEffects])],
})
export class ApplePayModule {}
