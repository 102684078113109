import { createAction, props } from '@ngrx/store';

import { ParentConfig } from './parent-config.state';

export enum ParentConfigActionTypes {
  SetParentConfig = '[Parent Config] Set Configurations',
  SetParentConfigError = '[Parent Config] Invalid Configuration',
  FetchParentConfigByToken = '[Parent Config] Fetch By Token',
  LoadedParentConfig = '[Parent Config] Loaded',
}

/**
 * Sets the parent config via the window.postMessage received from a parent app loading accesso pay through an iframe.
 */
export const setParentConfig = createAction(ParentConfigActionTypes.SetParentConfig, props<{ payload: ParentConfig; sessionId?: string }>());

/**
 * Handle errors when setting the parent config via iframe
 */
export const setParentConfigErrorAction = createAction(
  ParentConfigActionTypes.SetParentConfigError,
  props<{ payload?: ParentConfig; sessionId?: string; errorCode?: number }>()
);

/**
 * Fetches a parent configuration from the token passed in on the URL
 */
export const fetchParentConfig = createAction(ParentConfigActionTypes.FetchParentConfigByToken, props<{ payload: string }>());

/**
 * Loaded a parent configuration from the token passed in on the URL
 */
export const loadedParentConfigAction = createAction(ParentConfigActionTypes.LoadedParentConfig);
