export const ERROR_CODE_RESPONSE_NOT_OBJECT = 0;
export const ERROR_CODE_FAILED_TO_LOAD_IFRAME = 1;
export const ERROR_CODE_EWALLET_AUTHORIZE_PAYMENT_DECLINED = 2;
export const ERROR_CODE_PAY_WITH_TOKEN_PAYMENT_DECLINED = 3;
export const ERROR_CODE_SERVICE_FAILED = 4;
export const ERROR_CODE_INVALID_AMOUNT = 5;
export const ERROR_CODE_INVALID_CONFIGURATION = 6;
export const ERROR_CODE_MISSING_TOKEN = 7;

export const ERROR_CODE_MERCHANT_FAILED_TO_LOAD = 8;
export const ERROR_CODE_INVALID_SESSION_TOKEN = 9;
export const ERROR_CODE_INVALID_LOCALE = 10;

/***********************
 * Backend Error Codes *
 **********************/

/**
 * The card was declined by their bank.
 */
export const ERROR_CODE_CARD_DECLINED = 100;
/**
 * Address verification service failed and doesn't match what they have on file.
 */
export const ERROR_CODE_AVS_FAILED = 102;
/**
 * An invalid credit card was used.
 */
export const ERROR_CODE_INVALID_CREDIT_CARD = 107;
/**
 * The CVV didn't match what is on file.
 */
export const ERROR_CODE_CVV_FAILED = 117;
/**
 * This is a generic catch. You can see the many things it can be in the link below.
 * @see {@link https://accessoclientservices.atlassian.net/wiki/spaces/DFD/pages/929924198/Authorize+Non+3DS}
 */
export const ERROR_CODE_GENERIC_CODE = 120;
