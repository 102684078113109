import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { AcSpinnerModule } from './shared/ui/spinner/spinner.module';

import { FlexLayoutModule } from 'ngx-flexible-layout';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { InitializeModule } from './core/initialize/initialize.module';
import { LocaleIdService } from './core/locale/locale-id.service';
import { LocaleModule } from './core/locale/locale.module';
import { StoredWalletModule } from './feature/billing/stored-wallet/stored-wallet.module';
import { FeatureModule } from './feature/feature.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RoutingEffects } from './core/routing/routing.effects';
// TODO: remove or update to support new components
import { CustomStylesComponent } from './custom-styles.component';

const { production } = environment;

/**
 * Creates a new bugsnag error handler
 */
export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

@NgModule({
  declarations: [AppComponent, CustomStylesComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    StoreModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([RoutingEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 75, logOnly: production, name: 'accessoPay', connectInZone: true }),
    CoreModule,
    FeatureModule,
    RouterModule,
    AcSpinnerModule,
    FlexLayoutModule,
    StoredWalletModule,
    LocaleModule,
    // Needs to be the last module registered
    InitializeModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useClass: LocaleIdService,
    },
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
