import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { ApplicationBridgeEffects } from './application-bridge.effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, EffectsModule.forFeature([ApplicationBridgeEffects])],
})
export class ApplicationBridgeModule {}
