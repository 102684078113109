import { Inject, Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { select, Store } from '@ngrx/store';
import { timeout } from 'guest-app-ui';
import { get } from 'lodash-es';
import { map, Observable, Subject } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { selectAppConfig } from 'src/app/core/application-config/application-config.selectors';
import { WINDOW } from 'src/app/core/injection-token/window/window';
import { APP_CONFIG_RECAPTCHAV3 } from 'src/app/shared/enums/application-config.enum';
import { validString } from 'src/app/shared/utilities/types.utils';

export enum RecaptchaAction {
  VERIFY_3DS = '3ds',
  AUTHORIZE = 'authorize',
}

export interface RecaptchaConfiguration {
  enabled: boolean;
  key: string;
  enterprise?: boolean;
}

export const DEFAULT_RECAPTCHAV3_DOMAIN_URL = 'https://www.google.com';
export const GLOBAL_RECAPTCHAV3_DOMAIN_URL = 'https://www.recaptcha.net';
export const RECAPTCHAV3_TOKEN_MIN_LENGTH = 500;

@Injectable({
  providedIn: 'root',
})
export class RecaptchaService {
  private config?: RecaptchaConfiguration;

  constructor(@Inject(WINDOW) private window: Window, store: Store<AppState>) {
    store
      .pipe(
        select(selectAppConfig([APP_CONFIG_RECAPTCHAV3])),
        map((configs) => configs[APP_CONFIG_RECAPTCHAV3] ?? null)
      )
      .subscribe((recaptchav3) => (this.config = recaptchav3));
  }

  get enabled(): boolean {
    return this.config?.enabled === true && validString(this.siteKey);
  }

  get siteKey(): string {
    return this.config?.key ?? '';
  }

  /**
   * Executes a recaptcha and returns the token from Google
   * @param action The action being done
   * @returns The token received from Google
   */
  execute(action: RecaptchaAction): Observable<string | null> {
    const resolver = new Subject<string | null>();
    const { window } = this;

    if (!this.enabled || !get(window, 'grecaptcha')) {
      timeout(() => {
        resolver.next(null);
        resolver.complete();
      });
    } else {
      const recaptcha: ReCaptchaV2.ReCaptcha = get(window, `grecaptcha${this.config.enterprise ? '.enterprise' : ''}`);
      recaptcha.ready(() => {
        recaptcha.execute(this.siteKey, { action }).then((token) => {
          if (token.length < RECAPTCHAV3_TOKEN_MIN_LENGTH) {
            Bugsnag.notify(`RecaptchaService`, (event) => {
              event.addMetadata('Recaptcha token', { token, reason: 'Token is less than 500 characters' });
            });
          }
          // Push it to the next tick because this may endup getting ran syncronously
          timeout(() => {
            resolver.next(token);
            resolver.complete();
          });
        });
      });
    }

    return resolver.asObservable();
  }
}
