import { createReducer, on } from '@ngrx/store';
import * as ExtrasActions from 'src/app/feature/extras/extras.actions';
import { updateDonationSelectionAction } from './extras.actions';
import { ExtrasState, initialState } from './extras.state';

const reducer = createReducer(
  initialState,
  on(ExtrasActions.clearTicketProtectionDetailsAction, (state, action) => ({
    ...state,
    ticketProtection: { ...state.ticketProtection, quoteAmount: 0, quoteToken: null, isProtected: false, error: false },
  })),
  on(ExtrasActions.updateTicketProtectionAvailabilityAction, (state, action) => ({
    ...state,
    ticketProtection: { ...state.ticketProtection, configured: action.payload },
  })),
  on(ExtrasActions.updateTicketProtectionQuoteAction, (state, action) => ({
    ...state,
    ticketProtection: { ...state.ticketProtection, quoteAmount: action.payload },
  })),
  on(ExtrasActions.updateTicketProtectionQuoteTokenAction, (state, action) => ({
    ...state,
    ticketProtection: { ...state.ticketProtection, quoteToken: action.payload },
  })),
  on(ExtrasActions.updateTicketProtectionStatusAction, (state, action) => ({
    ...state,
    ticketProtection: { ...state.ticketProtection, isProtected: action.payload },
  })),
  on(ExtrasActions.updateTicketProtectionSplitInsuranceAction, (state, action) => ({
    ...state,
    ticketProtection: { ...state.ticketProtection, splitInsurance: action.payload },
  })),
  on(ExtrasActions.updateTicketProtectionPolicyInfoAction, (state, action) => ({
    ...state,
    ticketProtection: { ...state.ticketProtection, policyInfoId: action.payload },
  })),
  on(ExtrasActions.updateTicketProtectionErrorAction, (state, action) => ({ ...state, ticketProtection: { ...state.ticketProtection, error: true } })),
  on(ExtrasActions.donationRenderSuccessAction, (state, action) => ({ ...state, donation: { ...state.donation, rendered: true } })),
  on(ExtrasActions.updateDonationAvailabilityAction, (state, action) => ({ ...state, donation: { ...state.donation, configured: action.payload } })),
  on(ExtrasActions.updateDonationErrorAction, (state, action) => ({ ...state, donation: { ...state.donation, error: action.payload } })),
  on(ExtrasActions.updateDonationConfigAction, (state, { payload }) => ({
    ...state,
    donation: { ...state.donation, configured: payload.configured, splitDonation: payload.splitDonation },
  })),
  on(updateDonationSelectionAction, (state, action) => ({
    ...state,
    donation: {
      ...state.donation,
      amount: action.payload.donationAmount,
      type: action.payload.donationType,
    },
  })),
  on(ExtrasActions.updateDonationContinuedAction, (state, action) => ({
    ...state,
    donation: {
      ...state.donation,
      continued: action.payload.continued,
    },
  })),
  on(ExtrasActions.updateTicketProtectionContinuedAction, (state, action) => ({
    ...state,
    ticketProtection: {
      ...state.ticketProtection,
      continued: action.payload.continued,
    },
  })),
  on(ExtrasActions.resetContinuedAction, (state, action) => ({
    ...state,
    donation: {
      ...state.donation,
      continued: false,
    },
    ticketProtection: {
      ...state.ticketProtection,
      continued: false,
    },
  }))
);

export function extrasReducer(state, action): ExtrasState {
  return reducer(state, action);
}
