import { InsuranceAuthResponse } from 'src/app/core/api/responses/authorize';
import { DonationAuthResponse } from 'src/app/core/application-bridge/application-bridge.models';

export const MAX_NUM_GIFT_CARDS = 3;
export const MIN_NUM_GIFT_CARDS = 1;

export interface GiftCardState {
  isGiftCardOnlyPayment: boolean;
  maxEntries: number;
  totalBalance: number;
  currencyCode: string;
  showAddSuccessMsg: boolean;
  giftcards: GiftCard[];
}

export interface GiftCard {
  /**
   * SVC number
   */
  cardNumber: string;
  /**
   * Pre-authorization id
   */
  authId?: number;
  /**
   * Pre-authorization total
   */
  preAuthBalance?: number;
  /**
   * Pre-authorization payment reference
   */
  paymentReference?: string;
  /**
   * Current Balance
   */
  balance: number;
  /**
   * Currency code
   */
  currencyCode: string;
  /**
   * Pin number
   */
  pin?: string;
  /**
   * Amount allocated for cart total
   */
  amount?: number;
  /**
   * Amount allocated for donation
   */
  donation?: DonationAuthResponse;
  /**
   * Amount allocated for insurance
   */
  insurance?: InsuranceAuthResponse;
}

export interface GiftCardData {
  card_number: string;
  amount: number;
  donation: number;
  insurance: number;
  pin?: string;
}

export interface AmountType {
  type: string;
  amount: number;
}

export const initialState: GiftCardState = {
  isGiftCardOnlyPayment: false,
  maxEntries: MAX_NUM_GIFT_CARDS,
  totalBalance: 0,
  currencyCode: 'USD',
  showAddSuccessMsg: false,
  giftcards: [],
};
