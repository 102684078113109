import { Inject, Injectable } from '@angular/core';
import { Action, createSelector, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { sendAnalyticsEventAction } from 'src/app/core/analytics/analytics.actions';
import { Response } from 'src/app/core/api/api.interface';
import { ApiService } from 'src/app/core/api/api.service';
import { isResponseOK } from 'src/app/core/api/api.utilities';
import { CreateJwtResponse } from 'src/app/core/api/responses/create-jwt';
import { Enrollment3DSResponse, EnrollmentCheck3DSRequest } from 'src/app/core/api/responses/enrollment-3ds';
import { Validate3DSRequest, Validate3DSResponse } from 'src/app/core/api/responses/validate-3ds';
import { sendMessageAction } from 'src/app/core/application-bridge/application-bridge.actions';
import { PaymentFailed } from 'src/app/core/application-bridge/application-bridge.models';
import { selectAppConfig } from 'src/app/core/application-config/application-config.selectors';
import { WINDOW } from 'src/app/core/injection-token/window/window';
import { LocaleService } from 'src/app/core/locale/locale.service';
import { AcLoggerService } from 'src/app/core/logger/logger.service';
import { DialogData, NotificationDialogType } from 'src/app/core/notification/dialog/confirm-dialog/confirm-dialog.component';
import { showNotificationAction } from 'src/app/core/notification/notification.actions';
import { hidePageSpinner, showPageSpinner } from 'src/app/core/page-spinner/page-spinner.actions';
import { selectLineItemsAndFees, selectRecurring, selectUserInfo } from 'src/app/core/parent-config/parent-config.selectors';
import { ParentConfigState } from 'src/app/core/parent-config/parent-config.state';
import { LineItemsAndFees } from 'src/app/core/parent-config/parent-config.utils';
import { CreditCard } from 'src/app/core/payment-configuration/payment-configuration.model';
import { selectCreditCards } from 'src/app/core/payment-configuration/payment-configuration.selectors';
import { routeTo } from 'src/app/core/routing/routing.actions';
import { setAuthorizingAction } from 'src/app/core/session/session.actions';
import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import { EVENT_PAYMENT_FAILED } from 'src/app/shared/enums/application-bridge.enums';
import { APP_CONFIG_SANDBOX_MODE } from 'src/app/shared/enums/application-config.enum';
import { TokenType } from 'src/app/shared/enums/billing.enums';
import { selectPaymentAmount } from 'src/app/shared/selectors/configuration.selectors';
import { filter3DSCards, getPaymentConfigurationForCreditCardsByRawCardBrand } from 'src/app/shared/utilities/credit-cards.utils';
import { calculateSplitAmountWithDonation } from 'src/app/shared/utilities/donation.utils';
import { calculateSplitAmountWithInsurance } from 'src/app/shared/utilities/insurance.utils';
import { toInt } from 'src/app/shared/utilities/number.utils';
import { addScript } from 'src/app/shared/utilities/script-loader.utils';
import { joinStrings } from 'src/app/shared/utilities/string.utils';
import { validObject, validString } from 'src/app/shared/utilities/types.utils';
import { gatherPaymentDetailsForCybersource3DSAction, updateBillingDemographicsAction } from '../../billing/billing.actions';
import { selectUseSandbox } from '../../billing/billing.selectors';
import { selectDonation, selectTicketInsuranceQuoteToken, selectTicketProtection } from '../../extras/extras.selectors';
import { DonationState, TicketProtectionState } from '../../extras/extras.state';
import { RecaptchaAction, RecaptchaService } from '../../recaptcha/recaptcha.service';
import { has3DSTransactionRequirements, map3DSDataToAuthorizeRequest } from './cardinal-3ds.utils';

@Injectable({
  providedIn: 'root',
})
export class Cardinal3DSService {
  /**
   * The credit cards configured.
   */
  private creditCards: CreditCard[] = [];
  /**
   * If we're in a production environment.
   */
  private isProduction = false;
  /**
   * If cardinal is configured.
   */
  private cardinalConfigured = false;
  /**
   * Is a new cardinal session?
   */
  private isNewCardinalSession = true;
  /**
   * The 3DS Data.
   */
  private threeDSData: ThreeDSData;
  /**
   * The reference ID.
   */
  private referenceId = '';
  /**
   * The authorization ID
   */
  private authId;
  /**
   * The payment reference ID
   */
  private paymentReference = '';
  /**
   * Donation State
   */
  private donation: DonationState;
  /**
   * The amount to be paid by credit card
   */
  private paymentAmount: number;
  /**
   * The amount to be paid by credit card
   */
  private lineItemsAndFees: LineItemsAndFees;
  /**
   * Parent Config State: recurring
   */
  private isRecurring: boolean;
  /**
   * Parent Config State: user
   */
  private user: ParentConfigState['config']['user'];
  /**
   * the last merchant id from card selected
   */
  private lastMechantId: number;
  /**
   * Ticket insurance state
   */
  private insurance: TicketProtectionState;
  /**
   * The quote token for ticket insurance
   */
  private ticketInsuranceQuoteToken: string;
  /**
   * Sandbox mode
   */
  private useSandbox?: boolean;

  /**
   * @param window The window object
   * @param store The main store
   * @param logger Our logging service
   * @param api The api service
   */
  constructor(
    @Inject(WINDOW) private window: Window,
    private store: Store<AppState>,
    private logger: AcLoggerService,
    private api: ApiService,
    private localeService: LocaleService,
    private recaptcha: RecaptchaService
  ) {
    this.store
      .select(
        createSelector(
          selectCreditCards,
          selectLineItemsAndFees,
          selectRecurring,
          selectUserInfo,
          selectDonation,
          selectTicketProtection,
          selectTicketInsuranceQuoteToken,
          selectPaymentAmount,
          selectUseSandbox,
          selectAppConfig([APP_CONFIG_SANDBOX_MODE]),
          (cards, lineItemsAndFees, recurring, user, donation, ticketProtection, quoteToken, paymentAmount, useSandbox, appConfigs) => {
            return { cards, lineItemsAndFees, recurring, user, donation, ticketProtection, quoteToken, paymentAmount, useSandbox, appConfigs };
          }
        )
      )
      .subscribe(({ cards, lineItemsAndFees, recurring, user, donation, ticketProtection, quoteToken, paymentAmount, useSandbox, appConfigs }) => {
        this.creditCards = cards;
        this.isRecurring = recurring;
        this.user = user;
        this.lineItemsAndFees = lineItemsAndFees;
        this.donation = donation;
        this.paymentAmount = paymentAmount;
        this.useSandbox = useSandbox;
        this.insurance = ticketProtection;
        this.ticketInsuranceQuoteToken = quoteToken;
      });
  }

  /**
   * Find out if we have any 3ds enabled cards
   */
  has3DSCards(): boolean {
    return this.creditCards.some(filter3DSCards);
  }

  /**
   * Loads Cardinal's Songbird JavaScript SDK
   */
  loadAssets(): Observable<string> {
    return addScript(`https://songbird${this.useSandbox ? 'stag' : ''}.cardinalcommerce.com/edge/v1/songbird.js`, true, {
      id: 'ap-songbird',
    });
  }

  /**
   * Configure Cardinal
   * @see {@link https://cardinaldocs.atlassian.net/wiki/spaces/CC/pages/557065/Songbird.js#Songbird.js-Cardinal.configure}
   */
  configureCardinal(): boolean {
    if (this.cardinalConfigured) {
      return true;
    }

    this.window.Cardinal.configure({ logging: { level: this.logger.isEnabled() ? 'on' : 'off' } });
    this.cardinalConfigured = true;
    this.setupCardinalEvents();

    return true;
  }

  /**
   * Sets up Cardinal Events
   */
  setupCardinalEvents(): void {
    const { Cardinal } = this.window;

    // Remove Event Listeners before subscribing
    Cardinal.off('payments.setupComplete');
    Cardinal.off('payments.validated');

    // SUBSCRIBE TO CARDINAL EVENTS
    Cardinal.on('payments.setupComplete', () => {
      // Trigger bin detection
      this.triggerBinProcess();
    });

    Cardinal.on('payments.validated', (result, jwt) => {
      this._3dsTransactionResult(result, jwt);
    });
  }

  /**
   * Returns Cardinal session status
   */
  isCardinalSessionNew(): boolean {
    return this.isNewCardinalSession;
  }

  /**
   * Sets Cardinal session status
   * @param status Cardinal session status
   */
  setCardinalSessionStatus(status: boolean): void {
    this.isNewCardinalSession = status;
  }

  /**
   * Sets 3DS data
   * @param data 3ds data
   */
  set3DSData(data: ThreeDSData): void {
    this.threeDSData = data;
  }

  /**
   * Sets payment reference id
   * @param paymentReference auth id
   */
  setPaymentReference(paymentReference: string): void {
    this.paymentReference = validString(paymentReference) ? paymentReference : '';
  }

  /**
   * Sets auth id
   * @param authId auth id
   */
  setAuthId(authId: number): void {
    this.authId = authId;
  }

  /**
   * Sets 3ds reference id
   * @param referenceId reference id
   */
  setReferenceId(referenceId: string): void {
    this.referenceId = validString(referenceId) ? referenceId : '';
  }

  /**
   * Return 3ds data
   */
  get3DSData(): ThreeDSData {
    return this.threeDSData;
  }

  /**
   * Returns auth id
   */
  getAuthId(): number {
    return this.authId;
  }

  /**
   * Returns payment reference id
   */
  getPaymentReference(): string {
    return this.paymentReference;
  }

  /**
   * Returns reference id
   */
  getReferenceId(): string {
    return this.referenceId;
  }

  /**
   * Initiates Cardinal 3DS flow
   * @param data 3ds data
   * @throws {TypeError} if the arguments are not valid
   */
  initiateCardinal3DS(data: ThreeDSData): void {
    const { store } = this;

    store.dispatch(setAuthorizingAction({ authorizing: true }));

    if (!data) {
      store.dispatch(
        sendMessageAction({
          key: EVENT_PAYMENT_FAILED,
          payload: {},
        })
      );
      throw new TypeError('3DS data not valid');
    }

    this.set3DSData(data);

    if (this.isCardinalSessionNew()) {
      this.lastMechantId = data.cardDetails.paymentMerchantId;
      this.requestJWTtoken();
    } else {
      this.triggerBinProcess();
    }
  }

  /**
   * make the request and set the token to continue with the flow
   * @param data
   */
  requestJWTtoken(): void {
    this.api
      .post<CreateJwtResponse>(ApiRequestType.CREATE_CARDINAL_JWT, {
        merchant_id: this.lastMechantId,
      })
      .pipe(take(1))
      .subscribe((resp) => {
        if (!isResponseOK(resp.status)) {
          const { localeService } = this;
          return [
            sendMessageAction({
              key: EVENT_PAYMENT_FAILED,
              payload: {},
            }),
            hidePageSpinner({ initiator: 'cardinal: create jwt failed' }),
            showNotificationAction({
              buttonLabel: localeService.get('common.close'),
              dialogType: NotificationDialogType.GENERAL,
              initiator: 'Cardinal: JWT Response error',
              message: localeService.get('cardinal.jwterror'),
            }),
          ].forEach((action) => this.store.dispatch(action));
        }

        const { jwt, reference_id } = resp.response;
        this.setReferenceId(reference_id);
        this.window.Cardinal.setup('init', { jwt });
        this.setCardinalSessionStatus(false);
      });
  }

  /**
   * Triggers Cardinal's bin process event
   */
  triggerBinProcess(): void {
    const maskedValue = this.get3DSData().cardDetails.cardData.token.number?.maskedValue;
    this.window.Cardinal.trigger('bin.process', maskedValue).finally(this.requestEnrollmentCheck.bind(this));
  }

  /**
   * Returns enrollment request object
   */
  getEnrollmentRequestData(): EnrollmentCheck3DSRequest {
    const data = this.get3DSData();
    const { cartItems, fees } = this.lineItemsAndFees;
    const isRecurringFlag = toInt(this.isRecurring) as 0 | 1;
    const { paymentMerchantId, cardData } = data.cardDetails;
    const { token, tokenType } = cardData;
    const { address1, address2, city, state, zip, country, firstName, lastName, middleInitial, email, phone } = data.demographics;
    let card = {} as CreditCardInfo;
    const hasRequiredInfo = has3DSTransactionRequirements(cardData);
    let amount = this.paymentAmount;
    const { insurance, amount: amountAfterInsurance } = calculateSplitAmountWithInsurance(amount, this.insurance);
    amount = amountAfterInsurance;
    const { donation, amount: amountAfterDonations } = calculateSplitAmountWithDonation(amount, this.donation);
    amount = amountAfterDonations;

    if (!hasRequiredInfo) {
      this.handleInvalidData();
      return;
    }

    if (tokenType === TokenType.GOOGLE_PAY) {
      card = { tokenType, token_response: JSON.stringify(token) };
    } else {
      // CREDIT CARD flow
      card = { transient_token_jwt: token.transientTokenJwt };
    }

    const reqObj: EnrollmentCheck3DSRequest = {
      CARD: card,
      CARDHOLDER: {
        address: validString(address2) ? `${address1} ${address2}` : address1,
        city,
        country,
        customer_id: this.user?.customerId || '',
        email,
        f_name: firstName,
        l_name: lastName,
        m_name: middleInitial ? middleInitial : '',
        phone_number: phone,
        state,
        zip,
      },
      amount,
      merchant_id: paymentMerchantId,
      recurring_flag: isRecurringFlag,
      ...(isRecurringFlag && { recurring_type: 'recurring' }),
      reference_id: this.getReferenceId(),
      cartItems,
      fees,
      sandbox_mode: this.useSandbox,
      ...(donation && { donation }),
      ...(insurance && { insurance }),
    };

    if (data.saveCard) {
      reqObj.saveCardToProfile = true;
    }

    return reqObj;
  }

  /**
   * Request Enrollment Check
   */
  requestEnrollmentCheck(): void {
    const reqObj = this.getEnrollmentRequestData();
    if (!validObject(reqObj)) {
      return;
    }

    this.store.dispatch(showPageSpinner({ initiator: 'cardinal: request enrollment' }));

    this.recaptcha.execute(RecaptchaAction.VERIFY_3DS).subscribe((token) => {
      // Add recaptcha if configured
      if (validString(token)) {
        reqObj.recaptcha_token = token;
      }

      this.api
        .post<Enrollment3DSResponse>(ApiRequestType.ENROLLMENT_CHECK_3DS, reqObj)
        .pipe(take(1))
        .subscribe((data) => {
          if (!isResponseOK(data.status)) {
            return this.handleFailedEnrollmentCheck(data.response);
          }

          this.handleEnrollmentCheck(data.response);
        });
    });
  }

  /**
   * Handles a successful enrollment check
   * @param response The full API response
   */
  handleEnrollmentCheck(response: Enrollment3DSResponse): void {
    const enrollmentData = response;
    const actions: Action[] = [hidePageSpinner({ initiator: 'cardinal: enrollment' })];
    const is3DSEnrolled = enrollmentData.enrolled_3DS === 'Y';
    this.setPaymentReference(enrollmentData.paymentReference);
    this.setAuthId(enrollmentData.auth_id);

    if (is3DSEnrolled) {
      // Show 3DS authentication
      this.window.Cardinal.continue(
        'cca',
        {
          AcsUrl: enrollmentData.redirect_url,
          Payload: enrollmentData.pa_req,
        },
        {
          OrderDetails: {
            TransactionId: enrollmentData.authentication_transaction_id,
          },
        }
      );
    } else {
      // normal payment flow
      const { cardDetails, demographics } = this.get3DSData();
      const rawCardBrand = cardDetails?.cardData.code ?? response.card_type_code;
      const cardConfig = getPaymentConfigurationForCreditCardsByRawCardBrand(this.creditCards, rawCardBrand);
      const payload = map3DSDataToAuthorizeRequest(enrollmentData, cardDetails, cardConfig);
      const telCode = validString(demographics.telCode) ? demographics.telCode.replace(/[^0-9]/g, '') : '';

      actions.push(
        sendAnalyticsEventAction({
          action: 'pay',
          category: 'credit card - 3ds',
          label: 'payment complete',
          nonInteraction: true,
        }),
        updateBillingDemographicsAction({ payload: { ...demographics, valid: true, telCode } }),
        gatherPaymentDetailsForCybersource3DSAction({ payload })
      );
    }

    actions.forEach((action) => this.store.dispatch(action));
  }

  /**
   * Handles failed responses from the enrollment check
   * @param response The API response
   */
  handleFailedEnrollmentCheck(response: Enrollment3DSResponse): void {
    const { localeService } = this;
    const { error_code, error_msg } = response;

    const actions: Action[] = [
      sendAnalyticsEventAction({
        action: 'pay',
        category: 'credit card - 3ds',
        label: 'payment failed',
        nonInteraction: true,
      }),
      sendMessageAction({
        key: EVENT_PAYMENT_FAILED,
        payload: {
          errorCode: error_code,
          reason: error_msg,
        } as PaymentFailed,
      }),
      hidePageSpinner({ initiator: 'cardinal: enrollment check failed' }),
    ];

    const notificationPayload: DialogData = {
      dialogType: NotificationDialogType.GENERAL,
      buttonLabel: localeService.get('common.close'),
      message: localeService.get(`creditcard.paymentdeclined`),
      initiator: 'Enrollment Check 3DS: Failed',
    };

    const onClose = () => {
      this.store.dispatch(routeTo({ payload: ['select'] }));
    };

    if (validString(error_code)) {
      notificationPayload.message = localeService.get(`errorcode.${error_code}`);
      notificationPayload.onClose = onClose;
    } else if (validString(error_msg)) {
      notificationPayload.message = localeService.get(`creditcard.paymentdeclined`);
    } else {
      notificationPayload.message = localeService.get(`creditcard.internalerror`);
      notificationPayload.onClose = onClose;
    }

    actions.push(showNotificationAction(notificationPayload));

    actions.forEach((action) => this.store.dispatch(action));
  }

  /**
   * 3ds transaction result callback
   * @param result 3ds transaction result
   * @param jwt jwt
   * @throws {Error} if jwt is not valid
   */
  private _3dsTransactionResult(result, jwt): void {
    if (validString(jwt)) {
      this.validate3DS(jwt);
    } else {
      this.store.dispatch(
        sendMessageAction({
          key: EVENT_PAYMENT_FAILED,
          payload: {},
        })
      );
      throw new Error(`Error Completing 3DS Transaction: ${result.ErrorDescription}`);
    }
  }

  /**
   * Returns request object for validate3DS
   * @param jwt jwt
   */
  getValidate3DSRequestData(jwt: string): Validate3DSRequest {
    const data = this.get3DSData();
    const { paymentMerchantId, cardData } = data.cardDetails;
    const { cvv, token, tokenType, month, year } = cardData;
    const { cartItems, fees } = this.lineItemsAndFees;
    const { address1, address2, city, state, zip, country, firstName, lastName, middleInitial, email, phone } = data.demographics;
    const hasRequiredInfo = has3DSTransactionRequirements(cardData);

    if (!hasRequiredInfo || !validString(jwt)) {
      this.handleInvalidData();
      return;
    }

    const paymentReference = this.getPaymentReference();
    const authId = this.getAuthId();

    let card = {} as CreditCardInfo;
    if (tokenType === TokenType.GOOGLE_PAY) {
      card = {
        tokenType,
        token_response: JSON.stringify(token),
        cvv2: cvv ?? '',
        expire_date: validString(year) ? `${year.substring(2)}${month}` : '',
      };
    } else {
      // CREDIT CARD flow
      card = { transient_token_jwt: token.transientTokenJwt };
    }

    const reqObj: Validate3DSRequest = {
      CARD: card,
      CARDHOLDER: {
        address: joinStrings(' ', address1, address2),
        city,
        customer_id: this.user?.customerId || '',
        country,
        email,
        f_name: firstName,
        l_name: lastName,
        m_name: middleInitial ? middleInitial : '',
        phone_number: phone,
        state,
        zip,
      },
      amount: this.paymentAmount,
      payment_reference: paymentReference,
      auth_id: authId,
      jwt,
      merchant_id: paymentMerchantId,
      saveCardToProfile: !!data.saveCard,
      cartItems,
      fees,
    };

    if (validString(this.ticketInsuranceQuoteToken)) {
      reqObj.insurance_quote_token = this.ticketInsuranceQuoteToken;
    }

    return reqObj;
  }

  /**
   * Validate 3DS
   * @param jwt jwt
   * @throws {Error} if request object is not valid
   */
  validate3DS(jwt: string): void {
    const reqObj = this.getValidate3DSRequestData(jwt);

    if (!validObject(reqObj)) {
      this.store.dispatch(
        sendMessageAction({
          key: EVENT_PAYMENT_FAILED,
          payload: {},
        })
      );
      return;
    }

    this.store.dispatch(showPageSpinner({ initiator: 'cardinal: validate 3ds' }));
    this.api
      .post<Validate3DSResponse>(ApiRequestType.VALIDATE_3DS, reqObj)
      .pipe(take(1))
      .subscribe((data) => {
        if (!isResponseOK(data.status)) {
          this.handleFailedValidation(data);
          return;
        }

        this.handleValidate3DS(data.response);
      });
  }

  /**
   * Handles failed responses from the validate3ds
   * @param data The API response data
   */
  handleFailedValidation(data: Response<Validate3DSResponse>) {
    const { localeService } = this;
    const { error_code, error_msg } = data?.response;

    const actions: Action[] = [
      sendAnalyticsEventAction({
        action: 'pay',
        category: 'credit card - 3ds',
        label: 'payment failed',
        nonInteraction: true,
      }),
      sendMessageAction({
        key: EVENT_PAYMENT_FAILED,
        payload: {
          errorCode: error_code,
          reason: error_msg,
        } as PaymentFailed,
      }),
      hidePageSpinner({ initiator: 'cardinal: validate 3ds failed' }),
    ];

    const notificationPayload: DialogData = {
      dialogType: NotificationDialogType.GENERAL,
      buttonLabel: localeService.get('common.close'),
      message: localeService.get(`creditcard.paymentdeclined`),
      initiator: 'Validate 3DS: Failed',
    };
    const onClose = () => this.store.dispatch(routeTo({ payload: ['select'] }));

    if (validString(error_code)) {
      notificationPayload.message = localeService.get(`errorcode.${error_code}`);
      notificationPayload.onClose = onClose;
    } else if (validString(error_msg)) {
      notificationPayload.message = localeService.get(`creditcard.paymentdeclined`);
    } else {
      notificationPayload.message = localeService.get(`creditcard.internalerror`);
      notificationPayload.onClose = onClose;
    }

    actions.push(showNotificationAction(notificationPayload));

    actions.forEach((action) => this.store.dispatch(action));
  }

  /**
   * Handles a successful validate3ds call
   * @param response validate 3ds response
   */
  handleValidate3DS(response: Validate3DSResponse) {
    const { cardDetails, demographics } = this.get3DSData();
    const rawCardBrandCode = cardDetails.cardData.code ?? response?.card_type_code;
    const paymentConfiguration = getPaymentConfigurationForCreditCardsByRawCardBrand(this.creditCards, rawCardBrandCode);
    const authRequestPayload = map3DSDataToAuthorizeRequest(response, cardDetails, paymentConfiguration);
    const telCode = validString(demographics.telCode) ? demographics.telCode.replace(/[^0-9]/g, '') : '';

    [
      sendAnalyticsEventAction({
        action: 'pay',
        category: 'credit card - 3ds',
        label: 'payment complete',
        nonInteraction: true,
      }),
      updateBillingDemographicsAction({ payload: { ...demographics, valid: true, telCode } }),
      gatherPaymentDetailsForCybersource3DSAction({ payload: authRequestPayload }),
      hidePageSpinner({ initiator: 'cardinal: validate 3ds' }),
    ].forEach((action) => this.store.dispatch(action));
  }

  /**
   * Handles invalid data
   */
  handleInvalidData(): void {
    const { localeService } = this;
    const actions: Action[] = [
      sendMessageAction({
        key: EVENT_PAYMENT_FAILED,
        payload: {},
      }),
      hidePageSpinner({ initiator: 'cardinal: Invalid payment data' }),
      sendAnalyticsEventAction({
        action: 'pay',
        category: 'credit card - 3ds',
        label: 'payment failed',
        nonInteraction: true,
      }),
      showNotificationAction({
        dialogType: NotificationDialogType.GENERAL,
        buttonLabel: localeService.get('common.close'),
        message: localeService.get(`creditcard.invalid_data`),
        initiator: 'Cardinal: Invalid Data',
        onClose: () => this.store.dispatch(routeTo({ payload: ['select'] })),
      }),
    ];

    actions.forEach((action) => this.store.dispatch(action));
  }
}
