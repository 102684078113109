import { Pipe, PipeTransform } from '@angular/core';

import { LocaleService } from './locale.service';
import { SafeHtml } from '@angular/platform-browser';

/**
 * Handles translations in templates.
 */
@Pipe({
  name: 'locale',
})
export class LocalePipe implements PipeTransform {
  /**
   * @param localeService The locale service.
   */
  constructor(private localeService: LocaleService) {}

  /**
   * Transforms a `section.target` string to an actual locale.
   * @param value The section.target string value.
   * @param suffix The suffix if you want one.
   * @param asHtml If you want to return it as SafeHtml to be used with [textContent]
   */
  transform(value: any, suffix?: string, asHtml?: boolean): string | SafeHtml {
    return this.localeService.get(value, suffix, asHtml);
  }
}
