import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { paymentReducer } from './payment.reducer';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('payment', paymentReducer)],
})
export class PaymentModule {}
