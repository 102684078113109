import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, catchError, forkJoin, from, map, switchMap, take } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { selectAppConfig } from 'src/app/core/application-config/application-config.selectors';
import { APP_CONFIG_SALE_CYCLE_MERCHANT } from 'src/app/shared/enums/application-config.enum';
import { toObservable } from 'src/app/shared/utilities/observable.utils';
import { addScript } from 'src/app/shared/utilities/script-loader.utils';
import { validString } from 'src/app/shared/utilities/types.utils';

interface ScriptDetails {
  src: string;
  id: string;
  async: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ScriptsResolver implements Resolve<Observable<boolean>> {
  /**
   * Constructor
   * @param store The global store
   */
  constructor(private store: Store<AppState>) {}

  /**
   * Route's resolve method
   * @param route activated route snapshot
   * @param state router state snapshot
   * @returns an observable
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(selectAppConfig([APP_CONFIG_SALE_CYCLE_MERCHANT])),
      map((config) => config[APP_CONFIG_SALE_CYCLE_MERCHANT]),
      take(1),
      switchMap((saleCycleMerchant) => (validString(saleCycleMerchant) ? this.loadScripts(saleCycleMerchant) : toObservable(true)))
    );
  }

  /**
   * Load scripts based on merchant name
   * @param merchantName name of merchant
   * @returns an observable
   */
  loadScripts(merchantName: any): Observable<boolean> {
    const additionalScripts: ScriptDetails[] = this.gatherScriptDetails(merchantName);

    if (!additionalScripts.length) {
      return toObservable(true);
    }

    const obsArr = additionalScripts.map((srciptVal) => addScript(srciptVal.src, srciptVal.async, { id: srciptVal.id }));
    return forkJoin(obsArr).pipe(
      switchMap((_) => toObservable(true)),
      catchError(() => toObservable(true))
    );
  }

  /**
   * Gather script details
   * @param merchantName salecycle merchant name
   * @returns script details
   */
  gatherScriptDetails(saleCycleMerchant: any): ScriptDetails[] {
    const scriptsArr = [];

    if (validString(saleCycleMerchant)) {
      const saleCycleScript = this.buildSaleCycleScript(saleCycleMerchant);
      if (validString(saleCycleScript)) {
        scriptsArr.push({ src: saleCycleScript, id: 'acsp_salecycle', async: true });
      }
    }

    return scriptsArr;
  }

  /**
   * Builds salecycle script from merchant name
   * @param merchantName salecycle merchant name
   * @returns salecycle script
   */
  buildSaleCycleScript(merchantName: string): string {
    if (validString(merchantName)) {
      return `https://s.salecycle.com/${merchantName}/bundle.js`;
    }
    return '';
  }
}
