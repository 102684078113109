import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { clientConfigurationReducer } from './client-configuration.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ClientConfigurationEffects } from './client-configuration.effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('clientConfiguration', clientConfigurationReducer), EffectsModule.forFeature([ClientConfigurationEffects])],
})
export class ClientConfigurationModule {}
