import { createAction, props } from '@ngrx/store';

import { AuthorizeRequest } from 'src/app/core/api/responses/authorize';

export enum CreditCardActionTypes {
  AUTHORIZE_NON_3DS_TRANSACTION = '[CyberSource Credit Card] Authorize Non 3DS Transaction',
  GIFT_CARD_SPLIT_CREDITCARD = '[Cybersource Credit Card] Initialize Gift Card Split Payment With Credit Card',
  GIFT_CARD_SPLIT_CREDITCARD_SUCCESS = '[Gift Card Effects] Gift Card Split Payment With Credit Card Success',
}

/**
 * Authorizes a non-3ds transaction.
 */
export const authorizeCybersourceNon3dsTransactionAction = createAction(
  CreditCardActionTypes.AUTHORIZE_NON_3DS_TRANSACTION,
  props<{ payload: AuthorizeRequest }>()
);

/**
 * Initialize gift card split payment
 */
export const initializeGiftCardSplitPaymentWithCreditCardAction = createAction(
  CreditCardActionTypes.GIFT_CARD_SPLIT_CREDITCARD,
  props<{ amount: number; payload: AuthorizeRequest }>()
);

/**
 * Gift card split payment success
 */
export const giftCardSplitPaymentWithCreditCardSuccessAction = createAction(
  CreditCardActionTypes.GIFT_CARD_SPLIT_CREDITCARD_SUCCESS,
  props<{ payload: AuthorizeRequest }>()
);
