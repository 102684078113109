import { PaymentMethodsResponseObject } from '@adyen/adyen-web/dist/types/core/ProcessResponse/PaymentMethodsResponse/types';
import { PaymentMethod as AdyenPaymentMethod } from '@adyen/adyen-web/dist/types/types';
import { PaymentMethod } from 'src/app/core/api/responses/get-payment-configuration';
import { PaymentType } from 'src/app/core/application-bridge/application-bridge.models';
import { AlternatePayment, ConfiguredPayments, PaymentMethodCode, PaymentProviderType } from 'src/app/core/payment-configuration/payment-configuration.model';
import { ResultCode } from 'src/app/pages/redirect/redirect.enums';
import { IDealIssuer } from 'src/app/pages/select/alternate-payments/alternate-payments-details.component';
import { normalize } from './string.utils';
import { Dictionary, validArray, validObject } from './types.utils';

export interface IdealPaymentMethod extends AdyenPaymentMethod {
  issuers: { name: string; id: string }[];
}

/**
 * Find out if a payment is adyen alternate payment
 * @param paymentMethod The payment method to test
 */
export function filterAdyenAlternatePayments(paymentMethod: AlternatePayment | PaymentMethod): boolean {
  return (
    isAdyen(paymentMethod.paymentProviderName) &&
    ![PaymentMethodCode.CREDIT_CARD, PaymentMethodCode.APPLE_PAY, PaymentMethodCode.GOOGLE_PAY].includes(paymentMethod.paymentMethodCode)
  );
}

/**
 * Returns the display order of alternate payment that matches payment method code
 * @param alternatePayments alternate payments
 * @param paymentMethodCode payment method code
 */
export function getAlternatePaymentDisplayOrder(alternatePayments: AlternatePayment[], paymentMethodCode: PaymentMethodCode) {
  if (!validArray(alternatePayments)) {
    return 9999;
  }

  const found = alternatePayments.find((alternatePayment) => alternatePayment.paymentMethodCode === paymentMethodCode);
  return found?.displayOrder || 9999;
}

/**
 * Returns the payment merchant id of alternate payment that matches payment method code
 * @param alternatePayments alternate payments
 * @param paymentMethodCode payment method code
 */
export function getPaymentMerchantIdByPaymentMethodCode(alternatePayments: AlternatePayment[], paymentMethodCode: PaymentMethodCode): number {
  if (!validArray(alternatePayments)) {
    throw new TypeError('alternatePayments must be a valid array');
  }

  const found = alternatePayments.find((alternatePayment) => alternatePayment.paymentMethodCode === paymentMethodCode);

  if (!found) {
    throw new Error(`unable to find payment method matching code ${paymentMethodCode}`);
  }
  return found.paymentMerchantId;
}

/**
 * Returns the payment configuration of alternate payment that matches payment method code
 * @param alternatePayments alternate payments
 * @param paymentMethodCode payment method code
 * @throws {TypeError} when alternate payments passed is not a valid array
 * @throws {Error} when no matching alternate payment is found
 */
export function getPaymentConfigurationByPaymentMethodCode(alternatePayments: AlternatePayment[], paymentMethodCode: PaymentMethodCode): AlternatePayment {
  if (!validArray(alternatePayments)) {
    throw new TypeError('alternatePayments must be a valid array');
  }

  const found = alternatePayments.find((alternatePayment) => alternatePayment.paymentMethodCode === paymentMethodCode);

  if (!found) {
    throw new Error(`unable to find payment method matching code ${paymentMethodCode}`);
  }
  return found;
}

/**
 * Filter valid alternate payments
 * @param paymentMethodNames payment method names
 * @param alternatePayment alternate payment
 */
export function filterValidPaymentMethods(paymentMethodNames: string[] = [], alternatePayment: AlternatePayment): boolean {
  if (!validArray(paymentMethodNames)) {
    return true;
  }

  const normalizedPaymentMethodNames = paymentMethodNames.map(normalize);
  const normalizedAlternatePaymentName = normalize(alternatePayment.paymentMethodName);

  return !normalizedPaymentMethodNames.includes(normalizedAlternatePaymentName);
}

/**
 * Filter ideal bank issuers
 * @param adyenPaymentMethodResponse adyen payment method response
 */
export function getConfiguredIdealIssuerList(adyenPaymentMethodResponse: PaymentMethodsResponseObject): IDealIssuer[] {
  if (!validObject(adyenPaymentMethodResponse) || !validArray(adyenPaymentMethodResponse.paymentMethods)) {
    return [];
  }

  const idealPaymentMethod = adyenPaymentMethodResponse.paymentMethods.find((pm) => pm.type === PaymentType.IDEAL) as IdealPaymentMethod;

  return idealPaymentMethod?.issuers ? idealPaymentMethod.issuers.map((is) => ({ label: is.name, value: is.id })) : [];
}

/**
 * Get Provider name with payment method code
 * @param configuredPayments configured payments
 * @param paymentMethodCode payment method code
 * @returns provider name for payment method code
 */
export function getPaymentProviderName(configuredPayments: ConfiguredPayments, paymentMethodCode: PaymentMethodCode): PaymentProviderType {
  if (!validObject(configuredPayments) || !paymentMethodCode) {
    return null;
  }

  const { APPLE_PAY, CREDIT_CARD, GOOGLE_PAY, SVC } = PaymentMethodCode;
  const { applePayCards, creditCards, googlePayCards, giftCard, alternatePayments } = configuredPayments || {};

  switch (paymentMethodCode) {
    case APPLE_PAY:
      return applePayCards[0]?.paymentProviderName;
    case CREDIT_CARD:
      return creditCards[0]?.paymentProviderName;
    case GOOGLE_PAY:
      return googlePayCards[0]?.paymentProviderName;
    case SVC:
      return giftCard?.paymentProviderName;
    default:
      return alternatePayments.find((altPayment) => altPayment?.paymentMethodCode === paymentMethodCode)?.paymentProviderName;
  }
}

/**
 * Gets the colors to use with the buttons
 * @param key The payment selected
 * @returns The styles object
 */
export function getCustomPaymentMethodColor(key: string): Dictionary<string> {
  switch (key) {
    case PaymentType.ALIPAY:
    case PaymentType.ALIPAY_HK:
      return { backgroundColor: '#009fe8' };
    case PaymentType.WECHAT_PAY:
      return { backgroundColor: '#1AAD19' };
    case PaymentType.KAKAO_PAY:
      return { backgroundColor: '#F9E000' };
    case PaymentType.PAYPAL:
      return { backgroundColor: '#FFC43A' };
  }

  return null;
}

/**
 * Returns the payment method code
 * @param paymentType The payment type
 * @returns The payment method code
 */
export function getPaymentMethodCode(paymentType: PaymentType): PaymentMethodCode {
  switch (paymentType) {
    case PaymentType.CREDIT_CARD:
      return PaymentMethodCode.CREDIT_CARD;
    case PaymentType.ADYEN_MOBILE_PAY:
      return PaymentMethodCode.ADYEN_MOBILE_PAY;
    case PaymentType.AFTERPAY:
      return PaymentMethodCode.AFTERPAY;
    case PaymentType.ALIPAY:
      return PaymentMethodCode.ALIPAY;
    case PaymentType.ALIPAY_HK:
      return PaymentMethodCode.ALIPAY_HK;
    case PaymentType.AMAZON_PAY:
      return PaymentMethodCode.AMAZON_PAY;
    case PaymentType.APPLE_PAY:
      return PaymentMethodCode.APPLE_PAY;
    case PaymentType.BANCONTACT_DESKTOP:
      return PaymentMethodCode.BANCONTACT_DESKTOP;
    case PaymentType.BANCONTACT_MOBILE:
      return PaymentMethodCode.BANCONTACT_MOBILE;
    case PaymentType.GIROPAY:
      return PaymentMethodCode.GIROPAY;
    case PaymentType.GOOGLE_PAY:
      return PaymentMethodCode.GOOGLE_PAY;
    case PaymentType.GRABPAY:
      return PaymentMethodCode.GRABPAY;
    case PaymentType.IDEAL:
      return PaymentMethodCode.IDEAL;
    case PaymentType.KAKAO_PAY:
      return PaymentMethodCode.KAKAO_PAY;
    case PaymentType.KLARNA_PAY_NOW:
      return PaymentMethodCode.KLARNA_PAY_NOW;
    case PaymentType.KLARNA_PAY_OVER_TIME:
      return PaymentMethodCode.KLARNA_PAY_OVER_TIME;
    case PaymentType.KOREA_CYBER_PAYMENTS:
      return PaymentMethodCode.KOREA_CYBER_PAYMENTS;
    case PaymentType.LINE_PAY:
      return PaymentMethodCode.LINE_PAY;
    case PaymentType.MB_WAY:
      return PaymentMethodCode.MB_WAY;
    case PaymentType.NAVER_PAY:
      return PaymentMethodCode.NAVER_PAY;
    case PaymentType.PAYPAL:
      return PaymentMethodCode.PAYPAL;
    case PaymentType.SEPADD:
      return PaymentMethodCode.SEPADD;
    case PaymentType.SOFORT:
      return PaymentMethodCode.SOFORT;
    case PaymentType.TTB_QR_CODE:
      return PaymentMethodCode.TTB_QR_CODE;
    case PaymentType.WECHAT_PAY:
      return PaymentMethodCode.WECHAT_PAY;
    case PaymentType.WALLET:
    default:
      return paymentType as any;
  }
}

/**
 * Gets the payment method to use with PaymentComplete event.
 * @param code The code from the response
 * @returns The payment type to use with the PaymentComplete event
 */
export function getPaymentMethodNameByCode(code: string): PaymentType {
  switch (code) {
    case PaymentMethodCode.ADYEN_MOBILE_PAY:
      return PaymentType.ADYEN_MOBILE_PAY;
    case PaymentMethodCode.AFTERPAY:
      return PaymentType.AFTERPAY;
    case PaymentMethodCode.ALIPAY:
      return PaymentType.ALIPAY;
    case PaymentMethodCode.ALIPAY_HK:
      return PaymentType.ALIPAY_HK;
    case PaymentMethodCode.AMAZON_PAY:
      return PaymentType.AMAZON_PAY;
    case PaymentMethodCode.BANCONTACT_DESKTOP:
      return PaymentType.BANCONTACT_DESKTOP;
    case PaymentMethodCode.BANCONTACT_MOBILE:
      return PaymentType.BANCONTACT_MOBILE;
    case PaymentMethodCode.GIROPAY:
      return PaymentType.GIROPAY;
    case PaymentMethodCode.GRABPAY:
      return PaymentType.GRABPAY;
    case PaymentMethodCode.IDEAL:
      return PaymentType.IDEAL;
    case PaymentMethodCode.KAKAO_PAY:
      return PaymentType.KAKAO_PAY;
    case PaymentMethodCode.KOREA_CYBER_PAYMENTS:
      return PaymentType.KOREA_CYBER_PAYMENTS;
    case PaymentMethodCode.LINE_PAY:
      return PaymentType.LINE_PAY;
    case PaymentMethodCode.MB_WAY:
      return PaymentType.MB_WAY;
    case PaymentMethodCode.NAVER_PAY:
      return PaymentType.NAVER_PAY;
    case PaymentMethodCode.SEPADD:
      return PaymentType.SEPADD;
    case PaymentMethodCode.SOFORT:
      return PaymentType.SOFORT;
    case PaymentMethodCode.TTB_QR_CODE:
      return PaymentType.TTB_QR_CODE;
    case PaymentMethodCode.WECHAT_PAY:
      return PaymentType.WECHAT_PAY;
    default:
      return PaymentType.CREDIT_CARD;
  }
}

/**
 * Get payment logo name
 * @param paymentType Payment type
 * @returns Payment logo name
 */
export function getPaymentLogoName(paymentType: PaymentType): string {
  switch (paymentType) {
    case PaymentType.KLARNA_PAY_NOW:
    case PaymentType.KLARNA_PAY_OVER_TIME:
      return 'klarnapay';
    default:
      return paymentType;
  }
}

/**
 * Higher Order Function that Validate provider name
 * @param paymentProvider
 * @returns m
 */
export function isPaymentProvider<T extends PaymentProviderType>(paymentProvider: PaymentProviderType): (paymentProviderName: T) => boolean {
  return (paymentProviderName: T) => paymentProviderName === paymentProvider;
}

/**
 * Is PaymentProvider: Cybersource
 */
export const isCybersource = isPaymentProvider(PaymentProviderType.Cybersource);

/**
 * Is PaymentProvider: Adyen
 */
export const isAdyen = isPaymentProvider(PaymentProviderType.Adyen);

/**
 * Is PaymentStatus: Authorised
 */
export const isAuthorised = (resultCode: ResultCode) => [ResultCode.AUTHORISED, ResultCode.AUTHORIZED].includes(normalize(resultCode) as ResultCode);
