import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { sessionReducer } from './session.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SessionEffects } from './session.effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('session', sessionReducer), EffectsModule.forFeature([SessionEffects])],
})
export class SessionModule {}
