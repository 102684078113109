import { isNull, isPlainObject, isString, isUndefined } from 'lodash-es';
const { isArray } = Array;

type Primitive = string | number | boolean;

export interface Dictionary<T> {
  [key: string]: T;
}

export type PrimitiveDictionary = Dictionary<Primitive>;

/**
 * Test if a variable is null or undefined
 * @param test The value to test
 */
export function isNullOrUndefined(test: any): boolean {
  return isNull(test) || isUndefined(test);
}

/**
 * Test whether a variable is a primitive value
 * @param test The value to test
 */
export function isPrimitive(test: any): boolean {
  return (typeof test !== 'object' && typeof test !== 'function') || test === null;
}

/**
 * Test whether a variable is a string and not empty
 * @param str The value to test
 */
export function validString(str: any): boolean {
  return isString(str) && str.length > 0;
}

/**
 * Test whether a variable is an object and not empty
 * @param obj The value to test
 */
export function validObject(obj: any): boolean {
  return isPlainObject(obj) && Object.keys(obj).length > 0;
}

/**
 * Test whether a variable is numeric or not.
 * @param test The value to test
 */
export function isNumeric(test: any): boolean {
  return (typeof test === 'number' || validString(test)) && !isNaN(test);
}

/**
 * Test whether a variable is an array and not empty.
 * @param test The value to test
 */
export function validArray(test: any): boolean {
  return isArray(test) && test.length > 0;
}
