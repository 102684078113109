import { Action, Store, createSelector, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DialogData, NotificationDialogType } from 'src/app/core/notification/dialog/confirm-dialog/confirm-dialog.component';
import { ERROR_CODE_PAY_WITH_TOKEN_PAYMENT_DECLINED, ERROR_CODE_RESPONSE_NOT_OBJECT, ERROR_CODE_SERVICE_FAILED } from 'src/app/shared/enums/error-code.enums';
import { PayWithTokenRequest, PayWithTokenResponse } from 'src/app/core/api/responses/pay-with-token';
import { PaymentFailed, PaymentType } from 'src/app/core/application-bridge/application-bridge.models';
import { apiResponse, deleteApiRequest, getApiRequest, postApiRequest } from 'src/app/core/api/api.actions';
import {
  deleteStoredTokenAction,
  deleteStoredTokenSuccessAction,
  giftCardSplitPaymentWithStoredWalletSuccessAction,
  initializeGiftCardSplitPaymentWithStoredWalletAction,
  payWithStoredTokenAction,
} from './stored-wallet.actions';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { hidePageSpinner, showPageSpinner } from 'src/app/core/page-spinner/page-spinner.actions';
import { validArray, validString } from 'src/app/shared/utilities/types.utils';

import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import { AppState } from 'src/app/app.state';
import { CreditCard } from 'src/app/core/payment-configuration/payment-configuration.model';
import { DeliveryState } from '../../delivery/delivery.state';
import { EVENT_PAYMENT_FAILED } from 'src/app/shared/enums/application-bridge.enums';
import { Injectable } from '@angular/core';
import { LocaleService } from 'src/app/core/locale/locale.service';
import { ParentConfigState } from 'src/app/core/parent-config/parent-config.state';
import { PaymentConfigurationState } from 'src/app/core/payment-configuration/payment-configuration.state';
import { StoredWalletItem } from './stored-wallet.state';
import { getPaymentConfigurationForCreditCardsByRawCardBrand } from 'src/app/shared/utilities/credit-cards.utils';
import { isObject } from 'lodash-es';
import { responseRequestType } from 'src/app/core/api/api.utilities';
import { selectPaymentConfiguration } from 'src/app/core/payment-configuration/payment-configuration.selectors';
import { sendMessageAction } from 'src/app/core/application-bridge/application-bridge.actions';
import { sendAnalyticsEventAction } from 'src/app/core/analytics/analytics.actions';
import { setParentConfig } from 'src/app/core/parent-config/parent-config.actions';
import { showNotificationAction } from 'src/app/core/notification/notification.actions';
import { storedWalletEffectsSelector } from './stored-wallet.selectors';
import { gatherPaymentDetailsForStoredWalletAction, updateBillingDemographicsAction } from '../billing.actions';
import { selectGiftCardState } from '../../gift-card/gift-card.selectors';
import { hasPreAuthGiftCards } from '../../gift-card/gift-card.utils';
import { setAuthorizingAction } from 'src/app/core/session/session.actions';
import { convertDonationResponse } from 'src/app/shared/utilities/donation.utils';
import { selectTotalAmount } from 'src/app/shared/selectors/configuration.selectors';
import { convertInsuranceResponse } from 'src/app/shared/utilities/insurance.utils';

@Injectable({
  providedIn: 'root',
})
export class StoredWalletEffects {
  /**
   * The configured merchant ID
   */
  private merchantId: string;
  /**
   * If the environment data has been loaded.
   */
  private environmentLoaded = false;
  /**
   * The pending requests.
   */
  private pendingRequests: Action[] = [];
  /**
   * The parent configuration.
   */
  private parentConfig: ParentConfigState;
  /**
   * The delivery state
   */
  private deliveryState: DeliveryState;
  /**
   * Stored tokens
   */
  private storedWalletItems: StoredWalletItem[] = [];
  /**
   * Credit cards
   */
  private creditCards: CreditCard[];

  /**
   * Handles making payment with stored token
   */
  payWithToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(payWithStoredTokenAction),
      withLatestFrom(
        this.store.select(createSelector(selectGiftCardState, selectTotalAmount, (giftCardState, totalAmount) => ({ giftCardState, totalAmount })))
      ),
      mergeMap(([action, currStates]) => {
        const actions: Action[] = [setAuthorizingAction({ authorizing: true })];

        if (currStates.giftCardState.totalBalance > 0 && !hasPreAuthGiftCards(currStates.giftCardState.giftcards)) {
          actions.push(initializeGiftCardSplitPaymentWithStoredWalletAction({ amount: currStates.totalAmount, payload: action.payload }));
        } else {
          actions.push(
            postApiRequest({
              requestType: ApiRequestType.PAY_WITH_TOKEN,
              body: action.payload,
            })
          );
        }

        return actions;
      })
    )
  );

  /**
   * Completes split payment
   */
  completeSplitPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(giftCardSplitPaymentWithStoredWalletSuccessAction),
      map((action) => {
        return postApiRequest({
          requestType: ApiRequestType.PAY_WITH_TOKEN,
          body: action.payload,
        });
      })
    )
  );

  /**
   * Watches for responses of DeleteToken. If the response is ok, fire off action to delete the token from the state.
   */
  deleteTokenResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(apiResponse),
      filter(responseRequestType(ApiRequestType.DELETE_TOKEN)),
      mergeMap((action) => {
        const actions: Action[] = [hidePageSpinner({ initiator: 'delete stored token response ' })];
        if (action.isOk) {
          actions.push(deleteStoredTokenSuccessAction({ payload: action.response }));
        } else {
          const { localeService } = this;
          actions.push(
            showNotificationAction({
              buttonLabel: localeService.get('common.close'),
              dialogType: NotificationDialogType.GENERAL,
              initiator: 'Stored Wallet: delete token response error',
              message: localeService.get('wallet.deletefailure'),
            })
          );
        }
        return actions;
      })
    )
  );

  /**
   * Handles actions to request deleting a stored token.
   */
  deleteStoredToken = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteStoredTokenAction),
      mergeMap((action) => {
        const { user } = this.parentConfig.config;

        return [
          showPageSpinner({ initiator: 'delete stored token' }),
          deleteApiRequest({
            requestType: ApiRequestType.DELETE_TOKEN,
            params: { customerId: user?.customerId },
            headers: { Authorization: `Bearer ${user?.accessToken}` },
            body: action.payload,
          }),
        ];
      })
    )
  );

  /**
   * Handles actions to request to fetch all a users stored wallet items.
   */
  retrieveTokens$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setParentConfig),
        map((action) => {
          const { user } = action.payload;
          if (isObject(user) && validString(user.accessToken) && validString(user.customerId)) {
            const request = getApiRequest({
              requestType: ApiRequestType.RETRIEVE_TOKENS,
              params: {
                customerId: user.customerId,
              },
              headers: {
                Authorization: `Bearer ${user.accessToken}`,
              },
            });

            if (this.merchantId && this.environmentLoaded) {
              this.store.dispatch(request);
            } else {
              this.pendingRequests.push(request);
            }
          }
        })
      ),
    {
      dispatch: false,
    }
  );

  /**
   * Handles the pay with token response from our backend api
   */
  onPayWithTokenResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(apiResponse),
      filter(responseRequestType(ApiRequestType.PAY_WITH_TOKEN)),
      mergeMap((action) => {
        const actions: Action[] = [hidePageSpinner({ initiator: 'select: pay with token error' })];
        const response = action.response as PayWithTokenResponse;
        const { merchant_id } = action.body as PayWithTokenRequest;
        const storedWalletItem = this.getStoredWalletByToken((action.body as PayWithTokenRequest).token);

        if (action.isOk && isObject(response)) {
          const tokenResponse = response;
          const { cardHolder } = storedWalletItem;
          const { user } = this.parentConfig.config;
          const { address: address1, address2, city, country, firstName, lastName, state, zip } = cardHolder;
          const paymentConfiguration = getPaymentConfigurationForCreditCardsByRawCardBrand(this.creditCards, tokenResponse.card_type_code);

          actions.push(
            sendAnalyticsEventAction({
              action: 'pay',
              category: 'stored wallet',
              label: 'payment complete',
              nonInteraction: true,
            }),
            updateBillingDemographicsAction({
              payload: {
                valid: true,
                firstName,
                lastName,
                address1,
                address2: address2 || '',
                city: city || '',
                country,
                state: state || '',
                zip: zip || '',
                email: '',
                phone: '',
                telCode: '',
              },
            }),
            gatherPaymentDetailsForStoredWalletAction({
              payload: {
                amount: response.amount,
                method: PaymentType.WALLET,
                approval_code: tokenResponse.approval_code,
                authId: tokenResponse.paymentReference,
                expire_date: tokenResponse.card_expire_date,
                legacy: {
                  authId: tokenResponse.auth_id,
                  paymentMerchantId: merchant_id,
                  paymentMethodCode: paymentConfiguration.paymentMethodCode,
                  paymentProviderName: paymentConfiguration.paymentProviderName,
                  rawCardBrand: paymentConfiguration.rawCardBrand,
                },
                lastFour: tokenResponse.card_last_four,
                cardType: tokenResponse.card_type_code,
                ...(tokenResponse.donation && {
                  donation: convertDonationResponse(tokenResponse.donation),
                }),
                ...(tokenResponse.insurance && {
                  insurance: convertInsuranceResponse(tokenResponse.insurance),
                }),
              },
            })
          );
        } else {
          const { localeService } = this;
          const payload: PaymentFailed = {};
          const notificationAction: DialogData = {
            buttonLabel: localeService.get('common.close'),
            dialogType: NotificationDialogType.GENERAL,
            initiator: 'Stored Wallet: pay with token response',
            message: '',
          };

          if (!isObject(response)) {
            payload.errorCode = ERROR_CODE_RESPONSE_NOT_OBJECT;
            payload.reason = 'Back response is invalid';
            payload.details = typeof response;
            notificationAction.message = localeService.get('wallet.internalservererror');
          } else if (validString(response.error_msg)) {
            payload.errorCode = ERROR_CODE_SERVICE_FAILED;
            payload.reason = response.error_code;
            payload.details = response.error_msg;
            if (validString(response.error_code)) {
              notificationAction.message = localeService.get(`errorcode.${response.error_code}`);
            } else {
              notificationAction.message = localeService.get('wallet.paymentdeclined');
            }
          } else {
            payload.errorCode = ERROR_CODE_PAY_WITH_TOKEN_PAYMENT_DECLINED;
            payload.reason = response.approval_code;
            payload.details = response.approval_text;
            notificationAction.message = localeService.get('wallet.paymentdeclined');
          }

          actions.push(showNotificationAction(notificationAction));
          actions.push(
            sendMessageAction({
              key: EVENT_PAYMENT_FAILED,
              payload,
            })
          );
        }

        return actions;
      })
    )
  );

  /**
   * @param store The global store
   * @param actions$ Our actions stream
   * @param localeService The locale service
   */
  constructor(private store: Store<AppState>, private actions$: Actions, private localeService: LocaleService) {
    this.store.pipe(select(storedWalletEffectsSelector)).subscribe(({ storedWallet, merchantId, parentConfig, environment, delivery }) => {
      this.storedWalletItems = storedWallet;
      this.merchantId = merchantId;
      this.parentConfig = parentConfig;
      this.deliveryState = delivery;
      this.environmentLoaded = environment.loaded;

      if (this.parentConfig.loaded && this.environmentLoaded && this.pendingRequests.length) {
        this.pendingRequests.forEach((action) => this.store.dispatch(action));
        this.pendingRequests.length = 0;
      }
    });

    // need a seperate selector to get credit cards. We will not be able to use
    // selectCreditCard selector as the paymentConfiguration state is only built once we get payment configuration response from BE
    this.store
      .pipe(
        select(selectPaymentConfiguration),
        filter((paymentConfig: PaymentConfigurationState) => validArray(paymentConfig?.configuredPayments?.creditCards)),
        map((paymentConfig) => paymentConfig.configuredPayments.creditCards)
      )
      .subscribe((creditCards: CreditCard[]) => {
        this.creditCards = creditCards;
      });
  }

  /**
   * Returns the stored wallet item by a token
   * @param token The token to find by
   */
  getStoredWalletByToken(token: string) {
    return this.storedWalletItems.find((item) => item.token === token);
  }
}
