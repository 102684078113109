import { AbstractControl, ValidatorFn } from '@angular/forms';

import { isNumeric } from 'src/app/shared/utilities/types.utils';

export interface NumericOnlyValidationError {
  numericOnly: {
    positiveOnly: boolean;
    zeroOrLess: boolean;
  };
}

export function numericOnlyValidator(positiveOnly: boolean = false): ValidatorFn {
  return (control: AbstractControl): NumericOnlyValidationError | null => {
    if (isNumeric(control.value) && (!positiveOnly || (positiveOnly && control.value > 0))) {
      return null;
    }

    return {
      numericOnly: {
        positiveOnly,
        zeroOrLess: control.value <= 0,
      },
    };
  };
}
