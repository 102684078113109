import { createAction, props } from '@ngrx/store';

export enum PayPalActions {
  InitiatePayPal = '[PayPal] Initiate',
  MountPayPalButtons = '[Select Component] Mount PayPal Buttons',
  InitializeGiftCardSplitPayment = '[PayPal Service] Initialize GiftCard Split Payment',
}

/**
 * Initialize paypal
 */
export const initiatePayPal = createAction(PayPalActions.InitiatePayPal);

/**
 * Mount paypal buttons to DOM
 */
export const mountPayPalButtonsAction = createAction(PayPalActions.MountPayPalButtons, props<{ elementId: string }>());

/**
 * Initiate split payment with gift card
 */
export const initializeGiftCardSplitPaymentWithPayPalAction = createAction(
  PayPalActions.InitializeGiftCardSplitPayment,
  props<{ amount: number; payload: any }>()
);
