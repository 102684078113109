export interface PageSpinnerState {
  /**
   * If we should show the spinner or not.
   */
  show: boolean;
}

export const initialPageSpinnerState: PageSpinnerState = {
  show: false,
};
