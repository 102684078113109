import { createReducer, on } from '@ngrx/store';
import {
  updateMonthlyPaymentAvailabilityAction,
  updateUpliftPaymentReadyAction,
  updateMonthlyPaymentTokenAvailabilityAction,
  updatePaymentOptionsAvailabilityAction,
} from './payment-options.actions';
import { initialState, PaymentOptionsState } from './payment-options.state';

const reducer = createReducer(
  initialState,
  on(updateMonthlyPaymentAvailabilityAction, (state, action) => ({ ...state, isMonthlyPaymentAvailable: action.available })),
  on(updateMonthlyPaymentTokenAvailabilityAction, (state, action) => ({ ...state, isMonthlyPaymentTokenAvailable: action.available })),
  on(updatePaymentOptionsAvailabilityAction, (state, action) => ({ ...state, canDisplayPaymentOptions: action.available })),
  on(updateUpliftPaymentReadyAction, (state, action) => ({ ...state, isUpliftPaymentReady: action.ready }))
);

export function paymentOptionsReducer(action, state): PaymentOptionsState {
  return reducer(action, state);
}
