import { StoreModule } from '@ngrx/store';

import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { deliveryReducer } from './delivery.reducer';
import { DeliveryEffects } from './delivery.effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('delivery', deliveryReducer), EffectsModule.forFeature([DeliveryEffects])],
})
export class DeliveryModule {}
