import { Inject, Injectable } from '@angular/core';
import { createSelector, select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { AppState } from 'src/app/app.state';
import { CreateAccessoPaySessionRequest } from 'src/app/core/api/responses/create-accesso-pay-session';
import { SessionState } from 'src/app/core/api/responses/get-accesso-pay-session';
import { InitiateAltPayResponse } from 'src/app/core/api/responses/initiate-alt-pay';
import { selectClientConfiguration } from 'src/app/core/client-configuration/client-configuration.selectors';
import { ClientConfigurationState } from 'src/app/core/client-configuration/client-configuration.state';
import { DeviceType } from 'src/app/core/environment/environment.enums';
import { selectEnvironment } from 'src/app/core/environment/environment.selectors';
import { EnvironmentState } from 'src/app/core/environment/environment.state';
import { WINDOW } from 'src/app/core/injection-token/window/window';
import { selectParentConfig } from 'src/app/core/parent-config/parent-config.selectors';
import { ParentConfig, ParentConfigState } from 'src/app/core/parent-config/parent-config.state';
import { PaymentMethodCode, PaymentProviderType } from 'src/app/core/payment-configuration/payment-configuration.model';
import { isAdyen } from 'src/app/shared/utilities/alternate-payment.utils';
import { returnOrDefaultString } from 'src/app/shared/utilities/string.utils';
import { validString } from 'src/app/shared/utilities/types.utils';
import { objectToQueryParams } from 'src/app/shared/utilities/url.utils';
import { selectDelivery } from '../../delivery/delivery.selectors';
import { DeliveryState } from '../../delivery/delivery.state';
import { selectGiftCardState } from '../../gift-card/gift-card.selectors';
import { GiftCardState } from '../../gift-card/gift-card.state';
import { selectBillingDemographics } from '../billing.selectors';
import { prepareForAlternatePaymentRedirectAction, sendInitiateAltPayRequestAction } from './alternate-payments.actions';
import {
  AlternatePaymentQueryParams,
  AltPayRedirectDetails,
  getRedirectQueryParams,
  getRedirectResultEndpoint,
  isAdyenWebComponentRequired,
} from './alternate-payments.utils';

export interface SessionPayload {
  parentConfig?: ParentConfig;
  giftCardState?: GiftCardState;
  queryParams?: string;
  redirectUrl?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AlternatePaymentsService {
  /**
   * The redirect URL.
   */
  private redirectUrl: string;
  /**
   * Our parent configuration
   */
  private parentConfig: ParentConfigState;
  /**
   * Our client configuration
   */
  private clientConfiguration: ClientConfigurationState;
  /**
   * Our delivery state
   */
  private delivery: DeliveryState;
  /**
   * Initiate alt pay response
   */
  private initiateAltPayResponse: InitiateAltPayResponse;
  /**
   * The alternate payment billing demographics
   */
  private billingDemographics: Partial<DemographicsFormData>;
  /**
   * The gift card start
   */
  private giftCardState: GiftCardState;
  /**
   * The environment information
   */
  private environmentInfo: EnvironmentState;

  /**
   * Constructor
   * @param window The window object
   * @param store Store
   */
  constructor(@Inject(WINDOW) private window: Window, private store: Store<AppState>) {
    store
      .pipe(
        select(
          createSelector(
            selectParentConfig,
            selectDelivery,
            selectClientConfiguration,
            selectBillingDemographics,
            selectGiftCardState,
            selectEnvironment,
            (parentConfig, deliveryState, clientConfiguration, demographics, giftCardState, environment) => {
              const { language, merchantId, tenantName } = clientConfiguration;
              return {
                parentConfig,
                language,
                merchantId,
                tenantName,
                deliveryState,
                clientConfiguration,
                demographics,
                giftCardState,
                environment,
              };
            }
          )
        )
      )
      .subscribe(({ parentConfig, language, merchantId, tenantName, deliveryState, clientConfiguration, demographics, giftCardState, environment }) => {
        const { config } = parentConfig;
        this.parentConfig = parentConfig;
        this.clientConfiguration = clientConfiguration;
        this.redirectUrl = validString(config.paymentRedirectUrl)
          ? config.paymentRedirectUrl
          : `https://${location.host}/${tenantName}/${merchantId}/${language}/redirect-result/`;
        this.billingDemographics = demographics;
        this.delivery = deliveryState;
        this.giftCardState = giftCardState;
        this.environmentInfo = environment;
      });
  }

  /**
   * Returns Initiate alt pay response
   */
  getInitiateAltPayResponse(): InitiateAltPayResponse {
    return this.initiateAltPayResponse;
  }
  /**
   * Set Initiate alt pay response
   */
  setInitiateAltPayResponse(response: InitiateAltPayResponse): void {
    this.initiateAltPayResponse = response;
  }

  /**
   * Initiate Alternate Payment Flow
   * @param paymentDetails The alternate payment details
   */
  initiateAltPay(paymentDetails: Partial<AltPayRedirectDetails>): void {
    if (
      isAdyenWebComponentRequired(paymentDetails.paymentProvider, paymentDetails.paymentMethodCode) &&
      [DeviceType.DESKTOP, DeviceType.TV].some((type) => type === this.environmentInfo.device.type)
    ) {
      this.store.dispatch(sendInitiateAltPayRequestAction({ accessoPaySessionId: '', paymentDetails }));
    } else {
      this.prepareForRedirect(paymentDetails);
    }
  }

  /**
   * Get the window object
   * @returns The window object
   */
  private getWindow(): Window {
    return this.window;
  }

  /**
   * Gets the URL for a alternate payment to redirect to.
   * @returns The redirect url
   */
  getRedirectUrl(accessoPaySessionId: string, paymentMethodCode: PaymentMethodCode, paymentProvider: PaymentProviderType): string {
    const { tenantName } = this.clientConfiguration;
    const { location } = this.getWindow();
    const url = getRedirectResultEndpoint(location.host, paymentProvider);
    const queryParams: string = getRedirectQueryParams({
      accessoPaySessionId,
      tenant: tenantName,
      paymentMethodCode,
      paymentProvider,
      parentSessionId: this.parentConfig.sessionId,
    });

    return url + (url.includes('?') ? '&' : '?') + queryParams;
  }

  /**
   * We need to save off a session so when they come back we can pull it up.
   * @param paymentDetails The alternate payment details
   * @returns An observable to get a session id
   */
  private prepareForRedirect(paymentDetails: Partial<AltPayRedirectDetails>): void {
    const { parentConfig, billingDemographics, delivery, redirectUrl } = this;
    const { tenantName, language, merchantId } = this.clientConfiguration;
    const { paymentMethodCode, paymentProvider } = paymentDetails;
    const newConfig = cloneDeep(parentConfig.config);
    const isAdyenProvider = isAdyen(paymentProvider);

    newConfig.user = {
      ...(newConfig.user ?? {}),
      firstName: returnOrDefaultString(billingDemographics.firstName),
      lastName: returnOrDefaultString(billingDemographics.lastName),
      middleInitial: returnOrDefaultString(billingDemographics.middleInitial),
      address1: returnOrDefaultString(billingDemographics.address1),
      address2: returnOrDefaultString(billingDemographics.address2),
      city: returnOrDefaultString(billingDemographics.city),
      stateProvince: returnOrDefaultString(billingDemographics.state),
      country: returnOrDefaultString(billingDemographics.country),
      zipPostal: returnOrDefaultString(billingDemographics.zip),
      email: returnOrDefaultString(billingDemographics.email),
      phone: returnOrDefaultString(billingDemographics.phone),
      phoneCountryCode: returnOrDefaultString(billingDemographics.telCode),
    };

    const sessionPayload: SessionPayload = { parentConfig: newConfig, giftCardState: this.giftCardState, redirectUrl };

    if (!isAdyenProvider) {
      // Session information will be used to retrieve redirect information
      sessionPayload.queryParams = objectToQueryParams({
        [AlternatePaymentQueryParams.SESSION_ID]: this.parentConfig.sessionId,
        [AlternatePaymentQueryParams.TENANT]: tenantName,
        [AlternatePaymentQueryParams.PAYMENT_MERCHANT_ID]: paymentDetails?.paymentMerchantId,
        [AlternatePaymentQueryParams.PAYMENT_TYPE]: paymentDetails?.paymentType,
        paymentMethodCode,
      });
    }

    /**
     * We need to ensure we add the address to the InitializeData model so that the delivery resolve
     * passes when they come back and don't get kicked to the /select route.
     */
    if (validString(delivery.selectedDeliveryMethod)) {
      const { address } = delivery;
      newConfig.delivery = newConfig.delivery ?? {};
      newConfig.delivery.defaultSelectedId = delivery.selectedDeliveryMethod;

      newConfig.delivery.address = {
        ...(newConfig.delivery.address ?? {}),
        firstName: returnOrDefaultString(address?.firstName),
        lastName: returnOrDefaultString(address?.lastName),
        middleInitial: returnOrDefaultString(address?.middleInitial),
        address1: returnOrDefaultString(address?.address1),
        address2: returnOrDefaultString(address?.address2),
        city: returnOrDefaultString(address?.city),
        stateProvince: returnOrDefaultString(address?.state),
        country: returnOrDefaultString(address?.country),
        zipPostal: returnOrDefaultString(address?.zip),
        email: returnOrDefaultString(address?.email),
        phone: returnOrDefaultString(address?.phone),
        phoneCountryCode: returnOrDefaultString(address?.telCode),
      };
    }

    const sessionRequest: CreateAccessoPaySessionRequest = {
      language,
      merchantId,
      tenantName,
      payload: JSON.stringify(sessionPayload),
      sessionState: SessionState.REDIRECT,
    };

    this.store.dispatch(prepareForAlternatePaymentRedirectAction({ payload: { sessionRequest, paymentDetails } }));
  }
}
