import { createAction, props } from '@ngrx/store';
import { DonationState } from './extras.state';

/**
 * Our delivery action types.
 */
export enum ExtrasActionTypes {
  DonationRenderSuccess = '[Extras Component] Donation Render Success',
  UpdateDonationAvailability = '[Extras Component] Update Donation Availability',
  UpdateDonationConfig = '[Extras Component] Update Donation Config',
  UpdateDonationSelect = '[Extras Component] Update Donation Selection',
  UpdateDonationError = '[Extras Component] Update Donation Error',
  UpdateDonationContinued = '[Extras Component] Update Donation Continued',
  ClearTicketProtectionDetails = '[Extras Component] Clear Ticket Protection Details',
  DestroyTicketProtectionWidget = '[Extras Component] Destroy Ticket Guardian Widget',
  UpdateTicketProtectionAvailability = '[Extras Component] Update Ticket Protection Availability',
  UpdateTicketProtectionQuote = '[Extras Component] Update Ticket Protection Quote Value',
  UpdateTicketProtectionQuoteToken = '[Extras Component] Update Ticket Protection Quote Token',
  UpdateTicketProtectionStatus = '[Extras Component] Update Ticket Protection Status',
  UpdateTicketProtectionSplitInsuranceAction = '[Extras Component] Update Ticket Protection Split Insurance',
  UpdateTicketProtectionPolicyInfoAction = '[Extras Component] Update Ticket Protection Policy Info',
  UpdateTicketProtectionError = '[Extras Component] Update Ticket Protection Error',
  UpdateTicketProtectionContinued = '[Extras Component] Update Ticket Protection Continued',
  RegisterImpression = '[Extras Component] Register Ticket Guardian Impression',
  ResetContinued = '[Extras Effects] Reset Continued',
  HandleContinued = '[Extras Component] Handle Continued',
}

/**
 * The action to clear ticket protection details
 */
export const clearTicketProtectionDetailsAction = createAction(ExtrasActionTypes.ClearTicketProtectionDetails);

/**
 * The action to destroy ticket protection widget
 */
export const destroyTicketProtectionWidgetAction = createAction(ExtrasActionTypes.DestroyTicketProtectionWidget);

/**
 * The action to update if ticket protection is configured
 */
export const updateTicketProtectionAvailabilityAction = createAction(ExtrasActionTypes.UpdateTicketProtectionAvailability, props<{ payload: boolean }>());

/**
 * The action to update ticket protection quote token
 */
export const updateTicketProtectionQuoteTokenAction = createAction(ExtrasActionTypes.UpdateTicketProtectionQuoteToken, props<{ payload: string }>());

/**
 * The action to update ticket protection quote token
 */
export const updateTicketProtectionQuoteAction = createAction(ExtrasActionTypes.UpdateTicketProtectionQuote, props<{ payload: number }>());

/**
 * The action to update ticket protection status
 */
export const updateTicketProtectionStatusAction = createAction(ExtrasActionTypes.UpdateTicketProtectionStatus, props<{ payload: boolean }>());
/**
 * The action to update ticket protection status
 */
export const updateTicketProtectionSplitInsuranceAction = createAction(
  ExtrasActionTypes.UpdateTicketProtectionSplitInsuranceAction,
  props<{ payload: boolean }>()
);
/**
 * The action to update ticket protection policy info
 */
export const updateTicketProtectionPolicyInfoAction = createAction(ExtrasActionTypes.UpdateTicketProtectionPolicyInfoAction, props<{ payload: string }>());

/**
 * The action to update ticket protection error status
 */
export const updateTicketProtectionErrorAction = createAction(ExtrasActionTypes.UpdateTicketProtectionError);

/**
 * The action to register ticket guardian impression
 */
export const registerTicketGuardianImpressionAction = createAction(ExtrasActionTypes.RegisterImpression, props<{ quoteToken: string; merchantId: number }>());
/**
 * The action to update donation availability
 */
export const updateDonationConfigAction = createAction(ExtrasActionTypes.UpdateDonationConfig, props<{ payload: Partial<DonationState> }>());
/**
 * The action to update donation availability
 */
export const updateDonationAvailabilityAction = createAction(ExtrasActionTypes.UpdateDonationAvailability, props<{ payload: boolean }>());
/**
 * The action to update donation amount
 */
export const updateDonationSelectionAction = createAction(
  ExtrasActionTypes.UpdateDonationSelect,
  props<{ payload: { donationType: string; donationAmount: number } }>()
);
/**
 * The action to update ticket protection error status
 */
export const updateDonationErrorAction = createAction(ExtrasActionTypes.UpdateDonationError, props<{ payload: boolean }>());
/**
 * The action to indicate donation module has been rendered
 */
export const donationRenderSuccessAction = createAction(ExtrasActionTypes.DonationRenderSuccess);
/**
 * The action to set continued state for donation
 */
export const updateDonationContinuedAction = createAction(ExtrasActionTypes.UpdateDonationContinued, props<{ payload: { continued: boolean } }>());
/**
 * The action to set continued state for ticket protection
 */
export const updateTicketProtectionContinuedAction = createAction(
  ExtrasActionTypes.UpdateTicketProtectionContinued,
  props<{ payload: { continued: boolean } }>()
);
/**
 * The action to reset all continued states
 */
export const resetContinuedAction = createAction(ExtrasActionTypes.ResetContinued);
/**
 * The action to handle continued state
 */
export const handleContinuedAction = createAction(ExtrasActionTypes.HandleContinued);
