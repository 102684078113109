export const initialState: StoredWalletItem[] = [];

export interface StoredWalletItem {
  token: string;
  card: StoredCard;
  cardHolder: StoredCardHolder;
}

export interface StoredCard {
  lastFourDigits: string;
  cardType: string;
  month: string;
  year: string;
}

export interface StoredCardHolder {
  address: string;
  address2: string;
  city: string;
  country: string;
  firstName: string;
  lastName: string;
  state: string;
  zip: string;
}
