import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { validateZipField } from '../utilities/validator.utils';

export function zipCodeValidator(requiredCountry?: string, regexOverride: any = null): ValidatorFn {
  return function zipCodeValidatorFn(control: AbstractControl): ValidationErrors {
    (zipCodeValidatorFn as any).isZipCodeValidator = true;
    validateZipField(control, requiredCountry?.toUpperCase(), regexOverride);
    return control.errors;
  };
}
