import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { applicationConfigReducer } from './application-config.reducer';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { ApplicationConfigEffects } from './application-config.effects';

@NgModule({ declarations: [], imports: [CommonModule,
        StoreModule.forFeature('applicationConfig', applicationConfigReducer),
        EffectsModule.forFeature([ApplicationConfigEffects])], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class ApplicationConfigModule {}
