import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { validString } from 'src/app/shared/utilities/types.utils';
import { selectParentHostName, selectTenantName } from '../../client-configuration/client-configuration.selectors';
import { selectHostAppName } from '../../parent-config/parent-config.selectors';
import { selectIpAddress } from '../../environment/environment.selectors';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  tenantName: string;
  hostname: string;
  hostAppName: string;
  ipAddress: string;

  constructor(private store: Store<AppState>) {
    this.store
      .select(selectTenantName)
      .pipe(filter(validString), take(1))
      .subscribe((tenantName) => {
        this.tenantName = tenantName;
      });
    this.store
      .select(
        createSelector(selectParentHostName, selectHostAppName, selectIpAddress, (parentHostName, hostAppName, ipAddress) => ({
          parentHostName,
          hostAppName,
          ipAddress,
        }))
      )
      .subscribe(({ parentHostName, hostAppName, ipAddress }) => {
        this.hostname = validString(parentHostName) ? parentHostName : '';
        this.hostAppName = validString(hostAppName) ? hostAppName : '';
        this.ipAddress = ipAddress;
      });
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const newReq = req.clone({
      setHeaders: {
        'com-accessopassport-client': this.tenantName || '',
        'source-domain': this.hostname || '',
        'source-app': this.hostAppName || 'AccessoPay',
        'source-ip': this.ipAddress || '',
        Accept: 'application/json',
        ...req.headers.keys().reduce((acc, curr) => {
          const headerVal = req.headers.get(curr);
          acc[curr] = headerVal;
          return acc;
        }, {}),
      },
    });

    return next.handle(newReq);
  }
}
