import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, timeout } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { AdPlacementService } from './ad-placement.service';

@Injectable({
  providedIn: 'root',
})
export class AdPlacementResolve implements Resolve<boolean> {
  /**
   * Our constructor
   * @param store Store
   * @param adPlacementService Ad Placement Service
   */
  constructor(private store: Store<AppState>, private adPlacementService: AdPlacementService) {}

  resolve(): Observable<boolean> | boolean {
    return this.adPlacementService.loadScript().pipe(timeout(1000));
  }
}
