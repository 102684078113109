import { createSelector } from '@ngrx/store';
import { AppState } from './app.state';

export const selectGlobalState = (state: AppState): AppState => state;

/**
 * This selector tells you whether we should display the app or not.
 */
export const selectDisplayApp = createSelector(selectGlobalState, (state): boolean => {
  const { environment, applicationLocale, paymentConfiguration, applicationConfig } = state;
  return (
    environment.loaded &&
    !applicationLocale.loading &&
    !applicationLocale.loadingFiles &&
    !applicationConfig.loading &&
    (paymentConfiguration.errorLoading || !paymentConfiguration.loading)
  );
});

/**
 * This selector tells you whether we should display the full page spinner or not.
 */
export const selectDisplaySpinner = createSelector(selectGlobalState, (state: AppState): boolean => {
  return state.spinner.show;
});
