import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EffectsModule } from '@ngrx/effects';
import { GooglePayEffects } from './google-pay.effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, EffectsModule.forFeature([GooglePayEffects])],
})
export class GooglePayModule {}
