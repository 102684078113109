import { validObject, validString } from 'src/app/shared/utilities/types.utils';
import { LocaleService } from 'src/app/core/locale/locale.service';
import { ApiServiceResponse } from '../api/api.interface';
import { returnOrDefaultString } from 'src/app/shared/utilities/string.utils';

/**
 * Retrieve error message from api response
 * TODO: Make this dynamic for all payment types.
 * @param localeService locale service
 * @param response api response
 * @returns rendering error message
 */
export function getErrorLocaleFromResponse(localeService: LocaleService, response: ApiServiceResponse): string {
  if (validObject(response)) {
    const { error_code, error_msg, detail, title } = response;
    const code = returnOrDefaultString(error_code, title);
    const message = returnOrDefaultString(error_msg, detail);

    if (code && localeService.has(`errorcode.${code}`)) {
      return localeService.get(`errorcode.${code}`);
    }

    if (validString(message)) {
      return message;
    }
  }

  return localeService.get('creditcard.internalerror');
}
