/**
 * Preconfigured custom events
 * @see {@link https://support.optimizely.com/hc/en-us/articles/4410288960909-Custom-events-in-Optimizely-Web-Experimentation Optimizely Custom Events}
 *
 */
export enum OptimizelyCustomEvent {
  PAYMENT_AUTH = 'payment_authorize',
  PAYMENT_CANCEL = 'payment_cancel',
  PAYMENT_FAIL = 'payment_fail',
  PAYMENT_SUCCESS = 'payment_success',
}

/**
 * Types of messages to update the state of Optimizely Web Experimentation in the browser
 * Visit API docs for full list of parameters
 * @see {@link https://docs.developers.optimizely.com/web-experimentation/reference/push Push}
 */
export enum OpitmizelyMessageType {
  /**
   * Reactivate the snippet.
   * NOT USED
   */
  ACTIVATE = 'activate',
  /**
   * Listen to a snippet lifecycle event.
   * NOT USED
   */
  ADD_LISTENER = 'addListener',
  /**
   * Specify the number of days before the Optimizely Web Experimentation visitor cookies will be set to expire.
   * NOT USED
   */
  COOKIE_EXPIRATION = 'cookieExpiration',
  /**
   * Track custom events.
   * Only supporting this for now
   */
  EVENT = 'event',
}
