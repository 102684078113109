import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { PaymentConfigurationReducer } from './payment-configuration.reducer';
import { PaymentConfigurationEffects } from './payment-configuration.effects';

@NgModule({ declarations: [], imports: [CommonModule,
        StoreModule.forFeature('paymentConfiguration', PaymentConfigurationReducer),
        EffectsModule.forFeature([PaymentConfigurationEffects])], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class PaymentConfigurationModule {}
