import { createAction, props } from '@ngrx/store';
import { DeliveryOption } from '../../core/parent-config/parent-config.state';

/**
 * Our delivery action types.
 */
export enum DeliveryActionTypes {
  UpdateDelivery = '[Delivery Effects] Update Shipping',
  ClearDeliveryAddress = '[Delivery Screen] Clear Delivery Address',
  SelectDeliveryMethod = '[Delivery Screen] Select Delivery Method',
  UpdateShippingDetails = '[Delivery Screen] Update Shipping Details',
  HideDeliveryView = '[Delivery Guard] Hide Delivery View',
  ShowDeliveryView = '[Delivery Guard] Show Delivery View',
  UpdateDeliveryAmount = '[Delivery Screen] Update Delivery Amount',
}

/**
 * The action to update if delivery options are configured
 */
export const updateDeliveryAction = createAction(DeliveryActionTypes.UpdateDelivery, props<{ payload: DeliveryOption[] }>());
/**
 * The action to select a delivery option
 */
export const selectDeliveryMethodAction = createAction(DeliveryActionTypes.SelectDeliveryMethod, props<{ payload: DeliveryOption }>());
/**
 * The action to update delivery details
 */
export const updateDeliveryDetailsAction = createAction(DeliveryActionTypes.UpdateShippingDetails, props<{ payload: DemographicsFormData }>());
/**
 * The action to clear delivery address
 */
export const clearDeliveryAddressDetailsAction = createAction(DeliveryActionTypes.ClearDeliveryAddress);
/**
 * The action to hide delivery view
 */
export const hideDeliveryViewAction = createAction(DeliveryActionTypes.HideDeliveryView);
/**
 * The action to show delivery view
 */
export const ShowDeliveryViewAction = createAction(DeliveryActionTypes.ShowDeliveryView);
/**
 * Updates delivery amount from delivery option
 */
export const updateDeliveryAmountAction = createAction(DeliveryActionTypes.UpdateDeliveryAmount, props<{ payload: number }>());
