export const REDIRECT_PAYMENT_QUERY_PARAMS = {
  CARD_TYPE_CODE: 'acsop_ctc',
  CARD_LAST_FOUR: 'acsop_clf',
  CARD_EXPIRY: 'acsop_cexp',
  DONATION_STATUS: 'acsop_dst',
  DONATION_AMOUNT: 'acsop_da',
  INSURANCE_STATUS: 'acsop_ist',
  INSURANCE_AMOUNT: 'acsop_ia',
  ERROR_CODE: 'acsop_er',
  ERROR_CODE_DETAILS: 'acsop_erd',
  REDIRECT_STATUS: 'acsop_st',
  REDIRECT_RESULT_CODE: 'acsop_co',
  REDIRECT_RESULT_ERROR_CODE: 'acsop_reco',
  REDIRECT_PAYMENT_MERCHANT_ID: 'acsop_pid',
  REDIRECT_AUTH_ID: 'acsop_aid',
  REDIRECT_PAYMENT_TYPE: 'acsop_pt',
  REDIRECT_PAYMENT_ID: 'acsop_pi',
  REDIRECT_PAYMENT_REFERENCE_ID: 'acsop_prid',
  REDIRECT_SESSION_TOKEN: 'acsop_t',
  REDIRECT_BACKEND_STATUS_CODE: 'acsop_bsc',
};

export const QUERY_PARAM_SESSION_TOKEN = 't';

/**
 * Error codes we receive from UpdateAltPay response in php
 */
export enum RedirectErrorCode {
  UNKNOWN = 0,
  CONNECTION_TO_BACKEND_FAILED = 1,
  MISSING_PAYLOAD = 2,
  MISSING_REDIRECT_URL = 3,
  CURL_ERROR = 4,
  XML_PARSE_ERROR = 5,
}
