export interface PaymentOptionsState {
  isMonthlyPaymentAvailable: boolean;
  isUpliftPaymentReady: boolean;
  isMonthlyPaymentTokenAvailable: boolean;
  canDisplayPaymentOptions: boolean;
}

export const initialState: PaymentOptionsState = {
  isMonthlyPaymentAvailable: false,
  isUpliftPaymentReady: false,
  isMonthlyPaymentTokenAvailable: false,
  canDisplayPaymentOptions: false,
};

export interface UpliftProviderReference {
  order_id: string;
  customer_id: string;
}
