export enum AuthTypeEnums {
  CREDIT_CARD = 'EC',
}

export enum UpliftCardTypeCodes {
  VISA = '001',
  MASTERCARD = '002',
  UATP = '040',
}

export enum PayPalFundingSource {
  CREDIT = 'credit',
  PAYLATER = 'paylater',
  PAYPAL = 'paypal',
  VENMO = 'venmo',
}

export enum TokenType {
  GOOGLE_PAY = 'GooglePay',
  UPLIFT_CYBS = 'Uplift-CyberSource',
  CREDIT_CARD = 'CreditCard',
}

export enum CreditCardBrand {
  AMERICANEXPRESS = 'americanexpress',
  MISTERCASH = 'mistercash',
  BANCONTACT = 'bancontact',
  BANCONTACTDESKTOP = 'bancontactdesktop',
  CARTESBANCAIRES = 'cartesbancaires',
  CHINAUNIONPAY = 'chinaunionpay',
  DANKORT = 'dankort',
  DINERSCLUB = 'dinersclub',
  DISCOVERCARD = 'discovercard',
  DISCOVER = 'discover',
  JCB = 'jcb',
  LASER = 'laser',
  MASTERCARD = 'mastercard',
  MAESTROINTERNATIONAL = 'maestrointernational',
  MAESTROUKDOMESTIC = 'maestroukdomestic',
  SOLO = 'solo',
  VISA = 'visa',
  VISADANKORT = 'visadankort',
}

export enum BillingDefaultValue {
  FirstName = 'FirstName',
  LastName = 'LastName',
  Country = 'Country',
}
