import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DeliveryState } from './delivery.state';

/**
 * Selects the delivery state
 * @param state The application state
 * @returns The delivery state
 */
export const selectDelivery = createFeatureSelector<DeliveryState>('delivery');

/**
 * Selects the state to determine if delivery options are configured
 * @returns The delivery method selected
 */
export const selectIsDeliveryMethodsConfigured = createSelector(selectDelivery, (state: DeliveryState): boolean => state.deliveryMethodsConfigured);

/**
 * Selects the current selected delivery method
 * @returns The delivery method selected
 */
export const selectCurrentDeliveryMethod = createSelector(selectDelivery, (state: DeliveryState): string => state.selectedDeliveryMethod);

/**
 * Selects the current selected delivery methods address
 * @returns if the selected delivery method requries address collection
 */
export const selectCurrentDeliveryMethodAddress = createSelector(selectDelivery, (state: DeliveryState): boolean => state.collectAddress);

/**
 * Selects the state to show or hide delivery view
 * @returns true if we need to show delivery view
 */
export const selectShowDeliveryView = createSelector(selectDelivery, (state: DeliveryState): boolean => state.showDelivery);

/**
 * Selects the current selected delivery method amount
 * @returns delivery amount
 */
export const selectDeliveryAmount = createSelector(selectDelivery, (state: DeliveryState): number => state.deliveryAmount);

/**
 * Selects the delivery address input
 * @returns delivery address
 */
export const selectDeliveryAddress = createSelector(selectDelivery, (state: DeliveryState): DemographicsFormData => state?.address);
