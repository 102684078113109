import { Component, ViewEncapsulation, OnInit, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './app.state';
import { selectParentConfig } from './core/parent-config/parent-config.selectors';
import { Styles, ButtonStyles } from './core/parent-config/parent-config.state';
import { validObject, validString } from './shared/utilities/types.utils';
import { normalize } from './shared/utilities/string.utils';
import { DOCUMENT } from './core/injection-token/document/document';
import { take } from 'rxjs/operators';
import { has } from 'lodash-es';

@Component({
  selector: 'ap-custom-styles',
  templateUrl: './custom-style.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class CustomStylesComponent implements OnInit {
  /**
   * Confgured Styles
   */
  stylesConfig: Partial<Styles>;
  /**
   * Map DS that stores different CSS styles
   */
  stylesMap = new Map<string, string>();

  /**
   * Component constructor
   * @param store the global store
   * @param document the document object
   */
  constructor(private store: Store<AppState>, @Inject(DOCUMENT) private document: Document) {
    store
      .select(selectParentConfig)
      .pipe(take(1))
      .subscribe((parentConfig) => {
        this.stylesConfig = parentConfig.config.style;
      });
  }

  /**
   * Handles component intialization
   */
  ngOnInit() {
    this.configureButtonStyles();
    this.configureRadioButtonStyles();
    this.configureCheckboxStyles();
    this.configureLayoutStyles();
    this.configureInputStyles();
    this.configureModalStyles();
    this.overrideMatFormFieldStyles();

    this.appendCustomStyles();
  }

  /**
   * Validate style
   * @param styleObj style object
   * @param path path
   */
  validStyle(styleObj: ButtonStyles, path: string): boolean {
    return has(styleObj, path) && validString(styleObj[path]);
  }

  /**
   * Confgure Button Styles
   */
  configureButtonStyles(): void {
    const MAT_FLAT_BUTTON = '.mat-flat-button.mat-button-base';
    const MAT_PRIMARY = '.mat-primary';
    const MAT_WARN = '.mat-warn';
    const ACCESSO_LIGHT_THEME = '.accesso-light-theme';
    const MAT_PRIMARY_ICON = '.mat-icon-button.mat-primary';
    const primaryButtonDefaultStyles = {
      backgroundColor: '#0061c5',
      borderColor: '#0080ff',
      color: '#ffffff',
    };
    const primaryButtonDefaultActiveStyles = {
      backgroundColor: '#204d74',
      borderColor: '#122b40',
      color: '#ffffff',
    };

    if (validObject(this.stylesConfig?.buttons?.default)) {
      const defaultButtonStyles = this.stylesConfig.buttons.default;
      for (const prop in defaultButtonStyles) {
        if (this.validStyle(defaultButtonStyles, prop)) {
          this.constructStyleMap(MAT_FLAT_BUTTON, prop, defaultButtonStyles[prop]);
          this.constructStyleMap(`${MAT_FLAT_BUTTON}:focus`, prop, defaultButtonStyles[prop]);
        }
      }
    }

    if (validObject(this.stylesConfig?.buttons?.defaultHover)) {
      const defaultButtonHoverStyles = this.stylesConfig.buttons.defaultHover;
      for (const prop in defaultButtonHoverStyles) {
        if (this.validStyle(defaultButtonHoverStyles, prop)) {
          this.constructStyleMap(`${MAT_FLAT_BUTTON}:hover`, prop, defaultButtonHoverStyles[prop]);
        }
      }
    }

    if (validObject(this.stylesConfig?.buttons?.defaultActive)) {
      const defaultButtonActiveStyles = this.stylesConfig.buttons.defaultActive;
      for (const prop in defaultButtonActiveStyles) {
        if (this.validStyle(defaultButtonActiveStyles, prop)) {
          this.constructStyleMap(`${MAT_FLAT_BUTTON}:focus:active`, prop, defaultButtonActiveStyles[prop]);
        }
      }
    }

    if (validObject(this.stylesConfig?.buttons?.primary)) {
      const primaryButtonStyles = this.stylesConfig.buttons.primary;
      for (const prop in primaryButtonStyles) {
        if (this.validStyle(primaryButtonStyles, prop)) {
          this.constructStyleMap(`${MAT_FLAT_BUTTON}${MAT_PRIMARY}`, prop, primaryButtonStyles[prop]);
          this.constructStyleMap(`${MAT_FLAT_BUTTON}:focus${MAT_PRIMARY}`, prop, primaryButtonStyles[prop]);
        }
      }
      if (validString(primaryButtonStyles?.backgroundColor)) {
        this.constructStyleMap(`${ACCESSO_LIGHT_THEME} ${MAT_PRIMARY_ICON}`, 'color', primaryButtonStyles.backgroundColor);
      }
    } else {
      for (const prop in primaryButtonDefaultStyles) {
        if (this.validStyle(primaryButtonDefaultStyles, prop)) {
          this.constructStyleMap(`${MAT_FLAT_BUTTON}${MAT_PRIMARY}`, prop, primaryButtonDefaultStyles[prop]);
          this.constructStyleMap(`${MAT_FLAT_BUTTON}:focus${MAT_PRIMARY}`, prop, primaryButtonDefaultStyles[prop]);
        }
      }
      this.constructStyleMap(`${ACCESSO_LIGHT_THEME} ${MAT_PRIMARY_ICON}`, 'color', primaryButtonDefaultStyles.backgroundColor);
    }

    if (validObject(this.stylesConfig?.buttons?.primaryHover)) {
      const primaryButtonHoverStyles = this.stylesConfig.buttons.primaryHover;
      for (const prop in primaryButtonHoverStyles) {
        if (this.validStyle(primaryButtonHoverStyles, prop)) {
          this.constructStyleMap(`${MAT_FLAT_BUTTON}${MAT_PRIMARY}:hover`, prop, primaryButtonHoverStyles[prop]);
        }
      }
    } else {
      for (const prop in primaryButtonDefaultStyles) {
        if (this.validStyle(primaryButtonDefaultStyles, prop)) {
          this.constructStyleMap(`${MAT_FLAT_BUTTON}${MAT_PRIMARY}:hover`, prop, primaryButtonDefaultStyles[prop]);
        }
      }
    }

    if (validObject(this.stylesConfig?.buttons?.primaryActive)) {
      const primaryButtonActiveStyles = this.stylesConfig.buttons.primaryActive;
      for (const prop in primaryButtonActiveStyles) {
        if (this.validStyle(primaryButtonActiveStyles, prop)) {
          this.constructStyleMap(`${MAT_FLAT_BUTTON}${MAT_PRIMARY}:focus:active`, prop, primaryButtonActiveStyles[prop]);
        }
      }
    } else {
      for (const prop in primaryButtonDefaultActiveStyles) {
        if (this.validStyle(primaryButtonDefaultActiveStyles, prop)) {
          this.constructStyleMap(`${MAT_FLAT_BUTTON}${MAT_PRIMARY}:focus:active`, prop, primaryButtonDefaultActiveStyles[prop]);
        }
      }
    }

    if (validObject(this.stylesConfig?.buttons?.warn)) {
      const warnButtonStyles = this.stylesConfig.buttons.warn;
      for (const prop in warnButtonStyles) {
        if (this.validStyle(warnButtonStyles, prop)) {
          this.constructStyleMap(`${MAT_FLAT_BUTTON}${MAT_WARN}`, prop, warnButtonStyles[prop]);
          this.constructStyleMap(`${MAT_FLAT_BUTTON}:focus${MAT_WARN}`, prop, warnButtonStyles[prop]);
        }
      }
    }

    if (validObject(this.stylesConfig?.buttons?.warnHover)) {
      const warnButtonHoverStyles = this.stylesConfig.buttons.warnHover;
      for (const prop in warnButtonHoverStyles) {
        if (this.validStyle(warnButtonHoverStyles, prop)) {
          this.constructStyleMap(`${MAT_FLAT_BUTTON}${MAT_WARN}:hover`, prop, warnButtonHoverStyles[prop]);
        }
      }
    }

    if (validObject(this.stylesConfig?.buttons?.warnActive)) {
      const warnButtonActiveStyles = this.stylesConfig.buttons.warnActive;
      for (const prop in warnButtonActiveStyles) {
        if (this.validStyle(warnButtonActiveStyles, prop)) {
          this.constructStyleMap(`${MAT_FLAT_BUTTON}${MAT_WARN}:focus:active`, prop, warnButtonActiveStyles[prop]);
        }
      }
    }
  }

  /**
   * Configure Radio Button Styles
   */
  configureRadioButtonStyles(): void {
    if (!validObject(this.stylesConfig?.radio)) {
      return;
    }
    const { radio } = this.stylesConfig;
    const ACCESSO_LIGHT_THEME = '.accesso-light-theme';
    const MAT_PRIMARY = '.mat-primary';
    const ACTIVE_RADIO_BUTTON = '.mat-radio-button:not(.mat-radio-disabled)';
    const MAT_RADIO_OUTER_CIRCLE = '.mat-radio-outer-circle';
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} ${ACTIVE_RADIO_BUTTON} ${MAT_RADIO_OUTER_CIRCLE}`, 'borderColor', radio.defaultColor);
    this.constructStyleMap(
      `${ACCESSO_LIGHT_THEME} ${ACTIVE_RADIO_BUTTON}${MAT_PRIMARY}.mat-radio-checked ${MAT_RADIO_OUTER_CIRCLE}`,
      'borderColor',
      radio.borderColor
    );
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} ${ACTIVE_RADIO_BUTTON}${MAT_PRIMARY} .mat-radio-inner-circle`, 'backgroundColor', radio.backgroundColor);
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} .mat-radio-button-option--selected`, 'borderColor', radio.backgroundColor);
  }

  /**
   * Configure Checkbox Styles
   */
  configureCheckboxStyles(): void {
    if (!validObject(this.stylesConfig?.checkbox)) {
      return;
    }
    const { checkbox } = this.stylesConfig;
    const ACCESSO_LIGHT_THEME = '.accesso-light-theme';
    const MAT_PRIMARY = '.mat-primary';
    const MAT_CHECKBOX_CHECKED = '.mat-checkbox.mat-checkbox-checked';
    const MAT_CHECKBOX_BACKGROUND = '.mat-checkbox-background';
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} .mat-checkbox .mat-checkbox-frame`, 'borderColor', checkbox.defaultColor);
    this.constructStyleMap(
      `${ACCESSO_LIGHT_THEME} ${MAT_CHECKBOX_CHECKED}${MAT_PRIMARY} ${MAT_CHECKBOX_BACKGROUND}`,
      'backgroundColor',
      checkbox.backgroundColor
    );
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} ${MAT_CHECKBOX_CHECKED}${MAT_PRIMARY} ${MAT_CHECKBOX_BACKGROUND}`, 'borderColor', checkbox.backgroundColor);
    this.constructStyleMap(`.mat-checkbox-checkmark .mat-checkbox-checkmark-path`, 'stroke', checkbox.color, true);
  }

  /**
   * Configure Layout Styles
   */
  configureLayoutStyles(): void {
    if (!validObject(this.stylesConfig?.layout)) {
      return;
    }

    const { layout } = this.stylesConfig;
    this.constructStyleMap('.mat-typography', 'color', layout.textColor);
    this.constructStyleMap(
      '.border--all,.border--bottom,.border--top,.border--right,.border-left,.cdk-overlay-pane>.tooltip>.tooltip--arrow',
      'borderColor',
      layout.borderColor
    );
    this.constructStyleMap('.accesso-light-theme mat-label', 'color', layout.labelColor);
    this.constructStyleMap('html,body,.spinner--container,.cdk-overlay-pane>.tooltip,.tooltip>.tooltip--arrow', 'backgroundColor', layout.backgroundColor);
  }

  /**
   * Configure Input Styles
   */
  configureInputStyles(): void {
    if (!validObject(this.stylesConfig?.input)) {
      return;
    }
    const { input } = this.stylesConfig;
    const layoutStyles = validObject(this.stylesConfig.layout) ? this.stylesConfig.layout : {};
    // Material Style Constants
    const ACCESSO_LIGHT_THEME = '.accesso-light-theme';
    const MAT_INPUT = '.mat-form-field-type-mat-input:not(.mat-form-field-type-mat-checkbox-radio)';
    const MAT_INPUT_ELEMENT = '.mat-input-element';
    const MAT_SELECT = '.mat-select';
    const MAT_FORM_FIELD_FLEX = '.mat-form-field-flex';
    const MAT_FORMFIELD = '.mat-form-field';
    const MAT_FORMFIELD_WITH_LABEL = '.mat-form-field-has-label';
    const MAT_FOCUSED = '.mat-focused';

    // CSS style constants
    const BACKGROUND_COLOR = 'backgroundColor';
    const BORDER_COLOR = 'borderColor';
    const CARET_COLOR = 'caret-color';
    const COLOR = 'color';

    // Default state colors
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} ${MAT_INPUT} ${MAT_FORM_FIELD_FLEX}::after`, BORDER_COLOR, input.default.borderColor);
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} ${MAT_INPUT} ${MAT_FORM_FIELD_FLEX}::before`, BACKGROUND_COLOR, input.default.backgroundColor);
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} ${MAT_INPUT}:hover ${MAT_FORM_FIELD_FLEX}::before`, BACKGROUND_COLOR, input.default.hoverColor);
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} ${MAT_SELECT}`, BACKGROUND_COLOR, input.default.backgroundColor);
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} ${MAT_SELECT}`, BORDER_COLOR, input.default.borderColor);

    // input placeholder styles
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} ${MAT_INPUT_ELEMENT}::placeholder`, COLOR, input.default.placeholderColor);
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} ${MAT_INPUT_ELEMENT}::-webkit-input-placeholder`, COLOR, input.default.placeholderColor);
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} ${MAT_INPUT_ELEMENT}:-ms-input-placeholder`, COLOR, input.default.placeholderColor);
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} .mat-select-placeholder`, COLOR, input.default.placeholderColor);

    // Valid state colors
    this.constructStyleMap(
      `${ACCESSO_LIGHT_THEME} ${MAT_FORMFIELD}${MAT_FOCUSED}${MAT_FORMFIELD_WITH_LABEL} ${MAT_INPUT_ELEMENT}.ng-valid[color="primary"]`,
      BACKGROUND_COLOR,
      input.focused.backgroundColor
    );
    this.constructStyleMap(
      `${ACCESSO_LIGHT_THEME} ${MAT_FORMFIELD}${MAT_FOCUSED}${MAT_FORMFIELD_WITH_LABEL} ${MAT_INPUT_ELEMENT}.ng-pristine[color="primary"]`,
      BACKGROUND_COLOR,
      input.focused.backgroundColor
    );
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} ${MAT_INPUT}${MAT_FOCUSED} ${MAT_FORM_FIELD_FLEX}::after`, BORDER_COLOR, input.focused.borderColor);
    this.constructStyleMap(
      `${ACCESSO_LIGHT_THEME} ${MAT_FORMFIELD}.ng-untouched.ng-invalid${MAT_FOCUSED} ${MAT_INPUT_ELEMENT}`,
      CARET_COLOR,
      input.focused.borderColor
    );
    this.constructStyleMap(
      `${ACCESSO_LIGHT_THEME} ${MAT_FORMFIELD}.ng-dirty.ng-valid${MAT_FOCUSED} ${MAT_INPUT_ELEMENT}`,
      CARET_COLOR,
      input.focused.borderColor
    );
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} ${MAT_FORMFIELD}${MAT_FOCUSED} ${MAT_SELECT}`, BACKGROUND_COLOR, input.focused.backgroundColor);
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} ${MAT_FORMFIELD}${MAT_FOCUSED} ${MAT_SELECT}`, BORDER_COLOR, input.focused.borderColor);

    // flex-microform styles
    this.constructStyleMap(
      `${ACCESSO_LIGHT_THEME} ${MAT_FORMFIELD}${MAT_FOCUSED} ${MAT_INPUT_ELEMENT}.flex-microform-focused`,
      BACKGROUND_COLOR,
      input.focused.backgroundColor
    );
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} ${MAT_INPUT}${MAT_FOCUSED} ${MAT_FORM_FIELD_FLEX}::before`, BACKGROUND_COLOR, input.focused.backgroundColor);

    // text color for all states
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} ${MAT_FORMFIELD} ${MAT_INPUT_ELEMENT}`, COLOR, layoutStyles.textColor);
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} ${MAT_SELECT} .mat-select-value`, COLOR, layoutStyles.textColor);
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} ${MAT_FORMFIELD}.mat-primary .mat-select-arrow`, COLOR, layoutStyles.textColor);

    // Error state colors
    const MAT_INPUT_INVALID_STATE = '.ng-dirty.ng-invalid:not(:disabled)';
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} .mat-error`, COLOR, input.errorTextColor);
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} ${MAT_FORMFIELD}.ng-dirty.ng-invalid ${MAT_INPUT_ELEMENT}`, BACKGROUND_COLOR, input.error.backgroundColor);
    this.constructStyleMap(
      `${ACCESSO_LIGHT_THEME} ${MAT_INPUT}${MAT_INPUT_INVALID_STATE} ${MAT_FORM_FIELD_FLEX}::after`,
      BORDER_COLOR,
      input.error.borderColor
    );
    this.constructStyleMap(
      `${ACCESSO_LIGHT_THEME} ${MAT_INPUT}${MAT_INPUT_INVALID_STATE} ${MAT_FORM_FIELD_FLEX}::before`,
      BACKGROUND_COLOR,
      input.error.backgroundColor
    );
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} ${MAT_FORMFIELD}.ng-dirty.ng-invalid ${MAT_INPUT_ELEMENT}`, CARET_COLOR, input.error.borderColor);
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} .mat-form-field-invalid ${MAT_INPUT_ELEMENT}`, CARET_COLOR, input.error.borderColor);

    // select dropdown
    const MAT_SELECT_INVALID = '.mat-select.ng-touched.mat-select-invalid.mat-select-empty';
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} ${MAT_SELECT_INVALID}`, BACKGROUND_COLOR, input.error.backgroundColor);
    this.constructStyleMap(`${ACCESSO_LIGHT_THEME} ${MAT_SELECT_INVALID}`, BORDER_COLOR, input.error.borderColor);
  }

  /**
   * Configure Modal Styles
   */
  configureModalStyles(): void {
    if (!validString(this.stylesConfig?.modalBackgroundColor)) {
      return;
    }
    const layoutStyles = validObject(this.stylesConfig?.layout) ? this.stylesConfig.layout : {};
    const ACCESSO_LIGHT_THEMED_DIALOG_CONTAINER = '.accesso-light-theme .mat-dialog-container';
    this.constructStyleMap(`${ACCESSO_LIGHT_THEMED_DIALOG_CONTAINER}`, 'backgroundColor', this.stylesConfig.modalBackgroundColor);
    this.constructStyleMap(`${ACCESSO_LIGHT_THEMED_DIALOG_CONTAINER} .container:first-child`, 'color', layoutStyles.textColor);
    this.constructStyleMap(`${ACCESSO_LIGHT_THEMED_DIALOG_CONTAINER} .container>.session`, 'color', layoutStyles.labelColor);
  }

  /**
   * Overrides mat form field padding
   */
  overrideMatFormFieldStyles(): void {
    if (!validObject(this.stylesConfig?.formPadding) || !validString(this.stylesConfig.formPadding.top) || !validString(this.stylesConfig.formPadding.bottom)) {
      return;
    }
    this.constructStyleMap(`.accesso-light-theme .mat-form-field-wrapper`, 'paddingBottom', this.stylesConfig.formPadding.bottom);
  }

  /**
   * Constructs a style map based on the main class
   * @param mainClass main class name
   * @param property style property
   * @param value style property value
   * @param override override by adding important
   */
  constructStyleMap(mainClass: string, property: string, value: string, override = false): void {
    if (validString(value) && validString(property)) {
      this.stylesMap.set(mainClass, `${this.stylesMap.get(mainClass) || ''}${this.mapToCSSStyleProperty(property)}:${value}${override ? ' !important' : ''};`);
    }
  }

  /**
   * Constructs style classes from style map
   */
  constructStyleClass(): string {
    let style = '';
    for (const [key, value] of this.stylesMap.entries()) {
      style += `${key}{${value}}`;
    }
    return style;
  }

  /**
   * Maps passed property to a valid style property
   * @param prop camel cased style property
   */
  mapToCSSStyleProperty(prop: string): string {
    switch (normalize(prop)) {
      case 'backgroundcolor':
        return 'background-color';
      case 'fontsize':
        return 'font-size';
      case 'color':
        return 'color';
      case 'bordercolor':
        return 'border-color';
      case 'stroke':
        return 'stroke';
      case 'caretcolor':
        return 'caret-color';
      case 'paddingtop':
        return 'padding-top';
      case 'paddingbottom':
        return 'padding-bottom';
      default:
        return '';
    }
  }

  /**
   * Appends custom styles to the style element
   */
  appendCustomStyles(): void {
    const styleEl = this.document.getElementById('custom-styles');
    if (styleEl) {
      styleEl.innerHTML = `<style>${this.constructStyleClass()}</style>`;
      styleEl.style.visibility = 'hidden';
    }
  }
}
