import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createSelector, Store } from '@ngrx/store';
import { validString } from 'guest-app-ui';
import { isFunction } from 'lodash-es';
import { EMPTY } from 'rxjs';
import { filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { sendPaymentComplete } from 'src/app/core/analytics/analytics.actions';
import { apiResponse, postApiRequest } from 'src/app/core/api/api.actions';
import { responseRequestType } from 'src/app/core/api/api.utilities';
import { CreateAccessoPaySessionResponse } from 'src/app/core/api/responses/create-accesso-pay-session';
import { InitiateAltPayRequest } from 'src/app/core/api/responses/initiate-alt-pay';
import { sendMessageAction } from 'src/app/core/application-bridge/application-bridge.actions';
import { PaymentComplete, PaymentFailed, PaymentInfo, PaymentType, SplitPaymentComplete } from 'src/app/core/application-bridge/application-bridge.models';
import { selectLanguage } from 'src/app/core/client-configuration/client-configuration.selectors';
import { selectDeviceInfo, selectIpAddress } from 'src/app/core/environment/environment.selectors';
import { WINDOW } from 'src/app/core/injection-token/window/window';
import { LocaleService } from 'src/app/core/locale/locale.service';
import { DialogData, NotificationDialogType } from 'src/app/core/notification/dialog/confirm-dialog/confirm-dialog.component';
import { showNotificationAction } from 'src/app/core/notification/notification.actions';
import { hidePageSpinner } from 'src/app/core/page-spinner/page-spinner.actions';
import { selectLineItemsAndFees } from 'src/app/core/parent-config/parent-config.selectors';
import { PaymentMethodCode } from 'src/app/core/payment-configuration/payment-configuration.model';
import { selectAlternatePayments } from 'src/app/core/payment-configuration/payment-configuration.selectors';
import { routeTo } from 'src/app/core/routing/routing.actions';
import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import { EVENT_PAYMENT_FAILED, EVENT_PAYMENT_REDIRECT } from 'src/app/shared/enums/application-bridge.enums';
import { selectPaymentAmount, selectTotalAmount } from 'src/app/shared/selectors/configuration.selectors';
import { getPaymentConfigurationByPaymentMethodCode, isAuthorised } from 'src/app/shared/utilities/alternate-payment.utils';
import { formatDemographicsAsUserDataToCardHolder } from 'src/app/shared/utilities/demographics.utils';
import { calculateSplitAmountWithDonation, convertDonationResponse } from 'src/app/shared/utilities/donation.utils';
import { calculateSplitAmountWithInsurance, convertInsuranceResponse } from 'src/app/shared/utilities/insurance.utils';
import { IS_IN_IFRAME } from 'src/app/shared/utilities/window.utils';
import { selectDelivery } from '../../delivery/delivery.selectors';
import { selectDonation, selectTicketProtection } from '../../extras/extras.selectors';
import { selectGiftCardState } from '../../gift-card/gift-card.selectors';
import { hasPreAuthGiftCards } from '../../gift-card/gift-card.utils';
import { initiateTmbThanachartAction } from '../../tmbthanachart/tmbthanachart.actions';
import { selectBillingDemographics, selectPaymentCompleteBillingDemographics, selectPreAuthGiftCardPaymentDetails } from '../billing.selectors';
import {
  confirmReadyForRedirectAction,
  giftCardSplitPaymentWithAlternatePaymentsSuccessAction,
  initializeGiftCardSplitPaymentWithAlternatePaymentsAction,
  initiateAlternatePaymentRedirectAction,
  initiateAlternatePaymentsAction,
  initiateAltPayFailureAction,
  initiateBanContactCardAlternatePaymentAction,
  prepareForAlternatePaymentRedirectAction,
  prepPaymentCompleteAction,
  renderAdyenWebComponentAction,
  renderCustomQRAction,
  sendInitiateAltPayRequestAction,
} from './alternate-payments.actions';
import { AlternatePaymentsService } from './alternate-payments.service';
import {
  AlternatePaymentRedirectPayload,
  AltPayRedirectDetails,
  buildDeliveryPayload,
  getCompleteInitiateAltPayRequest,
  hasValidQrData,
  hasValidRedirectParams,
  isAdyenWebComponentRequired,
  isCustomQrComponentRequired,
  isPendingWithoutRedirect,
  mapAltPayAuthResponseToPaymentComplete,
} from './alternate-payments.utils';

export const selectInitAltPayRequestInfo = createSelector(
  selectLineItemsAndFees,
  selectLanguage,
  selectTicketProtection,
  selectDonation,
  selectPaymentAmount,
  selectBillingDemographics,
  selectDeviceInfo,
  selectIpAddress,
  (lineItemsAndFees, language, insuranceState, donationState, paymentAmount, demographics, device, ipAddress) => ({
    lineItemsAndFees,
    language,
    insuranceState,
    donationState,
    paymentAmount,
    demographics,
    device,
    ipAddress,
  })
);

@Injectable()
export class AlternatePaymentsEffects {
  /**
   * Our constructor
   * @param actions$ The action stream.
   * @param store The store.
   * @param altPayService The alternate payment service
   * @param locales Our locale service
   */
  constructor(
    @Inject(WINDOW) private window: Window,
    private actions$: Actions,
    private store: Store<AppState>,
    private altPayService: AlternatePaymentsService,
    private locales: LocaleService
  ) {}

  /**
   * This effect is triggered when you want to initate an alternate payment.
   */
  initiateAlternatePayments$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initiateAlternatePaymentsAction),
        map(({ paymentType, paymentMerchantId, paymentMethodCode, paymentProvider, issuerId }) =>
          this.altPayService.initiateAltPay({ paymentType, paymentMerchantId, paymentMethodCode, issuerId, paymentProvider })
        )
      ),
    { dispatch: false }
  );

  /**
   * This effect is triggered when you want to initate an alternate payment.
   */
  initiateAltPayRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendInitiateAltPayRequestAction),
      withLatestFrom(this.store.select(selectInitAltPayRequestInfo)),
      map(
        ([
          { accessoPaySessionId = '', paymentDetails },
          { lineItemsAndFees, language, insuranceState, donationState, paymentAmount, demographics, device, ipAddress },
        ]) => {
          const { cartItems, fees } = lineItemsAndFees;
          const { screen, navigator, location } = this.getWindow();
          const { paymentMethodCode, paymentMerchantId, encryptedToken, paymentProvider, issuerId } = paymentDetails;
          const { insurance, amount: amountAfterInsurance } = calculateSplitAmountWithInsurance(paymentAmount, insuranceState);
          const { amount: totalSplitAmount, donation } = calculateSplitAmountWithDonation(amountAfterInsurance, donationState);

          let baseRequest: InitiateAltPayRequest = {
            language,
            amount: totalSplitAmount,
            origin: location.origin,
            store_url: location.origin,
            merchant_id: paymentMerchantId,
            payment_type: paymentMethodCode,
            return_url: this.altPayService.getRedirectUrl(accessoPaySessionId, paymentMethodCode, paymentProvider),
            cartItems,
            fees,
            device_type: device.type,
            color_depth: screen.colorDepth,
            java_enabled: isFunction(navigator.javaEnabled) ? navigator.javaEnabled() : false,
            screen_height: screen.height,
            screen_width: screen.width,
            user_agent: navigator.userAgent,
            shopper_ip: ipAddress,
            ...(validString(issuerId) && { issuer_id: issuerId }),
            ...(insurance && { insurance }),
            ...(donation?.donation_amount > 0 && { donation }),
          };

          if (insuranceState.isProtected && !insuranceState.splitInsurance) {
            baseRequest = { ...baseRequest, CARDHOLDER: formatDemographicsAsUserDataToCardHolder(demographics) };
          }

          const initiateAltPayRequest: InitiateAltPayRequest = getCompleteInitiateAltPayRequest(paymentProvider, baseRequest, encryptedToken, demographics);

          return postApiRequest({
            requestType: ApiRequestType.INITIATE_ALT_PAY,
            body: initiateAltPayRequest,
            params: { module: 'alternatePayments' },
            callbackData: paymentDetails,
          });
        }
      )
    )
  );

  /**
   * This effect is triggered when you want to initate an alternate payment.
   */
  initiateAlternatePayResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(apiResponse),
      filter(responseRequestType(ApiRequestType.INITIATE_ALT_PAY)),
      tap(({ response }) => this.altPayService.setInitiateAltPayResponse(response)),
      mergeMap(({ isOk, response, body, callbackData: paymentDetails }) => {
        if (isOk) {
          const { amount, donation, insurance } = body;
          const { paymentMethodCode, paymentMerchantId, paymentProvider } = <AltPayRedirectDetails>paymentDetails;
          const paymentType = response.payment_type ?? paymentDetails.paymentType;

          if (isAuthorised(response.result_code)) {
            // handle auth response and issue payment complete
            return [
              prepPaymentCompleteAction({
                payload: {
                  paymentDetails,
                  response,
                  amount,
                },
              }),
            ];
          }

          if (validString(response.redirect_url)) {
            // redirect_url in response indicates a full page redirect is required
            return [
              initiateAlternatePaymentRedirectAction({
                amount,
                authId: response.auth_id,
                method: response.http_method,
                paymentReference: response.paymentReference,
                url: response.redirect_url,
                paymentMethodCode,
                redirectParams: {
                  MD: response.md,
                  PaReq: response.pa_req,
                  TermUrl: response.term_url,
                },
                ...(donation && { donation }),
                ...(insurance && { insurance }),
              }),
            ];
          } else {
            // Check which component is needed to complete payment
            const isAdyenWebCompRequired = response.action && isAdyenWebComponentRequired(paymentProvider, paymentMethodCode);

            if (isAdyenWebCompRequired) {
              return [renderAdyenWebComponentAction({ payload: { paymentType } })];
            } else if (isPendingWithoutRedirect(paymentMethodCode)) {
              const isCustomQrCodeRequired = hasValidQrData(response) && isCustomQrComponentRequired(paymentMethodCode);
              if (isCustomQrCodeRequired) {
                return [
                  renderCustomQRAction({
                    payload: {
                      authId: response.auth_id,
                      paymentMethodCode,
                      paymentMerchantId,
                      paymentProvider,
                      qrData: response.qr_code_data,
                    },
                  }),
                ];
              } else {
                //  APERS flow -> send payment complete for pending payment
                return [prepPaymentCompleteAction({ payload: { amount, paymentDetails, response } })];
              }
            } else {
              // Last resort -> go to payment type page
              return [routeTo({ payload: ['select', 'alternatepayments', paymentType] })];
            }
          }
        } else {
          return [initiateAltPayFailureAction({ response })];
        }
      })
    )
  );

  /**
   * InitiateAtlPay Error Handling
   */
  initiateAlternatePayFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initiateAltPayFailureAction),
      switchMap(({ response }) => {
        const { error_code, error_msg } = response;
        const initiator = 'Alternate Payments: payment initiation error';
        const onClose = () => this.store.dispatch(routeTo({ payload: ['select'] }));
        const notificationPayload: DialogData = {
          buttonLabel: this.locales.get('common.close'),
          dialogType: NotificationDialogType.GENERAL,
          initiator,
          message: '',
          onClose,
        };
        if (validString(error_code)) {
          notificationPayload.message = this.locales.get(`errorcode.${error_code}`);
        } else if (validString(error_msg)) {
          notificationPayload.message = error_msg;
        } else {
          notificationPayload.message = this.locales.get(`alternatepayment.internalerror`);
        }
        return [
          sendMessageAction({
            key: EVENT_PAYMENT_FAILED,
            payload: <PaymentFailed>{
              errorCode: error_code,
              details: error_msg,
              title: '',
            },
          }),
          hidePageSpinner({ initiator }),
          showNotificationAction(notificationPayload),
        ];
      })
    )
  );

  /**
   * This effect is triggered when you want to initate BanContact Card alternate payment.
   */
  initiateBanContactCardAlternatePayment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initiateBanContactCardAlternatePaymentAction),
        map((action) =>
          this.altPayService.initiateAltPay({
            encryptedToken: action.token,
            paymentType: PaymentType.BANCONTACT_DESKTOP,
            paymentMerchantId: action.paymentMerchantId,
            paymentMethodCode: PaymentMethodCode.BANCONTACT_DESKTOP,
            paymentProvider: action.paymentProvider,
          })
        )
      ),
    { dispatch: false }
  );

  /**
   * This effect is triggered when you want to initate an alternate payment.
   */
  prepareForRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(prepareForAlternatePaymentRedirectAction),
      withLatestFrom(
        this.store.select(createSelector(selectGiftCardState, selectTotalAmount, (giftCardState, totalAmount) => ({ giftCardState, totalAmount })))
      ),
      map(([{ payload }, { giftCardState, totalAmount }]) => {
        if (giftCardState.totalBalance > 0 && !hasPreAuthGiftCards(giftCardState.giftcards)) {
          return initializeGiftCardSplitPaymentWithAlternatePaymentsAction({ amount: totalAmount, payload });
        } else {
          const { sessionRequest: body, paymentDetails: callbackData } = payload;
          return postApiRequest({
            requestType: ApiRequestType.ACCESSO_PAY_SESSIONS,
            body,
            params: { module: 'alternatePayments' },
            callbackData,
          });
        }
      })
    )
  );

  /**
   * Completes split payment
   */
  completeSplitPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(giftCardSplitPaymentWithAlternatePaymentsSuccessAction),
      withLatestFrom(this.store.select(selectGiftCardState)),
      map(([{ payload }, giftCardState]) => {
        const { sessionRequest: body, paymentDetails } = payload;
        const parsedBody = JSON.parse(body.payload);
        parsedBody.giftCardState = giftCardState;

        return postApiRequest({
          requestType: ApiRequestType.ACCESSO_PAY_SESSIONS,
          body: { ...body, payload: JSON.stringify(parsedBody) },
          params: { module: 'alternatePayments' },
          callbackData: paymentDetails,
        });
      })
    )
  );

  /**
   * This effect is triggered when a session token is created
   */
  onSessionCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(apiResponse),
      filter(responseRequestType(ApiRequestType.ACCESSO_PAY_SESSIONS)),
      filter((action) => action.params.module === 'alternatePayments'),
      switchMap(({ isOk, response, callbackData: paymentDetails }) => {
        if (isOk) {
          const { redirectSessionKey: accessoPaySessionId = '' } = <CreateAccessoPaySessionResponse>response;
          return [sendInitiateAltPayRequestAction({ accessoPaySessionId, paymentDetails })];
        } else {
          return [
            showNotificationAction({
              buttonLabel: this.locales.get('common.close'),
              dialogType: NotificationDialogType.GENERAL,
              initiator: 'alternate payment service: accesso-pay-session failure',
              message: this.locales.get(`altpayerror.initiatefailure`),
            }),
          ];
        }
      })
    )
  );

  /**
   * This effect is triggered when you want to initiate an alternate payment redirect.
   * We need to show a message to the user that they're going to be redirect. Once they confirm,
   * we'll send them off.
   */
  initiateAlternatePaymentRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initiateAlternatePaymentRedirectAction),
      map((action) => {
        const { locales } = this;

        return showNotificationAction({
          initiator: 'confirm alternate payment redirect',
          dialogType: NotificationDialogType.GENERAL,
          buttonLabel: locales.get('common.confirm'),
          message: locales.get('alternatepayment.confirmredirect'),
          onClose: () => {
            this.store.dispatch(confirmReadyForRedirectAction({ ...action }));
          },
        });
      })
    )
  );

  /**
   * This effect is triggered once a user confirms that they will be redirected to make their
   * payment.
   */
  confirmReadyToRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmReadyForRedirectAction),
      withLatestFrom(
        this.store.select(
          createSelector(selectAlternatePayments, selectPreAuthGiftCardPaymentDetails, (altPaymentConfigs, preAuthGiftCards) => ({
            altPaymentConfigs,
            preAuthGiftCards,
          }))
        )
      ),
      switchMap(([action, { preAuthGiftCards, altPaymentConfigs }]) => {
        const { amount, url, method, paymentReference, authId, paymentMethodCode, redirectParams, donation, insurance } = action;
        const { paymentMerchantId, rawCardBrand, paymentProviderName } = getPaymentConfigurationByPaymentMethodCode(altPaymentConfigs, paymentMethodCode);
        if (!IS_IN_IFRAME) {
          const win = this.getWindow();
          win.location.href = url;
          return EMPTY;
        } else {
          let payload = <any>{};
          const primaryPayment: AlternatePaymentRedirectPayload = {
            amount,
            authId,
            method,
            paymentReference,
            url,
            paymentMerchantId,
            paymentMethodCode,
            paymentProviderName,
            rawCardBrand,
            ...(donation && { donation: convertDonationResponse(donation) }),
            ...(insurance && { insurance: convertInsuranceResponse({ ...insurance, insurance_amount: insurance.premium_amount }) }),
            ...(hasValidRedirectParams(redirectParams) && { redirectParams }),
          };

          if (preAuthGiftCards.length) {
            const secondaryPayments = preAuthGiftCards.map(({ amount, authId, paymentReference, ...details }) => ({
              ...details.legacy,
              amount,
              paymentReference: authId, // same as payment reference
              ...(details.donation && { donation: details.donation }),
              ...(details.insurance && { insurance: details.insurance }),
            }));

            payload = [primaryPayment, ...secondaryPayments];
          } else {
            payload = { ...primaryPayment };
          }

          return [
            hidePageSpinner({
              initiator: 'alternate payment redirect parent',
            }),
            sendMessageAction({
              key: EVENT_PAYMENT_REDIRECT,
              payload,
            }),
          ];
        }
      })
    )
  );

  useAdyenWebComponent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(renderAdyenWebComponentAction),
      map(({ payload }) => {
        const { paymentType } = payload;
        return routeTo({ payload: ['select', 'alternatepayments', 'adyen-web-ui', paymentType] });
      })
    )
  );

  useCustomQRComponent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(renderCustomQRAction),
      map(({ payload }) => {
        // For now, TMBThanachart Bank is the only payment type that requires a custom QR rendering
        return initiateTmbThanachartAction({ payload });
      })
    )
  );

  prepPaymentComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(prepPaymentCompleteAction),
      withLatestFrom(
        this.store.select(
          createSelector(
            selectGiftCardState,
            selectPreAuthGiftCardPaymentDetails,
            selectPaymentCompleteBillingDemographics,
            selectDelivery,
            (giftCardState, preAuthGiftCards, demographics, deliveryState) => ({
              giftCardState,
              preAuthGiftCards,
              deliveryState,
              demographics,
            })
          )
        )
      ),
      map(([{ payload }, { giftCardState, preAuthGiftCards, deliveryState, demographics }]) => {
        const { paymentDetails, response: authResponse, amount } = payload;
        const isSplitPayment = !giftCardState.isGiftCardOnlyPayment && giftCardState.totalBalance > 0;
        const delivery = deliveryState?.deliveryMethodsConfigured && deliveryState.address ? buildDeliveryPayload(deliveryState.address) : null;

        let paymentCompletePayload = <PaymentComplete | SplitPaymentComplete>{};

        const paymentAuth = <PaymentInfo>{
          ...mapAltPayAuthResponseToPaymentComplete(authResponse, paymentDetails),
          amount,
          billing: demographics,
        };

        if (isSplitPayment) {
          paymentCompletePayload = <SplitPaymentComplete>{
            delivery,
            paymentInfo: [...preAuthGiftCards, paymentAuth],
          };
        } else {
          const { billing, ...authDetails } = paymentAuth;
          paymentCompletePayload = <PaymentComplete>{ ...authDetails, user: { billing, delivery } };
        }

        return sendPaymentComplete({ payload: paymentCompletePayload });
      })
    )
  );

  /**
   * Returns the windows option.
   * @returns The window object
   */
  getWindow(): Window {
    return this.window;
  }
}
