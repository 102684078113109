import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LogInterceptor } from './interceptor/log.interceptor';
import { EffectsModule } from '@ngrx/effects';
import { ApiEffects } from './api.effects';
import { HeaderInterceptor } from './interceptor/header.interceptor';
import { PollingService } from './polling/polling.service';
import { EnvironmentInfoInterceptor } from './interceptor/environment-info.interceptor';

@NgModule({
  declarations: [],
  imports: [EffectsModule.forFeature([ApiEffects])],
  providers: [
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
    },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: LogInterceptor,
    },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: EnvironmentInfoInterceptor,
    },
    PollingService,
  ],
})
export class ApiModule {}
