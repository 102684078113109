import { createAction, props } from '@ngrx/store';

import { ClientConfigurationState } from './client-configuration.state';

export enum ClientConfigurationActionTypes {
  SetLanguage = '[Client Configuration] Set Language',
  UpdateClientConfiguration = '[Client Configuration] Update',
  SetOriginUrl = '[Client Configuration] Set Origin',
}

export const updateClientConfiguration = createAction(ClientConfigurationActionTypes.UpdateClientConfiguration, props<{ payload: ClientConfigurationState }>());

export const setLanguage = createAction(ClientConfigurationActionTypes.SetLanguage, props<{ payload: string }>());

export const setOriginUrl = createAction(ClientConfigurationActionTypes.SetOriginUrl, props<{ payload: string }>());
